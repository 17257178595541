<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar una categoría</p>
    <p *ngIf="!modalData.edit">Crear una nueva categoría</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="sickLeaveCategoryClass.nombreOptions"
        [(ngModel)]="sickLeaveCategoryClass.createEditObj.nombre"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('nombre', sickLeaveCategoryClass.createEditObj.nombre)">
      </customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !sickLeaveCategoryClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!sickLeaveCategoryClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="sickLeaveCategoryClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>