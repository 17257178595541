<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar un sprint</p>
    <p *ngIf="!modalData.edit">Crear un nuevo sprint</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="createEditSprintClass.nameOptions"
        [(ngModel)]="createEditSprintClass.createEditObj.nombre"
        (change)="checkIfDataIsCompleted(); updateDataHandler('nombre', createEditSprintClass.createEditObj.nombre)">
      </customInput>
      <ng-container *ngIf="createEditSprintClass.isEditingEnabled">
        <div class="customFormContainer">
          <label for="">¿Que rango de tiempo quieres utilizar?<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <mat-radio-button value="true" [checked]="createEditSprintClass.isCustomRange"
            (change)="getRangeTypeSelectedValue($event)">Rango
            personalizado</mat-radio-button>
          <mat-radio-button value="false" [checked]="!createEditSprintClass.isCustomRange"
            (change)="getRangeTypeSelectedValue($event)">Rango
            predefinido</mat-radio-button>
        </div>
        <selector *ngIf="!createEditSprintClass.isCustomRange" [options]="createEditSprintClass.sprintPeriodOptions"
          [(ngModel)]="createEditSprintClass.createEditObj.rango"
          (change)="calculateDates();checkIfDataIsCompleted();  updateDataHandler('rango', createEditSprintClass.createEditObj.rango)">
        </selector>

        <div class="customFormContainer">
          <label for="">Fechas<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <div class="dateContainer">
            <mat-date-range-input [rangePicker]="picker" [min]="createEditSprintClass.minDate"
              [disabled]="!createEditSprintClass.isCustomRange ">
              <input matStartDate placeholder="dd/mm/yyyy" (dateChange)="getSprintDate($event, null)"
                [value]="createEditSprintClass.createEditObj.fechaInicio">
              <input matEndDate placeholder="dd/mm/yyyy" (dateChange)="getSprintDate(null, $event);"
                [value]="createEditSprintClass.createEditObj.fechaFin">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker">
              <img src="/assets/Icons/QuotationEngine/calendarioIcon.svg" alt="" matDatepickerToggleIcon>
            </mat-datepicker-toggle>
            <mat-date-range-picker [ngStyle]="{'display': 'none'}" #picker></mat-date-range-picker>
          </div>
        </div>
        <div class="customFormContainer">
          <label for="">Tipo de sprint<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <mat-radio-group>
            <mat-radio-button value="general" [checked]="this.createEditSprintClass.typeSelected == 'general'"
              (change)="getTypeSelectedValue($event)">General</mat-radio-button>
            <mat-radio-button value="proyectos" [checked]="this.createEditSprintClass.typeSelected == 'proyectos'"
              (change)="getTypeSelectedValue($event)">Por proyectos</mat-radio-button>
            <mat-radio-button value="departamentos"
              [checked]="this.createEditSprintClass.typeSelected == 'departamentos'"
              (change)="getTypeSelectedValue($event)">Por
              departamentos</mat-radio-button>
            <mat-radio-button value="lineasNegocio"
              [checked]="this.createEditSprintClass.typeSelected == 'lineasNegocio'"
              (change)="getTypeSelectedValue($event)">Por
              línea de negocio</mat-radio-button>
          </mat-radio-group>

        </div>
        <ng-container *ngIf="createEditSprintClass.typeSelected == 'proyectos'">
          <selector [options]="createEditSprintClass.projectPeriodOptions"
            [(ngModel)]="createEditSprintClass.createEditObj.proyectos"
            (change)="checkIfDataIsCompleted();  updateDataHandler('proyectos', createEditSprintClass.createEditObj.proyectos)">
          </selector>
        </ng-container>
        <ng-container *ngIf="createEditSprintClass.typeSelected == 'departamentos'">
          <selector [options]="createEditSprintClass.departmentPeriodOptions"
            [(ngModel)]="createEditSprintClass.createEditObj.departamentos"
            (change)="checkIfDataIsCompleted();  updateDataHandler('departamentos', createEditSprintClass.createEditObj.departamentos)">
          </selector>
        </ng-container>
        <ng-container *ngIf="createEditSprintClass.typeSelected == 'lineasNegocio'">
          <selector [options]="createEditSprintClass.bussinesLineOptions"
            [(ngModel)]="createEditSprintClass.createEditObj.lineasNegocio"
            (change)="checkIfDataIsCompleted();  updateDataHandler('lineasNegocio', createEditSprintClass.createEditObj.lineasNegocio)">
          </selector>
        </ng-container>

      </ng-container>


    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createEditSprintClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!createEditSprintClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createEditSprintClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>