<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar una IP conocida</p>
    <p *ngIf="!modalData.edit">Crear una nueva IP conocida</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="ipClass.nameOptions" [(ngModel)]="ipClass.createEditKnownIP.nombre"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('nombre', ipClass.createEditKnownIP.nombre)">
      </customInput>
      <customInput [options]="ipClass.ipOptions" [(ngModel)]="ipClass.createEditKnownIP.ip"
        (change)="checkIfDataIsCompleted(); checkIfValidIp(); this.updateDataHandler('ip', ipClass.createEditKnownIP.ip)">
      </customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !ipClass.isCompleted && !modalData.edit}" (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!ipClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="ipClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>