export interface iBussinessLine {
  id?: number | null;
  nombre: string;
  descripcion: string;
  categorias: Array<any>;
  proyectos: Array<any>;
}

export class iBussinessLine {
  static convertFromCollection(ob: any): Array<iBussinessLine> {
    let iBussinessLineCollection: Array<iBussinessLine> = [];
    ob.forEach((element: any) => {
      iBussinessLineCollection.push(this.convertFromObject(element));
    });
    return iBussinessLineCollection;
  }

  static convertFromObject(ob: any): iBussinessLine {
    let iBussinessLineObj = new iBussinessLine();
    iBussinessLineObj.id = ob.id;
    iBussinessLineObj.nombre = ob.nombre;
    iBussinessLineObj.descripcion = ob.descripcion;
    iBussinessLineObj.categorias = ob.categorias ? ob.categorias : [];
    iBussinessLineObj.proyectos = ob.proyectos ? ob.proyectos : [];
    return iBussinessLineObj;
  }

  static createEmptyObject(): iBussinessLine {
    let iBussinessLineObj = new iBussinessLine();
    iBussinessLineObj.id = null;
    iBussinessLineObj.nombre = '';
    iBussinessLineObj.descripcion = '';
    iBussinessLineObj.categorias = [];
    iBussinessLineObj.proyectos = [];
    return iBussinessLineObj;
  }
}
