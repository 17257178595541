import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iFamily } from '../../Interfaces/Api/Family/iFamily';

export class FamilyCost {
  isCompleted: boolean = false;
  families: Array<iFamily> = [];
  familiesBackup: Array<iFamily> = [];
  isLoading: boolean = false;
  genericFilter: string = '';
  createEditObj: iFamily = {
    nombre: '',
  };
  nombreOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
}
