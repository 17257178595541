<div class="popupGeneralContainer">
  <div class="header">
    <p>Cambiar contraseña</p>
  </div>
  <div class="body">
    <customInput [options]="changePasswordClass.newPasswordOption"
      [(ngModel)]="changePasswordClass.passwordData.password"
      (input)="changePasswordClass.checkIsCompleteObjectHandler()"
      (change)="changePasswordClass.checkIfPasswordAreValidHandler()"></customInput>
    <customInput [options]="changePasswordClass.repeatNewPasswordOption"
      [(ngModel)]="changePasswordClass.passwordData.repeatPassword"
      (input)="changePasswordClass.checkIsCompleteObjectHandler()"
      (change)="changePasswordClass.checkIfPasswordAreValidHandler()"></customInput>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" (click)="updateSelfData()"
      [ngClass]="{'disabled': !changePasswordClass.isCompleteData || !changePasswordClass.isValidData, 'loading': changePasswordClass.isLoading}">

      <mat-icon *ngIf="!changePasswordClass.isLoading">check</mat-icon>
      <mat-spinner *ngIf="changePasswordClass.isLoading"></mat-spinner>
      <span>Finalizar</span>
    </btn>
  </div>
</div>