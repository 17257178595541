import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  StaticUtilitiesService,
  sha256,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/Services/Api/login.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedGuard implements CanActivate {
  constructor(
    private loginSE: LoginService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.loginSE.isLogged()) {
      this.loginSE.logOut();
      this.staticUtilitiesSE.goTo('/', slideNavigationTypes.slideToBottom);
      StaticUtilitiesService.showError(
        'Se ha producido un error al verificar el usuario',
        'Para acceder es necesario inciar sesión'
      );
      return false;
    }
    if (
      localStorage.getItem('role') === sha256('ROLE_USER') &&
      state.url !== '/home/inicio' &&
      localStorage.getItem('role') === sha256('ROLE_USER') &&
      state.url !== '/implicacion/daily' &&
      localStorage.getItem('role') === sha256('ROLE_USER') &&
      state.url !== '/implicacion' &&
      localStorage.getItem('role') === sha256('ROLE_USER') &&
      state.url !== '/ajustes-generales/mi-perfil'
    ) {
      this.loginSE.logOut();
      this.staticUtilitiesSE.goTo('/', slideNavigationTypes.slideToBottom);
      StaticUtilitiesService.showError(
        'Se ha producido un error de permisos',
        'Este usuario no tiene acceso a esta página'
      );
      return false;
    }
    return true;
  }
}
