import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iGeneralProjectDetail } from '../../Interfaces/Api/Project/iGeneralProjectDetail';
import { iSingleProjectInfo } from '../../Interfaces/Api/Project/iSingleProjectInfo';

export class EditProject {
  isLoading: boolean = false;
  isCompleted: boolean = false;
  projectToEdit: iSingleProjectInfo = iSingleProjectInfo.createEmptyObject();

  nameOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Nombre',
    classList: 'specialFormInput',
  };

  abreviationOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Abreviatura',
    classList: 'specialFormInput',
  };

  colorOption: iOptionsCustomInput = {
    type: 'color',
    placeholder: 'Escribe aquí...',
    label: 'Color',
    required: true,
    classList: 'specialFormInput',
  };
  managerOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
}
