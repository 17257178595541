import { environment } from 'src/environments/environment';
import { iDepartmentSelector } from '../Departments/iDepartmentSelector';

export interface iSimpleEmployee {
  id: number | null;
  name: string;
  surname?: string;
  profile?: string;
  username?: string;
  departamentos?: Array<iDepartmentSelector>;
}

export class iSimpleEmployee {
  static convertFromCollection(ob: any): Array<iSimpleEmployee> {
    let iSimpleEmployeeCollection: Array<iSimpleEmployee> = [];
    ob.forEach((element: any) => {
      iSimpleEmployeeCollection.push(this.convertFromObject(element));
    });
    return iSimpleEmployeeCollection;
  }

  static convertFromObject(ob: any): iSimpleEmployee {
    let iSimpleEmployeeObj = new iSimpleEmployee();
    iSimpleEmployeeObj.id = ob.id;
    iSimpleEmployeeObj.name = ob.nombre;
    iSimpleEmployeeObj.profile = ob.profile;
    iSimpleEmployeeObj.surname = ob.apellidos;
    iSimpleEmployeeObj.departamentos = ob.departamentos
      ? iDepartmentSelector.convertFromCollection(ob.departamentos)
      : [];

    return iSimpleEmployeeObj;
  }

  static createEmptyObject(): iSimpleEmployee {
    let iSimpleEmployeeObj = new iSimpleEmployee();
    iSimpleEmployeeObj.id = null;
    iSimpleEmployeeObj.name = '';
    return iSimpleEmployeeObj;
  }
}
