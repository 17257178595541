<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar un futurible</p>
    <p *ngIf="!modalData.edit">Crear un nuevo futurible</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="createEditFuturibleClass.nameOptions"
          [(ngModel)]="createEditFuturibleClass.createEditObj.nombre"
          (change)="updateDataHandler('nombre', createEditFuturibleClass.createEditObj.nombre);checkIfDataIsCompleted()">
        </customInput>
        <customInput [options]="createEditFuturibleClass.hourCostOptions"
          [(ngModel)]="createEditFuturibleClass.createEditObj.costeHora"
          (change)="updateDataHandler('costeHora', createEditFuturibleClass.createEditObj.costeHora);checkIfDataIsCompleted()">
        </customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="createEditFuturibleClass.anualCostOptions"
          [(ngModel)]="createEditFuturibleClass.createEditObj.costeAnual"
          (change)="updateDataHandler('costeAnual', createEditFuturibleClass.createEditObj.costeAnual);checkIfDataIsCompleted()">
        </customInput>
        <customInput [options]="createEditFuturibleClass.weekHoursOptions"
          [(ngModel)]="createEditFuturibleClass.createEditObj.horasSemanales"
          (change)="updateDataHandler('horasSemanales', createEditFuturibleClass.createEditObj.horasSemanales);checkIfDataIsCompleted()">
        </customInput>
      </div>
      <div class="columnDisplay">
        <div class="customFormContainer">
          <label for="">Categorias</label>
          <selector [options]="createEditFuturibleClass.categoryOptions"
            [(ngModel)]="createEditFuturibleClass.createEditObj.categoria"
            (change)="updateDataHandler('categoria', createEditFuturibleClass.createEditObj.categoria);checkIfDataIsCompleted()">
          </selector>
        </div>
        <div class="toggle">
          <label for="">Tipo del coste<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <toggleSubmenu [classList]="'black'" [elements]="createEditFuturibleClass.costType"
            [(ngModel)]="createEditFuturibleClass.optionSelected"
            (change)="setCostType();updateDataHandler('tipoCoste', createEditFuturibleClass.createEditObj.tipoCoste);checkIfDataIsCompleted()">
          </toggleSubmenu>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createEditFuturibleClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!createEditFuturibleClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createEditFuturibleClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>