import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalculateStimatedTime } from 'src/app/Shared/Class/Quoter/calculate-stimated-time';

@Component({
  selector: 'app-calculate-stimated-time-popup',
  templateUrl: './calculate-stimated-time-popup.component.html',
  styleUrls: ['./calculate-stimated-time-popup.component.scss'],
})
export class CalculateStimatedTimePopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  calculateStimatedTimeClass: CalculateStimatedTime =
    new CalculateStimatedTime();
  constructor(
    public dialogRef: MatDialogRef<CalculateStimatedTimePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  stimateTime() {
    this.calculateStimatedTimeClass.timeEstimated =
      (Number(this.calculateStimatedTimeClass.optimist) +
        Number(this.calculateStimatedTimeClass.realist! * 4) +
        Number(this.calculateStimatedTimeClass.pessimist)) /
      6;
    this.calculateStimatedTimeClass.deviation =
      (Number(this.calculateStimatedTimeClass.pessimist) -
        Number(this.calculateStimatedTimeClass.optimist)) /
      6;
    this.calculateStimatedTimeClass.timeFinal =
      Number(this.calculateStimatedTimeClass.timeEstimated) +
      Number(this.calculateStimatedTimeClass.deviationAmount);
    this.calculateStimatedTimeClass.moreDataOpened = true;
  }
  getDeviation(event) {
    switch (event) {
      case 0:
        this.calculateStimatedTimeClass.probability = 50;
        break;
      case 0.5:
        this.calculateStimatedTimeClass.probability = 65.15;
        break;
      case 1:
        this.calculateStimatedTimeClass.probability = 84.13;
        break;
      case 1.5:
        this.calculateStimatedTimeClass.probability = 93.32;
        break;
      case 2:
        this.calculateStimatedTimeClass.probability = 97.73;
        break;
      case 2.5:
        this.calculateStimatedTimeClass.probability = 99.38;
        break;
      case 3:
        this.calculateStimatedTimeClass.probability = 99.87;
        break;
      case -0.5:
        this.calculateStimatedTimeClass.probability = 30.85;
        break;
      case -1:
        this.calculateStimatedTimeClass.probability = 15.87;
        break;
      case -1.5:
        this.calculateStimatedTimeClass.probability = 6.68;
        break;
      case -2:
        this.calculateStimatedTimeClass.probability = 2.27;
        break;
      case -2.5:
        this.calculateStimatedTimeClass.probability = 0.62;
        break;
      case -3:
        this.calculateStimatedTimeClass.probability = 0.13;
        break;
    }
    this.calculateStimatedTimeClass.step = event;
    this.calculateStimatedTimeClass.deviationAmount =
      Number(this.calculateStimatedTimeClass.deviation) * Number(event);
    this.calculateStimatedTimeClass.timeFinal =
      this.calculateStimatedTimeClass.timeEstimated! +
      this.calculateStimatedTimeClass.deviationAmount;
  }
  send() {
    this.closePopup(this.calculateStimatedTimeClass.timeFinal);
  }
}
