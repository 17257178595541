import { iEmployeeList } from '../Employee/iEmployeeList';
import { iSimpleEmployee } from '../Employee/iSimpleEmployee';

export interface iRequestedHolidays {
  id: number | null;
  employee: iSimpleEmployee;
  requestedDays: number;
  startDate: string;
  endDate: string;
  remainingDays: number;
  employeesCoincidences: Array<iSimpleEmployee>;
}

export class iRequestedHolidays {
  static convertFromCollection(ob: any): Array<iRequestedHolidays> {
    let iSelfDataCollection: Array<iRequestedHolidays> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iRequestedHolidays {
    let iSelfDataObj = new iRequestedHolidays();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.employee = iSimpleEmployee.convertFromObject(ob.empleado);
    iSelfDataObj.requestedDays = ob.cantidadDias;
    iSelfDataObj.remainingDays = ob.diasRestantes;
    iSelfDataObj.endDate = ob.fechaFin;
    iSelfDataObj.startDate = ob.fechaIni;
    iSelfDataObj.employeesCoincidences = iSimpleEmployee.convertFromCollection(
      ob.coincidencias
    );

    return iSelfDataObj;
  }
}
