import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { PeriodService } from 'src/app/Services/Api/period.service';
import { period } from 'src/app/Shared/Class/Company/period';

@Component({
  selector: 'app-create-edit-period-popup',
  templateUrl: './create-edit-period-popup.component.html',
  styleUrls: ['./create-edit-period-popup.component.scss'],
})
export class CreateEditPeriodPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }
  periodClass: period = new period();

  constructor(
    public dialogRef: MatDialogRef<CreateEditPeriodPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private periodSE: PeriodService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.setInputLabel();
    this.periodClass.createEditPeriodObj = this.modalData.data;
    this.periodClass.backupCreateEditPeriodObj = JSON.parse(
      JSON.stringify(this.modalData.data)
    );
    this.periodClass.isCompleted = true;
  }
  setInputLabel() {
    switch (this.modalData.type) {
      case 'corto plazo':
        this.periodClass.periodOption.label = 'Corto plazo (semanas)';
        break;
      case 'medio plazo':
        this.periodClass.periodOption.label = 'Medio plazo (semanas)';
        break;
      case 'largo plazo':
        this.periodClass.periodOption.label = 'Largo plazo (semanas)';
        break;
    }
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.closePopup();
      }
    });
  }
  validateIfDataIsCorrectHandler(value: any) {
    switch (this.modalData.type) {
      case 'corto plazo':
        if (value) {
          this.update();
        } else {
          StaticUtilitiesService.showError(
            'Error al validar los campos',
            'El periodo a corto plazo es obligatorio y no puede estar vacío.'
          );
          this.periodClass.createEditPeriodObj.cortoPlazo =
            this.periodClass.backupCreateEditPeriodObj.cortoPlazo;
        }
        break;
      case 'medio plazo':
        if (value) {
          this.update();
        } else {
          StaticUtilitiesService.showError(
            'Error al validar los campos',
            'El periodo a medio plazo es obligatorio y no puede estar vacío.'
          );
          this.periodClass.createEditPeriodObj.medioPlazo =
            this.periodClass.backupCreateEditPeriodObj.medioPlazo;
        }
        break;
      case 'largo plazo':
        if (value) {
          this.update();
        } else {
          StaticUtilitiesService.showError(
            'Error al validar los campos',
            'El periodo a largo plazo es obligatorio y no puede estar vacío.'
          );
          this.periodClass.createEditPeriodObj.largoPlazo =
            this.periodClass.backupCreateEditPeriodObj.largoPlazo;
        }
        break;
    }
  }
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.periodClass.isLoading = false;
  }
  errorResponseHandler() {
    this.periodClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */

  update() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.periodSE.update(behaviorSubject, this.periodClass.createEditPeriodObj);
    this.periodClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successResponseHandler(res),
            error: false,
          },
          {
            method: this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
