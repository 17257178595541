import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CategoryProductService } from 'src/app/Services/Api/categoryProduct.service';
import { ProductService } from 'src/app/Services/Api/product.service';
import { TaxationService } from 'src/app/Services/Api/taxation.service';
import { Inventory } from 'src/app/Shared/Class/Products/inventory';

@Component({
  selector: 'app-create-edit-product-popup',
  templateUrl: './create-edit-product-popup.component.html',
  styleUrls: ['./create-edit-product-popup.component.scss'],
})
export class CreateEditProductPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  inventoryClass: Inventory = new Inventory();
  constructor(
    public dialogRef: MatDialogRef<CreateEditProductPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private productSE: ProductService,
    private categoryProductSE: CategoryProductService,
    private taxationSE: TaxationService
  ) {}

  ngOnInit(): void {
    this.getAllCategory();
    this.getAllTaxation();
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.inventoryClass.createEditObj.productos[0] = this.modalData.product;
    this.inventoryClass.createEditObj.productos[0].categorias =
      this.modalData.product.categoria.map((element) => {
        return element.id;
      });
    this.inventoryClass.backUpcreateEditObj.productos[0] = JSON.parse(
      JSON.stringify(this.modalData.product)
    );
    this.inventoryClass.backUpcreateEditObj.productos[0].categorias =
      this.modalData.product.categoria.map((element) => {
        return element.id;
      });
    this.inventoryClass.createEditObj.productos[0].fiscalidad =
      this.modalData.product.fiscalidad?.id;
    this.inventoryClass.isCompleted = true;
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  actionHubHandler() {
    !this.modalData.edit
      ? this.validateIfDataIsCorrectHandler()
      : this.closePopup();
  }
  validateIfDataIsCorrectHandler() {
    if (this.inventoryClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  checkIfDataIsCompleted() {
    const {
      nombre,
      cantidad,
      categorias,
      codigo,
      precio,
      precioCompra,
      fiscalidad,
    } = this.inventoryClass.createEditObj.productos[0];
    this.inventoryClass.isCompleted =
      !!nombre &&
      !!cantidad &&
      !!categorias &&
      codigo &&
      !!precio &&
      !!precioCompra &&
      !!fiscalidad;
  }

  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = {
        id: this.inventoryClass.createEditObj.productos[0].id,
      };
      switch (element) {
        case 'nombre':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo nombre es obligatorio y no puede estar vacío'
            );
            this.inventoryClass.createEditObj.productos[0].nombre =
              this.inventoryClass.backUpcreateEditObj.productos[0].nombre;
            this.inventoryClass.isCompleted = true;
          }
          break;
        case 'codigo':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo código es obligatorio y no puede estar vacío'
            );
            this.inventoryClass.createEditObj.productos[0].codigo =
              this.inventoryClass.backUpcreateEditObj.productos[0].codigo;
            this.inventoryClass.isCompleted = true;
          }
          break;
        case 'descripcion':
          obj[element] = value;
          this.update(obj);
          break;
        case 'categorias':
          if (value.length > 0) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo categorías es obligatorio y no puede estar vacío'
            );
            this.inventoryClass.createEditObj.productos[0].categorias = [
              ...this.inventoryClass.backUpcreateEditObj.productos[0]
                .categorias,
            ];
            this.inventoryClass.isCompleted = true;
          }
          break;
        case 'precio':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo precio es obligatorio y no puede estar vacío'
            );
            this.inventoryClass.createEditObj.productos[0].precio =
              this.inventoryClass.backUpcreateEditObj.productos[0].precio;
            this.inventoryClass.isCompleted = true;
          }
          break;
        case 'precioCompra':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo precio de compra es obligatorio y no puede estar vacío'
            );
            this.inventoryClass.createEditObj.productos[0].precio =
              this.inventoryClass.backUpcreateEditObj.productos[0].precio;
            this.inventoryClass.isCompleted = true;
          }
          break;
        case 'cantidad':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo cantidad es obligatorio y no puede estar vacío'
            );
            this.inventoryClass.createEditObj.productos[0].cantidad =
              this.inventoryClass.backUpcreateEditObj.productos[0].cantidad;
            this.inventoryClass.isCompleted = true;
          }
          break;
        default:
          obj[element] = value;
          this.update(obj);
          break;
      }
    }
  }

  /**
   * API RESPONSES
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.inventoryClass.isLoading = false;
    if (!this.modalData.edit) {
      setTimeout(() => {
        this.closePopup();
      }, 500);
    }
  }
  errorResponseHandler() {
    this.inventoryClass.isLoading = false;
  }
  successgetAllCategoryResponseHandler(res: iResultHttp) {
    this.inventoryClass.categoryProductOptions.items = res.data;
  }
  successgetAllTaxationResponseHandler(res: iResultHttp) {
    this.inventoryClass.taxationOptions.items = res.data;
  }

  /**
   * CALLS TO API
   */
  getAllCategory() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.categoryProductSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successgetAllCategoryResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  getAllTaxation() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.taxationSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successgetAllTaxationResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.productSE.create(behaviorSubject, this.inventoryClass.createEditObj);
    this.inventoryClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.productSE.update(behaviorSubject, obj);
    this.inventoryClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
