<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="!modalData.edit">Crear una nueva tarea</p>
    <p *ngIf="modalData.edit">Editar una tarea</p>
  </div>
  <div class="body">
    <div class="columnDisplay">
      <customInput [options]="createDailyClass.nameOption" [(ngModel)]="createDailyClass.newDaily.nombreTarea"
        (change)="createDailyClass.checkIfIsCompleteData(); updateDataHandler('nombre',createDailyClass.newDaily.nombreTarea)">
      </customInput>
      <customInput [options]="createDailyClass.dateOption"
        (change)="getDate($event);createDailyClass.checkIfIsCompleteData();updateDataHandler('fecha',this.createDailyClass.newDaily.fecha); createDailyClass.updateRecurrencyLabel()"
        [selectedDate]="selectedDate">
      </customInput>
    </div>

    <ng-container *ngIf="!modalData.edit">
      <div class="columnDisplay">

        <div class="customFormContainer">
          <label for="">Empleado<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [options]="createDailyClass.employeesOption" [(ngModel)]="createDailyClass.newDaily.usuario"
            (change)="createDailyClass.userId = createDailyClass.newDaily.usuario!; getAllProcessByUser(); this.createDailyClass.newDaily.proyecto = null; this.createDailyClass.newDaily.proceso= null;createDailyClass.checkIfIsCompleteData()"
            [ngClass]="{'readOnly': !createDailyClass.isAdmin}">
          </selector>
        </div>
        <div class="customFormContainer">
          <label for="">Proyecto</label>
          <selector [options]="createDailyClass.proyectOption" [(ngModel)]="createDailyClass.newDaily.proyecto"
            (change)="clearData();createDailyClass.filterProyectProcesses(); createDailyClass.filterProyectSubProcesses();createDailyClass.checkIfIsCompleteData()">
          </selector>
        </div>
      </div>
      <div class="columnDisplay">

        <div class="customFormContainer">
          <label for="">Proceso</label>
          <selector [options]="createDailyClass.processOption" [(ngModel)]="createDailyClass.newDaily.proceso"
            [ngClass]="{'readOnly': createDailyClass.newDaily.proyecto == null || createDailyClass.newDaily.subProceso}"
            (change)="createDailyClass.checkIfIsCompleteData()"></selector>
        </div>

        <div class="customFormContainer">
          <label for="">Tarea</label>
          <selector [options]="createDailyClass.subprocessOption" [(ngModel)]="createDailyClass.newDaily.subProceso"
            [ngClass]="{'readOnly': createDailyClass.newDaily.proyecto == null || createDailyClass.newDaily.proceso}"
            (change)="updateName();createDailyClass.checkIfIsCompleteData()"></selector>
        </div>
      </div>
    </ng-container>

    <div class="columnDisplay">
      <customInput [options]="createDailyClass.hoursOption" [(ngModel)]="createDailyClass.newDaily.horas"
        (change)="createDailyClass.updateEstimatedHours(); createDailyClass.checkIfIsCompleteData(); updateDataHandler('horasEstimadas',this.createDailyClass.newDaily.horasEstimadas)">
      </customInput>
      <customInput [options]="createDailyClass.minutesOption" [(ngModel)]="createDailyClass.newDaily.minutos"
        (change)="createDailyClass.updateEstimatedHours(); createDailyClass.checkIfIsCompleteData(); updateDataHandler('horasEstimadas',this.createDailyClass.newDaily.horasEstimadas)">
      </customInput>
    </div>
    <div class="recurringContainer" *ngIf="!modalData.edit">
      <mat-checkbox [(ngModel)]="createDailyClass.isRecurring"
        (change)="createDailyClass.updateRecurrencyLabel()"></mat-checkbox>
      <span for="">¿Quieres marcar esta tarea como recurrente?</span>
    </div>
    <div class="recurringContainer" *ngIf="modalData.edit && modalData.daily.codigo">
      <mat-checkbox [(ngModel)]="createDailyClass.isRecurring"
        (change)="createDailyClass.updateRecurrencyLabel()"></mat-checkbox>
      <span for="">Modificar periodo de concurrencia</span>
    </div>
    <ng-container *ngIf="createDailyClass.isRecurring">
      <div class="recurringData">
        <selector class="fullWidth" [options]="createDailyClass.recurrencyOption"
          [(ngModel)]="createDailyClass.recurrencySelected"></selector>
        <customInput [options]="createDailyClass.endDateOption" (change)="getEndDate($event);">
        </customInput>
      </div>
    </ng-container>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action"
      (click)="this.calculateDayRange();modalData.edit ? this.closePopup(this.createDailyClass.newDaily.rango): createDailyRange();"
      [ngClass]="{'disabled': !createDailyClass.isCompleteData, 'loading': createDailyClass.isLoading}">
      <mat-spinner *ngIf="createDailyClass.isLoading"></mat-spinner>
      <mat-icon *ngIf="!createDailyClass.isLoading">check</mat-icon>
      <span>Finalizar</span>
    </btn>
  </div>
</div>