import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iTaxation } from '../../Interfaces/Api/Taxation/iTaxation';

export class Taxation {
  isCompleted: boolean = false;
  taxations: Array<iTaxation> = [];
  taxationsBackup: Array<iTaxation> = [];
  isLoading: boolean = false;
  genericFilter: string = '';
  createEditObj: iTaxation = {
    nombre: '',
    valor: null,
    articulo: '',
  };
  backupCreateEditObj: iTaxation = {
    nombre: '',
    valor: null,
    articulo: '',
  };
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  valueOption: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Valor (%)',
    required: true,
    classList: 'specialFormInput',
  };
  legalArticleOption: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Artículo legal',
    classList: 'specialFormInput',
  };
}
