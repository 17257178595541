import { iBussinessLine } from '../BussinesLine/iBussinessLine';
import { iSimpleClient } from '../Client/iSimpleClient';
import { iSimpleEmployee } from '../Employee/iSimpleEmployee';

export interface iSingleProjectInfo {
  id: number | null;
  nombre: string;
  abreviatura: string;
  etiqueta: string;
  fase: string;
  estado: number;
  responsable: iSimpleEmployee | null;
  presupuesto: number | null;
  cliente: iSimpleClient | null;
  multiplicador: number | null;
  fechaFin: string;
  fechaInicio: string;
  lineaNegocio: Array<{ id: number; nombre: string }>;
}

export class iSingleProjectInfo {
  static convertFromCollection(ob: any): Array<iSingleProjectInfo> {
    let iSelfDataCollection: Array<iSingleProjectInfo> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iSingleProjectInfo {
    let iSelfDataObj = new iSingleProjectInfo();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.abreviatura = ob.abreviatura;
    iSelfDataObj.cliente = ob.cliente
      ? iSimpleClient.convertFromObject(ob.cliente)
      : null;
    iSelfDataObj.estado = ob.estado;
    iSelfDataObj.etiqueta = ob.etiqueta;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.fechaFin = ob.fechaFin;
    iSelfDataObj.fechaInicio = ob.fechaIni;
    iSelfDataObj.presupuesto = ob.presupuesto;
    iSelfDataObj.multiplicador = ob.multiplicador;
    iSelfDataObj.lineaNegocio = ob.lineaNegocio;
    iSelfDataObj.responsable = ob.responsable
      ? iSimpleEmployee.convertFromObject(ob.responsable)
      : null;
    iSelfDataObj.fase = ob.fase;
    return iSelfDataObj;
  }

  static createEmptyObject(): iSingleProjectInfo {
    let iSelfDataObj = new iSingleProjectInfo();
    iSelfDataObj.id = null;
    iSelfDataObj.abreviatura = '';
    iSelfDataObj.cliente = null;
    iSelfDataObj.etiqueta = '';
    iSelfDataObj.nombre = '';
    iSelfDataObj.fechaFin = '';
    iSelfDataObj.fechaInicio = '';
    iSelfDataObj.presupuesto = null;
    iSelfDataObj.responsable = null;
    iSelfDataObj.multiplicador = null;
    iSelfDataObj.lineaNegocio = [];
    iSelfDataObj.fase = '';
    return iSelfDataObj;
  }
}
