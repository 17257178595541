import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import * as XLSX from 'xlsx';
import * as Diacritics from 'diacritics';
import { filter, takeUntil } from 'rxjs';
import { CategoryProductService } from 'src/app/Services/Api/categoryProduct.service';
import { iCategoryProduct } from 'src/app/Shared/Interfaces/Api/Products/iCategoryProduct';
import { TaxationService } from 'src/app/Services/Api/taxation.service';
import { iTaxation } from 'src/app/Shared/Interfaces/Api/Taxation/iTaxation';
@Component({
  selector: 'app-manage-product-excel',
  templateUrl: './manage-product-excel.component.html',
  styleUrls: ['./manage-product-excel.component.scss'],
})
export class ManageProductExcelComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  formatos: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  file: any = null;
  newProducts: any = {
    productos: [],
  };
  categoryProducts: Array<iCategoryProduct> = [];
  taxations: Array<iTaxation> = [];
  validKeys: Array<string> = [
    'codigo',
    'nombre',
    'descripcion',
    'precio venta',
    'precio compra',
    'fiscalidad',
    'cantidad',
    'categorias',
  ];
  constructor(
    public dialogRef: MatDialogRef<ManageProductExcelComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private categoryProductSE: CategoryProductService,
    private taxationSE: TaxationService
  ) {}

  ngOnInit(): void {
    this.getAllCategory();
    this.getAllTaxation();
    this.clickOutClosePopupHandler();
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  excelDataTreatmentHandler(element) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(element.file);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      this.getDataExcel(data);
    };
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  downloadTemplate() {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'href',
      '/assets/Documents/plantilla-productos.xlsx'
    );
    downloadLink.setAttribute('download', 'plantilla-productos');
    downloadLink.click();
  }
  /**
   * GETTERS & SETTERS
   */
  getFile(event: any) {
    let BreakException = {};
    try {
      event.forEach((element: any) => {
        if (!this.formatos.includes(element.file.type)) {
          StaticUtilitiesService.showError('El formato no es válido');
          this.file = null;
          throw BreakException;
        }
        if (element.file.size > 10485760) {
          StaticUtilitiesService.showError('El archivo es demasiado pesado');
          this.file = null;
          throw BreakException;
        }

        this.excelDataTreatmentHandler(element);
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }
  getDataExcel(data: any) {
    this.newProducts.productos = [];
    var hasError: boolean = false;
    data.forEach((element) => {
      const product: any = {};
      Object.keys(element).forEach((key) => {
        const normalizedKey = Diacritics.remove(key.toLocaleLowerCase());
        if (!this.checkIfKeysAreValid(normalizedKey)) {
          hasError = true;
          return;
        }

        product[normalizedKey] = element[key];
      });
      if (!hasError) {
        if (product.categorias.includes(',')) {
          const categoriesSelected = product.categorias.split(',');
          const auxArrayIds: Array<iCategoryProduct> = [];
          categoriesSelected.forEach((category) => {
            auxArrayIds.push(
              this.categoryProducts.filter((auxCategory) =>
                auxCategory.nombre
                  .toLocaleLowerCase()
                  .includes(category.toLocaleLowerCase().trim())
              )[0]
            );
            product.categorias = auxArrayIds;
          });
        } else {
          product.categorias = [
            this.categoryProducts.filter((category) =>
              category.nombre
                .toLocaleLowerCase()
                .includes(product.categorias.toLocaleLowerCase().trim())
            )[0],
          ];
        }
        const filteredTaxation: Array<iTaxation> = this.taxations.filter(
          (taxation) => product['fiscalidad'] * 100 == taxation.valor
        );
        product['auxFiscalidad'] =
          filteredTaxation.length > 0 ? filteredTaxation[0] : null;
        product['precio'] = product['precio venta'];
        product['precioCompra'] = product['precio compra'];

        this.newProducts.productos.push(product);
      }
    });
    if (hasError) {
      StaticUtilitiesService.showError(
        'El formato del fichero no es válido.',
        'Algunas de las columnas en el archivo Excel cargado no son válidas.'
      );
      return;
    }
    this.closePopup(this.newProducts);
  }

  /**
   * HANDLERS
   */
  checkIfKeysAreValid(normalizedKey: string) {
    return this.validKeys.includes(normalizedKey);
  }

  /**
   * API RESPONSES
   */
  successgetAllCategoryResponseHandler(res: iResultHttp) {
    this.categoryProducts = res.data;
  }
  successgetAllTaxationResponseHandler(res: iResultHttp) {
    this.taxations = [...res.data];
  }

  /**
   * CALLS TO API
   */
  getAllCategory() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.categoryProductSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successgetAllCategoryResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  getAllTaxation() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.taxationSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successgetAllTaxationResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
}
