import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ClientService } from 'src/app/Services/Api/client.service';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';

@Component({
  selector: 'app-add-multiple-clients-revision-popup',
  templateUrl: './add-multiple-clients-revision-popup.component.html',
  styleUrls: ['./add-multiple-clients-revision-popup.component.scss'],
})
export class AddMultipleClientsRevisionPopupComponent implements OnInit {
  selectedClients: Array<any> = [];
  isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddMultipleClientsRevisionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private clientSE: ClientService,
    private popupSE: PopupService
  ) {}

  ngOnInit(): void {}
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  removeSelectedClients() {
    this.selectedClients.forEach((element) => {
      this.modalData.clients.splice(this.modalData.clients.indexOf(element), 1);
    });
    StaticUtilitiesService.showFeedback(
      'Se han eliminado los clientes seleccionados correctamente'
    );
    this.modalData.clients = [...this.modalData.clients];
    if (this.modalData.clients.length === 0) {
      this.forceClosePopup();
    }
  }

  /**
   * GETTERS && SETTERS
   */
  getSelectedClients(selectedEmployee: any) {
    this.selectedClients = [...selectedEmployee];
  }

  /**
   * HANDLERS
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorResponseHandler() {
    this.isLoading = false;
  }
  successCreateResponseHandler(res) {
    StaticUtilitiesService.showFeedback(
      `Se han creado los ${this.modalData.clients.length} clientes correctamente`
    );
    this.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorCreateResponseHandler() {
    this.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.clientSE.create(behaviorSubject, {
      clientes: this.modalData.clients,
    });
    this.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
