import { iSimpleClient } from '../Client/iSimpleClient';
import { iSimpleProject } from '../Project/iSimpleProject';

export interface iSingleClientInvoice {
  id: number | null;
  fecha: string;
  fechaEmision: string;
  pagada: boolean;
  url: string | null;
  fechaPago: string;
  cantidad: number | null;
  concepto: string;
  proyecto: iSimpleProject | null;
  numero: string;
  iva: number | null;
  irpf: number | null;
  cliente?: iSimpleClient | null;
}

export class iSingleClientInvoice {
  static convertFromCollection(ob: any): Array<iSingleClientInvoice> {
    let iSingleClientInvoiceCollection: Array<iSingleClientInvoice> = [];
    ob.forEach((element: any) => {
      iSingleClientInvoiceCollection.push(this.convertFromObject(element));
    });
    return iSingleClientInvoiceCollection;
  }

  static convertFromObject(ob: any): iSingleClientInvoice {
    let iSingleClientInvoiceObj = new iSingleClientInvoice();
    iSingleClientInvoiceObj.id = ob.id;
    iSingleClientInvoiceObj.fecha = ob.fecha;
    iSingleClientInvoiceObj.fechaEmision = ob.fechaEmision;
    iSingleClientInvoiceObj.pagada = ob.pagada;
    iSingleClientInvoiceObj.url = ob.url;
    iSingleClientInvoiceObj.fechaPago = ob.fechaPago;
    iSingleClientInvoiceObj.cantidad = ob.cantidad;
    iSingleClientInvoiceObj.concepto = ob.concepto;
    iSingleClientInvoiceObj.proyecto = iSimpleProject.convertFromObject(
      ob.proyecto
    );
    iSingleClientInvoiceObj.numero = ob.numero;
    iSingleClientInvoiceObj.iva = ob.iva;
    iSingleClientInvoiceObj.irpf = ob.irpf;
    return iSingleClientInvoiceObj;
  }

  static createEmptyObject(): iSingleClientInvoice {
    let iSingleClientInvoiceObj = new iSingleClientInvoice();
    iSingleClientInvoiceObj.id = null;
    iSingleClientInvoiceObj.fecha = '';
    iSingleClientInvoiceObj.fechaEmision = '';
    iSingleClientInvoiceObj.pagada = false;
    iSingleClientInvoiceObj.url = null;
    iSingleClientInvoiceObj.fechaPago = '';
    iSingleClientInvoiceObj.cantidad = null;
    iSingleClientInvoiceObj.concepto = '';
    iSingleClientInvoiceObj.proyecto = null;
    iSingleClientInvoiceObj.numero = '';
    iSingleClientInvoiceObj.iva = null;
    iSingleClientInvoiceObj.irpf = null;
    return iSingleClientInvoiceObj;
  }
}
