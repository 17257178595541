export interface iProjectIncidences {
  descripcion: string;
  fecha: string;
  tipo: { icon: string; nombre: string } | null;
}

export class iProjectIncidences {
  static convertFromCollection(ob: any): Array<iProjectIncidences> {
    let iSelfDataCollection: Array<iProjectIncidences> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iProjectIncidences {
    let iSelfDataObj = new iProjectIncidences();
    iSelfDataObj.descripcion = ob.descripcion;
    iSelfDataObj.fecha = ob.fecha;
    iSelfDataObj.tipo = ob.tipo;
    return iSelfDataObj;
  }

  static createEmptyObject(): iProjectIncidences {
    let iSelfDataObj = new iProjectIncidences();
    iSelfDataObj.descripcion = '';
    iSelfDataObj.fecha = '';
    iSelfDataObj.tipo = null;
    return iSelfDataObj;
  }
}
