export interface iSickLeaveCategory {
  id?: number | null;
  nombre: string;
}

export class iSickLeaveCategory {
  static convertFromCollection(ob: any): Array<iSickLeaveCategory> {
    let iSickLeaveCategoryCollection: Array<iSickLeaveCategory> = [];
    ob.forEach((element: any) => {
      iSickLeaveCategoryCollection.push(this.convertFromObject(element));
    });
    return iSickLeaveCategoryCollection;
  }

  static convertFromObject(ob: any): iSickLeaveCategory {
    let iSickLeaveCategoryObj = new iSickLeaveCategory();
    iSickLeaveCategoryObj.id = ob.id;
    iSickLeaveCategoryObj.nombre = ob.nombre;
    return iSickLeaveCategoryObj;
  }

  static createEmptyObject(): iSickLeaveCategory {
    let iSickLeaveCategoryObj = new iSickLeaveCategory();
    iSickLeaveCategoryObj.id = null;
    iSickLeaveCategoryObj.nombre = '';

    return iSickLeaveCategoryObj;
  }
}
