import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { VacationalPeriodService } from 'src/app/Services/Api/vacational-period.service';
import { VacationsPeriod } from 'src/app/Shared/Class/Vacations/vacations-period';

@Component({
  selector: 'app-create-edit-vacational-period-popup',
  templateUrl: './create-edit-vacational-period-popup.component.html',
  styleUrls: ['./create-edit-vacational-period-popup.component.scss'],
})
export class CreateEditVacationalPeriodPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  vacationsPeriodClass: VacationsPeriod = new VacationsPeriod();

  constructor(
    public dialogRef: MatDialogRef<CreateEditVacationalPeriodPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private vacationalPeriodSE: VacationalPeriodService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.vacationsPeriodClass.createEditObj = this.modalData.vacationalPeriod;
    this.vacationsPeriodClass.backUpCreateEditObj = JSON.parse(
      JSON.stringify(this.modalData.vacationalPeriod)
    );
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    !this.modalData.edit
      ? this.validateIfDataIsCorrectHandler()
      : this.modalData.edit
      ? this.closePopup()
      : this.forceClosePopup();
  }
  validateIfDataIsCorrectHandler() {
    if (this.vacationsPeriodClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = { id: this.vacationsPeriodClass.createEditObj.id };
      switch (element) {
        case 'nombre':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo nombre es obligatorio y no puede estar vacío.'
            );
            this.vacationsPeriodClass.createEditObj.nombre =
              this.vacationsPeriodClass.backUpCreateEditObj.nombre;
          }
          break;
        case 'dias':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo días es obligatorio y no puede estar vacío.'
            );
            this.vacationsPeriodClass.createEditObj.dias =
              this.vacationsPeriodClass.backUpCreateEditObj.dias;
          }
          break;
        default:
          obj[element] = value;
          this.update(obj);
          break;
      }
    }
  }
  checkIfDataIsCompleted() {
    this.vacationsPeriodClass.isCompleted =
      StaticUtilitiesService.isCompleteObject(
        this.vacationsPeriodClass.createEditObj
      );
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha creado el tipo de vacaciones correctamente'
    );
    this.vacationsPeriodClass.isLoading = false;
    this.closePopup();
  }
  errorCreateResponseHandler() {
    this.vacationsPeriodClass.isLoading = false;
  }
  successUpdateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha editado el tipo de vacaciones correctamente'
    );
    this.vacationsPeriodClass.isLoading = false;
  }
  errorUpdateResponseHandler() {
    this.vacationsPeriodClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.vacationalPeriodSE.create(
      behaviorSubject,
      this.vacationsPeriodClass.createEditObj
    );
    this.vacationsPeriodClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.vacationalPeriodSE.update(behaviorSubject, obj);
    this.vacationsPeriodClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorUpdateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
