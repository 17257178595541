export interface iProjectUser {
  id: number | null;
  nombre: string;
  apellidos: string;
  cant: number | null;
}

export class iProjectUser {
  static convertFromCollection(ob: any): Array<iProjectUser> {
    let iSelfDataCollection: Array<iProjectUser> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iProjectUser {
    let iSelfDataObj = new iProjectUser();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.apellidos = ob.apellidos;
    iSelfDataObj.cant = ob.cant;
    return iSelfDataObj;
  }

  static createEmptyObject(): iProjectUser {
    let iSelfDataObj = new iProjectUser();
    iSelfDataObj.id = null;
    iSelfDataObj.nombre = '';
    iSelfDataObj.apellidos = '';
    iSelfDataObj.cant = null;
    return iSelfDataObj;
  }
}
