export interface iFunnelProject {
  id: number | null;
  nombre: string;
  etiqueta: string;
  horas: number | null;
  presupuesto: number | null;
}

export class iFunnelProject {
  static convertFromCollection(ob: any): Array<iFunnelProject> {
    let iSelfDataCollection: Array<iFunnelProject> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iFunnelProject {
    let iSelfDataObj = new iFunnelProject();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.etiqueta = ob.etiqueta;
    iSelfDataObj.horas = ob.horas;
    iSelfDataObj.presupuesto = ob.presupuesto;
    return iSelfDataObj;
  }

  static createEmptyObject(): iFunnelProject {
    let iSelfDataObj = new iFunnelProject();
    iSelfDataObj.id = null;
    iSelfDataObj.nombre = '';
    iSelfDataObj.etiqueta = '';
    iSelfDataObj.horas = null;
    iSelfDataObj.presupuesto = null;
    return iSelfDataObj;
  }
}
