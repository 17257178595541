<card [imageActive]="false">
  <ng-container bodyContent>
    <div class="header">
      <span *ngIf="data.project.abbreviation !== ''">{{data.project.abbreviation}}</span>
      <span *ngIf="data.project.abbreviation === ''">-</span>
      <span>{{data.date | date:"dd MMMM YYYY"}}</span>
    </div>
    <div class="body">
      <span><b>Proceso: </b>
        <ng-container *ngIf="data.process !== '' ">
          {{data.process}}
        </ng-container>
        <ng-container *ngIf="data.process === '' ">
          -
        </ng-container>
      </span>
      <span><b>Tarea: </b>
        <ng-container *ngIf="data.taskName !== '' ">
          {{data.taskName}}
        </ng-container>
        <ng-container *ngIf="data.taskName === '' ">
          -
        </ng-container>x
      </span>
    </div>
    <div class="footer">
      <btn class="action" (click)="showReasonsEmitter.emit(true);">
        <ng-container>
          <mat-icon>close</mat-icon>
        </ng-container>
      </btn>
      <btn class="done" (click)="showReasonsEmitter.emit(false);completeDailyHandler()">
        <ng-container>
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <mat-icon *ngIf="!isLoading">done</mat-icon>
        </ng-container>
      </btn>
    </div>
  </ng-container>
</card>