import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { SickLeaveCategoryService } from 'src/app/Services/Api/sick-leave-category.service';
import { SickLeaveService } from 'src/app/Services/Api/sick-leave.service';
import { CreateSickLeave } from 'src/app/Shared/Class/SickLeaves/create-sick-leave';

@Component({
  selector: 'app-create-sick-leave-popup',
  templateUrl: './create-sick-leave-popup.component.html',
  styleUrls: ['./create-sick-leave-popup.component.scss'],
})
export class CreateSickLeavePopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  createSickLeaveClass: CreateSickLeave = new CreateSickLeave();
  constructor(
    public dialogRef: MatDialogRef<CreateSickLeavePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private sickLeaveSE: SickLeaveService,
    private sickLeaveCategorySE: SickLeaveCategoryService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.getAll();
    this.createSickLeaveClass.createSickLeaveObj.empleado = this.modalData;
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  checkIfDataIsCompleted() {
    this.createSickLeaveClass.isCompleted =
      StaticUtilitiesService.isCompleteObject(
        this.createSickLeaveClass.createSickLeaveObj
      );
  }
  successGetAllResponseHandler(res: iResultHttp) {
    this.createSickLeaveClass.categoryOptions.items = [...res.data];
  }
  parseDatesHandler() {
    this.createSickLeaveClass.createSickLeaveObj.fechaIni = new iDate(
      iDate.toPhp(new Date(this.createSickLeaveClass.date))
    ).toStringDate('JAP');
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.createSickLeaveClass.isLoading = false;
    this.closePopup();
  }
  errorCreateResponseHandler(res: iResultHttp) {
    this.createSickLeaveClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  getAll() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sickLeaveCategorySE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.parseDatesHandler();
    this.sickLeaveSE.create(
      behaviorSubject,
      this.createSickLeaveClass.createSickLeaveObj
    );
    this.createSickLeaveClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
}
