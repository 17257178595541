import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iFuturibleCategory } from '../../Interfaces/Api/Futurible/iFuturibleCategory';

export class FuturibleCategory {
  isCompleted: boolean = false;
  categories: Array<iFuturibleCategory> = [];
  categoriesBackup: Array<iFuturibleCategory> = [];
  isLoading: boolean = false;
  genericFilter: string = '';
  createEditObj: iFuturibleCategory = {
    nombre: '',
  };
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
}
