<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Detalles de la factura</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <div class="data">
          <label for="">Número</label>
          <span *ngIf="modalData.numero">{{modalData.numero}}</span>
          <span *ngIf="!modalData.numero">-</span>
        </div>
        <div class="data">
          <label for="">Cliente</label>
          <span *ngIf="modalData.cliente?.nombre !== ''">{{modalData.cliente.nombre}}</span>
          <span *ngIf="modalData.cliente?.nombre === ''">-</span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Concepto</label>
          <span *ngIf="modalData.concepto !== ''">{{modalData.concepto}}</span>
          <span *ngIf="modalData.concepto === ''">-</span>
        </div>
        <div class="data">
          <label for="">Proyecto</label>
          <span>{{modalData.proyecto.id == null ? "-" :modalData.proyecto.abreviatura + " - " +
            modalData.proyecto.nombre }}</span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Estado</label>
          <span>{{modalData.pagada ? "Pagada" : "Pendiente"}}</span>
        </div>
        <div class="data">
          <label for="">Fecha</label>
          <span *ngIf="modalData.fecha">{{modalData.fecha | date:"dd MMMM YYYY"}}</span>
          <span *ngIf="!modalData.fecha">-</span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Fecha emisión</label>
          <span *ngIf="modalData.fechaEmision">{{modalData.fechaEmision | date:"dd MMMM YYYY"}}</span>
          <span *ngIf="!modalData.fechaEmision">-</span>
        </div>
        <div class="data">
          <label for="">Fecha pago</label>
          <span *ngIf="modalData.fechaPago">{{modalData.fechaPago | date:"dd MMMM YYYY"}}</span>
          <span *ngIf="!modalData.fechaPago">-</span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Base imponible</label>
          <span>{{modalData.cantidad|currency:'EUR'}}</span>
        </div>
        <div class="data">
          <label for="">IVA</label>
          <span>{{ (modalData.cantidad! * modalData.iva!)|currency:'EUR'}}</span>

        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">IRPF</label>
          <span>{{ (modalData.cantidad! * modalData.irpf!)|currency:'EUR'}}</span>
        </div>
        <div class="data">
          <label for="">Total</label>
          <span>{{ (modalData.cantidad! * (1 + modalData.iva! -
            modalData.irpf!)) |currency:'EUR'}}</span>

        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup();">
      <ng-container>
        <mat-icon class="material-icons-outlined">
          arrow_back
        </mat-icon>
        <span>Volver</span>
      </ng-container>
    </btn>
  </div>
</div>