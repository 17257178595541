<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Vincular un proyecto</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <selector [options]="proyectOption" [(ngModel)]="project" (change)="checkIfDataIsCompleted()"></selector>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !isCompleted}" (click)="update()">
      <ng-container>
        <mat-icon *ngIf="!isLoading">check</mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>