<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Editar horario de fichaje</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="checkinRegisterClass.startHourOption" [(ngModel)]="checkinRegisterClass.editObj.startHour"
        (change)="updateDataHandler('horaInicio', checkinRegisterClass.editObj.startHour)"></customInput>
      <customInput [options]="checkinRegisterClass.endHourOption" [(ngModel)]="checkinRegisterClass.editObj.endHour"
        (change)="updateDataHandler('horaFin', checkinRegisterClass.editObj.endHour)">
      </customInput>

    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action">
      <ng-container>
        <mat-icon *ngIf="!checkinRegisterClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="checkinRegisterClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>