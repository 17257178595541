<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar un tipo</p>
    <p *ngIf="!modalData.edit">Crear un nuevo tipo</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="taxationClass.nameOptions" [(ngModel)]="taxationClass.createEditObj.nombre"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('nombre', taxationClass.createEditObj.nombre)">
      </customInput>
      <customInput [options]="taxationClass.valueOption" [(ngModel)]="taxationClass.createEditObj.valor"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('valor', taxationClass.createEditObj.valor)">
      </customInput>
      <customInput [options]="taxationClass.legalArticleOption" [(ngModel)]="taxationClass.createEditObj.articulo"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('articulo', taxationClass.createEditObj.articulo)">
      </customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !taxationClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!taxationClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="taxationClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>