export interface iFamily {
  id?: number | null;
  nombre: string;
}

export class iFamily {
  static convertFromCollection(ob: any): Array<iFamily> {
    let iSelfDataCollection: Array<iFamily> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iFamily {
    let iSelfDataObj = new iFamily();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.nombre = ob.nombre;
    return iSelfDataObj;
  }

  static createEmptyObject(): iFamily {
    let iSelfDataObj = new iFamily();
    iSelfDataObj.id = null;
    iSelfDataObj.nombre = '';
    return iSelfDataObj;
  }
}
