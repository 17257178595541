import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { SupplierService } from 'src/app/Services/Api/supplier.service';
import { Supplier } from 'src/app/Shared/Class/Supplier/supplier';

@Component({
  selector: 'app-create-edit-supplier-popup',
  templateUrl: './create-edit-supplier-popup.component.html',
  styleUrls: ['./create-edit-supplier-popup.component.scss'],
})
export class CreateEditSupplierPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  supplierClass: Supplier = new Supplier();
  constructor(
    public dialogRef: MatDialogRef<CreateEditSupplierPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private supplierSE: SupplierService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * GETTERS & SETTERS
   */
  setData() {
    if (this.modalData.edit) {
      this.supplierClass.createEditObj = this.modalData.supplier;
      this.supplierClass.backUpCreateEditObj = JSON.parse(
        JSON.stringify(this.modalData.supplier)
      );
      this.checkIfDataIsCompletedHandler();
    }
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    !this.modalData.edit
      ? this.create()
      : this.modalData.edit
      ? this.closePopup()
      : this.forceClosePopup();
  }
  checkIfDataIsCompletedHandler() {
    const { nombre, razonSocial } = this.supplierClass.createEditObj;
    this.supplierClass.isCompleted = !!nombre && !!razonSocial;
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = { id: this.supplierClass.createEditObj.id };
      switch (element) {
        case 'nombre':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo nombre es obligatorio y no puede estar vacío.'
            );
            this.supplierClass.createEditObj.nombre =
              this.supplierClass.backUpCreateEditObj.nombre;
          }
          break;
        case 'razonSocial':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo del coste por hora es obligatorio y no puede estar vacío.'
            );
            this.supplierClass.createEditObj.razonSocial =
              this.supplierClass.backUpCreateEditObj.razonSocial;
          }
          break;

        default:
          obj[element] = value;
          this.update(obj);
          break;
      }
    }
  }
  /**
   * API RESPONSES
   */
  successActionResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.supplierClass.isLoading = false;

    if (!this.modalData.edit) {
      setTimeout(() => {
        this.closePopup();
      }, 500);
    }
  }

  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.supplierSE.create(behaviorSubject, this.supplierClass.createEditObj);
    this.supplierClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successActionResponseHandler(res),
            error: false,
          },
          {
            method: () => (this.supplierClass.isLoading = false),
            error: true,
          },
        ]);
      });
  }
  update(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.supplierSE.update(behaviorSubject, obj);
    this.supplierClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successActionResponseHandler(res),
            error: false,
          },
          {
            method: () => (this.supplierClass.isLoading = false),
            error: true,
          },
        ]);
      });
  }
}
