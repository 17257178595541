<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Assignar vacaciones a empleado</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <selector [options]="employeesOption" [(ngModel)]="newRequest.empleado"
        (change)="checkIfDataIsCompletedHandler()"></selector>
      <div class=" customFormContainer">
        <label for="">Fechas<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <div class="dateContainer">
          <mat-date-range-input [rangePicker]="picker" [min]="minDate">
            <input matStartDate placeholder="dd/mm/yyyy" (dateChange)="getDates($event, null)">
            <input matEndDate placeholder="dd/mm/yyyy" (dateChange)="getDates(null, $event);">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker">
            <img src="/assets/Icons/QuotationEngine/calendarioIcon.svg" alt="" matDatepickerToggleIcon>
          </mat-datepicker-toggle>
          <mat-date-range-picker [ngStyle]="{'display': 'none'}" #picker></mat-date-range-picker>
        </div>
      </div>

    </div>

  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !isCompleted}" (click)="create()">
      <ng-container>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <mat-icon *ngIf="!isLoading">check</mat-icon>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>