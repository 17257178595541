export interface iSimpleClient {
  id: number | null;
  nombre: string;
}

export class iSimpleClient {
  static convertFromCollection(ob: any): Array<iSimpleClient> {
    let iSimpleClientCollection: Array<iSimpleClient> = [];
    ob.forEach((element: any) => {
      iSimpleClientCollection.push(this.convertFromObject(element));
    });
    return iSimpleClientCollection;
  }

  static convertFromObject(ob: any): iSimpleClient {
    let iSimpleClientObj = new iSimpleClient();
    iSimpleClientObj.id = ob.id;
    iSimpleClientObj.nombre = ob.nombre;
    return iSimpleClientObj;
  }

  static createEmptyObject(): iSimpleClient {
    let iSimpleClientObj = new iSimpleClient();
    iSimpleClientObj.id = null;
    iSimpleClientObj.nombre = '';
    return iSimpleClientObj;
  }
}
