import { iFuturibleCategory } from './iFuturibleCategory';

export interface iFuturibleList {
  id: number | null;
  name: string;
  hourCost: number;
  anualCost: number;
  costType: 'Directo' | 'Indirecto';
  weekHours: number;
  categories: Array<iFuturibleCategory>;
}

export class iFuturibleList {
  static convertFromCollection(ob: any): Array<iFuturibleList> {
    let iSelfDataCollection: Array<iFuturibleList> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iFuturibleList {
    let iSelfDataObj = new iFuturibleList();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.name = ob.nombre;
    iSelfDataObj.hourCost = ob.costeHora;
    iSelfDataObj.anualCost = ob.costeAnual;
    iSelfDataObj.costType =
      ob.tipoCoste.charAt(0).toUpperCase() + ob.tipoCoste.slice(1);
    iSelfDataObj.weekHours = ob.horasSemanales;
    iSelfDataObj.categories = iFuturibleCategory.convertFromCollection(
      ob.categoria
    );

    return iSelfDataObj;
  }

  static createEmptyObject(): iFuturibleList {
    let iSelfDataObj = new iFuturibleList();
    iSelfDataObj.id = null;
    iSelfDataObj.name = '';
    iSelfDataObj.hourCost = 0;
    iSelfDataObj.anualCost = 0;
    iSelfDataObj.costType = 'Directo';
    iSelfDataObj.weekHours = 0;
    iSelfDataObj.categories = [];
    return iSelfDataObj;
  }
}
