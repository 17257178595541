import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iPeriod } from '../../Interfaces/Api/Company/iPeriod';

export class period {
  periods: iPeriod = iPeriod.createEmptyObject();
  createEditPeriodObj: iPeriod = iPeriod.createEmptyObject();
  backupCreateEditPeriodObj: iPeriod = iPeriod.createEmptyObject();
  isCompleted: boolean = false;
  isLoading: boolean = false;

  /**
   * OPTIONS
   */
  periodOption: iOptionsCustomInput = {
    type: 'number',
    label: 'Corto plazo',
    required: true,
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
}
