export interface iDailyCategory {
  id: number | null;
  nombre: string;
  color: string;
}

export class iDailyCategory {
  static convertFromCollection(ob: any): Array<iDailyCategory> {
    let iDailyCategoryCollection: Array<iDailyCategory> = [];
    ob.forEach((element: any) => {
      iDailyCategoryCollection.push(this.convertFromObject(element));
    });
    return iDailyCategoryCollection;
  }

  static convertFromObject(ob: any): iDailyCategory {
    let iDailyCategoryObj = new iDailyCategory();
    iDailyCategoryObj.id = ob.id;
    iDailyCategoryObj.nombre = ob.nombre;
    iDailyCategoryObj.color = ob.color;
    return iDailyCategoryObj;
  }

  static createEmptyObject(): iDailyCategory {
    let iDailyCategoryObj = new iDailyCategory();
    iDailyCategoryObj.id = null;
    iDailyCategoryObj.nombre = '';
    iDailyCategoryObj.color = '';
    return iDailyCategoryObj;
  }
}
