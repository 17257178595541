import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { FuturibleCategoryService } from 'src/app/Services/Api/futurible-category.service';
import { FuturibleService } from 'src/app/Services/Api/futurible.service';
import { CreateEditFuturible } from 'src/app/Shared/Class/Futuribles/create-edit-futurible';

@Component({
  selector: 'app-create-edit-futuribles-popup',
  templateUrl: './create-edit-futuribles-popup.component.html',
  styleUrls: ['./create-edit-futuribles-popup.component.scss'],
})
export class CreateEditFuturiblesPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  createEditFuturibleClass: CreateEditFuturible = new CreateEditFuturible();
  constructor(
    public dialogRef: MatDialogRef<CreateEditFuturiblesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private futuribleSE: FuturibleService,
    private futuribleCategorySE: FuturibleCategoryService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
    this.getAllCategories();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.createEditFuturibleClass.createEditObj.id =
      this.modalData.futurible.id;
    this.createEditFuturibleClass.createEditObj.nombre =
      this.modalData.futurible.name;
    this.createEditFuturibleClass.createEditObj.costeHora =
      this.modalData.futurible.hourCost;
    this.createEditFuturibleClass.createEditObj.costeAnual =
      this.modalData.futurible.anualCost;
    this.createEditFuturibleClass.createEditObj.horasSemanales =
      this.modalData.futurible.weekHours;
    this.createEditFuturibleClass.createEditObj.tipoCoste =
      this.modalData.futurible.costType;
    this.createEditFuturibleClass.createEditObj.categoria =
      this.modalData.futurible.categories.map((category) => {
        return category.id;
      });
    this.modalData.futurible.costType === 'Directo'
      ? (this.createEditFuturibleClass.optionSelected = 0)
      : (this.createEditFuturibleClass.optionSelected = 1);
    this.createEditFuturibleClass.backUpCreateEditObj = JSON.parse(
      JSON.stringify(this.createEditFuturibleClass.createEditObj)
    );
  }
  setCostType() {
    this.createEditFuturibleClass.optionSelected === 0
      ? (this.createEditFuturibleClass.createEditObj.tipoCoste = 'Directo')
      : (this.createEditFuturibleClass.createEditObj.tipoCoste = 'Indirecto');
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    !this.modalData.edit
      ? this.validateIfDataIsCorrectHandler()
      : this.modalData.edit
      ? this.closePopup()
      : this.forceClosePopup();
  }
  validateIfDataIsCorrectHandler() {
    if (this.createEditFuturibleClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = { id: this.createEditFuturibleClass.createEditObj.id };
      switch (element) {
        case 'nombre':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo nombre es obligatorio y no puede estar vacío.'
            );
            this.createEditFuturibleClass.createEditObj.nombre =
              this.createEditFuturibleClass.backUpCreateEditObj.nombre;
          }
          break;
        case 'costeHora':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo del coste por hora es obligatorio y no puede estar vacío.'
            );
            this.createEditFuturibleClass.createEditObj.costeHora =
              this.createEditFuturibleClass.backUpCreateEditObj.costeHora;
          }
          break;
        case 'costeAnual':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo del coste anual es obligatorio y no puede estar vacío.'
            );
            this.createEditFuturibleClass.createEditObj.costeAnual =
              this.createEditFuturibleClass.backUpCreateEditObj.costeAnual;
          }
          break;
        case 'horasSemanales':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo de las horas semanales es obligatorio y no puede estar vacío.'
            );
            this.createEditFuturibleClass.createEditObj.horasSemanales =
              this.createEditFuturibleClass.backUpCreateEditObj.horasSemanales;
          }
          break;

        default:
          obj[element] = value;
          this.update(obj);
          break;
      }
    }
  }
  checkIfDataIsCompleted() {
    this.createEditFuturibleClass.isCompleted =
      StaticUtilitiesService.isCompleteObject(
        this.createEditFuturibleClass.createEditObj
      );
  }
  successGetAllCategoriesResponseHandler(res: iResultHttp) {
    this.createEditFuturibleClass.categoryOptions.items = [...res.data];
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha creado el futurible correctamente'
    );
    this.createEditFuturibleClass.isLoading = false;
    this.closePopup();
  }
  errorCreateResponseHandler() {
    this.createEditFuturibleClass.isLoading = false;
  }
  successUpdateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha editado el futurible correctamente'
    );
    this.createEditFuturibleClass.isLoading = false;
  }
  errorUpdateResponseHandler() {
    this.createEditFuturibleClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  getAllCategories() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.futuribleCategorySE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllCategoriesResponseHandler(res),
          error: false,
        });
      });
  }
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.futuribleSE.create(
      behaviorSubject,
      this.createEditFuturibleClass.createEditObj
    );
    this.createEditFuturibleClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.futuribleSE.update(behaviorSubject, obj);
    this.createEditFuturibleClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorUpdateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
