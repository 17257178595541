import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iDailyProcess } from 'src/app/Shared/Interfaces/Api/Process/iDailyProcess';
import { iProyectProcessSelector } from 'src/app/Shared/Interfaces/Api/Process/iProyectProcessSelector';
import { iUserProyectSelector } from 'src/app/Shared/Interfaces/Api/Project/iUserProyectSelector';

@Injectable({
  providedIn: 'root',
})
export class ProcessService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getProcessByProject(behaviorSubject: BehaviorSubject<any>, project: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/proceso/' + project, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iDailyProcess.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getProcessByProject,
            args: [behaviorSubject, project],
          });
        },
      });
  }
  getAllProcessByUser(behaviorSubject: BehaviorSubject<any>, userId: number) {
    this.http
      .get(this.urlApi + '/api/proceso/user/' + userId, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              projects: iUserProyectSelector.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.proyectos)
              ),
              processes: iProyectProcessSelector.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.procesos)
              ),
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllProcessByUser,
            args: [behaviorSubject, userId],
          });
        },
      });
  }
  update(behaviorSubject: BehaviorSubject<any>, obj: any) {
    this.http
      .put(this.urlApi + '/api/proceso/' + obj.id, obj, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha actualizado el proceso correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, obj],
          });
        },
      });
  }
  delete(behaviorSubject: BehaviorSubject<any>, ids: number | string) {
    let idsObjs = { procesos_ids: [ids] };
    this.http
      .delete(this.urlApi + '/api/proceso', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha eliminado el proceso correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, ids],
          });
        },
      });
  }
}
