import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  iResultHttp,
  iStaticUtilities,
  sha256,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/user.service';
import { Register } from 'src/app/Shared/Class/Register/register';
import { FennechLoaderComponent } from 'src/app/Shared/Components/fennech-loader/fennech-loader.component';
import { iRegister } from 'src/app/Shared/Interfaces/Api/Register/iRegister';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerClass: Register = new Register();

  constructor(
    private userSE: UserService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  ngOnInit(): void {}

  /**
   * HANDLERS
   */
  checkIfIsCompleteDataHandler() {
    this.registerClass.isCompleteData =
      StaticUtilitiesService.isCompleteObject(this.registerClass.newObj) &&
      !!this.registerClass.isChecked;
  }
  checkIsValidFormatHandler() {
    if (
      !StaticUtilitiesService.isValidFormat(this.registerClass.newObj.username)
    ) {
      StaticUtilitiesService.showError(
        'El formato del correo electrónico no es válido'
      );
      this.registerClass.isCompleteData = false;
    } else {
      this.checkIfIsCompleteDataHandler();
    }
  }
  passwordHassHandler() {
    const newObj: iRegister = JSON.parse(
      JSON.stringify(this.registerClass.newObj)
    );
    newObj.password = sha256(newObj.password)!;
    return newObj;
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    localStorage.setItem(
      iStaticUtilities.simpleCrypt('email'),
      iStaticUtilities.simpleCrypt(this.registerClass.newObj.username)
    );
    this.staticUtilitiesSE.closeLoader();
    setTimeout(() => {
      this.staticUtilitiesSE.goTo(
        '/verificacion',
        slideNavigationTypes.slideToTop
      );
    }, 500);
  }
  errorCreateResponseHandler() {
    this.staticUtilitiesSE.closeLoader();
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.staticUtilitiesSE.openLoader(FennechLoaderComponent);
    this.userSE.create(behaviorSubject, this.passwordHassHandler());
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
