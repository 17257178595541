import { iProcessAssignments } from '../Assignments/iProcessAssignments';

export interface iProjectPhasesProcess {
  id: number | null;
  nombre: string;
  horas: number | null;
  fechaInicio: string;
  fechaFin: string;
  completado: number | null;
  asignaciones: Array<iProcessAssignments>;
  sub_procesos: Array<any>;
}

export class iProjectPhasesProcess {
  static convertFromCollection(ob: any): Array<iProjectPhasesProcess> {
    let iSelfDataCollection: Array<iProjectPhasesProcess> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iProjectPhasesProcess {
    let iSelfDataObj = new iProjectPhasesProcess();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.horas = ob.horas;
    iSelfDataObj.fechaInicio = ob.fecha_ini;
    iSelfDataObj.fechaFin = ob.fecha_fin;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.completado = ob.completado;
    iSelfDataObj.asignaciones = iProcessAssignments.convertFromCollection(
      ob.asignaciones
    );
    iSelfDataObj.sub_procesos = ob.sub_procesos;
    return iSelfDataObj;
  }

  static createEmptyObject(): iProjectPhasesProcess {
    let iSelfDataObj = new iProjectPhasesProcess();
    iSelfDataObj.id = null;
    iSelfDataObj.horas = null;
    iSelfDataObj.fechaInicio = '';
    iSelfDataObj.fechaFin = '';
    iSelfDataObj.nombre = '';
    iSelfDataObj.completado = null;
    iSelfDataObj.asignaciones = [];
    iSelfDataObj.sub_procesos = [];
    return iSelfDataObj;
  }
}
