import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { filter, takeUntil } from 'rxjs';
import { CreateEditWeeklyResource } from 'src/app/Shared/Class/Implications/create-edit-weekly-resource';
import { Weekly } from 'src/app/Shared/Class/Implications/weekly';
import { ImplicationService } from 'src/app/Services/Api/implication.service';

@Component({
  selector: 'app-create-edit-project-weekly-resource',
  templateUrl: './create-edit-project-weekly-resource.component.html',
  styleUrls: ['./create-edit-project-weekly-resource.component.scss'],
})
export class CreateEditProjectWeeklyResourceComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  createEditWeeklyResourcesClass: CreateEditWeeklyResource =
    new CreateEditWeeklyResource();
  weeklyClass: Weekly = new Weekly();
  constructor(
    public dialogRef: MatDialogRef<CreateEditProjectWeeklyResourceComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private implicationSE: ImplicationService
  ) {}

  ngOnInit(): void {
    this.getWeeklyClass();
    this.setData();
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * GETTERS & SETTERS
   */
  getWeeklyClass() {
    this.weeklyClass = JSON.parse(sessionStorage.getItem('class')!);
  }
  setData() {
    this.createEditWeeklyResourcesClass.employeeOptions.items = [
      ...this.weeklyClass.employees.filter((employee) =>
        this.weeklyClass.selectedProject.empleados.includes(employee.id)
      ),
    ];
    this.createEditWeeklyResourcesClass.processOptions.items = [
      ...this.weeklyClass.process.filter(
        (process) => process.proyecto.id === this.weeklyClass.selectedProject.id
      ),
    ];
    this.getSubprocess();
  }
  getSubprocess() {
    this.createEditWeeklyResourcesClass.subProcessOptions.items = [
      ...this.weeklyClass.selectedProject.procesos.filter(
        (process) => process.padre === this.modalData.data.proceso_id
      ),
    ];
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  actionDiscardHandler() {
    this.forceClosePopup();
  }
  actionHubHandler() {
    if (this.modalData.edit) {
      this.forceClosePopup();
    } else {
      this.validateIfDataIsCorrectHandler();
    }
  }
  validateIfDataIsCorrectHandler() {
    if (this.createEditWeeklyResourcesClass.isCompleted) {
      this.dataTreatmentHandler();
    } else {
      StaticUtilitiesService.showError(
        'Hay errores en alguno de los campos',
        'Los campos obligatorios y no pueden estar vacíos'
      );
    }
  }

  checkIfDataIsCompletedHandler() {
    if (this.modalData.edit) {
      if (!this.modalData.data.horas) {
        StaticUtilitiesService.showError(
          'Hay errores en alguno de los campos',
          'La hora es un campo obligatorio y no puede estar vacío'
        );
      } else {
        this.dataTreatmentHandler();
      }
    } else {
      const { horas, id, proceso_id, subProceso_id } = this.modalData.data;
      if (horas && id && proceso_id) {
        this.createEditWeeklyResourcesClass.isCompleted = true;
      } else {
        this.createEditWeeklyResourcesClass.isCompleted = false;
      }
    }
  }
  dataTreatmentHandler() {
    const res: Array<any> = [];
    const selectedProjectId = this.weeklyClass.selectedProject.id;

    const selectedProject = this.weeklyClass.projectsWeekly.find(
      (project) => project.id === selectedProjectId
    );

    if (!this.modalData.edit) {
      selectedProject.recursos.push(this.modalData.data);
    } else {
      const matchingResource = selectedProject.recursos.find(
        (resource) => resource.proceso_id === this.modalData.data.proceso_id
      );
      if (matchingResource) {
        matchingResource.horas = Number(this.modalData.data.horas);
      }
    }

    this.weeklyClass.projectsWeekly.forEach((project) => {
      project.recursos.forEach((recurso) => {
        if (recurso.horas > 0) {
          res.push({
            idProceso: recurso.proceso_id,
            horas: recurso.horas,
            idUsuario: recurso.id,
            idProyecto: project.id,
            idSubProceso: recurso.subProceso_id,
            validado: this.weeklyClass.sprint.validado,
            sprint: this.modalData.data.sprint,
          });
        }
      });
    });

    const data = {
      fecha: new iDate(this.weeklyClass.sprint.fechaInicio).toStringDate('JAP'),
      implicaciones: res,
    };

    this.createImplication(data);
  }

  successCreateImplicationResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha asignado el recurso correctamente'
    );
    this.createEditWeeklyResourcesClass.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorCreateImplicationResponseHandler() {
    this.createEditWeeklyResourcesClass.isLoading = false;
  }

  /**
   * CALLS TO API
   */
  createImplication(data: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.createEditWeeklyResourcesClass.isLoading = true;
    this.implicationSE.create(behaviorSubject, data);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateImplicationResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateImplicationResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
