import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iEmployeeList } from '../../Interfaces/Api/Employee/iEmployeeList';
import { iRequestedHolidays } from '../../Interfaces/Api/Holidays/iRequestedHolidays';
import { istatisticCard } from '../../Interfaces/Utils/istatisticCard';
import { iDepartmentSelector } from '../../Interfaces/Api/Departments/iDepartmentSelector';
import { iNextHoliday } from '../../Interfaces/Api/Holidays/iNextHoliday';

export class Employees {
  generalStatistics: Array<istatisticCard> = [
    {
      label: 'Total empleados',
      value: 0,
      size: 'small',
      format: 'standard',
    },
    {
      label: 'Vacaciones',
      value: 0,
      size: 'small',
      format: 'standard',
    },
    {
      label: 'Baja',
      value: 0,
      size: 'small',
      format: 'standard',
    },
  ];
  structureCost: istatisticCard = {
    label: 'Coste de estructura',
    value: 0,
    size: 'medium',
    format: 'currency',
  };
  nextHolidays: Array<iNextHoliday> = [];
  requestedHolidays: Array<iRequestedHolidays> = [];

  employees: Array<iEmployeeList> = [];
  costType: Array<string> = ['Directo', 'Indirecto'];
  costTypeSelected: number = 0;
  selectedDepartments: Array<iDepartmentSelector> = [];
  formatos: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  file: any = null;
  newEmployees: any = {
    users: [],
  };
  /**
   * OPTIONS
   */
  stateOptions: iOptionsSelector = {
    items: [
      {
        canAccess: true,
        label: 'Activo',
      },
      {
        canAccess: false,
        label: 'Inactivo',
      },
    ],
    placeholder: 'Estado',
    clearable: false,
    append: true,
    bindLabel: 'label',
    bindValue: 'canAccess',
    classList: 'specialFormSelector',
    search: true,
  };
  hoursOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  costOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  rolOptions: iOptionsSelector = {
    items: [
      {
        value: 'ROLE_USER',
        label: 'Básico',
      },
      {
        value: 'ROLE_INTERMEDIO',
        label: 'Gestor',
      },
      {
        value: 'ROLE_ADMIN',
        label: 'Administrador',
      },
    ],
    placeholder: 'Rol',
    clearable: false,
    append: true,
    bindValue: 'value',
    bindLabel: 'label',
    classList: 'specialFormSelector',
    search: true,
  };
  departmentOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Departamento',
    clearable: false,
    append: true,
    multiple: {
      multipleLimitReplacer: 2,
      isMultiple: true,
    },
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
}
