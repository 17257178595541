import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iOptionsSelector,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CrmService } from 'src/app/Services/Api/crm.service';
import { DraftService } from 'src/app/Services/Api/draft.service';

@Component({
  selector: 'app-link-project-to-funnerl-offer-popup',
  templateUrl: './link-project-to-funnerl-offer-popup.component.html',
  styleUrls: ['./link-project-to-funnerl-offer-popup.component.scss'],
})
export class LinkProjectToFunnerlOfferPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }

  isCompleted: boolean = false;
  isLoading: boolean = false;
  project: any = null;
  proyectOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: false,
    search: true,
    label: 'Proyecto',
  };
  constructor(
    public dialogRef: MatDialogRef<LinkProjectToFunnerlOfferPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private draftSE: DraftService,
    private crmSE: CrmService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.getDrafts();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  checkIfDataIsCompleted() {
    this.isCompleted = !!this.project;
  }

  /**
   * API RESPONSES
   */
  successGetDraftsSelectorHandler(res: iResultHttp) {
    this.proyectOption.items = [...res.data];
  }
  successUpdateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha vinculado el proyecto correctamente.'
    );
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  /**
   * CALLS TO API
   */
  getDrafts() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.draftSE.getDraftSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetDraftsSelectorHandler(res),
          error: false,
        });
      });
  }
  update() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.crmSE.updateCRMBudget(behaviorSubject, {
      id: this.modalData,
      proyecto: this.project,
    });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateResponseHandler(res),
            error: false,
          },
          {
            method: () => (this.isLoading = false),
            error: true,
          },
        ]);
      });
  }
}
