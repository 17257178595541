import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { CreateEditEmployee } from 'src/app/Shared/Class/Employees/create-edit-employee';
import { CreateEditProcess } from 'src/app/Shared/Class/Process/create-edit-process';
import { ProjectService } from 'src/app/Services/Api/project.service';
import { EmployeeService } from 'src/app/Services/Api/employee.service';
import { ProcessService } from 'src/app/Services/Api/process.service';

@Component({
  selector: 'app-create-edit-process-popup',
  templateUrl: './create-edit-process-popup.component.html',
  styleUrls: ['./create-edit-process-popup.component.scss'],
})
export class CreateEditProcessPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  createEditProcessClass: CreateEditProcess = new CreateEditProcess();
  constructor(
    public dialogRef: MatDialogRef<CreateEditProcessPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private employeeSE: EmployeeService,
    private processSE: ProcessService
  ) {}

  ngOnInit(): void {
    this.getCompanyEmployees();
    this.setData();
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  actionDiscardHandler() {
    this.forceClosePopup();
  }
  actionHubHandler() {
    if (this.modalData.process != null) {
      this.forceClosePopup();
    } else {
      this.validateIfDataIsCorrectHandler();
    }
  }
  validateIfDataIsCorrectHandler() {}
  successGetCompanyEmployeesResponseHandler(res: iResultHttp) {
    this.createEditProcessClass.employeeOptions.items = [...res.data.data];
  }
  updateProcessHandler(element: string, value: any) {
    const obj: any = { id: this.modalData.process.id };
    obj[element] = value;
    this.updateProcess(obj);
  }
  checkIfQuickEditDataIsComplete(type: string, data: any) {
    switch (type) {
      case 'nombre':
        if (data) {
          this.updateProcessHandler('nombre', data);
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'El nombre es un campo obligatorio y no puede estar vacío.'
          );
          this.createEditProcessClass.createEditObj.nombre =
            this.createEditProcessClass.backupData.nombre;
        }
        break;
      case 'horas':
        if (data) {
          this.updateProcessHandler('horas', Number(data));
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'Las horas planificadas es un campo obligatorio y no puede estar vacío.'
          );
          this.createEditProcessClass.createEditObj.horas =
            this.createEditProcessClass.backupData.horas;
        }
        break;
      case 'completado':
        if (data) {
          if (data > 100 || data < 0) {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El valor mínimo es 0 y el valor máximo es 100.'
            );
            this.createEditProcessClass.createEditObj.completado =
              this.createEditProcessClass.backupData.completado;
          } else {
            this.updateProcessHandler('completado', data / 100);
          }
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'El porcentaje de completación es un campo obligatorio y no puede estar vacío.'
          );
          this.createEditProcessClass.createEditObj.horas =
            this.createEditProcessClass.backupData.horas;
        }
        break;
      case 'fechaIni':
        if (data) {
          if (
            data.getTime() >
            this.createEditProcessClass.createEditObj.fechaFin.getTime()
          ) {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'La fecha de inicio no puede ser mayor que la fecha de fin.'
            );
            this.createEditProcessClass.createEditObj.fechaInicial =
              this.createEditProcessClass.backupData.fechaInicial;
          } else {
            this.updateProcessHandler(
              'fechaIni',
              new iDate(iDate.toPhp(data)).toStringDate('JAP')
            );
          }
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'La fecha de inicio es un campo obligatorio y no puede estar vacío.'
          );
          this.createEditProcessClass.createEditObj.fechaInicial =
            this.createEditProcessClass.backupData.fechaInicial;
        }
        break;
      case 'fechaFin':
        if (data) {
          if (
            data.getTime() <
            this.createEditProcessClass.createEditObj.fechaInicial.getTime()
          ) {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'La fecha de fin no puede ser menor que la fecha de incio.'
            );
            this.createEditProcessClass.createEditObj.fechaFin =
              this.createEditProcessClass.backupData.fechaFin;
          } else {
            this.updateProcessHandler(
              'fechaIni',
              new iDate(iDate.toPhp(data)).toStringDate('JAP')
            );
          }
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'La fecha de fin es un campo obligatorio y no puede estar vacío.'
          );
          this.createEditProcessClass.createEditObj.fechaFin =
            this.createEditProcessClass.backupData.fechaFin;
        }
        break;
    }
  }
  successUpdateProcessResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.createEditProcessClass.isLoading = false;
  }
  /**
   * GETTERS & SETTERS
   */
  setData() {
    if (this.modalData.process !== null) {
      this.createEditProcessClass.createEditObj.id = this.modalData.process.id;
      this.createEditProcessClass.createEditObj.nombre =
        this.modalData.process.nombre;
      this.createEditProcessClass.createEditObj.horas =
        this.modalData.process.horas;
      this.createEditProcessClass.createEditObj.fechaInicial = new Date(
        this.modalData.process.fechaInicio
      );
      this.createEditProcessClass.createEditObj.fechaFin = new Date(
        this.modalData.process.fechaFin
      );
      this.createEditProcessClass.createEditObj.completado =
        this.modalData.process.completado * 100;
      this.createEditProcessClass.createEditObj.fase = {
        id: this.modalData.phase.id,
        nombre: this.modalData.phase.nombre,
      };
      this.createEditProcessClass.createEditObj.proyecto = {
        id: this.modalData.project.id,
        nombre: this.modalData.project.nombre,
      };
      this.createEditProcessClass.createEditObj.empleados =
        this.modalData.process.asignaciones.map(
          (asignacion) => asignacion.empleado.id
        );
      this.createEditProcessClass.backupData = {
        ...this.createEditProcessClass.createEditObj,
      };
    }
  }
  /**
   * CALLS TO API
   */

  getCompanyEmployees() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.employeeSE.getAllByCompany(behaviorSubject);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successGetCompanyEmployeesResponseHandler(res),
        error: false,
      });
    });
  }
  updateProcess(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.createEditProcessClass.isLoading = true;
    this.processSE.update(behaviorSubject, obj);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successUpdateProcessResponseHandler(res),
        error: false,
      });
    });
  }
}
