import { Component, OnInit } from '@angular/core';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CheckInService } from 'src/app/Services/Api/checkIn.service';
import { iCheckin } from '../../Interfaces/Api/Checkin/iCheckin';
import { CheckinPopupComponent } from 'src/app/Popups/checkin-popup/checkin-popup.component';

@Component({
  selector: 'checkinFloattingButton',
  templateUrl: './checkin-floatting-button.component.html',
  styleUrls: ['./checkin-floatting-button.component.scss'],
})
export class CheckinFloattingButtonComponent implements OnInit {
  lastCheckin: iCheckin | null = null;
  constructor(
    private checkInSE: CheckInService,
    private popupSE: PopupService
  ) {}

  ngOnInit(): void {}

  /**
   * POPUPS
   */
  openCheckinPopup() {
    this.popupSE.openPopup(CheckinPopupComponent, {
      newCheckin: this.lastCheckin === null,
      lastCheckin: this.lastCheckin,
    });
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) return;
        if (res.activeAction) {
        }
        subject.next('');
      });
  }

  /**
   * API RESPONSES
   */
  successGetLastCheckinResponseHandler(res: iResultHttp) {
    if (res.data.length > 0) {
      this.lastCheckin = res.data[0];
    } else {
      localStorage.removeItem('ultimaFecha');
      localStorage.removeItem('seconds');
    }
    this.openCheckinPopup();
  }

  /**
   * CALLS TO API
   */

  getLastCheckin() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.checkInSE.getLastCheckIn(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetLastCheckinResponseHandler(res),
          error: false,
        });
      });
  }
}
