<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData">Editar un cliente</p>
    <p *ngIf="!modalData">Crear un nuevo cliente</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="createEditClientClass.nameOptions"
          [(ngModel)]="createEditClientClass.createEditClientObj.clientes[0].nombre"
          (change)="checkIfDataIsCompletedHandler();checkIfAllDataIsCompleted('nombre', createEditClientClass.createEditClientObj.clientes[0].nombre)">
        </customInput>
        <customInput [options]="createEditClientClass.cifOptions"
          [(ngModel)]="createEditClientClass.createEditClientObj.clientes[0].cif"
          (change)="checkIfDataIsCompletedHandler()"
          (change)="updateClientHandler('cif', createEditClientClass.createEditClientObj.clientes[0].cif)">
        </customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="createEditClientClass.emailOptions"
          [(ngModel)]="createEditClientClass.createEditClientObj.clientes[0].mail"
          (change)="checkIfDataIsCompletedHandler(); checkIfEmailHasValidFormat(); checkIfAllDataIsCompleted('mail', createEditClientClass.createEditClientObj.clientes[0].mail)">
        </customInput>
        <customInput [options]="createEditClientClass.phoneOptions"
          [(ngModel)]="createEditClientClass.createEditClientObj.clientes[0].telefono"
          (change)="checkIfDataIsCompletedHandler(); updateClientHandler('telefono', createEditClientClass.createEditClientObj.clientes[0].telefono)">
        </customInput>
      </div>
      <customInput [options]="createEditClientClass.fiscalAddressOptions"
        [(ngModel)]="createEditClientClass.createEditClientObj.clientes[0].direccion_fiscal"
        (change)="checkIfDataIsCompletedHandler(); updateClientHandler('direccion_fiscal', createEditClientClass.createEditClientObj.clientes[0].direccion_fiscal)">
      </customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="discardActionHandler()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createEditClientClass.isCompleted && !modalData}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!createEditClientClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createEditClientClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>