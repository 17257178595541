<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="!modalData.edit">Asignar nuevo recurso</p>
    <p *ngIf="modalData.edit">Editar recurso</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="customFormContainer">
        <label for="">Empleado<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [ngClass]="{'readOnly': modalData.edit}" [options]="createEditWeeklyResourcesClass.employeeOptions"
          [(ngModel)]="modalData.data.id" (change)="checkIfDataIsCompletedHandler()">
        </selector>
      </div>
      <div class="customFormContainer">
        <label for="">Proceso<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [ngClass]="{'readOnly': modalData.edit}" [options]="createEditWeeklyResourcesClass.processOptions"
          [(ngModel)]="modalData.data.proceso_id" (change)="getSubprocess();checkIfDataIsCompletedHandler()">
        </selector>
      </div>
      <div class="customFormContainer">
        <label for="">Tarea</label>
        <selector [ngClass]="{'readOnly': modalData.edit}" [options]="createEditWeeklyResourcesClass.subProcessOptions"
          [(ngModel)]="modalData.data.subProceso_id" (change)="checkIfDataIsCompletedHandler()">
        </selector>
      </div>
      <customInput [options]="createEditWeeklyResourcesClass.hoursOption" [(ngModel)]="this.modalData.data.horas "
        (change)="checkIfDataIsCompletedHandler()">
      </customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="actionDiscardHandler()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createEditWeeklyResourcesClass.isCompleted && !modalData.edit}"
      (click)="actionHubHandler()">
      <ng-container>
        <mat-icon *ngIf="!createEditWeeklyResourcesClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createEditWeeklyResourcesClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>