import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { Funnel } from 'src/app/Shared/Class/Funnel/funnel';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { CrmService } from 'src/app/Services/Api/crm.service';

@Component({
  selector: 'app-create-funnel-column-popup',
  templateUrl: './create-funnel-column-popup.component.html',
  styleUrls: ['./create-funnel-column-popup.component.scss'],
})
export class CreateFunnelColumnPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  funnelClass: Funnel = new Funnel();
  constructor(
    public dialogRef: MatDialogRef<CreateFunnelColumnPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private crmSE: CrmService
  ) {}

  ngOnInit(): void {
    this.setOrderOfNewColumn();
    this.clickOutClosePopupHandler();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  validateIfDataIsCorrectHandler() {
    if (this.funnelClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  checkIfDataIsCompletedHandler() {
    this.funnelClass.isCompleted = this.funnelClass.newColumnObj.nombre != '';
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.funnelClass.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorCreateResponseHandler() {
    this.funnelClass.isLoading = false;
  }
  /**
   * GETTERS & SETTERS
   */
  setOrderOfNewColumn() {
    this.modalData.length == 0
      ? (this.funnelClass.newColumnObj.orden = 0)
      : (this.funnelClass.newColumnObj.orden =
          this.modalData[this.modalData.length - 1].orden + 1);
  }
  getCierre() {
    this.funnelClass.newColumnObj.cierre
      ? (this.funnelClass.newColumnObj.noCierre = false)
      : null;
  }
  getPerdido() {
    this.funnelClass.newColumnObj.noCierre
      ? (this.funnelClass.newColumnObj.cierre = false)
      : null;
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.funnelClass.isLoading = true;
    this.crmSE.createColumn(behaviorSubject, this.funnelClass.newColumnObj);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
