import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iSprintPeriod } from '../../Interfaces/Api/Sprint/iSprintPeriod';

export class SprintPeriod {
  isCompleted: boolean = false;
  sprintPeriods: Array<iSprintPeriod> = [];
  sprintPeriodsBackup: Array<iSprintPeriod> = [];
  isLoading: boolean = false;
  genericFilter: string = '';
  createEditObj: iSprintPeriod = {
    nombre: '',
    rango: null,
  };
  backupCreateEditObj: iSprintPeriod = {
    nombre: '',
    rango: null,
  };
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  rangeOption: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Rango (semanas)',
    required: true,
    classList: 'specialFormInput',
  };
}
