import { environment } from 'src/environments/environment';
import { iDepartmentSelector } from '../Departments/iDepartmentSelector';

export interface iEmployeeList {
  id: number | null;
  username: string;
  baja: boolean;
  canAccess: boolean;
  nombre: string;
  roles: Array<string>;
  apellidos: string;
  horas: number | null;
  departamentos: Array<iDepartmentSelector>;
  horasCorto: number | null;
  horasMedio: number | null;
  horasLargo: number | null;
  porcentajeCorto: number | null;
  porcentajeMedio: number | null;
  porcentajeLargo: number | null;
  profile: string;
  coste: number | null;
  tipoCoste: string;
}
export interface iEmployeeListFilter {
  all?: boolean;
}

export class iEmployeeList {
  static convertFromCollection(ob: any): Array<iEmployeeList> {
    let iEmployeeListCollection: Array<iEmployeeList> = [];
    ob.forEach((element: any) => {
      iEmployeeListCollection.push(this.convertFromObject(element));
    });
    return iEmployeeListCollection;
  }

  static convertFromObject(ob: any): iEmployeeList {
    let iEmployeeListObj = new iEmployeeList();
    iEmployeeListObj.id = ob.id;
    iEmployeeListObj.username = ob.username;
    iEmployeeListObj.canAccess = ob.canAccess;
    iEmployeeListObj.baja = ob.baja;
    iEmployeeListObj.nombre = ob.nombre;
    iEmployeeListObj.apellidos = ob.apellidos;
    iEmployeeListObj.horas = ob.horas;
    iEmployeeListObj.roles = ob.roles;
    iEmployeeListObj.departamentos = ob.departamentos ?? [];
    iEmployeeListObj.horasCorto = ob.horasCorto;
    iEmployeeListObj.horasMedio = ob.horasMedio;
    iEmployeeListObj.horasLargo = ob.horasLargo;
    iEmployeeListObj.porcentajeCorto = ob.porcentajeCorto;
    iEmployeeListObj.porcentajeMedio = ob.porcentajeMedio;
    iEmployeeListObj.porcentajeLargo = ob.porcentajeLargo;
    iEmployeeListObj.profile = ob.profile;
    iEmployeeListObj.coste = ob.coste;
    iEmployeeListObj.tipoCoste = ob.tipoCoste;
    return iEmployeeListObj;
  }

  static createEmptyObject(): iEmployeeList {
    let iEmployeeListObj = new iEmployeeList();
    iEmployeeListObj.id = null;
    iEmployeeListObj.username = '';
    iEmployeeListObj.canAccess = false;
    iEmployeeListObj.baja = false;
    iEmployeeListObj.nombre = '';
    iEmployeeListObj.apellidos = '';
    iEmployeeListObj.roles = [];
    iEmployeeListObj.horas = null;
    iEmployeeListObj.departamentos = [];
    iEmployeeListObj.horasCorto = null;
    iEmployeeListObj.horasMedio = null;
    iEmployeeListObj.horasLargo = null;
    iEmployeeListObj.porcentajeCorto = null;
    iEmployeeListObj.porcentajeMedio = null;
    iEmployeeListObj.porcentajeLargo = null;
    iEmployeeListObj.profile = '';
    iEmployeeListObj.coste = null;
    iEmployeeListObj.tipoCoste = '';
    return iEmployeeListObj;
  }
}
