export interface iDailyProject {
  id: number | null;
  nombre: string;
  abreviatura: string;
  idClockify: string;
}

export class iDailyProject {
  static convertFromCollection(ob: any): Array<iDailyProject> {
    let iDailyProjectCollection: Array<iDailyProject> = [];
    ob.forEach((element: any) => {
      iDailyProjectCollection.push(this.convertFromObject(element));
    });
    return iDailyProjectCollection;
  }

  static convertFromObject(ob: any): iDailyProject {
    let iDailyProjectObj = new iDailyProject();
    iDailyProjectObj.id = ob.id;
    iDailyProjectObj.nombre = ob.nombre;
    iDailyProjectObj.abreviatura = ob.abreviatura;
    iDailyProjectObj.idClockify = ob.idClockify;
    return iDailyProjectObj;
  }

  static createEmptyObject(): iDailyProject {
    let iDailyProjectObj = new iDailyProject();
    iDailyProjectObj.id = null;
    iDailyProjectObj.nombre = '';
    iDailyProjectObj.abreviatura = '';
    iDailyProjectObj.idClockify = '';
    return iDailyProjectObj;
  }
}
