import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iFunnelList } from '../../Interfaces/Api/Funnel/iFunnelList';
import { iCreateFunnelColumn } from '../../Interfaces/Api/Funnel/iCreateFunnelColumn';

export class Funnel {
  funnelList: Array<iFunnelList> = [];
  names: Array<string> = [];
  showNotification: boolean = true;
  isCompleted: boolean = false;
  isLoading: boolean = false;
  savedData: any = null;
  newColumnObj: iCreateFunnelColumn = {
    nombre: '',
    orden: null,
    empresa: Number(localStorage.getItem('numEmpresa')),
    cierre: false,
    noCierre: false,
  };
  /**
   * OPTIONS
   */
  nameOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Nombre',
    classList: 'specialFormInput',
  };
}
