<div class="popupGeneralContainer fullSize">
  <div class="header">
    <p>Crear múltiples empleados</p>
  </div>
  <div class="buttonsContainer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <div class="actions">
      <btn class="action" [ngClass]="{'disabled': selectedEmployees.length === 0}" (click)="removeSelectedEmployees()">
        <ng-container>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
          <span>Eliminar seleccionados</span>
        </ng-container>
      </btn>
      <btn class="action" (click)="openDisclaimerPopup()">
        <ng-container>
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <mat-icon class="material-icons-outlined" *ngIf="!isLoading">add_circle_outline</mat-icon>
          <span>Añadir los empleados</span>
        </ng-container>
      </btn>
    </div>
  </div>
  <employeeTableRevision [data]="modalData.employees" (update)="getSelectedEmployees($event)">
  </employeeTableRevision>
</div>