import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';
import { iProjectDaily } from '../Daily/iProjectDaily';
import { iProjectUser } from '../User/iProjectUser';
import { iProjectStatistic } from './iProjectStatistic';
import { iSingleProjectDetail } from './iSingleProjectDetail';
import { iInvoiceTable } from '../Bill/iInvoiceTable';

export interface iGeneralProjectDetail {
  estadisticas: iProjectStatistic;
  usuarios: Array<iProjectUser>;
  dailys: Array<iProjectDaily>;
  proyecto: iSingleProjectDetail;
  costes: any;
  productos: Array<any>;
}

export class iGeneralProjectDetail {
  static convertFromCollection(ob: any): Array<iGeneralProjectDetail> {
    let iSelfDataCollection: Array<iGeneralProjectDetail> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iGeneralProjectDetail {
    let iSelfDataObj = new iGeneralProjectDetail();
    iSelfDataObj.estadisticas = iProjectStatistic.convertFromObject(
      ob.estadisticas
    );
    iSelfDataObj.usuarios = iProjectUser.convertFromCollection(ob.usuarios);
    iSelfDataObj.dailys = iProjectDaily.convertFromCollection(ob.dailys);
    iSelfDataObj.proyecto = iSingleProjectDetail.convertFromObject(ob.proyecto);
    iSelfDataObj.costes = iStaticUtilities.normalizeNames(ob.costes);
    iSelfDataObj.productos = ob.productos;
    return iSelfDataObj;
  }

  static createEmptyObject(): iGeneralProjectDetail {
    let iSelfDataObj = new iGeneralProjectDetail();
    iSelfDataObj.estadisticas = {} as iProjectStatistic;
    iSelfDataObj.usuarios = [];
    iSelfDataObj.dailys = [];
    iSelfDataObj.proyecto = iSingleProjectDetail.createEmptyObject();
    iSelfDataObj.costes = [];
    iSelfDataObj.productos = [];
    return iSelfDataObj;
  }
}
