<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="!modalData.edit">Crear nuevo hito de cobro</p>
    <p *ngIf="modalData.edit">Editar hito de cobro</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <div class="customFormContainer">
          <label for="">Cliente<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [ngClass]="{'readOnly': modalData.edit || (!modalData.edit && modalData.obj)}"
            [options]="paymentMilestonesCreateClass.clientOptions"
            [(ngModel)]="paymentMilestonesCreateClass.newPaymentMilestonesObj.cliente"
            (change)="findProjects(true);checkIfDataIsCompletedHandler();">
          </selector>
        </div>
        <div class="customFormContainer">
          <label for="">Proyecto<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [ngClass]="{'readOnly': modalData.edit || (!modalData.edit && modalData.obj)}"
            [options]="paymentMilestonesCreateClass.projectOptions"
            [(ngModel)]="paymentMilestonesCreateClass.newPaymentMilestonesObj.proyecto"
            (change)="checkIfDataIsCompletedHandler()">
          </selector>
        </div>
      </div>
      <div class="columnDisplay">
        <customInput [options]="paymentMilestonesCreateClass.emisionDateOptions"
          (change)="getDate($event);checkIfDataIsCompletedHandler();  checkIfAllDataIsCompleted('fecha',paymentMilestonesCreateClass.newPaymentMilestonesObj.fecha )"
          [selectedDate]="paymentMilestonesCreateClass.newPaymentMilestonesObj.fecha"></customInput>
        <customInput [options]="paymentMilestonesCreateClass.quantityOptions"
          [(ngModel)]="paymentMilestonesCreateClass.newPaymentMilestonesObj.cantidad"
          (change)="checkIfDataIsCompletedHandler(); checkIfAllDataIsCompleted('cantidad',paymentMilestonesCreateClass.newPaymentMilestonesObj.cantidad )">
        </customInput>
      </div>

      <customInput [options]="paymentMilestonesCreateClass.conceptOptions"
        [(ngModel)]="paymentMilestonesCreateClass.newPaymentMilestonesObj.concepto"
        (change)="checkIfDataIsCompletedHandler(); checkIfAllDataIsCompleted('concepto',paymentMilestonesCreateClass.newPaymentMilestonesObj.concepto )">
      </customInput>

    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="modalData.edit ? closePopup() : forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': (!paymentMilestonesCreateClass.isCompleted && !modalData.edit) }"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!paymentMilestonesCreateClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="paymentMilestonesCreateClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>