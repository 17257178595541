import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/overrideStaticUtilities.service';
import { Contract } from 'src/app/Shared/Class/Contract/contract';
import { ContractService } from 'src/app/Services/Api/contract.service';

@Component({
  selector: 'app-create-contract-popup',
  templateUrl: './create-contract-popup.component.html',
  styleUrls: ['./create-contract-popup.component.scss'],
})
export class CreateContractPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  contractClass: Contract = new Contract();
  constructor(
    public dialogRef: MatDialogRef<CreateContractPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private contractSE: ContractService
  ) {}

  ngOnInit(): void {
    this.contractClass.newContract.empleado = this.modalData.id;
    this.clickOutClosePopupHandler();
  }
  /**
   * FUNCTIONALITY
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  generateRandonImage(img) {
    img.src = OverrideStaticUtilitiesService.returnRandomPlaceholderImage();
  }
  /**
   * GETTES & SETTERS
   */
  getStartDate(event: Date) {
    this.contractClass.newContract.inicio = new iDate(
      iDate.toPhp(event)
    ).toStringDate('JAP');
  }
  getEndDate(event: Date) {
    this.contractClass.newContract.fin = new iDate(
      iDate.toPhp(event)
    ).toStringDate('JAP');
  }
  /**
   * HANDLERS
   */

  checkIfIsDataCompleted() {
    this.contractClass.isDataComplete = StaticUtilitiesService.isCompleteObject(
      this.contractClass.newContract
    );
  }
  validateIfDataIsCorrectHandler() {
    if (this.contractClass.isDataComplete) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  successCreateResponseHandler(res: iResultHttp) {
    this.contractClass.isLoading = false;
    StaticUtilitiesService.showFeedback(String(res.message));
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorCreateResponseHandler(res: iResultHttp) {
    this.contractClass.isLoading = false;
  }

  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.contractClass.isLoading = true;
    this.contractSE.create(behaviorSubject, this.contractClass.newContract);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
}
