import {
  StaticUtilitiesService,
  iOptionsCustomInput,
  sha256,
} from '@quasar-dynamics/basic-designsystem';
import { iChangePassword } from '../../Interfaces/Api/User/iChangePassword';

export class ChangePassword {
  passwordData: iChangePassword = iChangePassword.createEmptyObject();
  isCompleteData: boolean = false;
  isValidData: boolean = false;
  isLoading: boolean = false;
  newPasswordOption: iOptionsCustomInput = {
    label: 'Nueva contraseña',
    placeholder: 'Escribe aquí...',
    type: 'password',
    classList: 'specialFormInput',
    required: true,
  };
  repeatNewPasswordOption: iOptionsCustomInput = {
    label: 'Repite la contraseña',
    placeholder: 'Escribe aquí...',
    type: 'password',
    classList: 'specialFormInput',
    required: true,
  };

  constructor() {}

  /**
   * HANDLERS
   */
  checkIfPasswordAreValidHandler() {
    if (this.isCompleteData) {
      if (this.passwordData.password === this.passwordData.repeatPassword) {
        this.isValidData = true;
      } else {
        this.isValidData = false;
        StaticUtilitiesService.showError('Las contraseñas no coinciden');
      }
    }
  }
  checkIsCompleteObjectHandler() {
    this.isCompleteData = StaticUtilitiesService.isCompleteObject(
      this.passwordData
    );
  }

  /**
   * GETTERS & SETTERS
   */

  returnReadyPasswordObject(): iChangePassword {
    const passwordDataTreated = new iChangePassword();
    passwordDataTreated.password = sha256(this.passwordData.password)!;
    return passwordDataTreated;
  }
}
