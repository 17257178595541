import {
  StaticUtilitiesService,
  iOptionsCustomInput,
} from '@quasar-dynamics/basic-designsystem';
import { iLogin } from '../Interfaces/Api/iLogin';

export class Login {
  /**
   * FORM VARIABLES
   */
  loginData: iLogin = {
    username: '',
    password: '',
  };

  /**
   * OTHER VARIABLES
   */
  isDataCompleted: boolean = false;
  isValidFormat: boolean = true;
  /**
   * INPUT OPTIONS
   */
  emailOptions: iOptionsCustomInput = {
    placeholder: 'Correo electronico',
    type: 'text',
  };
  passwordOptions: iOptionsCustomInput = {
    placeholder: 'Contraseña',
    type: 'password',
  };

  constructor() {}

  /**
   * VALIDATE DATA
   */

  checkIfCompletedAndValidFormat() {
    this.isDataCompleted = StaticUtilitiesService.isCompleteObject(
      this.loginData
    );
  }

  checkIfIsValidEmailFormat() {
    this.isValidFormat = StaticUtilitiesService.isValidFormat(
      this.loginData.username
    );
    !StaticUtilitiesService.isValidFormat(this.loginData.username)
      ? StaticUtilitiesService.showError(
          'Hay errores en alguno de los campos',
          'El formato del correo no es válido'
        )
      : null;
  }
}
