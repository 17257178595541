import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { ProjectService } from 'src/app/Services/Api/project.service';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { CreateFunnelBudget } from 'src/app/Shared/Class/Funnel/create-funnel-budget';
import { iFunnelPhase } from 'src/app/Shared/Interfaces/Api/Funnel/iFunnelPhase';

@Component({
  selector: 'app-create-funnel-budget-popup',
  templateUrl: './create-funnel-budget-popup.component.html',
  styleUrls: ['./create-funnel-budget-popup.component.scss'],
})
export class CreateFunnelBudgetPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  createFunnelBudgetClass: CreateFunnelBudget = new CreateFunnelBudget();
  constructor(
    public dialogRef: MatDialogRef<CreateFunnelBudgetPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private projectSE: ProjectService
  ) {}
  ngOnInit(): void {
    this.checkIfModalDataHasDataAndSetIt();
    this.clickOutClosePopupHandler();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * GETTERS & SETTERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  checkIfModalDataHasDataAndSetIt() {
    this.modalData
      ? (this.createFunnelBudgetClass.newFunnelBudgetOBJ.cliente =
          this.modalData)
      : null;
  }
  getStartDate(event: Date) {
    this.createFunnelBudgetClass.newFunnelBudgetOBJ.fechaInicio = new iDate(
      iDate.toPhp(event)
    ).toStringDate('JAP');
  }
  getEndDate(event: Date) {
    this.createFunnelBudgetClass.newFunnelBudgetOBJ.fechaFin = new iDate(
      iDate.toPhp(event)
    ).toStringDate('JAP');
  }
  setPhases() {
    const phaseArray: Array<iFunnelPhase> = [
      {
        nombre: 'Presupuesto',
        procesos: [
          {
            nombre: 'Presupuesto',
            horas: this.createFunnelBudgetClass.days! * 8,
            fechaInicio:
              this.createFunnelBudgetClass.newFunnelBudgetOBJ.fechaInicio,
            fechaFin: this.createFunnelBudgetClass.newFunnelBudgetOBJ.fechaFin,
            empleados: [],
          },
        ],
        fechaInicio:
          this.createFunnelBudgetClass.newFunnelBudgetOBJ.fechaInicio,
        fechaFin: this.createFunnelBudgetClass.newFunnelBudgetOBJ.fechaFin,
      },
    ];
    this.createFunnelBudgetClass.newFunnelBudgetOBJ.fases = [...phaseArray];
    this.createFunnelBudgetClass.newFunnelBudgetOBJ.horas =
      this.createFunnelBudgetClass.days! * 8;
  }
  setReadOnlyInputs() {
    if (this.createFunnelBudgetClass.fixedDays) {
      this.createFunnelBudgetClass.priceOptions.readOnly = true;
      this.createFunnelBudgetClass.daysOptions.readOnly = false;
    } else {
      this.createFunnelBudgetClass.priceOptions.readOnly = false;
      this.createFunnelBudgetClass.daysOptions.readOnly = true;
    }
  }
  changeDays() {
    if (
      this.createFunnelBudgetClass.days != null &&
      this.createFunnelBudgetClass.newFunnelBudgetOBJ.coste_dia != null &&
      this.createFunnelBudgetClass.newFunnelBudgetOBJ.multiplicador != null
    ) {
      this.createFunnelBudgetClass.price =
        this.createFunnelBudgetClass.days *
        this.createFunnelBudgetClass.newFunnelBudgetOBJ.coste_dia! *
        this.createFunnelBudgetClass.newFunnelBudgetOBJ.multiplicador!;
    } else {
      this.createFunnelBudgetClass.price = 0;
    }
    this.checkIfDataIsCompletedHandler();
  }
  changePrice() {
    if (
      this.createFunnelBudgetClass.price != null &&
      this.createFunnelBudgetClass.newFunnelBudgetOBJ.coste_dia != null &&
      this.createFunnelBudgetClass.newFunnelBudgetOBJ.multiplicador != null
    ) {
      this.createFunnelBudgetClass.days =
        this.createFunnelBudgetClass.price /
        this.createFunnelBudgetClass.newFunnelBudgetOBJ.coste_dia /
        this.createFunnelBudgetClass.newFunnelBudgetOBJ.multiplicador;
    } else {
      this.createFunnelBudgetClass.days = 0;
    }
    this.checkIfDataIsCompletedHandler();
  }
  /**
   * HANDLERS
   */

  validateIfDataIsCorrectHandler() {
    if (this.createFunnelBudgetClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  checkIfDataIsCompletedHandler() {
    const {
      nombre,
      abreviatura,
      fechaInicio,
      fechaFin,
      coste_dia,
      multiplicador,
    } = this.createFunnelBudgetClass.newFunnelBudgetOBJ;

    this.createFunnelBudgetClass.isCompleted =
      !!nombre &&
      !!abreviatura &&
      !!fechaInicio &&
      !!fechaFin &&
      !!coste_dia &&
      !!multiplicador &&
      !!this.createFunnelBudgetClass.days &&
      !!this.createFunnelBudgetClass.price;
    if (new Date(fechaInicio) > new Date(fechaFin)) {
      StaticUtilitiesService.showError(
        'Hay errores en los campos',
        'La fecha de inicio no puede ser mayor a la fecha de fin'
      );
      this.createFunnelBudgetClass.isCompleted = false;
    } else if (new Date(fechaFin) < new Date(fechaInicio)) {
      StaticUtilitiesService.showError(
        'Hay errores en los campos',
        'La fecha de fin no puede ser menor a la fecha de inicio'
      );
      this.createFunnelBudgetClass.isCompleted = false;
    }
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.createFunnelBudgetClass.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorCreateResponseHandler() {
    this.createFunnelBudgetClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  create() {
    this.setPhases();

    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.create(
      behaviorSubject,
      this.createFunnelBudgetClass.newFunnelBudgetOBJ
    );
    this.createFunnelBudgetClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
