import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iSprint } from '../../Interfaces/Api/Sprint/iSprint';
import { iSprintPeriod } from '../../Interfaces/Api/Sprint/iSprintPeriod';

export class CreateEditSprint {
  isCompleted: boolean = false;
  sprintPeriods: Array<iSprintPeriod> = [];
  isLoading: boolean = false;
  minDate: Date = new Date();
  isCustomRange: boolean = true;
  isEditingEnabled: boolean = true;
  typeSelected: string = 'general';
  createEditObj: iSprint = {
    nombre: '',
    fechaInicio: '',
    fechaFin: '',
    finalizado: false,
    validado: false,
    activo: true,
    rango: null,
  };
  backupCreateEditObj: iSprint = {
    nombre: '',
    fechaInicio: '',
    fechaFin: '',
    finalizado: false,
    validado: false,
    activo: true,
    rango: null,
  };
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  sprintPeriodOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    label: 'Rango',
    classList: 'specialFormSelector',
    search: true,
    required: true,
  };
  projectPeriodOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    label: 'Proyecto',
    classList: 'specialFormSelector',
    search: true,
    required: true,
    multiple: {
      isMultiple: true,
    },
  };
  departmentPeriodOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    label: 'Departamento',
    classList: 'specialFormSelector',
    search: true,
    required: true,
    multiple: {
      isMultiple: true,
    },
  };
  bussinesLineOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    label: 'Lineas de negocio',
    classList: 'specialFormSelector',
    search: true,
    required: true,
    multiple: {
      isMultiple: true,
    },
  };
}
