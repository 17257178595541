<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p *ngIf="!modalData.edit">Crear un prospecto</p>
    <p *ngIf="modalData.edit">Editar un prospecto</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="customFormContainer">
        <label for="">Cliente<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [ngClass]="{'readOnly': this.modalData.edit}" [options]="createEditFunnelProposalClass.clientOption"
          [(ngModel)]="createEditFunnelProposalClass.newFunnelBudget.cliente"
          (change)="getClientProjects();  this.createEditFunnelProposalClass.newFunnelBudget.proyecto = null;validateIfDataIsCompleted()">
        </selector>
      </div>
      <div class="customFormContainer">
        <label for="">Proyecto</label>
        <selector [ngClass]="{'readOnly': !createEditFunnelProposalClass.newFunnelBudget.cliente}"
          [options]="createEditFunnelProposalClass.projectsOption"
          [(ngModel)]="this.createEditFunnelProposalClass.newFunnelBudget.proyecto"
          (change)="setBudgetData();validateIfDataIsCompleted(); updateDataHandler('proyecto', this.createEditFunnelProposalClass.newFunnelBudget.proyecto)">
        </selector>
      </div>
      <customInput [options]="createEditFunnelProposalClass.temperatureOptions"
        [(ngModel)]="this.createEditFunnelProposalClass.newFunnelBudget.temperatura"
        (change)="validateIfDataIsCompleted(); updateDataHandler('temperatura', this.createEditFunnelProposalClass.newFunnelBudget.temperatura)">
      </customInput>

      <div class="customFormContainer" *ngIf="!modalData.edit">
        <label for="">Columna<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="createEditFunnelProposalClass.columnOption"
          [(ngModel)]="this.createEditFunnelProposalClass.newFunnelBudget.estadoCRM"
          (change)="validateIfDataIsCompleted(); ">
        </selector>
      </div>
    </div>
    <div class="buttonContainer">
      <btn class="secondary"
        *ngIf="createEditFunnelProposalClass.newFunnelBudget.proyecto && createEditFunnelProposalClass.newFunnelBudget.id"
        (click)="goToEditActualFunnelBudget()">
        <ng-container>
          <mat-icon class="material-icons-outlined">
            edit
          </mat-icon>
          <span>Editar oferta actual</span>
        </ng-container>
      </btn>
      <btn class="secondary" (click)="openCreateClientPopup()" *ngIf="!modalData.edit">
        <ng-container>
          <mat-icon class="material-icons-outlined">
            add_circle_outline
          </mat-icon>
          <span>Añadir nuevo cliente</span>
        </ng-container>
      </btn>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.modalData.edit ? this.forceClosePopup() : forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createEditFunnelProposalClass.isCompleted && !this.modalData.edit}"
      (click)="this.modalData.edit ? this.closePopup() :  validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!createEditFunnelProposalClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createEditFunnelProposalClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>