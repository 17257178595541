import { environment } from 'src/environments/environment';

export interface iDailyUser {
  id: number | null;
  nombre: string;
  apellidos: string;
  profile: string;
}

export class iDailyUser {
  static convertFromCollection(ob: any): Array<iDailyUser> {
    let iDailyUserCollection: Array<iDailyUser> = [];
    ob.forEach((element: any) => {
      iDailyUserCollection.push(this.convertFromObject(element));
    });
    return iDailyUserCollection;
  }

  static convertFromObject(ob: any): iDailyUser {
    let iDailyUserObj = new iDailyUser();
    iDailyUserObj.id = ob.id;
    iDailyUserObj.nombre = ob.nombre;
    iDailyUserObj.apellidos = ob.apellidos;
    iDailyUserObj.profile = ob.profile;
    return iDailyUserObj;
  }

  static createEmptyObject(): iDailyUser {
    let iDailyUserObj = new iDailyUser();
    iDailyUserObj.id = null;
    iDailyUserObj.nombre = '';
    iDailyUserObj.apellidos = '';
    iDailyUserObj.profile = '';
    return iDailyUserObj;
  }
}
