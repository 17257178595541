import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { CreateBill } from 'src/app/Shared/Class/Bill/create-bill';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { FennechLoaderComponent } from 'src/app/Shared/Components/fennech-loader/fennech-loader.component';
import { ProjectService } from 'src/app/Services/Api/project.service';
import { FamilyService } from 'src/app/Services/Api/family.service';
import { ExpenseService } from 'src/app/Services/Api/expense.service';
import { TaxationService } from 'src/app/Services/Api/taxation.service';
import { SupplierService } from 'src/app/Services/Api/supplier.service';

@Component({
  selector: 'app-create-edit-bill',
  templateUrl: './create-edit-bill.component.html',
  styleUrls: ['./create-edit-bill.component.scss'],
})
export class CreateEditBillComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  billClass: CreateBill = new CreateBill();

  constructor(
    public dialogRef: MatDialogRef<CreateEditBillComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private projectSE: ProjectService,
    private familySE: FamilyService,
    private taxationSE: TaxationService,
    private expenseSE: ExpenseService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private suplierSE: SupplierService
  ) {}

  ngOnInit(): void {
    this.getAllProjects();
    this.getAllTaxations();
    this.getAllFamilies();
    +this.getAllSupliers();
    this.clickOutClosePopupHandler();
    if (this.modalData.project) {
      this.billClass.newBill.proyecto = this.modalData.project.id;
    }
    if (this.modalData.edit) {
      this.setData();
    }
    if (this.staticUtilitiesSE.getActualRoute().includes('/contabilidad')) {
      this.billClass.projectActive = true;
    }
    if (this.modalData.month != undefined && this.modalData.year != undefined) {
      this.billClass.dateOption.date = {
        minDate: this.getStartAndEndOfMonth(
          this.modalData.month,
          this.modalData.year
        ).start,
        maxDate: this.getStartAndEndOfMonth(
          this.modalData.month,
          this.modalData.year
        ).end,
      };
    }
  }
  /**
   * GETTERS & SETTERS
   */
  getStartAndEndOfMonth(
    month: number,
    year: number
  ): { start: Date; end: Date } {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    return { start: firstDay, end: lastDay };
  }
  /**
   * FUNCTIONALITY
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  removeFile() {
    this.billClass.file = null;
  }
  previewDocument() {
    sessionStorage.setItem('embeddedUrl', this.billClass.file.base64);
    window.open(location.origin + '/previsualizar-documento', '_blank');
  }
  /**
   * HANDLERS
   */

  successGetAllProjectsResponseHandler(res: iResultHttp) {
    this.billClass.projectOptions.items = res.data;
  }
  successGetAllFamoliesResponseHandler(res: iResultHttp) {
    this.billClass.familyOptions.items = res.data;
  }
  successGetAllTaxationResponseHandler(res: iResultHttp) {
    this.billClass.taxationsOptions.items = [...res.data];
  }
  checkIfIsDataCompletedHandler() {
    const {
      estado,
      directo,
      tipo,
      fecha,
      iva,
      cantidad,
      familia,
      concepto,
      proveedor,
      proyecto,
    } = this.billClass.newBill;

    if (!directo) {
      this.billClass.isCompleted =
        estado !== null &&
        directo !== null &&
        tipo !== null &&
        fecha.trim() !== '' &&
        iva !== null &&
        cantidad !== null &&
        familia !== null &&
        concepto.trim() !== '';
    } else {
      this.billClass.isCompleted =
        estado !== null &&
        directo !== null &&
        tipo !== null &&
        fecha.trim() !== '' &&
        iva !== null &&
        cantidad !== null &&
        familia !== null &&
        concepto.trim() !== '' &&
        !!proyecto;
    }
  }
  validateIfDataIsCorrectHandler() {
    if (this.billClass.isCompleted) {
      this.parseNumbersHandler();
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  parseNumbersHandler() {
    this.billClass.newBill.cantidad = Number(this.billClass.newBill.cantidad);
    this.billClass.newBill.tipo = Number(this.billClass.newBill.tipo);
    this.billClass.newBill.iva = Number(this.billClass.newBill.iva);
    this.billClass.newBill.familia = Number(this.billClass.newBill.familia);
    this.billClass.newBill.proyecto = Number(this.billClass.newBill.proyecto);
    this.billClass.newBill.dia = Number(this.billClass.newBill.dia);
    this.billClass.newBill.mes = Number(this.billClass.newBill.mes);
    this.billClass.newBill.estado = Number(this.billClass.newBill.estado);
    this.billClass.newBill.anyo = Number(this.billClass.newBill.anyo);
  }

  updateProjectHandler(element, value) {
    if (this.modalData.edit) {
      const obj = { id: this.modalData.bill.id };
      obj[element] = value;
      switch (element) {
        case 'fecha':
          this.billClass.newBill.fecha = new iDate(value).toStringDate('JAP');
          this.billClass.newBill.dia = new iDate(value)
            .toJavascript()
            .getDate();
          this.billClass.newBill.mes =
            new iDate(value).toJavascript().getMonth() + 1;
          this.billClass.newBill.anyo = new iDate(value)
            .toJavascript()
            .getFullYear();
          obj['dia'] = this.billClass.newBill.dia;
          obj['mes'] = this.billClass.newBill.mes;
          obj['anyo'] = this.billClass.newBill.anyo;

          if (value) {
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Se ha producido un error al válidar los campos',
              `El campo ${element} es obligatorio y no puede estar vacío.`
            );
          }
          break;

        default:
          if (value != null && value != undefined) {
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Se ha producido un error al válidar los campos',
              `El campo ${element} es obligatorio y no puede estar vacío.`
            );
          }
          break;
      }
    }
  }

  /**
   * API RESPONSES
   */
  successCreateBillResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.billClass.isLoading = false;
    setTimeout(() => {
      this.closePopup(this.billClass.newBill);
    }, 500);
  }
  errorCreateBillResponseHandler() {
    this.billClass.isLoading = false;
  }
  successUpdateBillResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.billClass.isLoading = false;
  }
  errorUpdateBillResponseHandler() {
    this.billClass.isLoading = false;
  }
  successGetSelectorResponseHandler(res: iResultHttp) {
    this.billClass.suplierOptions.items = [...res.data];
  }
  /**
   * GETTERS & SETTERS
   */
  getFile(event: any) {
    let BreakException = {};
    try {
      event.forEach((element: any) => {
        if (!this.billClass.formatos.includes(element.file.type)) {
          StaticUtilitiesService.showError('El formato no es válido');
          this.billClass.file = null;
          throw BreakException;
        }
        if (element.file.size > 10485760) {
          StaticUtilitiesService.showError('El archivo es demasiado pesado');
          this.billClass.file = null;
          throw BreakException;
        }

        this.billClass.file = element;
        this.billClass.newBill.urlDocument = element.base64;
        this.updateProjectHandler(
          'urlDocument',
          this.billClass.newBill.urlDocument
        );
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }
  getDate(date: Date) {
    this.billClass.newBill.fecha = new iDate(iDate.toPhp(date)).toStringDate(
      'JAP'
    );
    this.billClass.newBill.dia = date.getDate();
    this.billClass.newBill.mes = date.getMonth() + 1;
    this.billClass.newBill.anyo = date.getFullYear();
    this.checkIfIsDataCompletedHandler();
    this.updateProjectHandler('fecha', this.billClass.newBill.fecha);
  }
  setData() {
    const {
      cantidad,
      concepto,
      directo,
      estado,
      familia,
      fecha,
      id,
      iva,
      numeroFactura,
      tipo,
      proveedor,
      proyecto,
      url,
    } = this.modalData.bill;
    this.billClass.newBill.id = id;
    this.billClass.newBill.cantidad = cantidad;
    this.billClass.newBill.concepto = concepto;
    this.billClass.newBill.tipo = tipo;
    this.billClass.newBill.directo = directo;
    this.billClass.newBill.iva = iva;
    this.billClass.newBill.familia = familia?.id;
    (this.billClass.newBill.numeroFactura = numeroFactura),
      (this.billClass.newBill.fecha = new iDate(fecha).toStringDate('JAP'));
    this.billClass.newBill.dia = new iDate(fecha).toJavascript().getDate();
    this.billClass.newBill.mes = new iDate(fecha).toJavascript().getMonth() + 1;
    this.billClass.newBill.anyo = new iDate(fecha).toJavascript().getFullYear();
    this.billClass.newBill.estado = estado;
    this.billClass.newBill.urlDocument = url;
    this.billClass.newBill.proveedor = proveedor ? proveedor.id : null;
    this.billClass.newBill.proyecto = proyecto ? proyecto.id : null;
    this.billClass.dateOption.date!.selectedDate = new iDate(
      fecha
    ).toJavascript();
    this.billClass.isCompleted = true;
  }
  /**
   * CALLS TO API
   */

  getAllProjects() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.getAllSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllProjectsResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  getAllSupliers() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.suplierSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSelectorResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  getAllFamilies() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.familySE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllFamoliesResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.expenseSE.create(behaviorSubject, this.billClass.newBill);
    this.billClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateBillResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateBillResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(obj) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.expenseSE.update(behaviorSubject, obj);
    this.billClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateBillResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorUpdateBillResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  getAllTaxations() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.taxationSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllTaxationResponseHandler(res),
          error: false,
        });
      });
  }
}
