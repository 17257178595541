import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
} from '@quasar-dynamics/basic-designsystem';
import { iBonusHours } from '../../Interfaces/Api/BonusHours/iBonusHours';
import { iBonusHoursFilter } from '../../Interfaces/Api/BonusHours/iBonusHoursFilter';

export class BonusHours {
  bonusHoursFilter: iBonusHoursFilter = {
    num_pagina: 1,
    num_devoluciones: 10,
  };
  bonusHours: Array<iBonusHours> = [];
  isLoading: boolean = false;
  isDataCompleted: boolean = false;
  /**
   * CREATE EDIT BONUS HOURS
   */
  createEditObj: iBonusHours = {
    codigo: '',
    nombre: '',
    descripcion: '',
    horas: null,
    precio: null,
  };
  backupCreateEditObj: iBonusHours = {
    codigo: '',
    nombre: '',
    descripcion: '',
    horas: null,
    precio: null,
  };

  codeOptions: iOptionsCustomInput = {
    label: 'Código',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  hoursOptions: iOptionsCustomInput = {
    label: 'Horas',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  priceOptions: iOptionsCustomInput = {
    label: 'Precio',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };

  descriptionOptions: IOptionCustomTextArea = {
    label: 'Descripción',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
    rows: 6,
    cols: 0,
  };
}
