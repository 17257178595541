import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { HolidayService } from 'src/app/Services/Api/holiday.service';

@Component({
  selector: 'app-request-holiday-popup',
  templateUrl: './request-holiday-popup.component.html',
  styleUrls: ['./request-holiday-popup.component.scss'],
})
export class RequestHolidayPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  isCompleted: boolean = false;
  isLoading: boolean = false;
  minDate: Date = new Date();
  newRequest: any = {
    fechaIni: null,
    fechaFin: null,
    solicitud: true,
    empleado: localStorage.getItem('idUser'),
  };
  constructor(
    public dialogRef: MatDialogRef<RequestHolidayPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private holidaySE: HolidayService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * GETTERS & SETTERS
   */
  getDatePhase(fechaInicio: any, fechaFin: any) {
    if (fechaInicio) {
      this.newRequest.fechaIni = fechaInicio.value;
    }
    if (fechaFin) {
      this.newRequest.fechaFin = fechaFin.value;
    }
    if (this.newRequest.fechaIni && this.newRequest.fechaFin) {
      this.isCompleted = true;
    }
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  validateIfDataIsCorrectHandler() {
    if (this.isCompleted) {
      this.generateRequest();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  successGenerateRequestResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
    this.closePopup();
  }
  errorGenerateRequestResResponseHandler(res: iResultHttp) {
    this.isLoading = false;
  }
  parseDatesHandler() {
    return {
      fechaIni: new iDate(
        iDate.toPhp(new Date(this.newRequest.fechaIni))
      ).toStringDate('JAP'),
      fechaFin: new iDate(
        iDate.toPhp(new Date(this.newRequest.fechaFin))
      ).toStringDate('JAP'),
      solicitud: true,
      empleado: Number(localStorage.getItem('idUser')),
      aprobada: false,
    };
  }
  /**
   * CALLS TO API
   */
  generateRequest() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.holidaySE.create(behaviorSubject, this.parseDatesHandler());
    this.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGenerateRequestResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorGenerateRequestResResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
}
