export interface iResourceSelector {
  id: number | null;
  nombre: string;
  coste: number;
  baja: boolean;
  futurible: boolean;
}

export class iResourceSelector {
  static convertFromCollection(ob: any): Array<iResourceSelector> {
    let iSelfDataCollection: Array<iResourceSelector> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iResourceSelector {
    let iSelfDataObj = new iResourceSelector();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.coste = ob.coste;
    iSelfDataObj.baja = ob.baja;
    iSelfDataObj.futurible = ob.futurible;
    return iSelfDataObj;
  }

  static createEmptyObject(): iResourceSelector {
    let iSelfDataObj = new iResourceSelector();
    iSelfDataObj.id = null;
    iSelfDataObj.nombre = '';
    iSelfDataObj.coste = 0;
    iSelfDataObj.baja = false;
    iSelfDataObj.baja = false;
    return iSelfDataObj;
  }
}
