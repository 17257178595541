import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iCategoryProduct } from '../../Interfaces/Api/Products/iCategoryProduct';
import { iProductFilter } from '../../Interfaces/Api/Products/iProductFilter';

export class Inventory {
  filterOpened: boolean = false;
  productFilter: iProductFilter = {
    num_pagina: 1,
    num_devoluciones: 10,
  };
  productList: Array<any> = [];
  isCompleted: boolean = false;
  isLoading: boolean = false;
  formatos: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  file: any = null;
  createEditObj: any = {
    productos: [
      {
        nombre: '',
        codigo: '',
        descripcion: '',
        cantidad: null,
        precio: null,
        precioCompra: null,
        categorias: [],
      },
    ],
  };
  backUpcreateEditObj: any = {
    productos: [
      {
        nombre: '',
        codigo: '',
        descripcion: '',
        cantidad: null,
        precio: null,
        categorias: [],
      },
    ],
  };
  newProducts: any = {
    productos: [],
  };

  /**
   * FILTERS OPTIOND
   */
  categoryProductFilterOptions: iOptionsSelector = {
    items: [],
    label: 'Categoría',
    placeholder: 'Selecciona una opción...',
    clearable: true,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };

  /**
   * OPTIONS
   */
  categoryProductOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Filtrar por categoría',
    clearable: true,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    multiple: {
      isMultiple: true,
    },
    classList: 'specialFormSelector',
    search: true,
  };
  taxationOptions: iOptionsSelector = {
    label: 'Tipo de fiscalidad',
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
    required: true,
    clearable: false,
  };
  nombreOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Nombre',
    required: true,
    classList: 'specialFormInput',
  };
  codigoOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Código',
    required: true,
    classList: 'specialFormInput',
  };

  descriptionOptions: IOptionCustomTextArea = {
    label: 'Descripción',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
    rows: 6,
    cols: 0,
  };
  precioOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Precio venta (€/Ud)',
    required: true,
    classList: 'specialFormInput',
  };
  precioCompraOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Precio compra (€/Ud.)',
    required: true,
    classList: 'specialFormInput',
  };
  cantidadOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Cantidad (Ud)',
    required: true,
    classList: 'specialFormInput',
  };
}
