import { iSickLeaves } from './iSickLeaves';

export interface iUserSickLeaves {
  totalDias: number;
  bajas: Array<iSickLeaves>;
}

export class iUserSickLeaves {
  static convertFromCollection(ob: any): Array<iUserSickLeaves> {
    let iUserSickLeavesCollection: Array<iUserSickLeaves> = [];
    ob.forEach((element: any) => {
      iUserSickLeavesCollection.push(this.convertFromObject(element));
    });
    return iUserSickLeavesCollection;
  }

  static convertFromObject(ob: any): iUserSickLeaves {
    let iUserSickLeavesObj = new iUserSickLeaves();
    iUserSickLeavesObj.totalDias = ob.totalDias;
    iUserSickLeavesObj.bajas = iSickLeaves.convertFromCollection(ob.bajas);
    return iUserSickLeavesObj;
  }

  static createEmptyObject(): iUserSickLeaves {
    let iUserSickLeavesObj = new iUserSickLeaves();
    iUserSickLeavesObj.totalDias = 0;
    iUserSickLeavesObj.bajas = [];

    return iUserSickLeavesObj;
  }
}
