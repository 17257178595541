import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
} from '@quasar-dynamics/basic-designsystem';
import { iContact } from '../../Interfaces/Api/Contact/iContact';
import { iContactFilter } from '../../Interfaces/Api/Contact/iContactFilter';

export class Contact {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  filter: iContactFilter = {
    num_pagina: 1,
    num_devoluciones: 10,
    cliente: -1,
  };
  createEditObj: iContact = {
    nombre: '',
    puesto: '',
    cliente: null,
  };

  contactList: Array<iContact> = [];

  /**
   * OPTIONS
   */
  nameOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Nombre',
    classList: 'specialFormInput',
  };
  surnameOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: false,
    label: 'Apellidos',
    classList: 'specialFormInput',
  };
  emailOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: false,
    label: 'Email',
    classList: 'specialFormInput',
  };
  phoneOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: false,
    label: 'Teléfono',
    classList: 'specialFormInput',
  };
  positionOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Cargo',
    classList: 'specialFormInput',
  };
  observationsOptions: IOptionCustomTextArea = {
    label: 'Observaciones',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
    rows: 6,
    cols: 0,
  };
}
