export interface iDailyProcess {
  id?: number | null;
  nombre: string;
  fase: any;
}

export class iDailyProcess {
  static convertFromCollection(ob: any): Array<iDailyProcess> {
    let iDailyProcessCollection: Array<iDailyProcess> = [];
    ob.forEach((element: any) => {
      iDailyProcessCollection.push(this.convertFromObject(element));
    });
    return iDailyProcessCollection;
  }

  static convertFromObject(ob: any): iDailyProcess {
    let iDailyProcessObj = new iDailyProcess();
    iDailyProcessObj.id = ob.id;
    iDailyProcessObj.nombre = ob.nombre;
    iDailyProcessObj.fase = ob.fase;
    return iDailyProcessObj;
  }

  static createEmptyObject(): iDailyProcess {
    let iDailyProcessObj = new iDailyProcess();
    iDailyProcessObj.nombre = '';
    iDailyProcessObj.fase = null;
    return iDailyProcessObj;
  }
}
