<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Generar un nuevo contrato</p>
  </div>
  <div class="body">
    <div class="imageContainer" [ngClass]="{'placeholder': !modalData.profile}">
      <img [src]="modalData.profile" (error)="generateRandonImage($event.target)" alt="">
    </div>
    <p class="title">{{modalData.nombre}} {{modalData.apellidos}}</p>
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="contractClass.startDateOption"
          (change)="getStartDate($event); checkIfIsDataCompleted()"></customInput>
        <customInput [options]="contractClass.endDateOption" (change)="getEndDate($event); checkIfIsDataCompleted()">
        </customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="contractClass.costOption" [(ngModel)]="contractClass.newContract.costeHora"
          (change)="checkIfIsDataCompleted()">
        </customInput>
        <customInput [options]="contractClass.hoursOption" [(ngModel)]="contractClass.newContract.horas"
          (change)="checkIfIsDataCompleted()"></customInput>
      </div>
    </div>

  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !contractClass.isDataComplete}"
      (click)="validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!contractClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="contractClass.isLoading"></mat-spinner>
        <span *ngIf="!contractClass.isLoading">Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>