import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { BonusHoursService } from 'src/app/Services/Api/bonus-hours.service';
import { BonusHours } from 'src/app/Shared/Class/BonusHours/bonus-hours';

@Component({
  selector: 'app-create-edit-bonus-hours-popup',
  templateUrl: './create-edit-bonus-hours-popup.component.html',
  styleUrls: ['./create-edit-bonus-hours-popup.component.scss'],
})
export class CreateEditBonusHoursPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  bonusHoursClass: BonusHours = new BonusHours();
  constructor(
    public dialogRef: MatDialogRef<CreateEditBonusHoursPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private bonusHoursSE: BonusHoursService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.bonusHoursClass.createEditObj = this.modalData.bonusHours;
    this.bonusHoursClass.backupCreateEditObj = JSON.parse(
      JSON.stringify(this.modalData.bonusHours)
    );
    this.bonusHoursClass.isDataCompleted = true;
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    !this.modalData.edit
      ? this.validateIfDataIsCorrectHandler()
      : this.forceClosePopup();
  }
  validateIfDataIsCorrectHandler() {
    if (this.bonusHoursClass.isDataCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Hay campos obligatiorios que estan vacíos.'
      );
    }
  }
  checkIfDataIsCompleted() {
    const { nombre, precio, horas, codigo } =
      this.bonusHoursClass.createEditObj;
    this.bonusHoursClass.isDataCompleted =
      !!nombre && !!precio && !!horas && !!codigo;
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = { id: this.bonusHoursClass.createEditObj.id };
      obj[element] = value;
      this.update(obj);
    }
  }

  /**
   * API RESPONSES
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.bonusHoursClass.isLoading = false;
    if (!this.modalData.edit) {
      setTimeout(() => {
        this.closePopup();
      }, 500);
    }
  }
  errorResponseHandler() {
    this.bonusHoursClass.isLoading = false;
  }

  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bonusHoursSE.create(
      behaviorSubject,
      this.bonusHoursClass.createEditObj
    );
    this.bonusHoursClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successResponseHandler(res),
            error: false,
          },
          {
            method: this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bonusHoursSE.update(behaviorSubject, obj);
    this.bonusHoursClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successResponseHandler(res),
            error: false,
          },
          {
            method: this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
