import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { CreateEditFunnelProposal } from 'src/app/Shared/Class/Funnel/create-edit-funnel-proposal';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { CrmService } from 'src/app/Services/Api/crm.service';
import { Funnel } from 'src/app/Shared/Class/Funnel/funnel';
import { ClientService } from 'src/app/Services/Api/client.service';
import { ProjectService } from 'src/app/Services/Api/project.service';
import { CreateFunnelBudget } from 'src/app/Shared/Class/Funnel/create-funnel-budget';
import { CreateFunnelBudgetPopupComponent } from '../create-funnel-budget-popup/create-funnel-budget-popup.component';

@Component({
  selector: 'app-create-edit-funnel-proposal',
  templateUrl: './create-edit-funnel-proposal-popup.component.html',
  styleUrls: ['./create-edit-funnel-proposal-popup.component.scss'],
})
export class CreateEditFunnelProposalPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeHandler();
    }
  }
  createEditFunnelProposalClass: CreateEditFunnelProposal =
    new CreateEditFunnelProposal();
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private crmSE: CrmService,
    private clientSE: ClientService,
    private projectSE: ProjectService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}
  ngOnInit(): void {
    this.checkIfModalDataHasDataAndSetIt();
    this.clickOutClosePopupHandler();

    this.getAllColumns();
    this.getAllClients();
    this.getAllProjects();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  goToEditActualFunnelBudget() {
    this.forceClosePopup();
    this.staticUtilitiesSE.goTo(
      '/portfolio/cotizador/editar-oferta',
      slideNavigationTypes.slideToTop,
      { proyecto: this.createEditFunnelProposalClass.newFunnelBudget.proyecto }
    );
  }
  /**
   * POPUPS
   */
  openCreateClientPopup() {
    this.closePopup({
      otherPopup: true,
      client: true,
      budget: false,
      data: this.createEditFunnelProposalClass.newFunnelBudget,
    });
  }
  openCreateFunnelBudgetPopup() {
    this.closePopup({
      otherPopup: true,
      client: false,
      budget: true,
      clientId: this.createEditFunnelProposalClass.newFunnelBudget.cliente,
      data: this.createEditFunnelProposalClass.newFunnelBudget,
    });
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.closeHandler();
      }
    });
  }
  validateIfDataIsCorrectHandler() {
    if (this.createEditFunnelProposalClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  validateIfDataIsCompleted() {
    const { cliente, estadoCRM, temperatura } =
      this.createEditFunnelProposalClass.newFunnelBudget;

    this.createEditFunnelProposalClass.isCompleted =
      !!cliente && !!estadoCRM && !!temperatura;
  }
  successGetAllColumnResponseHandler(res: iResultHttp) {
    this.createEditFunnelProposalClass.columnOption.items = [...res.data];
  }
  successGetAllClientResponseHandler(res: iResultHttp) {
    this.createEditFunnelProposalClass.clientOption.items = [...res.data];
  }
  successGetAllProjectResponseHandler(res: iResultHttp) {
    this.createEditFunnelProposalClass.projects = [...res.data.data];
    this.getClientProjects();
  }
  successGetAllBudgetsResponseHandler(res: iResultHttp) {
    this.createEditFunnelProposalClass.budgets = [...res.data];
  }
  successCreateResponseHandler(res: iResultHttp) {
    this.createEditFunnelProposalClass.isLoading = false;
    StaticUtilitiesService.showFeedback(String(res.message));
    setTimeout(() => {
      this.closePopup({ otherPopup: false });
    }, 500);
  }
  errorCreateResponseHandler(res: iResultHttp) {
    this.createEditFunnelProposalClass.isLoading = false;
  }
  successUpdateResponseHandler(res: iResultHttp) {
    this.createEditFunnelProposalClass.isLoading = false;
    StaticUtilitiesService.showFeedback(String(res.message));
  }
  errorUpdateResponseHandler(res: iResultHttp) {
    this.createEditFunnelProposalClass.isLoading = false;
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      switch (element) {
        case 'temperatura':
          if (value) {
            const obj: any = {
              id: this.modalData.data.id,
              cliente:
                this.createEditFunnelProposalClass.newFunnelBudget.cliente,
              temperatura:
                this.createEditFunnelProposalClass.newFunnelBudget.temperatura,
              presupuesto:
                this.createEditFunnelProposalClass.newFunnelBudget.presupuesto,
              proyecto:
                this.createEditFunnelProposalClass.newFunnelBudget.proyecto,
            };
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo temperatura es obligatorio y no puede estar vacío'
            );
          }
          break;
        default:
          const obj: any = {
            id: this.modalData.data.id,
            cliente: this.createEditFunnelProposalClass.newFunnelBudget.cliente,
            temperatura:
              this.createEditFunnelProposalClass.newFunnelBudget.temperatura,
            presupuesto:
              this.createEditFunnelProposalClass.newFunnelBudget.presupuesto,
            proyecto:
              this.createEditFunnelProposalClass.newFunnelBudget.proyecto,
          };
          this.update(obj);
          break;
      }
    }
  }
  closeHandler() {
    if (this.modalData.edit) {
      this.closePopup();
    } else {
      this.forceClosePopup();
    }
  }
  /**
   * GETTERS & SETTERS
   */
  getClientProjects() {
    this.createEditFunnelProposalClass.projectsOption.items = [
      ...this.createEditFunnelProposalClass.projects.filter(
        (project) =>
          project.cliente?.id ===
          this.createEditFunnelProposalClass.newFunnelBudget.cliente
      ),
    ];
  }
  checkIfModalDataHasDataAndSetIt() {
    if (this.modalData.data) {
      this.createEditFunnelProposalClass.newFunnelBudget.id =
        this.modalData.data.id;

      if (this.modalData.data.cliente.id !== undefined) {
        this.createEditFunnelProposalClass.newFunnelBudget.cliente =
          this.modalData.data.cliente.id;
      } else {
        this.createEditFunnelProposalClass.newFunnelBudget.cliente =
          this.modalData.data.cliente;
      }

      this.createEditFunnelProposalClass.newFunnelBudget.temperatura =
        this.modalData.data.temperatura;
      this.createEditFunnelProposalClass.newFunnelBudget.proyecto =
        this.modalData.data.proyecto != null
          ? this.modalData.data.proyecto?.id
          : null;
      this.createEditFunnelProposalClass.newFunnelBudget.presupuesto =
        this.modalData.data.presupuesto != null
          ? Number(this.modalData.data.presupuesto.toFixed(2))
          : Number(this.modalData.data.proyecto?.presupuesto.toFixed(2));
      if (this.createEditFunnelProposalClass.newFunnelBudget.proyecto) {
        this.createEditFunnelProposalClass.budgetOptions.readOnly = true;
      }
    }
  }
  setBudgetData() {
    this.createEditFunnelProposalClass.projectsOption.items.filter(
      (project) =>
        project.id ===
        this.createEditFunnelProposalClass.newFunnelBudget.proyecto
    );
    this.createEditFunnelProposalClass.newFunnelBudget.presupuesto =
      this.createEditFunnelProposalClass.projectsOption.items.filter(
        (project) =>
          project.id ===
          this.createEditFunnelProposalClass.newFunnelBudget.proyecto
      )[0].presupuesto;
  }
  /**
   * CALLS TO API
   */
  getAllColumns() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.crmSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllColumnResponseHandler(res),
          error: false,
        });
      });
  }
  getAllClients() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.clientSE.getAllCompanyClients(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllClientResponseHandler(res),
          error: false,
        });
      });
  }
  getAllProjects() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.getAll(
      behaviorSubject,
      this.createEditFunnelProposalClass.mainProjectFilter
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllProjectResponseHandler(res),
          error: false,
        });
      });
  }
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.createEditFunnelProposalClass.isLoading = true;
    this.crmSE.createCRMBudget(
      behaviorSubject,
      this.createEditFunnelProposalClass.newFunnelBudget
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
  update(data: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.createEditFunnelProposalClass.isLoading = true;
    this.crmSE.updateCRMBudget(behaviorSubject, data);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorUpdateResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
}
