import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ProductService } from 'src/app/Services/Api/product.service';

@Component({
  selector: 'app-add-multiple-products-revision-popup',
  templateUrl: './add-multiple-products-revision-popup.component.html',
  styleUrls: ['./add-multiple-products-revision-popup.component.scss'],
})
export class AddMultipleProductsRevisionPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  selectedProducts: Array<any> = [];
  isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddMultipleProductsRevisionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private productSE: ProductService
  ) {}

  ngOnInit(): void {}
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  removeSelectedProducts() {
    this.selectedProducts.forEach((element) => {
      this.modalData.products.productos.splice(
        this.modalData.products.productos.indexOf(element),
        1
      );
    });
    StaticUtilitiesService.showFeedback(
      'Se han eliminado los productos seleccionados correctamente'
    );
    this.modalData.products.productos = [...this.modalData.products.productos];
    if (this.modalData.products.productos.length === 0) {
      this.forceClosePopup();
    }
  }
  /**
   * GETTERS && SETTERS
   */
  getSelectedProducts(selectedProducts: any) {
    this.selectedProducts = [...selectedProducts];
  }

  /**
   * HANDLERS
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorResponseHandler() {
    this.isLoading = false;
  }
  getProductsTreatedHandler() {
    const copyModalData: any = JSON.parse(
      JSON.stringify(this.modalData.products)
    );
    copyModalData.productos.forEach((element) => {
      element.categorias = element.categorias.map((category) => {
        return category.id;
      });
      element['fiscalidad'] = element.auxFiscalidad?.id;
    });
    return copyModalData;
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.productSE.create(behaviorSubject, this.getProductsTreatedHandler());
    this.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
