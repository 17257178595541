import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iInvoiceTable } from 'src/app/Shared/Interfaces/Api/Bill/iInvoiceTable';
import { iSimpleEmployee } from 'src/app/Shared/Interfaces/Api/Employee/iSimpleEmployee';
import { iFunnelBudget } from 'src/app/Shared/Interfaces/Api/Funnel/iFunnelBudget';
import { iProjectIncidences } from 'src/app/Shared/Interfaces/Api/Incidences/iProjectIncidences';
import { iProjectPhases } from 'src/app/Shared/Interfaces/Api/Phases/iProjectPhases';
import { iGeneralProjectDetail } from 'src/app/Shared/Interfaces/Api/Project/iGeneralProjectDetail';
import { iProject } from 'src/app/Shared/Interfaces/Api/Project/iProject';
import { iProjectFilter } from 'src/app/Shared/Interfaces/Api/Project/iProjectFilter';
import { iProjectSelector } from 'src/app/Shared/Interfaces/Api/Project/iProjectSelector';
import { iProjectStatistic } from 'src/app/Shared/Interfaces/Api/Project/iProjectStatistic';
import { iProjectCountStatistics } from 'src/app/Shared/Interfaces/Api/Project/iProjectStatistics';
import { iSimpleProject } from 'src/app/Shared/Interfaces/Api/Project/iSimpleProject';
import { iSingleProjectHistoric } from 'src/app/Shared/Interfaces/Api/Project/iSingleProjectHistoric';
import { iSingleProjectInfo } from 'src/app/Shared/Interfaces/Api/Project/iSingleProjectInfo';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getAllSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/proyectos/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iProjectSelector.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllSelector,
            args: [behaviorSubject],
          });
        },
      });
  }
  getAll(
    behaviorSubject: BehaviorSubject<any>,
    projectFilters: iProjectFilter
  ) {
    this.http
      .get(
        this.urlApi + '/api/proyecto' + this.optionsGet(projectFilters),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              data: iProject.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
              total: data.total,
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, projectFilters],
          });
        },
      });
  }
  getAllByUser(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(
        this.urlApi + '/api/proyectos/empleado' + this.optionsGet({ user: id }),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iSimpleProject.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllByUser,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getOverloadPotentialProject(
    behaviorSubject: BehaviorSubject<any>,
    id: number
  ) {
    this.http
      .get(
        this.urlApi + '/api/implicacion/sobrecargados/' + id,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getOverloadPotentialProject,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getSingle(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iGeneralProjectDetail.convertFromObject(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingle,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getSingleProjectInfo(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/info/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iSingleProjectInfo.convertFromObject(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectInfo,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getSingleProjectStatistic(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/estadisticas/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iProjectStatistic.convertFromObject(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectStatistic,
            args: [behaviorSubject, id],
          });
        },
      });
  }

  getSingleProjectHistorics(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/historico/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iSingleProjectHistoric.convertFromObject(
              iStaticUtilities.normalizeNames(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectHistorics,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getSingleProjectIncidences(
    behaviorSubject: BehaviorSubject<any>,
    id: number
  ) {
    this.http
      .get(this.urlApi + '/api/proyecto/incidencias/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iProjectIncidences.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectIncidences,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getSingleProjectCosts(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/costes/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iStaticUtilities.normalizeNames(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectCosts,
            args: [behaviorSubject, id],
          });
        },
      });
  }

  getSingleProjectPhases(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/fases/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iProjectPhases.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectPhases,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getSingleProjectProduct(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/productos/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectProduct,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getSingleProjectPaymentMilestone(
    behaviorSubject: BehaviorSubject<any>,
    id: number
  ) {
    this.http
      .get(this.urlApi + '/api/proyecto/facturas/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iInvoiceTable.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectPaymentMilestone,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getSingleProjectEmployees(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/proyecto/empleados/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iSimpleEmployee.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleProjectEmployees,
            args: [behaviorSubject, id],
          });
        },
      });
  }

  getStatistics(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/proyecto/estadisticas', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iProjectCountStatistics.convertFromObject(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStatistics,
            args: [behaviorSubject],
          });
        },
      });
  }
  update(behaviorSubject: BehaviorSubject<any>, object: any) {
    this.http
      .put(this.urlApi + '/api/proyecto/' + object.id, object, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha cambiado el estado del proyecto correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, object],
          });
        },
      });
  }
  create(behaviorSubject: BehaviorSubject<any>, object: iFunnelBudget) {
    this.http
      .post(this.urlApi + '/api/proyecto', object, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha creado la oferta correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, object],
          });
        },
      });
  }
  updateAllHistorics(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(
        this.urlApi + '/api/proyecto/actualizar/historicos',
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updateAllHistorics,
            args: [behaviorSubject],
          });
        },
      });
  }
  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(this.urlApi + '/api/proyecto', this.sendBodyOptions({ id: id }))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
