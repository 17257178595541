import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './Pages/login/login.component';
import { LoggedGuard } from './Shared/Guards/logged.guard';
import { PreviewDocumentComponent } from './Pages/preview-document/preview-document.component';
import { RegisterComponent } from './Pages/register/register.component';
import { PageNotFoundComponent } from './Pages/page-not-found/page-not-found.component';
import { EmailValidationComponent } from './Pages/email-validation/email-validation.component';
import { RegisterReminderEmailValidationComponent } from './Pages/register/register-reminder-email-validation/register-reminder-email-validation.component';
import { SendRecoveryPasswordEmailComponent } from './Pages/send-recovery-password-email/send-recovery-password-email.component';
import { RecoveryPasswordComponent } from './Pages/recovery-password/recovery-password.component';
import { RolePermissionGuard } from './Shared/Guards/role-permission.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'validacion',
    component: EmailValidationComponent,
    data: { title: 'Validación de cuenta' },
  },

  /**
   * LOGIN
   */
  {
    path: 'recuperacion-de-cuenta',
    component: SendRecoveryPasswordEmailComponent,
    data: { title: 'Enviar email de recuperación de contraseña' },
  },
  {
    path: 'recuperar-cuenta',
    component: RecoveryPasswordComponent,
    data: { title: 'Cambiar contraseña' },
  },
  {
    path: 'registro',
    component: RegisterComponent,
    data: { title: 'Date de alta como empresa' },
  },
  {
    path: 'verificacion',
    component: RegisterReminderEmailValidationComponent,
    data: { title: 'Comprueba el correo electrónico' },
  },
  {
    path: 'previsualizar-documento',
    canActivate: [LoggedGuard],
    component: PreviewDocumentComponent,
    data: { title: 'Previsualización del documento' },
  },
  {
    path: 'implicacion',
    canActivate: [LoggedGuard],
    loadChildren: () =>
      import('./Pages/implication/implication.module').then(
        (m) => m.ImplicacionModule
      ),
  },
  {
    path: 'ajustes-generales/mi-perfil',
    canActivate: [LoggedGuard],
    loadChildren: () =>
      import('./Pages/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'home',
    canActivate: [LoggedGuard],
    loadChildren: () =>
      import('./Pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'recursos',
    canActivate: [LoggedGuard, RolePermissionGuard],
    loadChildren: () =>
      import('./Pages/resources/resource.module').then((m) => m.ResourceModule),
  },
  {
    path: 'portfolio',
    canActivate: [LoggedGuard, RolePermissionGuard],
    loadChildren: () =>
      import('./Pages/portfolio/portfolio.module').then(
        (m) => m.PortfolioModule
      ),
  },
  {
    path: 'contabilidad',
    canActivate: [LoggedGuard, RolePermissionGuard],
    loadChildren: () =>
      import('./Pages/treasury/treasury.module').then((m) => m.TreasuryModule),
  },
  {
    path: 'crm',
    canActivate: [LoggedGuard, RolePermissionGuard],
    loadChildren: () =>
      import('./Pages/crm/crm.module').then((m) => m.CrmModule),
  },
  {
    path: 'ajustes-generales',
    canActivate: [LoggedGuard],
    loadChildren: () =>
      import('./Pages/general-settings/general-settings.module').then(
        (m) => m.GeneralSettingsModule
      ),
  },
  {
    path: 'lineas-negocio',
    canActivate: [LoggedGuard, RolePermissionGuard],
    loadChildren: () =>
      import('./Pages/bussiness-line/bussiness-line.module').then(
        (m) => m.BussinessLineModule
      ),
  },

  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    data: { title: 'La página que busca no existe' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
