import {
  StaticUtilitiesService,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iCreateDaily } from '../../Interfaces/Api/Daily/iCreateDaily';
import { iProyectProcessSelector } from '../../Interfaces/Api/Process/iProyectProcessSelector';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/overrideStaticUtilities.service';

export class CreateDaily {
  isAdmin: boolean = false;
  newDaily: iCreateDaily = iCreateDaily.createEmptyObject();
  procesos: Array<iProyectProcessSelector> = [];
  isCompleteData: boolean = false;
  isRecurring: boolean = false;
  isLoading: boolean = false;
  recurrencySelected: number | null = null;
  recurrencyEndDate: any = null;
  userId: number = Number(localStorage.getItem('idUser'));
  daysRange: Array<string> = [];
  nameOption: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  dateOption: iOptionsCustomInput = {
    label: 'Fecha',
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    classList: 'specialFormInput',
    required: true,

    date: {
      selectedDate: new Date(),
      minDate: new Date(),
    },
  };
  employeesOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'name',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: false,
    search: true,
  };
  proyectOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: true,
    search: true,
  };
  processOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: true,
    search: true,
  };
  subprocessOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: true,
    search: true,
  };
  hoursOption: iOptionsCustomInput = {
    label: 'Horas estimadas',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  minutesOption: iOptionsCustomInput = {
    label: 'Minutos estimados',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  recurrencyOption: iOptionsSelector = {
    items: [
      {
        label: 'Todos los días hábiles (de lunes a viernes)',
        value: 1,
      },
      {
        label: 'Cada semana, el ',
        value: 2,
      },
      {
        label: 'Todos los meses, el ',
        value: 3,
      },
      {
        label: 'Todos los meses, el último ',
        value: 4,
      },
      {
        label: 'Anualmente, el ',
        value: 5,
      },
    ],
    placeholder: 'Selecciona una opción...',
    label: 'Recurrencia',
    append: true,
    bindLabel: 'label',
    bindValue: 'value',
    classList: 'specialFormSelector',
    clearable: true,
    search: true,
    required: true,
  };
  endDateOption: iOptionsCustomInput = {
    label: 'Fecha de fin',
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    classList: 'specialFormInput',
    required: true,
    date: {
      minDate: new Date(),
    },
  };
  constructor() {}

  /**
   * FUNCTIONALITIES
   */
  filterProyectProcesses() {
    this.newDaily.proceso = null;
    this.processOption.items = [
      ...this.procesos.filter((element) => {
        return (
          element.proyecto === this.newDaily.proyecto && !element.subProceso
        );
      }),
    ];
  }
  filterProyectSubProcesses() {
    this.newDaily.proceso = null;
    this.subprocessOption.items = [
      ...this.procesos.filter((element) => {
        return (
          element.proyecto === this.newDaily.proyecto && element.subProceso
        );
      }),
    ];
  }
  updateEstimatedHours() {
    if (this.newDaily.horas && this.newDaily.minutos) {
      this.newDaily.horasEstimadas =
        Number(this.newDaily.horas) + Number(this.newDaily.minutos) / 60;

      return;
    }
    if (this.newDaily.horas && !this.newDaily.minutos) {
      this.newDaily.horasEstimadas = Number(this.newDaily.horas);
      return;
    }
    if (!this.newDaily.horas && this.newDaily.minutos) {
      this.newDaily.horasEstimadas = Number(this.newDaily.minutos) / 60;
      return;
    }
  }
  checkIfIsCompleteData() {
    const { nombreTarea, fecha, horas } = this.newDaily;
    this.isCompleteData = !!nombreTarea && !!fecha && !!horas;
  }
  updateRecurrencyLabel() {
    const selectedDate: Date = new Date(this.newDaily.fecha);

    this.recurrencyOption.items[1].label =
      'Cada semana, el ' +
      OverrideStaticUtilitiesService.getDayName(selectedDate).toLowerCase();

    this.recurrencyOption.items[2].label =
      'Todos los meses, el  ' +
      OverrideStaticUtilitiesService.getWeekdayOccurrence(
        selectedDate
      ).toLowerCase();
    this.recurrencyOption.items[3].label =
      'Todos los meses, el último ' +
      OverrideStaticUtilitiesService.getDayName(selectedDate).toLowerCase();

    this.recurrencyOption.items[4].label =
      'Anualmente, el ' +
      selectedDate.getDate() +
      ' de ' +
      OverrideStaticUtilitiesService.getMonthName(selectedDate.getMonth());
    this.recurrencyOption.items = [...this.recurrencyOption.items];

    if (!this.isRecurring) {
      this.recurrencySelected = null;
      this.recurrencyEndDate = null;
      this.newDaily.rango = [];
      this.newDaily.rango.push(this.newDaily.fecha);
    }
  }
}
