import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iRegister } from '../../Interfaces/Api/Register/iRegister';

export class Register {
  isCompleteData: boolean = false;
  newObj: iRegister = {
    nombre: '',
    apellidos: '',
    username: '',
    password: '',
    horas: 40,
    empresa: '',
  };
  isChecked: boolean = false;
  /**
   * OPTIONS
   */
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  surnameOptions: iOptionsCustomInput = {
    label: 'Apellidos',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  emailOptions: iOptionsCustomInput = {
    label: 'Correo electrónico',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  passwordOptions: iOptionsCustomInput = {
    label: 'Contraseña',
    type: 'password',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  companyOptions: iOptionsCustomInput = {
    label: 'Empresa',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
}
