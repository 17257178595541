<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Gestionar baja médica</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="createSickLeaveClass.dateOption"
        (change)="createSickLeaveClass.date = $event; checkIfDataIsCompleted()"
        [selectedDate]="createSickLeaveClass.date"></customInput>
      <div class="customFormContainer">
        <label for="">Categoría<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [ngClass]="{'readOnly': modalData.edit || (!modalData.edit && modalData.obj)}"
          [options]="createSickLeaveClass.categoryOptions"
          [(ngModel)]="createSickLeaveClass.createSickLeaveObj.categoria" (change)="checkIfDataIsCompleted()">
        </selector>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createSickLeaveClass.isCompleted}" (click)="create()">
      <ng-container>
        <mat-icon *ngIf="!createSickLeaveClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createSickLeaveClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>