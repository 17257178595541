export interface iClientList {
  id: number | null;
  nombre: string;
  cif: string;
  email: string;
  telefono: string;
  direccionFiscal: string;
  proyectos: number | null;
}

export class iClientList {
  static convertFromCollection(ob: any): Array<iClientList> {
    let iClientListCollection: Array<iClientList> = [];
    ob.forEach((element: any) => {
      iClientListCollection.push(this.convertFromObject(element));
    });
    return iClientListCollection;
  }

  static convertFromObject(ob: any): iClientList {
    let iClientListObj = new iClientList();
    iClientListObj.id = ob.id;
    iClientListObj.nombre = ob.nombre;
    iClientListObj.cif = ob.cif;
    iClientListObj.email = ob.mail;
    iClientListObj.telefono = ob.telefono;
    iClientListObj.direccionFiscal = ob.direccionFiscal;
    iClientListObj.proyectos = ob.proyectos.length;
    return iClientListObj;
  }

  static createEmptyObject(): iClientList {
    let iClientListObj = new iClientList();
    iClientListObj.id = null;
    iClientListObj.nombre = '';
    iClientListObj.cif = '';
    iClientListObj.email = '';
    iClientListObj.telefono = '';
    iClientListObj.direccionFiscal = '';
    iClientListObj.proyectos = null;
    return iClientListObj;
  }
}
