<div class="loginGeneralContainer">
  <div class="left">
    <form (submit)="loginClass.isDataCompleted && loginClass.isValidFormat ? login(): null">
      <div class="logo">
        <img src="/assets/Images/Logos/Fenech_V2-20.png" />
      </div>
      <div class="welcomeMessageContainer">
        <p class="title">Bienvenido</p>
        <p class="subtitle">Introduce tus datos para ingresar en Fennech</p>
      </div>
      <div class="inputs">
        <customInput [options]="loginClass.emailOptions" [(ngModel)]="loginClass.loginData.username" name="username"
          (input)="loginClass.checkIfCompletedAndValidFormat()" (change)="loginClass.checkIfIsValidEmailFormat()">
        </customInput>
        <customInput [options]="loginClass.passwordOptions" [(ngModel)]="loginClass.loginData.password" name="password"
          (input)="loginClass.checkIfCompletedAndValidFormat()">
        </customInput>
      </div>
      <btn class="loginButton" [ngClass]="{'disabled': !loginClass.isDataCompleted || !loginClass.isValidFormat}">
        <ng-container>
          <span>Iniciar sesión</span>
        </ng-container>
      </btn>
      <a class="recoveryPassword" routerLink="/recuperacion-de-cuenta" routerAnimationTop>He olvidado mi contraseña</a>
    </form>
    <div class="footer">
      <img src="/assets/Images/Logos/logoQuasarDashboard.png" />
      <p>2023 Copyright © Atlas Venture Builder S.L. Valencia, España</p>
    </div>
  </div>
  <div class="right"></div>
</div>