export interface iCreateEditProcess {
  id: number | null;
  nombre: string;
  horas: number | null;
  fechaInicial: Date;
  fechaFin: Date;
  completado: number | null;
  empleados: Array<number>;
  fase: { id: number | null; nombre: string };
  proyecto: { id: number | null; nombre: string };
}

export class iCreateEditProcess {
  static createEmptyObject(): iCreateEditProcess {
    let iCreateEditProcessObj = new iCreateEditProcess();
    iCreateEditProcessObj.id = null;
    iCreateEditProcessObj.nombre = '';
    iCreateEditProcessObj.horas = null;
    iCreateEditProcessObj.fechaInicial = new Date();
    iCreateEditProcessObj.fechaFin = new Date();
    iCreateEditProcessObj.completado = null;
    iCreateEditProcessObj.empleados = [];
    iCreateEditProcessObj.fase = { id: null, nombre: '' };
    iCreateEditProcessObj.proyecto = { id: null, nombre: '' };

    return iCreateEditProcessObj;
  }
}
