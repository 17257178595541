import { Injectable } from '@angular/core';
import { sha256 } from '@quasar-dynamics/basic-designsystem';
import { iRoutes } from 'src/app/Shared/Interfaces/Utils/iRoutes';

@Injectable({
  providedIn: 'root',
})
export class OverrideStaticUtilitiesService {
  private routes: Array<iRoutes> = [
    {
      label: 'Dashboard',
      icon: '/assets/Icons/Sidebar/dashboard.svg',
      route: '/home/dashboard',
      activeRoute: '/home/dashboard',
      canBeOpened: false,
      isAdmin: true,
      isIntermediate: true,
      isGestor: true,
      permiso: 1,
    },
    {
      label: 'Lineas de negocio',
      icon: '/assets/Icons/Sidebar/bussiness-line.svg',
      route: '/lineas-negocio/listado',
      activeRoute: '/lineas-negocio',
      canBeOpened: false,
      isAdmin: true,
      isIntermediate: false,
      isGestor: true,
      permiso: 2,
    },
    {
      label: 'Portfolio',
      icon: '/assets/Icons/Sidebar/portfolio.svg',
      route: '/portfolio',
      activeRoute: '/portfolio',
      canBeOpened: true,
      isAdmin: true,
      isIntermediate: true,
      isGestor: true,
      permiso: 3,
      subRoutes: [
        {
          label: 'Cotizador',
          route: '/portfolio/cotizador/nuevo-presupuesto',
          activeRoute: '/portfolio/cotizador',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Plantillas de cotización',
          route: '/portfolio/plantillas-cotizacion',
          activeRoute: '/portfolio/plantillas-cotizacion',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Proyectos',
          route: '/portfolio/proyectos',
          activeRoute: '/portfolio/proyectos',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Borradores',
          route: '/portfolio/borradores',
          activeRoute: '/portfolio/borradores',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
      ],
    },
    {
      label: 'Recursos',
      icon: '/assets/Icons/Sidebar/team.svg',
      route: '/recursos/empleados/dashboard',
      activeRoute: '/recursos',
      canBeOpened: true,
      isAdmin: true,
      isIntermediate: true,
      isGestor: true,
      permiso: 4,
      subRoutes: [
        {
          label: 'Empleados',
          route: '/recursos/empleados',
          activeRoute: '/recursos/empleados',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Futuribles',
          route: '/recursos/futuribles',
          activeRoute: '/recursos/futuribles',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
      ],
    },
    {
      label: 'Implicación',
      icon: '/assets/Icons/Sidebar/implicacion.svg',
      route: '/implicacion/daily',
      activeRoute: '/implicacion',
      canBeOpened: true,
      isAdmin: false,
      isGestor: true,
      isIntermediate: true,
      permiso: 5,
      subRoutes: [
        {
          label: 'Sprint',
          route: '/implicacion/sprint',
          activeRoute: '/implicacion/sprint',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
          permiso: 5,
        },
        {
          label: 'Daily',
          route: '/implicacion/daily',
          activeRoute: '/implicacion/daily',
          canBeOpened: false,
          isAdmin: false,
          isIntermediate: false,
          isGestor: true,
        },
        {
          label: 'Registro tareas',
          route: '/implicacion/registro',
          activeRoute: '/implicacion/registro',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
          permiso: 5,
        },
        {
          label: 'Seguimiento diario',
          route: '/implicacion/seguimiento',
          activeRoute: '/implicacion/seguimiento',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
          permiso: 5,
        },
      ],
    },
    {
      label: 'Contabilidad',
      icon: '/assets/Icons/Sidebar/tesoreria.svg',
      route: '/contabilidad',
      activeRoute: '/contabilidad',
      canBeOpened: true,
      isAdmin: true,
      isIntermediate: true,
      isGestor: true,
      permiso: 6,
      subRoutes: [
        {
          label: 'Ingresos',
          route: '/contabilidad/ingresos',
          activeRoute: '/contabilidad/ingresos',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Tesorería',
          route: '/contabilidad/tesoreria',
          activeRoute: '/contabilidad/tesoreria',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Inventario',
          route: '/contabilidad/inventario',
          activeRoute: '/contabilidad/inventario',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Bonos de horas',
          route: '/contabilidad/bonos',
          activeRoute: '/contabilidad/bonos',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Proveedores',
          route: '/contabilidad/proveedores',
          activeRoute: '/contabilidad/proveedores',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
      ],
    },
    {
      label: 'CRM',
      icon: '/assets/Icons/Sidebar/crm.svg',
      route: '/crm',
      activeRoute: '/crm',
      canBeOpened: true,
      isAdmin: true,
      isIntermediate: true,
      isGestor: true,
      permiso: 7,
      subRoutes: [
        {
          label: 'Funnel',
          route: '/crm/funnel',
          activeRoute: '/crm/funnel',
          canBeOpened: true,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
        {
          label: 'Clientes',
          route: '/crm/clientes',
          activeRoute: '/crm/clientes',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
        },
      ],
    },
    {
      label: 'Ajustes generales',
      icon: '/assets/Icons/Sidebar/settings.svg',
      route: '/ajustes-generales/mi-perfil',
      activeRoute: '/ajustes-generales',
      isGestor: true,
      canBeOpened: true,
      isAdmin: true,
      isIntermediate: true,
      subRoutes: [
        {
          label: 'Preferencias',
          route: '/ajustes-generales/preferencias',
          activeRoute: '/ajustes-generales/preferencias',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
          permiso: 8,
        },
        {
          label: 'Registro de fichajes',
          route: '/ajustes-generales/registro-de-fichajes',
          activeRoute: '/ajustes-generales/registro-de-fichajes',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
          permiso: 9,
        },
        {
          label: 'Registro de accesos',
          route: '/ajustes-generales/registro-de-accesos',
          activeRoute: '/ajustes-generales/registro-de-accesos',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: true,
          permiso: 9,
        },
        {
          label: 'Roles y permisos',
          route: '/ajustes-generales/roles-permisos',
          activeRoute: '/ajustes-generales/roles-permisos',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: true,
          isGestor: false,
        },
        {
          label: 'Opciones de cuenta',
          route: '/ajustes-generales/opciones-de-cuenta',
          activeRoute: '/ajustes-generales/opciones-de-cuenta',
          canBeOpened: false,
          isAdmin: true,
          isIntermediate: false,
          isGestor: false,
        },
        {
          label: 'Mi perfil',
          route: '/ajustes-generales/mi-perfil',
          activeRoute: '/ajustes-generales/mi-perfil',
          canBeOpened: false,
          isAdmin: false,
          isIntermediate: false,
          isGestor: true,
        },
      ],
    },
  ];
  constructor() {}

  static returnRandomPlaceholderImage() {
    const randomUrlImages: Array<string> = [
      './assets/Images/Placeholder/fennech_placeholder.png',
      './assets/Images/Placeholder/fennech_placeholder2.png',
      './assets/Images/Placeholder/fennech_placeholder3.png',
      './assets/Images/Placeholder/fennech_placeholder4.png',
      './assets/Images/Placeholder/fennech_placeholder5.png',
      './assets/Images/Placeholder/fennech_placeholder6.png',
      './assets/Images/Placeholder/fennech_placeholder7.png',
      './assets/Images/Placeholder/fennech_placeholder8.png',
    ];
    const randomIndex = Math.floor(Math.random() * randomUrlImages.length);
    return randomUrlImages[randomIndex];
  }

  static setInvisibleBackdrop() {
    document
      .querySelector('.cdk-overlay-container')
      ?.classList.add('invisible');
  }
  static removeInvisibleBackdrop() {
    document
      .querySelector('.cdk-overlay-container')
      ?.classList.remove('invisible');
  }
  static getMonthNumber(monthName: string): number | null {
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    const index = months.indexOf(monthName);

    if (index !== -1) {
      return index;
    } else {
      console.error('Nombre de mes no válido');
      return null;
    }
  }
  static getMonthName(monthNumber: number): string | null {
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    if (monthNumber >= 0 && monthNumber < months.length) {
      return months[monthNumber];
    } else {
      console.error('Número de mes no válido');
      return null;
    }
  }
  static getDayName(date: Date): string {
    const dayNames = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ];
    const dayIndex = date.getDay();
    return dayNames[dayIndex];
  }
  static getWeekdayOccurrence(date: Date): string {
    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    const occurrence = Math.ceil(dayOfMonth / 7);
    const daysOfWeek = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ];
    const dayName = daysOfWeek[dayOfWeek];

    // Return the result in the desired format
    let ordinal;
    switch (occurrence) {
      case 1:
        ordinal = 'primer';
        break;
      case 2:
        ordinal = 'segundo';
        break;
      case 3:
        ordinal = 'tercer';
        break;
      case 4:
        ordinal = 'cuarto';
        break;
      case 5:
        ordinal = 'quinto';
        break;
      default:
        ordinal = `${occurrence}th`; // This case is very unlikely to happen
    }

    return `${ordinal} ${dayName}`;
  }

  static grabLateralSrcoll(selector: string) {
    let mouseDown = false;
    let startX, scrollLeft;
    const slider = document.querySelector(selector) as HTMLElement;

    const startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const stopDragging = (e) => {
      mouseDown = false;
    };

    const move = (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    };

    slider.addEventListener('mousemove', move, false);
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }
  static setTimeTrackerToken(token: string) {
    localStorage.setItem('timeTrackerToken', token);
  }
  static setTimeTrackerRefreshToken(token: string) {
    localStorage.setItem('timeTrackerRefreshToken', token);
  }
  static calculateProportionalPart() {
    const currentDate = new Date();
    const periodStartDay = 25;
    let daysRemaining = periodStartDay - currentDate.getDate();
    if (daysRemaining < 0) {
      daysRemaining += 30;
    }
    daysRemaining++;
    const baseAmount = 3.5;
    const proportionalPart = (baseAmount / 30) * daysRemaining;

    return proportionalPart;
  }
  static checkUserRoleHandler() {
    var role: string = 'user';
    switch (localStorage.getItem('role')) {
      case sha256('ROLE_ADMIN'):
        role = 'admin';
        break;
      case sha256('ROLE_INTERMEDIO'):
        role = 'intermedio';
        break;
    }
    return role;
  }
  static checkIfIsValidIp(ip: string): boolean {
    const regex: RegExp =
      /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;
    return regex.test(ip);
  }

  static getRoutes() {
    return new OverrideStaticUtilitiesService().routes;
  }

  static getLastWorkday(): Date {
    const today: Date = new Date();
    const dayOfWeek = today.getDay();
    let lastWorkday = new Date(today);

    if (dayOfWeek === 1) {
      lastWorkday.setDate(today.getDate() - 3);
    } else if (dayOfWeek >= 2 && dayOfWeek <= 5) {
      lastWorkday.setDate(today.getDate() - 1);
    } else if (dayOfWeek === 6) {
      lastWorkday.setDate(today.getDate() - 1);
    } else if (dayOfWeek === 0) {
      lastWorkday.setDate(today.getDate() - 2);
    }

    return lastWorkday;
  }

  static removeDuplicates<T>(array: T[]): T[] {
    return Array.from(new Set(array));
  }

  static async fileToBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            resolve(base64data);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(xhr.response);
        } else {
          reject(new Error('Failed to load file'));
        }
      };
      xhr.onerror = () => {
        reject(new Error('Failed to load file'));
      };
      xhr.send();
    });
  }

  static getWorkdays(startDate: Date, endDate: Date): string[] {
    const workdays: string[] = [];

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        workdays.push(currentDate.toISOString().split('T')[0]);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return workdays;
  }
  static getWeeklyDates(today: Date, endDate: Date): string[] {
    const weeklyDates: string[] = [];

    let currentDate = new Date(today);

    while (currentDate <= endDate) {
      weeklyDates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 7);
    }

    return weeklyDates;
  }
  static getFirstWeekdayOfMonth(startDate: Date, endDate: Date): string[] {
    const weekday = startDate.getDay();
    const firstWeekdays: string[] = [];

    let currentDate = new Date(startDate);
    currentDate.setDate(1);

    while (currentDate <= endDate) {
      while (currentDate.getDay() !== weekday) {
        currentDate.setDate(currentDate.getDate() + 1);
      }
      if (currentDate <= endDate) {
        firstWeekdays.push(currentDate.toISOString().split('T')[0]);
      }

      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(1);
    }

    return firstWeekdays;
  }
  static getLastWeekdayOfMonth(startDate: Date, endDate: Date): string[] {
    const weekday = startDate.getDay();
    const lastWeekdays: string[] = [];

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      currentDate.setMonth(currentDate.getMonth() + 1);
      currentDate.setDate(0);
      while (currentDate.getDay() !== weekday) {
        currentDate.setDate(currentDate.getDate() - 1);
      }

      if (currentDate <= endDate && currentDate >= startDate) {
        lastWeekdays.push(currentDate.toISOString().split('T')[0]);
      }

      currentDate.setDate(1);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return lastWeekdays;
  }

  static getAnnualDates(startDate: Date, endDate: Date): string[] {
    const annualDates: string[] = [];

    let currentYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const month = startDate.getMonth();
    const day = startDate.getDate();

    while (currentYear <= endYear) {
      const currentDate = new Date(currentYear, month, day);
      if (currentDate >= startDate && currentDate <= endDate) {
        annualDates.push(currentDate.toISOString().split('T')[0]);
      }
      currentYear += 1;
    }

    return annualDates;
  }
}
