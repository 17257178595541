<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Imputar horas a un proyecto</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="customFormContainer">
        <label for="">Proyecto<sup [ngStyle]="{color: 'red'}">*</sup></label>
        <selector [options]="proyectOption"
          (change)="clearData();filterProyectProcesses();filterProyectSubProcesses();checkIfIsCompleteDataHandler()"
          [(ngModel)]="editObj.proyecto">
        </selector>
      </div>
      <div class="customFormContainer">
        <label for="">Proceso</label>
        <selector [options]="processOption" [(ngModel)]="editObj.proceso"
          [ngClass]="{'readOnly': editObj.proyecto == null ||editObj.subProceso}"
          (change)="checkIfIsCompleteDataHandler()"></selector>
      </div>
      <div class="customFormContainer">
        <label for="">Subproceso</label>
        <selector [options]="subprocessOption" [(ngModel)]="editObj.subProceso"
          [ngClass]="{'readOnly': editObj.proyecto == null || editObj.proceso}"
          (change)="checkIfIsCompleteDataHandler()"></selector>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !isCompleteData}" (click)="assignProjectToDaily()">
      <ng-container>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <mat-icon *ngIf="!isLoading">check</mat-icon>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>