import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
  iOptionsCustomInput,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iInvoiceTable } from 'src/app/Shared/Interfaces/Api/Bill/iInvoiceTable';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { InvoiceService } from 'src/app/Services/Api/invoice.service';

@Component({
  selector: 'app-pay-invoice-popup',
  templateUrl: './pay-invoice-popup.component.html',
  styleUrls: ['./pay-invoice-popup.component.scss'],
})
export class PayInvoicePopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  isCompleted: boolean = false;
  isLoading: boolean = false;
  dateOption: iOptionsCustomInput = {
    label: 'Fecha de pago',
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    classList: 'specialFormInput',
    required: true,
  };
  invoicePaidObj: any = {
    id: null,
    pagada: true,
    fecha_pago: null,
  };
  constructor(
    public dialogRef: MatDialogRef<PayInvoicePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: iInvoiceTable,
    private popupSE: PopupService,
    private invoiceSE: InvoiceService
  ) {}

  ngOnInit(): void {
    this.invoicePaidObj.id = this.modalData.id;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  validateIfDataIsCorrectHandler() {
    if (this.isCompleted) {
      this.payInvoice();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  successPayInvoiceResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
    this.closePopup();
  }
  errorPayInvoiceResponseHandler(res: iResultHttp) {
    this.isLoading = false;
  }
  /**
   * GETTERS & SETTERS
   */
  getDate(event: Date) {
    this.invoicePaidObj.fecha_pago = new iDate(iDate.toPhp(event)).toStringDate(
      'JAP'
    );
    this.isCompleted = true;
  }

  /**
   * FUNCTIONALITIES
   */
  payInvoice() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.invoiceSE.update(behaviorSubject, this.invoicePaidObj);
    this.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successPayInvoiceResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorPayInvoiceResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
}
