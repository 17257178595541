<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Pagar la factura</p>
  </div>
  <div class="body">
    <customInput [options]="dateOption" (change)="getDate($event)"></customInput>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !isCompleted}" (click)="validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!isLoading">check</mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>