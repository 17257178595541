export interface iSupplier {
  id?: number | null;
  nombre: string;
  razonSocial: string;
  cif: string;
  contacto: string;
  servicio: string;
  empresa?: number;
}

export class iSupplier {
  static convertFromCollection(ob: any): Array<iSupplier> {
    let iSupplierCollection: Array<iSupplier> = [];
    ob.forEach((element: any) => {
      iSupplierCollection.push(this.convertFromObject(element));
    });
    return iSupplierCollection;
  }

  static convertFromObject(ob: any): iSupplier {
    let iSupplierObj = new iSupplier();

    iSupplierObj.id = ob.id;
    iSupplierObj.nombre = ob.nombre;
    iSupplierObj.razonSocial = ob.razonSocial;
    iSupplierObj.cif = ob.cif;
    iSupplierObj.contacto = ob.contacto;
    iSupplierObj.servicio = ob.servicio;

    return iSupplierObj;
  }

  static createEmptyObject(): iSupplier {
    let iSupplierObj = new iSupplier();
    iSupplierObj.id = null;
    iSupplierObj.nombre = '';
    iSupplierObj.razonSocial = '';
    iSupplierObj.cif = '';
    iSupplierObj.contacto = '';
    iSupplierObj.servicio = '';

    return iSupplierObj;
  }
}
