export interface iBonusHoursSelector {
  id?: number | null;
  nombre: string;
  horas: number | null;
  precio: number | null;
}

export class iBonusHoursSelector {
  static convertFromCollection(ob: any): Array<iBonusHoursSelector> {
    let iBonusHoursSelectorCollection: Array<iBonusHoursSelector> = [];
    ob.forEach((element: any) => {
      iBonusHoursSelectorCollection.push(this.convertFromObject(element));
    });
    return iBonusHoursSelectorCollection;
  }

  static convertFromObject(ob: any): iBonusHoursSelector {
    let iBonusHoursSelectorObj = new iBonusHoursSelector();
    iBonusHoursSelectorObj.id = ob.id;
    iBonusHoursSelectorObj.nombre = ob.nombre;
    iBonusHoursSelectorObj.horas = ob.horas;
    iBonusHoursSelectorObj.precio = ob.precio;
    return iBonusHoursSelectorObj;
  }

  static createEmptyObject(): iBonusHoursSelector {
    let iBonusHoursSelectorObj = new iBonusHoursSelector();
    iBonusHoursSelectorObj.id = null;
    iBonusHoursSelectorObj.nombre = '';
    iBonusHoursSelectorObj.horas = null;
    iBonusHoursSelectorObj.precio = null;
    return iBonusHoursSelectorObj;
  }
}
