import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { CreateInvoice } from 'src/app/Shared/Class/Invoice/create-invoice';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { InvoiceService } from 'src/app/Services/Api/invoice.service';
import { TaxationService } from 'src/app/Services/Api/taxation.service';

@Component({
  selector: 'app-create-edit-invoice-popup',
  templateUrl: './create-edit-invoice-popup.component.html',
  styleUrls: ['./create-edit-invoice-popup.component.scss'],
})
export class CreateEditInvoicePopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  createInvoiceClass: CreateInvoice = new CreateInvoice();
  constructor(
    public dialogRef: MatDialogRef<CreateEditInvoicePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private invoiceSE: InvoiceService,
    private taxationSE: TaxationService
  ) {}

  ngOnInit(): void {
    if (this.modalData.edit) {
      this.createInvoiceClass.newInvoiceObj.id = this.modalData.id;
    }
    this.getAllTaxations();
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  generateNewObj() {
    return {
      numero: this.createInvoiceClass.newInvoiceObj.numero,
      iva: this.createInvoiceClass.newInvoiceObj.iva * 0.01,
      irpf: this.createInvoiceClass.newInvoiceObj.irpf * 0.01,
      fechaEmision: this.createInvoiceClass.newInvoiceObj.fechaEmision,
      potencial: false,
      factura: true,
    };
  }
  /**
   * GETTERS & SETTERS
   */
  getDate(event: Date) {
    this.createInvoiceClass.newInvoiceObj.fechaEmision = new iDate(
      iDate.toPhp(event)
    ).toStringDate('JAP');
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  checkIfDataIsCompleteHandler() {
    this.createInvoiceClass.isCompleted =
      StaticUtilitiesService.isCompleteObject(
        this.createInvoiceClass.newInvoiceObj
      );
  }
  validateIfDataIsCorrectHandler() {
    if (
      StaticUtilitiesService.isCompleteObject(
        this.createInvoiceClass.newInvoiceObj
      )
    ) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha generado la factura correctamente'
    );
    this.createInvoiceClass.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorCreateResponseHandler(res: iResultHttp) {
    this.createInvoiceClass.isLoading = false;
  }
  successGetAllTaxationResponseHandler(res: iResultHttp) {
    this.createInvoiceClass.taxationsOptions.items = [...res.data];
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.invoiceSE.update(behaviorSubject, this.generateNewObj());
    this.createInvoiceClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
  getAllTaxations() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.taxationSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllTaxationResponseHandler(res),
          error: false,
        });
      });
  }
}
