import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuasarLibraryModule } from '@quasar-dynamics/basic-designsystem';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LoginComponent } from './Pages/login/login.component';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './Shared/Adapters/CustomDateAdapter';
import localEs from '@angular/common/locales/es';
import { ConfirmPopupComponent } from './Popups/confirm-popup/confirm-popup.component';
import { ChangePasswordPopupComponent } from './Popups/change-password-popup/change-password-popup.component';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { CreateDailyPopupComponent } from './Popups/create-daily-popup/create-daily-popup.component';
import { CompleteDailyPopupComponent } from './Popups/complete-daily-popup/complete-daily-popup.component';
import { MatSliderModule } from '@angular/material/slider';
import { ValidateDailyPopupComponent } from './Popups/validate-daily-popup/validate-daily-popup.component';
import { QuickEditEmployeeComponent } from './Popups/quick-edit-employee/quick-edit-employee.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreateContractPopupComponent } from './Popups/create-contract-popup/create-contract-popup.component';
import { CreateEditBillComponent } from './Popups/create-edit-bill/create-edit-bill.component';
import { PreviewDocumentComponent } from './Pages/preview-document/preview-document.component';
import { AssignProcessEmployeePopupComponent } from './Popups/assign-process-employee-popup/assign-process-employee-popup.component';
import { CreateEditProcessPopupComponent } from './Popups/create-edit-process-popup/create-edit-process-popup.component';
import { DailyReminderPopupComponent } from './Popups/daily-reminder-popup/daily-reminder-popup.component';
import { NotValidatedDailyCardComponent } from './Shared/Components/not-validated-daily-card/not-validated-daily-card.component';
import { InvoiceDetailsPopupComponent } from './Popups/invoice-details-popup/invoice-details-popup.component';
import { PayInvoicePopupComponent } from './Popups/pay-invoice-popup/pay-invoice-popup.component';
import { CreatePaymentMilestonesPopupComponent } from './Popups/create-payment-milestones-popup/create-payment-milestones-popup.component';
import { CreateEditInvoicePopupComponent } from './Popups/create-edit-invoice-popup/create-edit-invoice-popup.component';
import { CreateEditClientPopupComponent } from './Popups/create-edit-client-popup/create-edit-client-popup.component';
import { CalculateStimatedTimePopupComponent } from './Popups/calculate-stimated-time-popup/calculate-stimated-time-popup.component';
import { NewMilestonePaymentComponent } from './Popups/new-milestone-payment-popup/new-milestone-payment-popup.component';
import { QuickEditProjectPopupComponent } from './Popups/quick-edit-project-popup/quick-edit-project-popup.component';
import { CreateEditProjectWeeklyResourceComponent } from './Popups/create-edit-project-weekly-resource/create-edit-project-weekly-resource.component';
import { CreateFunnelColumnPopupComponent } from './Popups/create-funnel-column-popup/create-funnel-column-popup.component';
import { CreateEditFunnelProposalPopupComponent } from './Popups/create-edit-funnel-proposal-popup/create-edit-funnel-proposal-popup.component';
import { CreateFunnelBudgetPopupComponent } from './Popups/create-funnel-budget-popup/create-funnel-budget-popup.component';
import { CreateEditDepartmentPopupComponent } from './Popups/create-edit-department-popup/create-edit-department-popup.component';
import { CreateEditCategoryPopupComponent } from './Popups/create-edit-category-popup/create-edit-category-popup.component';
import { CreateEditCategoryProductPopupComponent } from './Popups/create-edit-category-product-popup/create-edit-category-product-popup.component';
import { CreateEditProductPopupComponent } from './Popups/create-edit-product-popup/create-edit-product-popup.component';
import { AddMultipleProductsRevisionPopupComponent } from './Popups/add-multiple-products-revision-popup/add-multiple-products-revision-popup.component';
import { ProductListTableRevisionComponent } from './Pages/treasury/inventory/product-list-table-revision/product-list-table-revision.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AssignProductProyectPopupComponent } from './Popups/assign-product-proyect-popup/assign-product-proyect-popup.component';
import { RegisterComponent } from './Pages/register/register.component';
import { PageNotFoundComponent } from './Pages/page-not-found/page-not-found.component';
import { EmailValidationComponent } from './Pages/email-validation/email-validation.component';
import { CreateEditKnownIpPopupComponent } from './Popups/create-edit-known-ip-popup/create-edit-known-ip-popup.component';
import { SelectTemplateOptionPopupComponent } from './Popups/select-template-option-popup/select-template-option-popup.component';
import { CreateEditFuturibleCategoryComponent } from './Popups/create-edit-futurible-category/create-edit-futurible-category.component';
import { CreateEditFuturiblesPopupComponent } from './Popups/create-edit-futuribles-popup/create-edit-futuribles-popup.component';
import { AddMultipleEmployeeRevisionPopupComponent } from './Popups/add-multiple-employee-revision-popup/add-multiple-employee-revision-popup.component';
import { EmployeeTableRevisionComponent } from './Pages/resources/employee/dashboard-employee/holders/employee-table-revision/employee-table-revision.component';
import { CreateEditVacationalPeriodPopupComponent } from './Popups/create-edit-vacational-period-popup/create-edit-vacational-period-popup.component';
import { RequestHolidayPopupComponent } from './Popups/request-holiday-popup/request-holiday-popup.component';
import { ViewUbicationPopupComponent } from './Popups/view-ubication-popup/view-ubication-popup.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CreateEditSickLeaveCategoryPopupComponent } from './Popups/create-edit-sick-leave-category-popup/create-edit-sick-leave-category-popup.component';
import { MedicalDischargePopupComponent } from './Popups/medical-discharge-popup/medical-discharge-popup.component';
import { CreateSickLeavePopupComponent } from './Popups/create-sick-leave-popup/create-sick-leave-popup.component';
import { CreateEditPeriodPopupComponent } from './Popups/create-edit-period-popup/create-edit-period-popup.component';
import { RegisterReminderEmailValidationComponent } from './Pages/register/register-reminder-email-validation/register-reminder-email-validation.component';
import { ManageEmployeeExcelComponent } from './Popups/manage-employee-excel/manage-employee-excel.component';
import { ManageProductExcelComponent } from './Popups/manage-product-excel/manage-product-excel.component';
import { DailyDetailPopupComponent } from './Popups/daily-detail-popup/daily-detail-popup.component';
import { ManageDailyPopupComponent } from './Popups/manage-daily-popup/manage-daily-popup.component';
import { CreateEditSprintPeriodPopupComponent } from './Popups/create-edit-sprint-period-popup/create-edit-sprint-period-popup.component';
import { CreateEditSprintPopupComponent } from './Popups/create-edit-sprint-popup/create-edit-sprint-popup.component';
import { MatRadioModule } from '@angular/material/radio';
import { CreateEditBussinesLineCategoryPopupComponent } from './Popups/create-edit-bussines-line-category-popup/create-edit-bussines-line-category-popup.component';
import { CreateEditBussinessLinePopupComponent } from './Popups/create-edit-bussiness-line-popup/create-edit-bussiness-line-popup.component';
import { CreateEditFamilyCostPopupComponent } from './Popups/create-edit-family-cost-popup/create-edit-family-cost-popup.component';
import { CreateEditBonusHoursPopupComponent } from './Popups/create-edit-bonus-hours-popup/create-edit-bonus-hours-popup.component';
import { CreateEditTaxationPopupComponent } from './Popups/create-edit-taxation-popup/create-edit-taxation-popup.component';
import { ManageClientsExcelPopupComponent } from './Popups/manage-clients-excel-popup/manage-clients-excel-popup.component';
import { AddMultipleClientsRevisionPopupComponent } from './Popups/add-multiple-clients-revision-popup/add-multiple-clients-revision-popup.component';
import { ClientsTableRevisionComponent } from './Pages/crm/clients/_holders/clients-table-revision/clients-table-revision.component';
import { AssignHoursToProyectPopupComponent } from './Popups/assign-hours-to-proyect-popup/assign-hours-to-proyect-popup.component';
import { CheckinPopupComponent } from './Popups/checkin-popup/checkin-popup.component';
import { CheckinFloattingButtonComponent } from './Shared/Layout/checkin-floatting-button/checkin-floatting-button.component';
import { SendRecoveryPasswordEmailComponent } from './Pages/send-recovery-password-email/send-recovery-password-email.component';
import { RecoveryPasswordComponent } from './Pages/recovery-password/recovery-password.component';
import { ProductDetailPopupComponent } from './Popups/product-detail-popup/product-detail-popup.component';
import { ManageRolesPermissionsPopupComponent } from './Popups/manage-roles-permissions-popup/manage-roles-permissions-popup.component';
import { CreateEditContactPopupComponent } from './Popups/create-edit-contact-popup/create-edit-contact-popup.component';
import { CreateEditFunnelOfferNotesPopupComponent } from './Popups/create-edit-funnel-offer-notes-popup/create-edit-funnel-offer-notes-popup.component';
import { CreateEditFunnelOfferNextStepsPopupComponent } from './Popups/create-edit-funnel-offer-next-steps-popup/create-edit-funnel-offer-next-steps-popup.component';
import { LinkProjectToFunnerlOfferPopupComponent } from './Popups/link-project-to-funnerl-offer-popup/link-project-to-funnerl-offer-popup.component';
import { CreateEditFunnelOfferFollowPopupComponent } from './Popups/create-edit-funnel-offer-follow-popup/create-edit-funnel-offer-follow-popup.component';
import { CreateEditSupplierPopupComponent } from './Popups/create-edit-supplier-popup/create-edit-supplier-popup.component';
import { ManageSuppliersExcelPopupComponent } from './Popups/manage-suppliers-excel-popup/manage-suppliers-excel-popup.component';
import { AddMultipleSuppliersRevisionPopupComponent } from './Popups/add-multiple-suppliers-revision-popup/add-multiple-suppliers-revision-popup.component';
import { SuppliersTableListRevisionComponent } from './Pages/treasury/suppliers/suppliers-table-list-revision/suppliers-table-list-revision.component';
import { ManageCostExcelPopupComponent } from './Popups/manage-cost-excel-popup/manage-cost-excel-popup.component';
import { CostTableRevisionComponent } from './Pages/treasury/costs/_holders/cost-table-revision/cost-table-revision.component';
import { AddMultipleCostsRevisionPopupComponent } from './Popups/add-multiple-costs-revision-popup/add-multiple-costs-revision-popup.component';
import { LinkBussinesLineToProjectPopupComponent } from './Popups/link-bussines-line-to-project-popup/link-bussines-line-to-project-popup.component';
import { EditCheckinHoursPopupComponent } from './Popups/edit-checkin-hours-popup/edit-checkin-hours-popup.component';
import { AssignHolidaysPopupComponent } from './Popups/assign-holidays-popup/assign-holidays-popup.component';

registerLocaleData(localEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConfirmPopupComponent,
    ChangePasswordPopupComponent,
    CreateDailyPopupComponent,
    CompleteDailyPopupComponent,
    ValidateDailyPopupComponent,
    QuickEditEmployeeComponent,
    CreateContractPopupComponent,
    CreateEditBillComponent,
    PreviewDocumentComponent,
    AssignProcessEmployeePopupComponent,
    CreateEditProcessPopupComponent,
    DailyReminderPopupComponent,
    NotValidatedDailyCardComponent,
    InvoiceDetailsPopupComponent,
    PayInvoicePopupComponent,
    CreatePaymentMilestonesPopupComponent,
    CreateEditInvoicePopupComponent,
    CreateEditClientPopupComponent,
    CalculateStimatedTimePopupComponent,
    NewMilestonePaymentComponent,
    QuickEditProjectPopupComponent,
    CreateEditProjectWeeklyResourceComponent,
    CreateFunnelColumnPopupComponent,
    CreateEditFunnelProposalPopupComponent,
    CreateFunnelBudgetPopupComponent,
    CreateEditDepartmentPopupComponent,
    CreateEditCategoryPopupComponent,
    CreateEditCategoryProductPopupComponent,
    CreateEditProductPopupComponent,
    AddMultipleProductsRevisionPopupComponent,
    ProductListTableRevisionComponent,
    AssignProductProyectPopupComponent,
    RegisterComponent,
    PageNotFoundComponent,
    EmailValidationComponent,
    CreateEditKnownIpPopupComponent,
    SelectTemplateOptionPopupComponent,
    CreateEditFuturibleCategoryComponent,
    CreateEditFuturiblesPopupComponent,
    AddMultipleEmployeeRevisionPopupComponent,
    EmployeeTableRevisionComponent,
    CreateEditVacationalPeriodPopupComponent,
    RequestHolidayPopupComponent,
    ViewUbicationPopupComponent,
    CreateEditSickLeaveCategoryPopupComponent,
    MedicalDischargePopupComponent,
    CreateSickLeavePopupComponent,
    CreateEditPeriodPopupComponent,
    RegisterReminderEmailValidationComponent,
    ManageEmployeeExcelComponent,
    ManageProductExcelComponent,
    DailyDetailPopupComponent,
    ManageDailyPopupComponent,
    CreateEditSprintPeriodPopupComponent,
    CreateEditSprintPopupComponent,
    CreateEditBussinesLineCategoryPopupComponent,
    CreateEditBussinessLinePopupComponent,
    CreateEditFamilyCostPopupComponent,
    CreateEditBonusHoursPopupComponent,
    CreateEditTaxationPopupComponent,
    ManageClientsExcelPopupComponent,
    AddMultipleClientsRevisionPopupComponent,
    ClientsTableRevisionComponent,
    AssignHoursToProyectPopupComponent,
    CheckinPopupComponent,
    CheckinFloattingButtonComponent,
    SendRecoveryPasswordEmailComponent,
    RecoveryPasswordComponent,
    ProductDetailPopupComponent,
    ManageRolesPermissionsPopupComponent,
    CreateEditContactPopupComponent,
    CreateEditFunnelOfferNotesPopupComponent,
    CreateEditFunnelOfferNextStepsPopupComponent,
    LinkProjectToFunnerlOfferPopupComponent,
    CreateEditFunnelOfferFollowPopupComponent,
    CreateEditSupplierPopupComponent,
    ManageSuppliersExcelPopupComponent,
    AddMultipleSuppliersRevisionPopupComponent,
    SuppliersTableListRevisionComponent,
    ManageCostExcelPopupComponent,
    CostTableRevisionComponent,
    AddMultipleCostsRevisionPopupComponent,
    LinkBussinesLineToProjectPopupComponent,
    EditCheckinHoursPopupComponent,
    AssignHolidaysPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    QuasarLibraryModule,
    BrowserAnimationsModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    MatIconModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    GoogleMapsModule,
    MatRadioModule,
  ],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
