import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {}

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
}
