export interface iUserProyectSelector {
  id: number | null;
  nombre: string;
}

export class iUserProyectSelector {
  static convertFromCollection(ob: any): Array<iUserProyectSelector> {
    let iUserProyectSelectorCollection: Array<iUserProyectSelector> = [];
    ob.forEach((element: any) => {
      iUserProyectSelectorCollection.push(this.convertFromObject(element));
    });
    return iUserProyectSelectorCollection;
  }

  static convertFromObject(ob: any): iUserProyectSelector {
    let iUserProyectSelectorObj = new iUserProyectSelector();
    iUserProyectSelectorObj.id = ob.id;
    iUserProyectSelectorObj.nombre = ob.nombre;
    return iUserProyectSelectorObj;
  }

  static createEmptyObject(): iUserProyectSelector {
    let iUserProyectSelectorObj = new iUserProyectSelector();
    iUserProyectSelectorObj.id = null;
    iUserProyectSelectorObj.nombre = '';
    return iUserProyectSelectorObj;
  }
}
