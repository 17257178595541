<div class="popupGeneralContainer">
  <div class="header">
    <p>Validar tarea - {{dailyName}}</p>
  </div>
  <div class="body">
    <customInput [options]="validateDailyClass.estimatedHoursOption"
      [(ngModel)]="validateDailyClass.selectedDaily.horasEstimadas"></customInput>
    <customInput [options]="validateDailyClass.imputeHoursOption" [(ngModel)]="validateDailyClass.validateDaily.horas"
      (change)="validateDailyClass.checkIfDataIsCompleted()"></customInput>
    <customInput [options]="validateDailyClass.estimatedProgress"
      [(ngModel)]="validateDailyClass.selectedDaily.avanceTeorico"></customInput>
    <customInput [options]="validateDailyClass.realProgress" [(ngModel)]="validateDailyClass.validateDaily.avanceReal"
      (change)="validateDailyClass.checkIfDataIsCompleted()">
    </customInput>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !validateDailyClass.isDataCompleted}" (click)="validateDaily()">
      <ng-container>
        <mat-icon>check</mat-icon>
        <span>Guardar</span>
      </ng-container>
    </btn>
  </div>
</div>