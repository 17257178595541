import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DailyService } from 'src/app/Services/Api/daily.service';
import { ValidateDaily } from 'src/app/Shared/Class/Implications/validate-daily';

@Component({
  selector: 'app-validate-daily-popup',
  templateUrl: './validate-daily-popup.component.html',
  styleUrls: ['./validate-daily-popup.component.scss'],
})
export class ValidateDailyPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  validateDailyClass = new ValidateDaily();
  dailyName: string = '';
  constructor(
    public dialogRef: MatDialogRef<ValidateDailyPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dailySE: DailyService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();

    this.validateDailyClass.selectedDaily = this.modalData;

    this.dailyName = this.modalData.nombre;
    this.validateDailyClass.validateDaily.horas = Number(
      this.modalData.horas.toFixed(2)
    );
    this.validateDailyClass.selectedDaily.avanceTeorico = Number(
      (this.validateDailyClass.selectedDaily.avanceTeorico! * 100).toFixed(2)
    );
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  successValidateDailyResponseHandler(res) {
    StaticUtilitiesService.showFeedback(res.message);
    this.closePopup();
  }

  /**
   * CALLS TO API
   */

  validateDaily() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dailySE.validateDaily(
      behaviorSubject,
      this.validateDailyClass.generateValidateDailyObject()
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successValidateDailyResponseHandler(res),
        error: false,
      });
    });
  }
}
