<table mat-table [dataSource]="data">
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <mat-checkbox (change)="addToSelected(element)"></mat-checkbox>
      </div>

    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Nombre</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span class="bold" *ngIf="element.nombre != '' && element.apellidos != ''">{{element.nombre}}
          {{element.apellidos}}</span>
        <span class="bold" *ngIf="element.nombre == '' && element.apellidos == ''">-</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Email</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span>{{element.username ?? '-'}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="userType">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Tipo de usuario</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span *ngIf="element.roles.includes('ROLE_USER')">Básico</span>
        <span *ngIf="element.roles.includes('ROLE_INTERMEDIO')">Gestor</span>
        <span *ngIf="element.roles.includes('ROLE_ADMIN')">Administrador</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Coste (€/hora)</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span>{{element.coste | currency:"EUR"}}/hora</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="anualCost">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Coste anual (€)</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span>{{element.costeAnual | currency:"EUR"}}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="costType">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Tipo de coste</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span class="costType">
          {{element.tipoCoste }}
        </span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="contract">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Tipo de contrato</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span>{{contractTypeHandler(element.horas)}}</span>

      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="diasVacaciones">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Días de vacaciones</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span>{{element.diasVacaciones}} días</span>

      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns; ">
  </tr>
</table>