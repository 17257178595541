import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iProjectFilter } from '../../Interfaces/Api/Project/iProjectFilter';
import { iProject } from '../../Interfaces/Api/Project/iProject';
import { iCreateEditFunnelBudget } from '../../Interfaces/Api/Funnel/iCreateEditFunnelBudget';

export class CreateEditFunnelProposal {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  mainProjectFilter: iProjectFilter = {
    num_devoluciones: 999999999,
    num_pagina: 1,
    tag: 'estadisticas',
  };

  projects: Array<iProject> = [];
  budgets: Array<iProject> = [];
  newFunnelBudget: iCreateEditFunnelBudget = {
    estadoCRM: null,
    cliente: null,
    temperatura: null,
    proyecto: null,
    presupuesto: null,
  };
  /**
   * OPTIONS
   */
  clientOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: false,
    search: true,
  };
  columnOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: false,
    search: true,
  };
  projectsOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: false,
    search: true,
  };
  temperatureOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Temperatura',
    required: true,
    classList: 'specialFormInput',
  };
  budgetOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Presupuesto (€)',
    required: true,
    classList: 'specialFormInput',
  };
}
