import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iCategoryProduct } from '../../Interfaces/Api/Products/iCategoryProduct';
import { iCreateEditCategoryProduct } from '../../Interfaces/Api/Products/iCreateEditCategoryProduct';

export class CategoryProducts {
  isCompleted: boolean = false;
  categories: Array<iCategoryProduct> = [];
  categoriesBackup: Array<iCategoryProduct> = [];
  isLoading: boolean = false;
  genericFilter: string = '';
  createEditObj: iCreateEditCategoryProduct = {
    nombre: '',
    descripcion: '',
  };
  nombreOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  descriptionOption: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Descripción',
    classList: 'specialFormInput',
  };
}
