import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iClientList } from 'src/app/Shared/Interfaces/Api/Client/iClientList';
import { iClientListFilter } from 'src/app/Shared/Interfaces/Api/Client/iClientListFilter';
import { iClientSelector } from 'src/app/Shared/Interfaces/Api/Client/iClientSelector';
import { iClientStatistic } from 'src/app/Shared/Interfaces/Api/Client/iClientStatistic';
import { iCreateEditClient } from 'src/app/Shared/Interfaces/Api/Client/iCreateEditClient';
import { iSimpleClient } from 'src/app/Shared/Interfaces/Api/Client/iSimpleClient';
import { iSingleClient } from 'src/app/Shared/Interfaces/Api/Client/iSingleClient';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }

  getSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/cliente/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iClientSelector.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSelector,
            args: [behaviorSubject],
          });
        },
      });
  }
  getAll(
    behaviorSubject: BehaviorSubject<any>,
    clientFilters: iClientListFilter
  ) {
    this.http
      .get(
        this.urlApi + '/api/cliente' + this.optionsGet(clientFilters),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              data: iClientList.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
              total: data.total,
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, clientFilters],
          });
        },
      });
  }
  getStatistic(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(
        this.urlApi +
          '/api/estadisticas/cliente' +
          this.optionsGet({ cliente: id }),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iClientStatistic.convertFromObject(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStatistic,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getAllCompanyClients(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(
        this.urlApi +
          '/api/cliente' +
          this.optionsGet({
            tag: 'crm',
            empresa: localStorage.getItem('numEmpresa'),
          }),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iSimpleClient.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllCompanyClients,
            args: [behaviorSubject],
          });
        },
      });
  }
  getSingle(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/cliente/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iSingleClient.convertFromObject(
              iStaticUtilities.normalizeNames(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingle,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/api/cliente', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha creado el cliente correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  edit(behaviorSubject: BehaviorSubject<any>, data: iCreateEditClient) {
    this.http
      .put(this.urlApi + '/api/cliente/' + data.id, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha editado el cliente correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.edit,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(this.urlApi + '/api/cliente', this.sendBodyOptions({ id: id }))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
