import {
  iDate,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iCheckin } from '../../Interfaces/Api/Checkin/iCheckin';
import { iCheckinFilter } from '../../Interfaces/Api/Checkin/iCheckinFilter';
import { iCheckinRegisterEditHour } from '../../Interfaces/Api/Checkin/iCheckinRegisterEditHour';

export class CheckinRegister {
  filterOpened: boolean = false;
  checkinRegisterFilter: iCheckinFilter = {
    num_pagina: 1,
    num_devoluciones: 10,
    fecha: new iDate(iDate.toPhp(new Date())).toStringDate('JAP'),
  };
  checkinRegister: Array<iCheckinFilter> = [];
  dateOption: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Escribe aquí...',
    label: 'Fecha',
    classList: 'specialFormInput',
  };
  userOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: true,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
  stateOptions: iOptionsSelector = {
    items: ['En curso', 'Pausado', 'Finalizado'],
    placeholder: 'Selecciona una opción...',
    clearable: true,
    append: true,
    classList: 'specialFormSelector',
    search: true,
  };

  /**
   * EDIT CHECKIN REGISTER HOUR
   */
  isLoading: boolean = false;
  editObj: iCheckinRegisterEditHour = {
    id: null,
    startDate: '',
    endDate: '',
    startHour: '',
    endHour: '',
  };
  startHourOption: iOptionsCustomInput = {
    type: 'time',
    placeholder: 'Escribe aquí...',
    label: 'Hora de inicio',
    classList: 'specialFormInput',
  };
  endHourOption: iOptionsCustomInput = {
    type: 'time',
    placeholder: 'Escribe aquí...',
    label: 'Hora de fin',
    classList: 'specialFormInput',
  };
}
