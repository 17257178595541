import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { EmployeeService } from 'src/app/Services/Api/employee.service';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/overrideStaticUtilities.service';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { VacationalPeriodService } from 'src/app/Services/Api/vacational-period.service';
import { iVacationPeriod } from 'src/app/Shared/Interfaces/Api/Vacations/iVacationPeriod';

@Component({
  selector: 'app-add-multiple-employee-revision-popup',
  templateUrl: './add-multiple-employee-revision-popup.component.html',
  styleUrls: ['./add-multiple-employee-revision-popup.component.scss'],
})
export class AddMultipleEmployeeRevisionPopupComponent implements OnInit {
  selectedEmployees: Array<any> = [];
  vacationalPeriods: Array<iVacationPeriod> = [];
  isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddMultipleEmployeeRevisionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private employeeSE: EmployeeService,
    private popupSE: PopupService,
    private vacationalPeriodSE: VacationalPeriodService
  ) {}

  ngOnInit(): void {
    this.getAllVacationalPeriods();
  }

  /**
   * POPUPS
   */
  openDisclaimerPopup() {
    const confirmCloseResponseMessage: iConfirmPopup = {
      buttonConfirmText: 'Sí, confirmar',
      buttonCancelText: 'No, cancelar',
      title: `¿Estas seguro de que quieres activar las ${this.modalData.employees.length} licencias?`,
      description:
        'Al registrar a un nuevo empleado, se activará automáticamente una licencia asociada a su cuenta. Por favor, revise su correo electrónico para completar la validación.',
      extraDescription:
        `Si activa la licencia a partir de hoy, en el periodo de facturación actual, abonará ` +
        OverrideStaticUtilitiesService.calculateProportionalPart().toFixed(2) +
        ` €. A partir del siguiente periodo se le cobrará la tarifa regular 3.50 € por licencia.`,
      icon: '/assets/Icons/warning.svg',
    };
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmPopupComponent, confirmCloseResponseMessage);
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) return;
        if (res.activeAction) {
          this.create();
        }
        subject.next('');
      });
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  removeSelectedEmployees() {
    this.selectedEmployees.forEach((element) => {
      this.modalData.employees.splice(
        this.modalData.employees.indexOf(element),
        1
      );
    });
    StaticUtilitiesService.showFeedback(
      'Se han eliminado los empleados seleccionados correctamente'
    );
    this.modalData.employees = [...this.modalData.employees];
    if (this.modalData.employees.length === 0) {
      this.forceClosePopup();
    }
  }
  generateEmployeesObj() {
    this.modalData.employees.forEach((employee) => {
      employee['periodoVacacional'] = this.vacationalPeriods.filter(
        (vacationalPeriod) => vacationalPeriod.dias == employee.diasVacaciones
      )[0]?.id;
    });
    return this.modalData.employees;
  }
  /**
   * GETTERS && SETTERS
   */
  getSelectedEmployees(selectedEmployee: any) {
    this.selectedEmployees = [...selectedEmployee];
  }

  /**
   * API RESPONSES
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorResponseHandler() {
    this.isLoading = false;
  }
  successCreateResponseHandler(res) {
    StaticUtilitiesService.showFeedback(
      `Se han creado los ${this.modalData.employees.length} empleados correctamente`
    );
    this.isLoading = false;
    this.forceClosePopup();
  }
  errorCreateResponseHandler() {
    this.isLoading = false;
  }
  successGetAllVacationalPeriodsResponseHandler(res: iResultHttp) {
    this.vacationalPeriods = [...res.data];
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.generateEmployeesObj();
    this.employeeSE.create(behaviorSubject, {
      users: this.generateEmployeesObj(),
    });
    this.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  getAllVacationalPeriods() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.vacationalPeriodSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllVacationalPeriodsResponseHandler(res),
          error: false,
        });
      });
  }
}
