import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iOptionsCustomInput,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { NextStepService } from 'src/app/Services/Api/nextStep.service';

@Component({
  selector: 'app-create-edit-funnel-offer-next-steps-popup',
  templateUrl: './create-edit-funnel-offer-next-steps-popup.component.html',
  styleUrls: ['./create-edit-funnel-offer-next-steps-popup.component.scss'],
})
export class CreateEditFunnelOfferNextStepsPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  isCompleted: boolean = false;
  isLoading: boolean = false;
  name: string = '';
  date: any = null;
  nameOption: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Selecciona una fecha...',
    classList: 'specialFormInput',
    required: true,
  };
  dateOption: iOptionsCustomInput = {
    label: 'Fecha',
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    classList: 'specialFormInput',
    required: true,
  };
  constructor(
    public dialogRef: MatDialogRef<CreateEditFunnelOfferNextStepsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private nextStepSE: NextStepService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.modalData.edit ? this.setData() : null;
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.name = this.modalData.nextStep.nombre;
    this.date = new iDate(this.modalData.nextStep.fecha).toJavascript();
  }
  getDate(event) {
    this.date = event;
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  checkIfDataIsCompleted() {
    this.isCompleted = !!this.name && !!this.date;
  }
  updateDataHandler() {
    if (this.modalData.edit) {
      const obj: any = {
        id: this.modalData.nextStep.id,
        clienteEstadoCrm: this.modalData.offer,
        nombre: this.name,
        fecha: new iDate(iDate.toPhp(this.date)).toStringDate('JAP'),
      };

      this.update(obj);
    }
  }

  /**
   * API RESPONSES
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
    !this.modalData.edit ? this.closePopup() : null;
  }

  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.nextStepSE.create(behaviorSubject, {
      nombre: this.name,
      clienteEstadoCrm: this.modalData.offer,
      fecha: new iDate(iDate.toPhp(this.date)).toStringDate('JAP'),
    });
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => (this.isLoading = false),
            error: true,
          },
        ]);
      });
  }
  update(obj) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.nextStepSE.update(behaviorSubject, obj);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => (this.isLoading = false),
            error: true,
          },
        ]);
      });
  }
}
