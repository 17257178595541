import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';

export class CalculateStimatedTime {
  moreDataOpened: boolean = false;
  optimist: number | null = null;
  realist: number | null = null;
  pessimist: number | null = null;
  timeEstimated: number | null = null;
  deviation: number | null = null;
  deviationAmount: number = 0;
  timeFinal: number = 0;
  probability: number = 50;
  step: number = 0;

  optimistTimeOptions: iOptionsCustomInput = {
    label: 'Tiempo optimista (horas)',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
  };

  realistTimeOptions: iOptionsCustomInput = {
    label: 'Tiempo realista (horas)',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
  };

  pessimistTimeOptions: iOptionsCustomInput = {
    label: 'Tiempo pesimista (horas)',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
  };
}
