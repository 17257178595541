import {
  iDate,
  iOptionsCustomInput,
} from '@quasar-dynamics/basic-designsystem';
import { iContract } from '../../Interfaces/Api/Contract/iContract';
import { iNewContract } from '../../Interfaces/Api/Contract/iNewContract';

export class Contract {
  isLoading: boolean = false;
  isDataComplete: boolean = false;
  newContract: iNewContract = {
    horas: null,
    inicio: new iDate(iDate.toPhp(new Date())).toStringDate('JAP'),
    costeHora: null,
    empleado: null,
  };
  startDateOption: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Fecha de inicio',
    label: 'Fecha de inicio',
    required: true,
    classList: 'specialFormInput',
  };
  endDateOption: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Fecha de fin',
    label: 'Fecha de fin',
    required: false,
    classList: 'specialFormInput',
  };
  costOption: iOptionsCustomInput = {
    type: 'number',
    label: 'Coste (€/hora)',
    required: true,
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  hoursOption: iOptionsCustomInput = {
    type: 'number',
    label: 'Horas semanales',
    required: true,
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
}
