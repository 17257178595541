import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { IPService } from 'src/app/Services/Api/IP.service';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/overrideStaticUtilities.service';
import { Ip } from 'src/app/Shared/Class/AccessRegister/ip';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { iKnownIp } from 'src/app/Shared/Interfaces/Api/IP/iKnownIp';

@Component({
  selector: 'app-create-edit-known-ip-popup',
  templateUrl: './create-edit-known-ip-popup.component.html',
  styleUrls: ['./create-edit-known-ip-popup.component.scss'],
})
export class CreateEditKnownIpPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  ipClass: Ip = new Ip();
  constructor(
    public dialogRef: MatDialogRef<CreateEditKnownIpPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private ipSE: IPService,
    private popupSE: PopupService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.ipClass.createEditKnownIP = this.modalData.ip;
    this.ipClass.backUpCreateEditKnownIP = JSON.parse(
      JSON.stringify(this.modalData.ip)
    );
    this.ipClass.isCompleted = true;
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    !this.modalData.edit
      ? this.validateIfDataIsCorrectHandler()
      : this.forceClosePopup();
  }
  validateIfDataIsCorrectHandler() {
    if (this.ipClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'El campo nombre es obligatorio y no pueden estar vacíos.'
      );
    }
  }
  checkIfDataIsCompleted() {
    this.ipClass.isCompleted = StaticUtilitiesService.isCompleteObject(
      this.ipClass.createEditKnownIP
    );
  }
  checkIfValidIp() {
    if (
      !OverrideStaticUtilitiesService.checkIfIsValidIp(
        this.ipClass.createEditKnownIP.ip
      )
    ) {
      this.ipClass.isCompleted = false;
      StaticUtilitiesService.showError(
        'Hay errores en los campos.',
        'La IP no tiene un formato válido.'
      );
      this.ipClass.createEditKnownIP.ip =
        this.ipClass.backUpCreateEditKnownIP.ip;
    }
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = { id: this.ipClass.createEditKnownIP.id };
      switch (element) {
        case 'nombre':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Hay errores en los campos.',
              'El campo nombre es un campo obligatorio y no puede estar vacío.'
            );
            this.ipClass.createEditKnownIP.nombre =
              this.ipClass.backUpCreateEditKnownIP.nombre;
          }
          break;
        case 'ip':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Hay errores en los campos.',
              'El campo IP es un campo obligatorio y no puede estar vacío.'
            );
            this.ipClass.createEditKnownIP.ip =
              this.ipClass.backUpCreateEditKnownIP.ip;
          }
          break;
      }
    }
  }
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.ipClass.isLoading = false;
    if (!this.modalData.edit) {
      setTimeout(() => {
        this.closePopup();
      }, 500);
    }
  }
  errorResponseHandler() {
    this.ipClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.ipSE.create(behaviorSubject, this.ipClass.createEditKnownIP);
    this.ipClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successResponseHandler(res),
            error: false,
          },
          {
            method: this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(ip: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.ipSE.update(behaviorSubject, ip);
    this.ipClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successResponseHandler(res),
            error: false,
          },
          {
            method: this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
