<div class="registerGeneralContainer">
  <div class="left">
    <h1>
      Estás <b>a un paso </b>de <br> llevar tu productividad <br><b>más allá.</b>
    </h1>
    <h2>Da de alta tu empresa en fennech</h2>
  </div>
  <div class="right">
    <div class="formContainer">
      <img src="/assets/Images/Logos/logoFennechAnimado.gif" alt="">
      <customInput [options]="registerClass.nameOptions" [(ngModel)]="registerClass.newObj.nombre"
        (change)="checkIfIsCompleteDataHandler()"></customInput>
      <customInput [options]="registerClass.surnameOptions" [(ngModel)]="registerClass.newObj.apellidos"
        (change)="checkIfIsCompleteDataHandler()"></customInput>
      <customInput [options]="registerClass.emailOptions" [(ngModel)]="registerClass.newObj.username"
        (change)="checkIfIsCompleteDataHandler(); checkIsValidFormatHandler()"></customInput>
      <customInput [options]="registerClass.passwordOptions" [(ngModel)]="registerClass.newObj.password"
        (change)="checkIfIsCompleteDataHandler()"></customInput>
      <customInput [options]="registerClass.companyOptions" [(ngModel)]="registerClass.newObj.empresa"
        (change)="checkIfIsCompleteDataHandler()"></customInput>
      <div class="privacyPolitics">
        <mat-checkbox [(ngModel)]="registerClass.isChecked" (change)="checkIfIsCompleteDataHandler()"></mat-checkbox>
        <span for="">He leído y acepto la <a>política de privacidad</a> de fennech.</span>
      </div>
      <btn class="action" [ngClass]="{'disabled': !registerClass.isCompleteData}" (click)="create()">
        <ng-container>
          <span>Darse de alta</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>