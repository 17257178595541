export interface iBonusHours {
  id?: number | null;
  codigo: string;
  nombre: string;
  descripcion: string;
  horas: number | null;
  precio: number | null;
}

export class iBonusHours {
  static convertFromCollection(ob: any): Array<iBonusHours> {
    let iBonusHoursCollection: Array<iBonusHours> = [];
    ob.forEach((element: any) => {
      iBonusHoursCollection.push(this.convertFromObject(element));
    });
    return iBonusHoursCollection;
  }

  static convertFromObject(ob: any): iBonusHours {
    let iBonusHoursObj = new iBonusHours();
    iBonusHoursObj.id = ob.id;
    iBonusHoursObj.codigo = ob.codigo;
    iBonusHoursObj.nombre = ob.nombre;
    iBonusHoursObj.descripcion = ob.descripcion;
    iBonusHoursObj.horas = ob.horas;
    iBonusHoursObj.precio = ob.precio;
    return iBonusHoursObj;
  }

  static createEmptyObject(): iBonusHours {
    let iBonusHoursObj = new iBonusHours();
    iBonusHoursObj.id = null;
    iBonusHoursObj.codigo = '';
    iBonusHoursObj.nombre = '';
    iBonusHoursObj.descripcion = '';
    iBonusHoursObj.horas = null;
    iBonusHoursObj.precio = null;
    return iBonusHoursObj;
  }
}
