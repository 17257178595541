export interface iBussinessLineCategory {
  id?: number | null;
  nombre: string;
}

export class iBussinessLineCategory {
  static convertFromCollection(ob: any): Array<iBussinessLineCategory> {
    let iBussinessLineCategoryCollection: Array<iBussinessLineCategory> = [];
    ob.forEach((element: any) => {
      iBussinessLineCategoryCollection.push(this.convertFromObject(element));
    });
    return iBussinessLineCategoryCollection;
  }

  static convertFromObject(ob: any): iBussinessLineCategory {
    let iBussinessLineCategoryObj = new iBussinessLineCategory();
    iBussinessLineCategoryObj.id = ob.id;
    iBussinessLineCategoryObj.nombre = ob.nombre;
    return iBussinessLineCategoryObj;
  }

  static createEmptyObject(): iBussinessLineCategory {
    let iBussinessLineCategoryObj = new iBussinessLineCategory();
    iBussinessLineCategoryObj.id = null;
    iBussinessLineCategoryObj.nombre = '';
    return iBussinessLineCategoryObj;
  }
}
