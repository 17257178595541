export interface iUserSelector {
  id: number;
  nombre: string;
}

export class iUserSelector {
  static convertFromCollection(ob: any): Array<iUserSelector> {
    let iUserSelectorCollection: Array<iUserSelector> = [];
    ob.forEach((element: any) => {
      iUserSelectorCollection.push(this.convertFromObject(element));
    });
    return iUserSelectorCollection;
  }

  static convertFromObject(ob: any): iUserSelector {
    let iUserSelectorObj = new iUserSelector();

    iUserSelectorObj.id = ob.id;
    iUserSelectorObj.nombre = ob.nombre;
    return iUserSelectorObj;
  }
}
