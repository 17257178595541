import { iSickLeaveCategory } from './iSickLeaveCategory';

export interface iSickLeaves {
  id: number;
  fechaIni: string;
  fechaFin: string | null;
  dias: number;
  categoria: iSickLeaveCategory;
}

export class iSickLeaves {
  static convertFromCollection(ob: any): Array<iSickLeaves> {
    let iSickLeavesCollection: Array<iSickLeaves> = [];
    ob.forEach((element: any) => {
      iSickLeavesCollection.push(this.convertFromObject(element));
    });
    return iSickLeavesCollection;
  }

  static convertFromObject(ob: any): iSickLeaves {
    let iSickLeavesObj = new iSickLeaves();
    iSickLeavesObj.id = ob.id;
    iSickLeavesObj.fechaIni = ob.fechaIni;
    iSickLeavesObj.fechaFin = ob.fechaFin;
    iSickLeavesObj.dias = ob.dias;
    iSickLeavesObj.categoria = iSickLeaveCategory.convertFromObject(
      ob.categoria
    );

    return iSickLeavesObj;
  }
}
