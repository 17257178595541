export interface iCheckinHistoric {
  id: number | null;
  nombre: string;
  fecha: string;
  tiempo: number | null;
}

export class iCheckinHistoric {
  static convertFromCollection(ob: any): Array<iCheckinHistoric> {
    let iCheckinCollection: Array<iCheckinHistoric> = [];
    ob.forEach((element: any) => {
      iCheckinCollection.push(this.convertFromObject(element));
    });
    return iCheckinCollection;
  }

  static convertFromObject(ob: any): iCheckinHistoric {
    let iCheckinObj = new iCheckinHistoric();
    iCheckinObj.id = ob.id;
    iCheckinObj.nombre = ob.nombre;
    iCheckinObj.fecha = ob.fecha;
    iCheckinObj.tiempo = ob.tiempo;
    return iCheckinObj;
  }
}
