import { environment } from 'src/environments/environment';
import { iContract } from '../Contract/iContract';
import { iDepartmentSelector } from '../Departments/iDepartmentSelector';

export interface iSingleEmployee {
  id: number | null;
  nombre: string;
  apellidos: string;
  profile: string;
  username: string;
  canAccess: boolean | null;
  coste: number | null;
  costeAnual: number | null;
  horas: number | null;
  horasCorto: number | null;
  horasLargo: number | null;
  horasMedio: number | null;
  tipoCoste: string;
  departamentos: Array<iDepartmentSelector>;
  contratos: Array<iContract>;
  roles: Array<string>;
  periodoVacacional: number | null;
  baja: boolean;
  permisos: Array<{
    id: number;
    permiso: { id: number; nombre: string };
    editar: boolean;
  }>;
}

export class iSingleEmployee {
  static convertFromCollection(ob: any): Array<iSingleEmployee> {
    let iSingleEmployeeCollection: Array<iSingleEmployee> = [];
    ob.forEach((element: any) => {
      iSingleEmployeeCollection.push(this.convertFromObject(element));
    });
    return iSingleEmployeeCollection;
  }

  static convertFromObject(ob: any): iSingleEmployee {
    let iSingleEmployeeObj = new iSingleEmployee();
    iSingleEmployeeObj.id = ob.id;
    iSingleEmployeeObj.nombre = ob.nombre;
    iSingleEmployeeObj.apellidos = ob.apellidos;
    iSingleEmployeeObj.profile = ob.profile;
    iSingleEmployeeObj.username = ob.username;
    iSingleEmployeeObj.canAccess = ob.canAccess;
    iSingleEmployeeObj.coste = ob.coste;
    iSingleEmployeeObj.costeAnual = ob.costeAnual;
    iSingleEmployeeObj.horas = ob.horas;
    iSingleEmployeeObj.horasCorto = ob.horasCorto;
    iSingleEmployeeObj.horasLargo = ob.horasLargo;
    iSingleEmployeeObj.horasMedio = ob.horasMedio;
    iSingleEmployeeObj.tipoCoste = ob.tipoCoste;
    iSingleEmployeeObj.departamentos = ob.departamentos;
    iSingleEmployeeObj.contratos = ob.contratos;
    iSingleEmployeeObj.roles = ob.roles;
    iSingleEmployeeObj.baja = ob.baja;
    iSingleEmployeeObj.permisos = ob.userPermisos;
    iSingleEmployeeObj.periodoVacacional = ob.periodoVacacional
      ? ob.periodoVacacional.id
      : null;
    return iSingleEmployeeObj;
  }

  static createEmptyObject(): iSingleEmployee {
    let iSingleEmployeeObj = new iSingleEmployee();
    iSingleEmployeeObj.id = null;
    iSingleEmployeeObj.nombre = '';
    iSingleEmployeeObj.apellidos = '';
    iSingleEmployeeObj.profile = '';
    iSingleEmployeeObj.username = '';
    iSingleEmployeeObj.canAccess = null;
    iSingleEmployeeObj.coste = null;
    iSingleEmployeeObj.costeAnual = null;
    iSingleEmployeeObj.horas = null;
    iSingleEmployeeObj.horasCorto = null;
    iSingleEmployeeObj.horasLargo = null;
    iSingleEmployeeObj.horasMedio = null;
    iSingleEmployeeObj.tipoCoste = '';
    iSingleEmployeeObj.departamentos = [];
    iSingleEmployeeObj.contratos = [];
    iSingleEmployeeObj.roles = [];
    iSingleEmployeeObj.baja = false;

    iSingleEmployeeObj.periodoVacacional = null;

    return iSingleEmployeeObj;
  }
}
