<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar {{modalData.type}}</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <ng-container *ngIf="modalData.type === 'corto plazo'">
        <customInput [options]="periodClass.periodOption" [(ngModel)]="periodClass.createEditPeriodObj.cortoPlazo"
          (change)="validateIfDataIsCorrectHandler(periodClass.createEditPeriodObj.cortoPlazo)">
        </customInput>
      </ng-container>
      <ng-container *ngIf="modalData.type === 'medio plazo'">
        <customInput [options]="periodClass.periodOption" [(ngModel)]="periodClass.createEditPeriodObj.medioPlazo"
          (change)="validateIfDataIsCorrectHandler(periodClass.createEditPeriodObj.medioPlazo)">
        </customInput>
      </ng-container>
      <ng-container *ngIf="modalData.type === 'largo plazo'">
        <customInput [options]="periodClass.periodOption" [(ngModel)]="periodClass.createEditPeriodObj.largoPlazo"
          (change)="validateIfDataIsCorrectHandler(periodClass.createEditPeriodObj.largoPlazo)">
        </customInput>
      </ng-container>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.closePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !periodClass.isCompleted && !modalData.edit}"
      (click)="this.closePopup()">
      <ng-container>
        <mat-icon *ngIf="!periodClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="periodClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>