import { transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  AnimationControllerService,
  GeneralLoaderService,
  StaticUtilitiesService,
  TokenManagmentService,
  VariablesDesignSystemService,
  iUnsubscribeDestroy,
  slideNavigationFunctions,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { filter, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginService } from './Services/Api/login.service';
import { OverrideTokenManagmentServiceService } from './Services/Utils/OverrideTokenManagmentService.service';
import { TimeTrackerService } from './Services/Api/time-tracker.service';
import { FennechLoaderComponent } from './Shared/Components/fennech-loader/fennech-loader.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition(
        '* => slideToLeft',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
      ),
      transition(
        '* => slideToRight',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
      ),
      transition(
        '* => slideToTop',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
      ),
      transition(
        '* => slideToBottom',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom
        )
      ),
      transition(
        '* => slideToLeftDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToLeft)
      ),
      transition(
        '* => slideToRightDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToRight)
      ),
      transition(
        '* => slideToTopDuplicate',
        slideNavigationFunctions.getAnimation(slideNavigationTypes.slideToTop)
      ),
      transition(
        '* => slideToBottomDuplicate',
        slideNavigationFunctions.getAnimation(
          slideNavigationTypes.slideToBottom
        )
      ),
    ]),
  ],
})
export class AppComponent extends iUnsubscribeDestroy implements OnInit {
  title = 'fennech';
  activeButton: boolean = true;
  constructor(
    private router: Router,
    private titleSE: Title,
    public animService: AnimationControllerService,
    private loginSE: LoginService,
    private timeTrackSE: TimeTrackerService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private generalLoaderSE: GeneralLoaderService
  ) {
    super();
  }
  ngOnInit(): void {
    VariablesDesignSystemService.setIsRelease(environment.production);
    VariablesDesignSystemService.setUrlApi(environment.urlApi);

    this.refreshTokenHandler();
    this.timeTrackerRefreshTokenHandler();
    this.routerHandler();
    this.generalLoaderSE.setLoaderComponent(FennechLoaderComponent);
  }

  /**
   * HANDLERS
   */

  refreshTokenHandler() {
    TokenManagmentService.listenerTokenLost()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        this.loginSE.logOut();
        setTimeout(() => {
          location.reload();
        }, 1000);
      });

    TokenManagmentService.listenerStartRefreshToken()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!location.pathname.includes('login')) {
          this.loginSE.refreshToken();
        }
      });
  }
  timeTrackerRefreshTokenHandler() {
    OverrideTokenManagmentServiceService.listenerTimeTrackerTokenLost()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        this.loginSE.isLogged()
          ? StaticUtilitiesService.showError(
              'Ha habido un problema, intentalo más tarde'
            )
          : null;
        this.loginSE.logOut();
      });

    OverrideTokenManagmentServiceService.listenerStartTimeTrackerRefreshToken()
      .pipe(takeUntil(this._unsub))
      .subscribe((res) => {
        if (!location.pathname.includes('login')) {
          this.timeTrackSE.refreshToken();
        }
      });
  }

  routerHandler() {
    this.animService.setCurrentAnimation(slideNavigationTypes.slideToTop);
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          if (
            route.snapshot.url[0].path == 'login' ||
            route.snapshot.url[0].path == 'recuperar-cuenta' ||
            route.snapshot.url[0].path == 'recuperacion-de-cuenta'
          ) {
            this.activeButton = false;
          } else {
            this.activeButton = true;
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleSE.setTitle(`fennech - ${title}`);
        }
      });
  }
}
