import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { PaymentMilestonesCreate } from 'src/app/Shared/Class/PaymentMilestones/payment-milestones-create';
import { ClientService } from 'src/app/Services/Api/client.service';
import { InvoiceService } from 'src/app/Services/Api/invoice.service';
import { ProjectService } from 'src/app/Services/Api/project.service';
import { iSimpleProject } from 'src/app/Shared/Interfaces/Api/Project/iSimpleProject';

@Component({
  selector: 'app-create-payment-milestones-popup',
  templateUrl: './create-payment-milestones-popup.component.html',
  styleUrls: ['./create-payment-milestones-popup.component.scss'],
})
export class CreatePaymentMilestonesPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  paymentMilestonesCreateClass: PaymentMilestonesCreate =
    new PaymentMilestonesCreate();

  constructor(
    public dialogRef: MatDialogRef<CreatePaymentMilestonesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private clientSE: ClientService,
    private invoiceSE: InvoiceService,
    private projectSE: ProjectService
  ) {}

  ngOnInit(): void {
    this.getClients();
    this.getAllProjects();
    this.setData();
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  findProjects(changeEvent: boolean = false) {
    changeEvent ? this.clearData() : null;
    this.paymentMilestonesCreateClass.newPaymentMilestonesObj.cliente;
    this.paymentMilestonesCreateClass.projects.forEach((proyecto) => {
      if (
        proyecto.cliente?.id ==
        this.paymentMilestonesCreateClass.newPaymentMilestonesObj.cliente
      ) {
        this.paymentMilestonesCreateClass.projectOptions.items.push(proyecto);
      }
    });
    this.paymentMilestonesCreateClass.projectOptions.items = [
      ...this.paymentMilestonesCreateClass.projectOptions.items,
    ];
  }
  clearData() {
    this.paymentMilestonesCreateClass.projectOptions.items = [];
    this.paymentMilestonesCreateClass.newPaymentMilestonesObj.proyecto = null;
  }
  /**
   * GETTERS & SETTERS
   */
  getDate(event) {
    this.paymentMilestonesCreateClass.newPaymentMilestonesObj.fecha = new iDate(
      iDate.toPhp(event)
    ).toStringDate('JAP');
  }
  setData() {
    if (this.modalData.edit) {
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj.cantidad =
        this.modalData.obj?.cantidad;
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj.concepto =
        this.modalData.obj?.concepto;
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj.fecha =
        this.modalData.obj?.fecha;
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj.cliente =
        this.modalData.obj?.cliente.id ?? null;
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj.proyecto = this
        .modalData.edit
        ? this.modalData.obj.proyecto.id
        : this.modalData.obj.proyecto;
      this.paymentMilestonesCreateClass.backUpNewPaymentMilestonesObj = {
        ...this.paymentMilestonesCreateClass.newPaymentMilestonesObj,
      };
    } else {
      if (this.modalData.obj) {
        this.paymentMilestonesCreateClass.newPaymentMilestonesObj.cliente =
          this.modalData.obj?.cliente.id ?? null;
        this.paymentMilestonesCreateClass.newPaymentMilestonesObj.proyecto =
          this.modalData.obj?.proyecto ?? null;
      }
    }
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  validateIfDataIsCorrectHandler() {
    const { cliente, fecha, cantidad, concepto, proyecto } =
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj;
    if (cliente && fecha && cantidad && concepto && proyecto) {
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj['potencial'] =
        false;
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  successGetClientsResponseHandler(res: iResultHttp) {
    this.paymentMilestonesCreateClass.clientOptions.items = [...res.data];
  }
  checkIfDataIsCompletedHandler() {
    const { cliente, fecha, cantidad, concepto, proyecto } =
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj;
    if (cliente && fecha && cantidad && concepto && proyecto) {
      this.paymentMilestonesCreateClass.isCompleted = true;
    } else {
      this.paymentMilestonesCreateClass.isCompleted = false;
    }
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.paymentMilestonesCreateClass.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  actionHandler() {
    this.modalData.edit
      ? this.closePopup()
      : this.validateIfDataIsCorrectHandler();
  }
  errorCreateResponseHandler(res: iResultHttp) {
    this.paymentMilestonesCreateClass.isLoading = false;
  }
  updatePaymentMilestonesHandler(element: string, value: any) {
    const obj: any = { id: this.modalData.obj?.id };
    obj[element] = value;
    this.modalData.edit ? this.editPaymentMilestones(obj) : null;
  }
  checkIfAllDataIsCompleted(element: string, value: any) {
    switch (element) {
      case 'fecha':
        if (value) {
          this.updatePaymentMilestonesHandler(element, value);
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'La fecha es un campo obligatorio y no puede estar vacío.'
          );
          this.paymentMilestonesCreateClass.newPaymentMilestonesObj.fecha =
            this.paymentMilestonesCreateClass.backUpNewPaymentMilestonesObj.fecha;
        }
        break;
      case 'concepto':
        if (value) {
          this.updatePaymentMilestonesHandler(element, value);
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'El concepto es un campo obligatorio y no puede estar vacío.'
          );
          this.paymentMilestonesCreateClass.newPaymentMilestonesObj.concepto =
            this.paymentMilestonesCreateClass.backUpNewPaymentMilestonesObj.concepto;
        }
        break;
      case 'cantidad':
        if (value) {
          this.updatePaymentMilestonesHandler(element, value);
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'La cantidad es un campo obligatorio y no puede estar vacío.'
          );
          this.paymentMilestonesCreateClass.newPaymentMilestonesObj.cantidad =
            this.paymentMilestonesCreateClass.backUpNewPaymentMilestonesObj.cantidad;
        }
        break;
    }
  }
  successEditPaymentMilsetonesResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.paymentMilestonesCreateClass.isLoading = false;
  }
  errorEditPaymentMilsetonesResponseHandler() {
    this.paymentMilestonesCreateClass.isLoading = false;
  }
  successGetProjectsResponseHandler(res: iResultHttp) {
    this.paymentMilestonesCreateClass.projects = [...res.data.data];
    if (!this.modalData.edit && this.modalData.obj) {
      this.paymentMilestonesCreateClass.projectOptions.items = [
        ...this.paymentMilestonesCreateClass.projects,
      ];
    } else {
      this.findProjects();
    }
  }
  /**
   * CALLS TO API
   */
  getClients() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.clientSE.getAllCompanyClients(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetClientsResponseHandler(res),
          error: false,
        });
      });
  }
  getAllProjects() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.getAll(
      behaviorSubject,
      this.paymentMilestonesCreateClass.projectFilers
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetProjectsResponseHandler(res),
          error: false,
        });
      });
  }
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.invoiceSE.create(
      behaviorSubject,
      this.paymentMilestonesCreateClass.newPaymentMilestonesObj
    );
    this.paymentMilestonesCreateClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
  editPaymentMilestones(obj) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.invoiceSE.update(
      behaviorSubject,
      obj,
      'Se ha modificado el hito de cobro correctamente'
    );

    this.paymentMilestonesCreateClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successEditPaymentMilsetonesResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorEditPaymentMilsetonesResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
