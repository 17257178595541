<div class="popupGeneralContainer">
  <div class="header">
    <p>Crear nuevo presupuesto</p>
  </div>
  <div class="body">
    <ng-container *ngIf="!isTemplate">
      <div class="optionContainer">
        <div class="option" (click)="forceClosePopup()">
          <mat-icon class="material-icons-outlined">
            add_circle_outline
          </mat-icon>
          <p>Desde cero</p>
        </div>
        <div class="option" (click)="isTemplate = true">
          <mat-icon class="material-icons-outlined">
            file_upload
          </mat-icon>
          <p>Desde plantilla</p>
        </div>
      </div>

      <btn class="secondary" (click)="forceClosePopup()">
        <ng-container>
          <mat-icon>close</mat-icon>
          <span>Cancelar</span>
        </ng-container>
      </btn>
    </ng-container>
    <ng-container *ngIf="isTemplate">
      <div class="customFormContainer">
        <label for="">Selecciona la plantilla<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="templateOptions" [(ngModel)]="templateSelected">
        </selector>
      </div>
      <btn class="action" [ngClass]="{'disabled': templateSelected === null}" (click)="closePopup(templateSelected)">
        <ng-container>
          <span>Cargar plantilla</span>
        </ng-container>
      </btn>
    </ng-container>
  </div>

</div>