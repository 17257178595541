import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupService } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'employeeTableRevision',
  templateUrl: './employee-table-revision.component.html',
  styleUrls: ['./employee-table-revision.component.scss'],
})
export class EmployeeTableRevisionComponent implements OnInit {
  @Input() data: Array<any> = [];
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  employeeSelected: Array<any> = [];
  displayedColumns: string[] = [
    'actions',
    'name',
    'email',
    'userType',
    'cost',
    'anualCost',
    'costType',
    'contract',
    'diasVacaciones',
  ];
  constructor(private popupSE: PopupService) {}

  ngOnInit(): void {}

  addToSelected(product: any) {
    if (this.employeeSelected.includes(product)) {
      this.employeeSelected.splice(this.employeeSelected.indexOf(product), 1);
    } else {
      this.employeeSelected.push(product);
    }
    this.update.emit(this.employeeSelected);
  }
  /**
   * HANDLERS
   */
  contractTypeHandler(horas: number) {
    if (horas == 40) {
      return 'Jorna completa';
    }
    if (horas == 20) {
      return 'Media jornada';
    }
    return 'Jorna parcial (' + horas + ' h)';
  }
}
