import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iDailyCategory } from '../../Interfaces/Api/Category/iDailyCategory';
import { iCreateEditDepartment } from '../../Interfaces/Api/Departments/iCreateEditDepartment';
import { iCreateEditCategory } from '../../Interfaces/Api/Category/iCreateEditCategory';

export class Categories {
  isCompleted: boolean = false;
  categories: Array<iDailyCategory> = [];
  categoriesBackup: Array<iDailyCategory> = [];
  isLoading: boolean = false;
  genericFilter: string = '';
  createEditObj: iCreateEditCategory = {
    nombre: '',
    color: '#000000',
  };
  nombreOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  colorOption: iOptionsCustomInput = {
    type: 'color',
    placeholder: 'Escribe aquí...',
    label: 'Color',
    required: true,
    classList: 'specialFormInput',
  };
}
