import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  iResultHttp,
  iStaticUtilities,
  sha256,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { AccessRegisterService } from 'src/app/Services/Api/access-register.service';
import { LoginService } from 'src/app/Services/Api/login.service';
import { TimeTrackerService } from 'src/app/Services/Api/time-tracker.service';
import { UserService } from 'src/app/Services/Api/user.service';
import { Login } from 'src/app/Shared/Class/login';
import { FennechLoaderComponent } from 'src/app/Shared/Components/fennech-loader/fennech-loader.component';
import { iLogin } from 'src/app/Shared/Interfaces/Api/iLogin';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginClass = new Login();

  constructor(
    private staticUtilitiesSE: StaticUtilitiesService,
    private loginSE: LoginService,
    private userSE: UserService,
    private timeTrackerSE: TimeTrackerService,
    private accessRegisterSE: AccessRegisterService
  ) {}

  ngOnInit(): void {}

  /**
   * HANDLERS
   */

  successGetMyDataResponseHandler(res: iResultHttp) {
    localStorage.setItem('idUser', res.data.id);
    localStorage.setItem('nombre', res.data.nombre);
    localStorage.setItem('apellidos', res.data.apellidos);
    localStorage.setItem('numEmpresa', res.data.empresa.id);
    localStorage.setItem(
      iStaticUtilities.simpleCrypt('permisos'),
      iStaticUtilities.simpleCrypt(JSON.stringify(res.data.permisos))
    );
    if (res.data.idClockify) {
      localStorage.setItem('id_clockify', res.data.idClockify);
      localStorage.setItem('user_clockify', res.data.userClockify);
    }
    res.data.empresa.idClockify
      ? localStorage.setItem('id_clockify_empresa', res.data.empresa.idClockify)
      : null;
  }
  /**
   * CALLS TO API
   */

  login() {
    const newData: iLogin = {
      username: this.loginClass.loginData.username,
      password: sha256(this.loginClass.loginData.password)!,
    };
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.staticUtilitiesSE.openLoader(FennechLoaderComponent);
    this.loginSE.login(behaviorSubject, newData);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.getMyData(),
          error: false,
        },
        {
          method: () => this.timeTrackerLogin(),
          error: false,
        },
        {
          method: () => this.registerAccess(),
          error: false,
        },
      ]);

      this.staticUtilitiesSE.closeLoader();
    });
  }
  timeTrackerLogin() {
    const newData: iLogin = {
      username: this.loginClass.loginData.username,
      password:
        'a665a45920422f9d417e4867efdc4fb8a04a1f3fff1fa07e998e86f7f7a27ae3',
    };
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.timeTrackerSE.login(behaviorSubject, newData);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
    });
  }
  getMyData() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.userSE.getMyData(behaviorSubject);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: this.successGetMyDataResponseHandler(res),
          error: false,
        },
        {
          method: this.loginSE.redirectToRol(),
          error: false,
        },
      ]);
    });
  }
  registerAccess() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    navigator.geolocation.getCurrentPosition((position) => {
      var location: { latitud: string; longitud: string } = {
        latitud: '',
        longitud: '',
      };
      location.latitud = String(position.coords.latitude);
      location.longitud = String(position.coords.longitude);
      setTimeout(() => {
        this.accessRegisterSE.create(behaviorSubject, location);
      }, 1000);
    });

    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject);
      });
  }
}
