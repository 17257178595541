import { iSingleClientInvoice } from '../Bill/iSingleClientInvoice';
import { iSimpleProject } from '../Project/iSimpleProject';
import { iSimpleClient } from './iSimpleClient';

export interface iSingleClient {
  id: number | null;
  mail: string;
  nombre: string;
  telefono: string | null;
  direccionFiscal: string;
  cif: string;
  proyectos: Array<iSimpleProject>;
  facturas: Array<iSingleClientInvoice>;
  hitos: Array<iSingleClientInvoice>;
}

export class iSingleClient {
  static convertFromCollection(ob: any): Array<iSingleClient> {
    let iSingleClientCollection: Array<iSingleClient> = [];
    ob.forEach((element: any) => {
      iSingleClientCollection.push(this.convertFromObject(element));
    });
    return iSingleClientCollection;
  }

  static convertFromObject(ob: any): iSingleClient {
    let iSingleClientObj = new iSingleClient();
    iSingleClientObj.id = ob.id;
    iSingleClientObj.mail = ob.mail;
    iSingleClientObj.nombre = ob.nombre;
    iSingleClientObj.telefono = ob.telefono;
    iSingleClientObj.direccionFiscal = ob.direccionFiscal;
    iSingleClientObj.cif = ob.cif;
    iSingleClientObj.proyectos = iSimpleProject.convertFromCollection(
      ob.proyectos
    );
    iSingleClientObj.facturas = iSingleClientInvoice.convertFromCollection(
      ob.facturas
    );
    iSingleClientObj.hitos = iSingleClientInvoice.convertFromCollection(
      ob.hitos
    );
    return iSingleClientObj;
  }

  static createEmptyObject(): iSingleClient {
    let iSingleClientObj = new iSingleClient();
    iSingleClientObj.id = null;
    iSingleClientObj.mail = '';
    iSingleClientObj.nombre = '';
    iSingleClientObj.telefono = '';
    iSingleClientObj.direccionFiscal = '';
    iSingleClientObj.cif = '';
    iSingleClientObj.proyectos = [];
    iSingleClientObj.facturas = [];
    iSingleClientObj.hitos = [];
    return iSingleClientObj;
  }
}
