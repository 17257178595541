export interface iChangePassword {
  password: string;
  repeatPassword?: string;
}
export class iChangePassword {
  static createEmptyObject(): iChangePassword {
    const newChangePasswordObj = new iChangePassword();
    newChangePasswordObj.password = '';
    newChangePasswordObj.repeatPassword = '';
    return newChangePasswordObj;
  }
}
