import { iSimpleEmployee } from '../Employee/iSimpleEmployee';

export interface iAccessRegisterList {
  id: number | null;
  ip: string;
  closeDate: string;
  latitud: string;
  longitud: string;
  creationDate: string;
  user: iSimpleEmployee;
  alerta: boolean;
}

export class iAccessRegisterList {
  static convertFromCollection(ob: any): Array<iAccessRegisterList> {
    let iSelfDataCollection: Array<iAccessRegisterList> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iAccessRegisterList {
    let iSelfDataObj = new iAccessRegisterList();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.closeDate = ob.closeDate;
    iSelfDataObj.creationDate = ob.creationDate;
    iSelfDataObj.ip = ob.ip;
    iSelfDataObj.latitud = ob.latitud;
    iSelfDataObj.longitud = ob.longitud;
    iSelfDataObj.alerta = ob.alerta;
    iSelfDataObj.user = iSimpleEmployee.convertFromObject(ob.usuario);
    return iSelfDataObj;
  }

  static createEmptyObject(): iAccessRegisterList {
    let iSelfDataObj = new iAccessRegisterList();
    iSelfDataObj.id = null;
    iSelfDataObj.ip = '';
    iSelfDataObj.closeDate = '';
    iSelfDataObj.creationDate = '';
    iSelfDataObj.latitud = '';
    iSelfDataObj.longitud = '';
    iSelfDataObj.alerta = false;
    iSelfDataObj.user = iSimpleEmployee.createEmptyObject();
    return iSelfDataObj;
  }
}
