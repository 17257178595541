import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iNotValidatedDaily } from '../../Interfaces/Api/Daily/iNotValidatedDaily';
import { DailyService } from 'src/app/Services/Api/daily.service';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { NotValidatedDailies } from '../../Class/Daily/not-validated-dailies';

@Component({
  selector: 'notValidatedDailyCard',
  templateUrl: './not-validated-daily-card.component.html',
  styleUrls: ['./not-validated-daily-card.component.scss'],
})
export class NotValidatedDailyCardComponent implements OnInit {
  @Input() data: iNotValidatedDaily = iNotValidatedDaily.createEmptyObject();
  @Input() notValidatedDailiesClass: NotValidatedDailies =
    new NotValidatedDailies();
  @Output() update: EventEmitter<iNotValidatedDaily> =
    new EventEmitter<iNotValidatedDaily>();
  @Output() showReasonsEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  isLoading: boolean = false;
  showReasons: boolean = false;

  constructor(private dailySE: DailyService) {}

  ngOnInit(): void {}

  /*
   * HANDLERS
   */
  completeDailyHandler() {
    const editDaily = {
      id: this.data.id,
      enviado: true,
      completada: true,
      porcentaje: 100,
    };
    this.updateDaily(editDaily);
  }
  successUpdateDailyResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback('Daily completado correctamente');
    this.isLoading = false;
    this.update.emit(this.data);
  }
  errorUpdateDailyResponseHandler(res: iResultHttp) {
    this.isLoading = false;
  }

  /**
   * CALLS TO API
   */
  updateDaily(editDaily) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.dailySE.update(behaviorSubject, editDaily);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateDailyResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorUpdateDailyResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
}
