export interface iCategoryProduct {
  id: number | null;
  nombre: string;
  descripcion: string;
}
export class iCategoryProduct {
  static convertFromCollection(ob: any): Array<iCategoryProduct> {
    let iCategoryProductCollection: Array<iCategoryProduct> = [];
    ob.forEach((element: any) => {
      iCategoryProductCollection.push(this.convertFromObject(element));
    });
    return iCategoryProductCollection;
  }

  static convertFromObject(ob: any): iCategoryProduct {
    let iCategoryProductObj = new iCategoryProduct();
    iCategoryProductObj.id = ob.id;
    iCategoryProductObj.nombre = ob.nombre;
    iCategoryProductObj.descripcion = ob.description;
    return iCategoryProductObj;
  }

  static createEmptyObject(): iCategoryProduct {
    let iCategoryProductObj = new iCategoryProduct();
    iCategoryProductObj.id = null;
    iCategoryProductObj.nombre = '';
    iCategoryProductObj.descripcion = '';
    return iCategoryProductObj;
  }
}
