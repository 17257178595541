import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iBussinessLineCategory } from '../../Interfaces/Api/BussinesLine/iBussinessLineCategory';

export class BussinesLineCategories {
  isCompleted: boolean = false;
  categories: Array<iBussinessLineCategory> = [];
  categoriesBackup: Array<iBussinessLineCategory> = [];
  isLoading: boolean = false;
  genericFilter: string = '';

  createEditObj: iBussinessLineCategory = {
    nombre: '',
  };
  backupCreateEditObj: iBussinessLineCategory = {
    nombre: '',
  };
  nombreOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
}
