import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/user.service';
import { ChangePassword } from 'src/app/Shared/Class/User/change-password';

@Component({
  selector: 'app-change-password-popup',
  templateUrl: './change-password-popup.component.html',
  styleUrls: ['./change-password-popup.component.scss'],
})
export class ChangePasswordPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  changePasswordClass = new ChangePassword();
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private userSE: UserService
  ) {}

  ngOnInit() {
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */

  successUpdateSelfDataResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.forceClosePopup();
  }
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  /**
   * CALLS TO API
   */

  updateSelfData() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.changePasswordClass.isLoading = true;
    this.userSE.updateSelfData(
      behaviorSubject,
      this.changePasswordClass.returnReadyPasswordObject()
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: this.successUpdateSelfDataResponseHandler(res),
        error: false,
      });
      this.changePasswordClass.isLoading = false;
    });
  }
}
