<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Edición rápida del proyecto</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="editProjectClass.nameOptions" [(ngModel)]="modalData.nombre"
        (change)="validateIfDataIsCorrectHandler('nombre', modalData.nombre)"></customInput>
      <customInput [options]="editProjectClass.abreviationOptions" [(ngModel)]="modalData.abreviatura"
        (change)="validateIfDataIsCorrectHandler('abreviatura', modalData.abreviatura)">
      </customInput>
      <div class="customFormContainer fullWidth">
        <label for="">Responsable<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="editProjectClass.managerOptions" [(ngModel)]="modalData.responsable.id"
          (change)="validateIfDataIsCorrectHandler('responsable', modalData.responsable.id)">
        </selector>
      </div>
      <customInput [options]="editProjectClass.colorOption" [(ngModel)]="modalData.etiqueta"
        (change)="validateIfDataIsCorrectHandler('etiqueta', modalData.etiqueta)"></customInput>
      <btn class="action" (click)="goToEditProject()">
        <ng-container>
          <img [src]="'/assets/Icons/editEmployee.svg'" alt="">
          <span>Editar proyecto completo</span>
        </ng-container>
      </btn>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action">
      <ng-container>
        <mat-icon *ngIf="!editProjectClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="editProjectClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>