<div class="checkinFloattingButtonContainer" [title]="'Abrir popup para fichar'" (click)="getLastCheckin()">
  <div class="clock">
    <div class="top"></div>
    <div class="right"></div>
    <div class="left"></div>
    <div class="bottom"></div>
    <div class="minute"></div>
    <div class="second"></div>
    <div class="center"></div>
  </div>
</div>