import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { SprintPeriodService } from 'src/app/Services/Api/sprint-period.service';
import { SprintPeriod } from 'src/app/Shared/Class/Sprint/sprint-period';

@Component({
  selector: 'app-create-edit-sprint-period-popup',
  templateUrl: './create-edit-sprint-period-popup.component.html',
  styleUrls: ['./create-edit-sprint-period-popup.component.scss'],
})
export class CreateEditSprintPeriodPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  sprintPeriodClass: SprintPeriod = new SprintPeriod();

  constructor(
    public dialogRef: MatDialogRef<CreateEditSprintPeriodPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private sprintPeriodSE: SprintPeriodService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.sprintPeriodClass.createEditObj = this.modalData.sprintPeriod;
    this.sprintPeriodClass.backupCreateEditObj = JSON.parse(
      JSON.stringify(this.modalData.sprintPeriod)
    );
    this.modalData.sprintPeriod.activo
      ? (this.sprintPeriodClass.rangeOption.readOnly = true)
      : (this.sprintPeriodClass.rangeOption.readOnly = false);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    !this.modalData.edit
      ? this.validateIfDataIsCorrectHandler()
      : this.modalData.edit
      ? this.closePopup()
      : this.forceClosePopup();
  }
  validateIfDataIsCorrectHandler() {
    if (this.sprintPeriodClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = { id: this.sprintPeriodClass.createEditObj.id };
      switch (element) {
        case 'nombre':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo nombre es obligatorio y no puede estar vacío.'
            );
            this.sprintPeriodClass.createEditObj.nombre =
              this.sprintPeriodClass.backupCreateEditObj.nombre;
          }
          break;
        case 'rango':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo rango es obligatorio y no puede estar vacío.'
            );
            this.sprintPeriodClass.createEditObj.rango =
              this.sprintPeriodClass.backupCreateEditObj.rango;
          }
          break;
        default:
          obj[element] = value;
          this.update(obj);
          break;
      }
    }
  }
  checkIfDataIsCompleted() {
    const { nombre, rango } = this.sprintPeriodClass.createEditObj;
    this.sprintPeriodClass.isCompleted = !!nombre && !!rango;
  }

  /**
   * RESPONSES HANDLERS
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.sprintPeriodClass.isLoading = false;
    !this.modalData.edit ? this.closePopup() : null;
  }
  errorResponseHandler() {
    this.sprintPeriodClass.isLoading = false;
  }

  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sprintPeriodSE.create(
      behaviorSubject,
      this.sprintPeriodClass.createEditObj
    );
    this.sprintPeriodClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sprintPeriodSE.update(behaviorSubject, obj);
    this.sprintPeriodClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
