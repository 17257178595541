export interface iExpenseList {
  nombre: string;
  directo: number | null;
  indirecto: number | null;
  cerrado?: boolean;
}

export class iExpenseList {
  static convertFromCollection(ob: any): Array<iExpenseList> {
    let iExpenseListCollection: Array<iExpenseList> = [];
    ob.forEach((element: any) => {
      iExpenseListCollection.push(this.convertFromObject(element));
    });
    return iExpenseListCollection;
  }

  static convertFromObject(ob: any): iExpenseList {
    let iExpenseListObj = new iExpenseList();
    iExpenseListObj.nombre = ob.nombre;
    iExpenseListObj.directo = ob.Directo;
    iExpenseListObj.indirecto = ob.Indirecto;
    iExpenseListObj.cerrado = ob.Cerrado;
    return iExpenseListObj;
  }

  static createEmptyObject(): iExpenseList {
    let iExpenseListObj = new iExpenseList();
    iExpenseListObj.nombre = '';
    iExpenseListObj.directo = null;
    iExpenseListObj.indirecto = null;
    iExpenseListObj.cerrado = false;
    return iExpenseListObj;
  }
}
