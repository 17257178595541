import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iSickLeaveCategory } from '../../Interfaces/Api/SickLeaves/iSickLeaveCategory';

export class SickLeave {
  isCompleted: boolean = false;
  categories: Array<iSickLeaveCategory> = [];
  categoriesBackup: Array<iSickLeaveCategory> = [];
  isLoading: boolean = false;
  genericFilter: string = '';
  createEditObj: iSickLeaveCategory = {
    nombre: '',
  };
  backupCreateEditObj: iSickLeaveCategory = {
    nombre: '',
  };
  nombreOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
}
