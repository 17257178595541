<div class="popupGeneralContainer fullSize">
  <div class="header">
    <p>Crear múltiples costes</p>
  </div>
  <div class="buttonsContainer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <div class="actions">
      <btn class="action" [ngClass]="{'disabled': selectedCosts.length === 0}" (click)="removeSelectedCosts()">
        <ng-container>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
          <span>Eliminar seleccionados</span>
        </ng-container>
      </btn>
      <btn class="action" (click)="create()">
        <ng-container>
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <mat-icon class="material-icons-outlined" *ngIf="!isLoading">add_circle_outline</mat-icon>
          <span>Añadir los costes</span>
        </ng-container>
      </btn>
    </div>
  </div>
  <costTableRevision [data]="modalData.costs" (update)="getSelectedCosts($event)">
  </costTableRevision>
</div>