import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iOptionsSelector,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DailyService } from 'src/app/Services/Api/daily.service';
import { ProcessService } from 'src/app/Services/Api/process.service';
import { Daily } from 'src/app/Shared/Class/Implications/daily';
import { iProyectProcessSelector } from 'src/app/Shared/Interfaces/Api/Process/iProyectProcessSelector';

@Component({
  selector: 'app-assign-hours-to-proyect-popup',
  templateUrl: './assign-hours-to-proyect-popup.component.html',
  styleUrls: ['./assign-hours-to-proyect-popup.component.scss'],
})
export class AssignHoursToProyectPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }
  proyectOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: false,
    search: true,
  };
  processOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: true,
    search: true,
  };
  subprocessOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    clearable: true,
    search: true,
  };
  procesos: Array<iProyectProcessSelector> = [];
  isLoading: boolean = false;
  isCompleteData: boolean = false;
  editObj: any = {
    id: null,
    proyecto: null,
    proceso: null,
    subProceso: null,
  };
  constructor(
    public dialogRef: MatDialogRef<AssignHoursToProyectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private processSE: ProcessService,
    private dailySE: DailyService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.getAllProcessByUser();
    this.editObj.id = this.modalData.daily.id;
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  filterProyectProcesses() {
    this.editObj.proceso = null;
    this.processOption.items = [
      ...this.procesos.filter((element) => {
        return (
          element.proyecto === this.editObj.proyecto && !element.subProceso
        );
      }),
    ];
  }
  filterProyectSubProcesses() {
    this.editObj.proceso = null;
    this.subprocessOption.items = [
      ...this.procesos.filter((element) => {
        return element.proyecto === this.editObj.proyecto && element.subProceso;
      }),
    ];
  }
  clearData() {
    this.editObj.subProceso = null;
    this.editObj.proceso = null;
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.closePopup();
      }
    });
  }
  checkIfIsCompleteDataHandler() {
    const { proyecto } = this.editObj;
    this.isCompleteData = !!proyecto;
  }
  /**
   * API RESPONSES
   */
  successGetAllProcessByUserResponseHandler(res: iResultHttp) {
    this.proyectOption.items = [...res.data.projects];
    this.procesos = [...res.data.processes];
  }
  successAssignProjectToDailyResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se han imputado las horas al proyecto correctamente'
    );
    this.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorAssignProjectToDailyResponseHandler(res: iResultHttp) {
    this.isLoading = false;
  }

  /**
   * CALLS TO API
   */
  getAllProcessByUser() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.processSE.getAllProcessByUser(
      behaviorSubject,
      this.modalData.daily.usuario.id
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successGetAllProcessByUserResponseHandler(res),
        error: false,
      });
    });
  }
  assignProjectToDaily() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.dailySE.update(behaviorSubject, this.editObj);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.successAssignProjectToDailyResponseHandler(res),
          error: false,
        },
        {
          method: () => this.errorAssignProjectToDailyResponseHandler(res),
          error: true,
        },
      ]);
    });
  }
}
