import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iProjectFilter } from '../../Interfaces/Api/Project/iProjectFilter';
import { iProject } from '../../Interfaces/Api/Project/iProject';

export class PaymentMilestonesCreate {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  projectFilers: iProjectFilter = {
    num_devoluciones: 99999999,
    num_pagina: 1,
    tag: 'estadisticas',
    potencial: false,
  };
  projects: Array<iProject> = [];
  clientOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
  phaseOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    classList: 'specialFormSelector',
    search: true,
  };
  processOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    classList: 'specialFormSelector',
    search: true,
  };
  projectOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindValue: 'id',
    bindLabel: 'nombre',
    classList: 'specialFormSelector',
    search: true,
  };
  emisionDateOptions: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    required: true,
    label: 'Fecha',
    classList: 'specialFormInput',
  };
  conceptOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Concepto',
    classList: 'specialFormInput',
  };
  quantityOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Cantidad (€)',
    classList: 'specialFormInput',
  };
  newPaymentMilestonesObj: any = {
    cliente: null,
    fecha: null,
    cantidad: null,
    concepto: '',
    proyecto: null,
    factura: false,
  };
  backUpNewPaymentMilestonesObj: any = {
    cliente: null,
    fecha: null,
    cantidad: null,
    concepto: '',
    proyecto: null,
    factura: false,
  };
  newMilestonesPaymentQuotationEngine: any = {
    id: null,
    fecha: null,
    importe: null,
    concepto: '',
    fase: null,
    proceso: null,
  };
}
