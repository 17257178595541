export interface iNewBill {
  id?: number | null;
  concepto: string;
  cantidad: number | null;
  tipo: number | null;
  directo: boolean | null;
  iva: number | null;
  familia: number | null;
  proyecto: number | null;
  numeroFactura: number | null;
  dia: number | null;
  mes: number | null;
  fecha: string;
  estado: number | null;
  anyo: number | null;
  urlDocument: string;
  proveedor: number | null;
}

export class iNewBill {
  static createEmptyObject(): iNewBill {
    let iSelfDataObj = new iNewBill();
    iSelfDataObj.id = null;
    iSelfDataObj.concepto = '';
    iSelfDataObj.cantidad = null;
    iSelfDataObj.tipo = null;
    iSelfDataObj.directo = null;
    iSelfDataObj.iva = null;
    iSelfDataObj.familia = null;
    iSelfDataObj.proyecto = null;
    iSelfDataObj.numeroFactura = null;
    iSelfDataObj.dia = null;
    iSelfDataObj.mes = null;
    iSelfDataObj.fecha = '';
    iSelfDataObj.estado = null;
    iSelfDataObj.anyo = null;
    iSelfDataObj.urlDocument = '';
    iSelfDataObj.proveedor = null;
    return iSelfDataObj;
  }
}
