<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="!modalData.process">Crear nuevo proceso</p>
    <p *ngIf="modalData.process">Editar proceso</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="createEditProcessClass.nameOptions"
          [(ngModel)]="createEditProcessClass.createEditObj.nombre"
          (change)="checkIfQuickEditDataIsComplete('nombre', createEditProcessClass.createEditObj.nombre)">
        </customInput>
        <div class="customFormContainer">
          <label for="">Integrantes<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [options]="createEditProcessClass.employeeOptions"
            [(ngModel)]="this.createEditProcessClass.createEditObj.empleados"
            (change)="updateProcessHandler('empleados', createEditProcessClass.createEditObj.empleados)">
          </selector>
        </div>
      </div>
      <div class="columnDisplay">
        <customInput [options]="createEditProcessClass.projectOptions"
          [(ngModel)]="createEditProcessClass.createEditObj.proyecto.nombre">
        </customInput>

        <customInput [options]="createEditProcessClass.phaseOptions"
          [(ngModel)]="createEditProcessClass.createEditObj.fase.nombre"></customInput>

      </div>
      <div class="columnDisplay">
        <customInput [options]="createEditProcessClass.hoursOptions"
          [(ngModel)]="createEditProcessClass.createEditObj.horas"
          (change)="checkIfQuickEditDataIsComplete('horas', createEditProcessClass.createEditObj.horas)"></customInput>
        <customInput [options]="createEditProcessClass.completedOptions"
          [(ngModel)]="createEditProcessClass.createEditObj.completado"
          (change)="checkIfQuickEditDataIsComplete('completado', createEditProcessClass.createEditObj.completado)">
        </customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="createEditProcessClass.startDateOptions"
          [selectedDate]="createEditProcessClass.createEditObj.fechaInicial"
          (change)="checkIfQuickEditDataIsComplete('fechaIni', $event)">
        </customInput>
        <customInput [options]="createEditProcessClass.endDateOptions" [selectedDate]="
          createEditProcessClass.createEditObj.fechaFin" (change)="checkIfQuickEditDataIsComplete('fechaFin', $event)">
        </customInput>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="actionDiscardHandler()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createEditProcessClass.isCompleted && modalData.process === null}"
      (click)="actionHubHandler()">
      <ng-container>
        <mat-icon *ngIf="!createEditProcessClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createEditProcessClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>