<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar tipo de vacaciones</p>
    <p *ngIf="!modalData.edit">Crear una nuevo tipo de vacaciones</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="vacationsPeriodClass.nameOptions" [(ngModel)]="vacationsPeriodClass.createEditObj.nombre"
        (change)="updateDataHandler('nombre', vacationsPeriodClass.createEditObj.nombre);checkIfDataIsCompleted()">
      </customInput>
      <customInput [options]="vacationsPeriodClass.daysOptions" [(ngModel)]="vacationsPeriodClass.createEditObj.dias"
        (change)="updateDataHandler('dias', vacationsPeriodClass.createEditObj.dias);checkIfDataIsCompleted()">
      </customInput>
      <div class="customFormContainer">
        <label for="">Tipo<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="vacationsPeriodClass.typeOptions" [(ngModel)]="vacationsPeriodClass.createEditObj.tipo"
          (change)="updateDataHandler('tipo', vacationsPeriodClass.createEditObj.tipo);checkIfDataIsCompleted()">
        </selector>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !vacationsPeriodClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!vacationsPeriodClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="vacationsPeriodClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>