import {
  iDate,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iCreateSickLeave } from '../../Interfaces/Api/SickLeaves/iCreateSickLeave';

export class CreateSickLeave {
  isLoading: boolean = false;
  isCompleted: boolean = false;
  date: Date = new Date();
  createSickLeaveObj: iCreateSickLeave = {
    empleado: null,
    fechaIni: new iDate(iDate.toPhp(new Date())).toStringDate('JAP'),
    categoria: null,
  };
  categoryOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
  dateOption: iOptionsCustomInput = {
    label: 'Fecha de baja',
    placeholder: 'Escribe aquí...',
    type: 'date',
    classList: 'specialFormInput',
    date: {
      maxDate: new Date(),
    },
    required: true,
  };
}
