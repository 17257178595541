<div class="popupGeneralContainer">
  <div class="header">
    <p>Roles y permisos de {{modalData.nombre}} {{modalData.apellidos}}</p>
  </div>
  <div class="body">
    <div class="roles">
      <label for="">Listado de los roles</label>
      <div class="radioButtonsContainer">
        <mat-radio-button value="empleado" [checked]="modalData.roles.includes('ROLE_USER')"
          (click)="$event.preventDefault();changeEmployeeRole('empleado')">Empleado</mat-radio-button>
        <mat-radio-button value="gestor" [checked]="modalData.roles.includes('ROLE_GESTOR')"
          (click)="$event.preventDefault(); changeEmployeeRole('gestor')">Gestor</mat-radio-button>
        <mat-radio-button value="intermedio" [checked]="modalData.roles.includes('ROLE_INTERMEDIO')"
          (click)="$event.preventDefault(); changeEmployeeRole('intermedio')">Intermedio</mat-radio-button>
        <mat-radio-button value="administrador" [ngClass]="{'disabled': this.isIntermedite}"
          [checked]="modalData.roles.includes('ROLE_ADMIN')"
          (click)="$event.preventDefault(); ; changeEmployeeRole('intermedio')">Administrador</mat-radio-button>
      </div>
    </div>
    <div class="permissions" *ngIf="modalData.roles.includes('ROLE_GESTOR')">
      <label for="">Listado de los permisos</label>
      <mat-spinner *ngIf="!isDataLoaded"></mat-spinner>
      <div class="checksContainer">
        <ng-container *ngFor="let permission of permissions">
          <div class="check">
            <mat-checkbox [checked]="checkIfHasThisPermissionHandler(permission.id)"
              (change)="updatePermission($event, permission.id)">{{permission.nombre}}</mat-checkbox>
            <!--Todo: Activar cuando se acabe de depurar-->
            <ng-container *ngIf="false">
              <div class="subCheck">
                <mat-checkbox [checked]="checkIfHasThisPermissionHandler(permission.id)"
                  (change)="updateViewPermission($event, permission.id)">Ver</mat-checkbox>
                <mat-checkbox [checked]="checkIfHasThisPermissionAndEditHandler(permission.id)"
                  (change)="updateEditPermission($event, permission.id)">
                  Ver y editar</mat-checkbox>
              </div>
              <!--Todo: Fin todo-->

            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cerrar</span>
      </ng-container>
    </btn>
    <btn class="action" (click)="closePopup()">
      <ng-container>
        <mat-icon *ngIf="!isLoading">save</mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Guardar</span>
      </ng-container>
    </btn>
  </div>
</div>