<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Crear una oferta</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="createFunnelBudgetClass.nameOptions"
          [(ngModel)]="createFunnelBudgetClass.newFunnelBudgetOBJ.nombre" (change)="checkIfDataIsCompletedHandler()">
        </customInput>
        <customInput [options]="createFunnelBudgetClass.abreviatureOptions"
          [(ngModel)]="createFunnelBudgetClass.newFunnelBudgetOBJ.abreviatura"
          (change)="checkIfDataIsCompletedHandler()"></customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="createFunnelBudgetClass.startDateOptions"
          (change)="getStartDate($event);checkIfDataIsCompletedHandler()">
        </customInput>
        <customInput [options]="createFunnelBudgetClass.endDateOptions"
          [(ngModel)]="createFunnelBudgetClass.newFunnelBudgetOBJ.fechaInicio"
          (change)="getEndDate($event);checkIfDataIsCompletedHandler()">
        </customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="createFunnelBudgetClass.dayCostOptions"
          [(ngModel)]="createFunnelBudgetClass.newFunnelBudgetOBJ.coste_dia" (change)="checkIfDataIsCompletedHandler()"
          (input)="createFunnelBudgetClass.fixedDays ? changeDays() : changePrice()">
        </customInput>
        <customInput [options]="createFunnelBudgetClass.multiplierOptions"
          [(ngModel)]="createFunnelBudgetClass.newFunnelBudgetOBJ.multiplicador"
          (change)="checkIfDataIsCompletedHandler()"
          (input)="createFunnelBudgetClass.fixedDays ? changeDays() : changePrice()"></customInput>
      </div>
      <div class="customToggleContainer">
        <toggle [(ngModel)]="createFunnelBudgetClass.fixedDays" (change)="setReadOnlyInputs()">
        </toggle>
        <span>¿Quieres fijar los días?</span>
      </div>

      <div class="columnDisplay">
        <customInput [options]="createFunnelBudgetClass.daysOptions" [(ngModel)]="createFunnelBudgetClass.days"
          (input)="changeDays()" (change)="checkIfDataIsCompletedHandler()"></customInput>
        <customInput [options]="createFunnelBudgetClass.priceOptions" [(ngModel)]="createFunnelBudgetClass.price"
          (input)="changePrice()" (change)="checkIfDataIsCompletedHandler()">
        </customInput>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createFunnelBudgetClass.isCompleted}"
      (click)="validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!createFunnelBudgetClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createFunnelBudgetClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>