import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iProjectFilter } from '../../Interfaces/Api/Project/iProjectFilter';
import { iFamily } from '../../Interfaces/Api/Family/iFamily';
import { iNewBill } from '../../Interfaces/Api/Bill/iNewBill';

export class CreateBill {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  formatos: Array<string> = ['application/pdf'];
  file: any = null;
  families: Array<iFamily> = [];
  projectActive: boolean = false;
  projectFilter: iProjectFilter = {
    num_devoluciones: 999999,
    num_pagina: 1,
    tag: 'estadisticas',
    potencial: false,
  };
  newBill: iNewBill = iNewBill.createEmptyObject();
  billTypeOptions: iOptionsSelector = {
    items: [
      {
        label: 'Fijo',
        value: 0,
      },
      {
        label: 'Variable',
        value: 1,
      },
    ],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'label',
    bindValue: 'value',
    classList: 'specialFormSelector',
    search: true,
  };
  allocationOfExpenditureOptions: iOptionsSelector = {
    items: [
      {
        label: 'Directo',
        value: true,
      },
      {
        label: 'Indirecto',
        value: false,
      },
    ],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'label',
    bindValue: 'value',
    classList: 'specialFormSelector',
    search: true,
  };
  stateOptions: iOptionsSelector = {
    items: [
      {
        label: 'Pendiente',
        value: 0,
      },
      {
        label: 'Pagado',
        value: 1,
      },
    ],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'label',
    bindValue: 'value',
    classList: 'specialFormSelector',
    search: true,
  };
  taxationsOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'valor',
    classList: 'specialFormSelector',
    search: true,
    label: 'Tipo de fiscalidad',
    required: true,
  };
  familyOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
  projectOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: true,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
  suplierOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: true,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
  quantityOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Cantidad (€)',
    classList: 'specialFormInput',
  };
  dateOption: iOptionsCustomInput = {
    label: 'Fecha',
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    classList: 'specialFormInput',
    required: true,
    date: {
      selectedDate: null,
    },
  };
  billNumberOptions: iOptionsCustomInput = {
    label: 'Nº factura',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  conceptOptions: iOptionsCustomInput = {
    label: 'Concepto',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
}
