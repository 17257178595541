import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iNewBill } from 'src/app/Shared/Interfaces/Api/Bill/iNewBill';
import { iExpenseDetail } from 'src/app/Shared/Interfaces/Api/Expense/iExpenseDetail';
import { iExpenseDetailFilter } from 'src/app/Shared/Interfaces/Api/Expense/iExpenseDetailFilter';
import { iExpenseList } from 'src/app/Shared/Interfaces/Api/Expense/iExpenseList';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExpenseService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getStatistics(
    behaviorSubject: BehaviorSubject<any>,
    filter: iExpenseDetailFilter
  ) {
    this.http
      .get(
        this.urlApi +
          '/api/estadistica/coste/' +
          filter.year +
          '/' +
          filter.month,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStatistics,
            args: [behaviorSubject, filter],
          });
        },
      });
  }

  getAllCost(behaviorSubject: BehaviorSubject<any>, year: number) {
    this.http
      .get(environment.urlApi + '/api/coste/' + year, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iExpenseList.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllCost,
            args: [behaviorSubject, year],
          });
        },
      });
  }
  getAllByMonthAndYear(
    behaviorSubject: BehaviorSubject<any>,
    dataFilter: iExpenseDetailFilter
  ) {
    this.http
      .get(
        environment.urlApi +
          '/api/coste/' +
          dataFilter.year +
          '/' +
          dataFilter.month +
          this.optionsGet(dataFilter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              data: iExpenseDetail.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.costes)
              ),
              total: data.total,
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllByMonthAndYear,
            args: [behaviorSubject, dataFilter],
          });
        },
      });
  }
  getHourIndirectCost(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(environment.urlApi + '/api/indirecto/hora', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getHourIndirectCost,
            args: [behaviorSubject],
          });
        },
      });
  }
  closeMonth(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(
        environment.urlApi + '/api/mes/cerrado/' + data.year + '/' + data.month,
        {},
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'El mes se ha cerrado correctamente',
            data: '',
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.closeMonth,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  openMonth(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .delete(
        environment.urlApi + '/api/mes/cerrado/' + data.year + '/' + data.month,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'El mes se ha reabierto correctamente',
            data: '',
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.openMonth,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  generatePayroll(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(
        environment.urlApi +
          '/api/coste/personal/' +
          data.year +
          '/' +
          data.month,
        {},
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.closeMonth,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  create(behaviorSubject: BehaviorSubject<any>, data: iNewBill) {
    this.http
      .post(
        this.urlApi + '/api/coste/' + data.anyo + '/' + data.mes,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha creado el gasto correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  createBulk(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/api/coste/bulk', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createBulk,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  update(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(this.urlApi + '/api/coste/' + data.id, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha editado el gasto correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(this.urlApi + '/api/coste', this.sendBodyOptions({ id: id }))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
