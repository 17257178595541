import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OverrideTokenManagmentServiceService {
  protected static _refreshTimeTrackerTokenComplete = new Subject<any>();
  protected static _startTimeTrackerTokenRefresh = new Subject<any>();
  protected static _timeTrackertokenLost = new Subject<any>();

  constructor() {}

  /** Acciona el listener de cuando pierdes el token */
  public static timeTrackerTokenLost() {
    localStorage.removeItem('timeTrackerToken');
    this._timeTrackertokenLost.next(true);
  }
  /** Acciona el listener de cuando tienes que refrescar el token */
  public static startTimeTrackerTokenRefresh() {
    this._startTimeTrackerTokenRefresh.next(true);
  }
  /** Acciona el listener de cuando COMPLETA el refrescar token */
  public static completeTimeTrackerRefreshToken() {
    this._refreshTimeTrackerTokenComplete.next(true);
  }

  /**
   * Escuchas el evento para cuando haya que refrescar el token
   * @returns {Subject}
   */
  public static listenerStartTimeTrackerRefreshToken() {
    return this._startTimeTrackerTokenRefresh;
  }
  /**
   * Escucha el eventoo para cuando se COMPLETA el refrescar token
   * @returns {Subject}
   */
  public static listenerTimeTrackerRefreshToken() {
    return this._refreshTimeTrackerTokenComplete;
  }
  /**
   * Escucha el evento para cuando pierde el TOKEN (El cual habrá que navegar a la página de inicio y enviar una notificación)
   * @returns {Subject}
   */
  public static listenerTimeTrackerTokenLost() {
    return this._timeTrackertokenLost;
  }

  /**
   * Te devuelve el tiempo de expiración del token
   * @param {string} token
   * @returns {Ojbect}
   */
  public static parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  /**
   * Te devuelve el token completamente parseado a un objeto manejable
   * @param {string} token
   * @returns {Object}
   */
  public static getParseToken(token: any) {
    var base64Url = token.split('.')[1];
    return JSON.parse(atob(base64Url));
  }
}
