import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import * as XLSX from 'xlsx';
import * as Diacritics from 'diacritics';
import { iNewBill } from 'src/app/Shared/Interfaces/Api/Bill/iNewBill';
import { Project } from 'src/app/Shared/Class/Projects/project';
import { ProjectService } from 'src/app/Services/Api/project.service';
import { filter, takeUntil } from 'rxjs';
import { FamilyService } from 'src/app/Services/Api/family.service';
import { TaxationService } from 'src/app/Services/Api/taxation.service';
import { SupplierService } from 'src/app/Services/Api/supplier.service';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/overrideStaticUtilities.service';
@Component({
  selector: 'app-manage-cost-excel-popup',
  templateUrl: './manage-cost-excel-popup.component.html',
  styleUrls: ['./manage-cost-excel-popup.component.scss'],
})
export class ManageCostExcelPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  formatos: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  projects: Array<any> = [];
  file: any = null;
  costs: Array<any> = [];
  families: Array<any> = [];
  taxations: Array<any> = [];
  suppliers: Array<any> = [];
  indicendes: Array<any> = [];
  validKeys: Array<string> = [
    'numerodefactura',
    'concepto',
    'fecha',
    'cantidad',
    'iva',
    'tipodecoste',
    'imputaciondelcoste',
    'estado',
    'categoria',
    'proveedor(cif)',
    'proyecto(codigo)',
  ];
  constructor(
    public dialogRef: MatDialogRef<ManageCostExcelPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private projectSE: ProjectService,
    private familySE: FamilyService,
    private taxationSE: TaxationService,
    private suplierSE: SupplierService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.getAllFamilies();
    this.getAllProjects();
    this.getAllSupliers();
    this.getAllTaxations();
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  excelDateToJSDateHanlder(serial: number): Date {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate()
    );
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  downloadTemplate() {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'href',
      '/assets/Documents/plantilla-costes.xlsx'
    );
    downloadLink.setAttribute('download', 'plantilla-costes');
    downloadLink.click();
  }
  generateExcelFile(data: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'registro_costes_incidencias.xlsx');
  }
  generateIncidenceArrayObjToDonwload() {
    var newIncidencesArray: Array<any> = [];
    this.indicendes.forEach((incidence) => {
      const newIncidenceObj: any = {
        'Número de factura': incidence.numerodefactura,
        Concepto: incidence.concepto,
        Fecha: incidence.fecha,
        Cantidad: incidence.cantidad,
        IVA: incidence.iva,
        'Tipo de coste': incidence.tipodecoste,
        'Imputación del coste': incidence.imputaciondelcoste,
        Estado: incidence.estado,
        Categoría: incidence.categoria,
        'Proveedor (CIF)': incidence['proveedor(cif)'],
        'Proyecto (Código)': incidence['proyecto(codigo)'],
      };
      newIncidencesArray.push(newIncidenceObj);
    });
    this.generateExcelFile(newIncidencesArray);
  }
  /**
   * GETTERS & SETTERS
   */
  getFile(event: any) {
    let BreakException = {};
    try {
      event.forEach((element: any) => {
        if (!this.formatos.includes(element.file.type)) {
          StaticUtilitiesService.showError('El formato no es válido');
          this.file = null;
          throw BreakException;
        }
        if (element.file.size > 10485760) {
          StaticUtilitiesService.showError('El archivo es demasiado pesado');
          this.file = null;
          throw BreakException;
        }

        this.excelDataTreatmentHandler(element);
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }
  getDataExcel(data: any) {
    this.costs = [];
    var hasError: boolean = false;
    data.forEach((element) => {
      const cost: any = {};
      Object.keys(element).forEach((key) => {
        const normalizedKey = Diacritics.remove(
          key.toLocaleLowerCase()
        ).replace(/\s/g, '');

        if (!this.checkIfKeysAreValid(normalizedKey)) {
          hasError = true;
          return;
        }

        cost[normalizedKey] = element[key];
      });
      if (!hasError) {
        const newCost: any = {
          concepto: cost.concepto,
          cantidad: Number(cost.cantidad),
          tipo: cost.tipodecoste.toLowerCase() == 'fijo' ? 0 : 1,
          directo:
            cost.imputaciondelcoste.toLowerCase() == 'directo' ? true : false,
          iva: null,
          familia: null,
          proyecto: null,
          numeroFactura: cost.numerodefactura ?? '',
          dia: this.excelDateToJSDateHanlder(cost.fecha).getDate(),
          mes: this.excelDateToJSDateHanlder(cost.fecha).getMonth() + 1,
          anyo: this.excelDateToJSDateHanlder(cost.fecha).getFullYear(),
          fecha: new iDate(
            iDate.toPhp(this.excelDateToJSDateHanlder(cost.fecha))
          ).toStringDate('JAP'),
          estado: cost.estado.toLocaleLowerCase() == 'pagado' ? 1 : 0,
          year: this.excelDateToJSDateHanlder(cost.fecha).getFullYear(),
          month: this.excelDateToJSDateHanlder(cost.fecha).getMonth() + 1,
          urlDocument: '',
          proveedor: null,
        };
        this.checkIfTaxationIsValid(cost, newCost);
        this.checkIfProjectIsValid(cost, newCost);
        this.checkIfSupplierIsValid(cost, newCost);
        this.checkIfCategoryIsValid(cost, newCost);

        if (!this.indicendes.includes(cost)) {
          this.costs.push(newCost);
        }
        this.indicendes = [
          ...OverrideStaticUtilitiesService.removeDuplicates(this.indicendes),
        ];
      }
    });
    if (hasError) {
      StaticUtilitiesService.showError(
        'El formato del fichero no es válido.',
        'Algunas de las columnas en el archivo Excel cargado no son válidas.'
      );
      return;
    }
    if (this.indicendes.length > 0) {
      StaticUtilitiesService.showWarning(
        'Algunos de los registros no son válido y no se han cargado.',
        'Se van a descargar los registros no válidos.'
      );
      this.indicendes.forEach((incidence) => {
        incidence.fecha = new iDate(
          iDate.toPhp(this.excelDateToJSDateHanlder(incidence.fecha))
        ).toStringDate('EU');
      });
      this.generateIncidenceArrayObjToDonwload();
    }
    this.closePopup(this.costs);
  }
  /**
   * HANDLERS
   */
  checkIfKeysAreValid(normalizedKey: string) {
    return this.validKeys.includes(normalizedKey);
  }
  excelDataTreatmentHandler(element) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(element.file);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      this.getDataExcel(data);
    };
  }
  checkIfTaxationIsValid(cost, newCost) {
    if (cost.iva) {
      if (this.taxations.some((taxation) => taxation.valor === cost.iva)) {
        newCost.iva = cost.iva;
      } else {
        this.indicendes.push(cost);
      }
    } else {
      this.indicendes.push(cost);
    }
  }
  checkIfProjectIsValid(cost, newCost) {
    if (newCost.directo) {
      if (String(cost['proyecto(codigo)'])) {
        if (
          this.projects.some((project) =>
            project.nombre.includes(String(cost['proyecto(codigo)']))
          )
        ) {
          newCost.proyecto = this.projects.filter((project) =>
            project.nombre.includes(String(cost['proyecto(codigo)']))
          )[0];
        } else {
          this.indicendes.push(cost);
        }
      } else {
        this.indicendes.push(cost);
      }
    }
    if (!newCost.directo) {
      if (String(cost['proyecto(codigo)'])) {
        if (
          this.projects.some((project) =>
            project.nombre.includes(String(cost['proyecto(codigo)']))
          )
        ) {
          newCost.proyecto = this.projects.filter((project) =>
            project.nombre.includes(String(cost['proyecto(codigo)']))
          )[0];
        } else {
          this.indicendes.push(cost);
        }
      }
    }
  }
  checkIfSupplierIsValid(cost, newCost) {
    if (cost['proyecto(codigo)']) {
      if (
        this.suppliers.some((supplier) =>
          supplier.nombre.includes(cost['proveedor(cif)'])
        )
      ) {
        newCost.proveedor = this.suppliers.filter((supplier) =>
          supplier.nombre.includes(cost['proveedor(cif)'])
        )[0];
      } else {
        this.indicendes.push(cost);
      }
    }
  }
  checkIfCategoryIsValid(cost, newCost) {
    if (cost.categoria) {
      if (
        this.families.some((family) => family.nombre.includes(cost.categoria))
      ) {
        newCost.familia = this.families.filter((family) =>
          family.nombre.includes(cost.categoria)
        )[0];
      } else {
        this.indicendes.push(cost);
      }
    } else {
      this.indicendes.push(cost);
    }
  }
  /**
   * API RESPONSES
   */
  successGetAllProjectsResponseHandler(res: iResultHttp) {
    this.projects = res.data;
  }
  successGetAllFamoliesResponseHandler(res: iResultHttp) {
    this.families = res.data;
  }
  successGetAllTaxationResponseHandler(res: iResultHttp) {
    this.taxations = [...res.data];
  }
  successGetSelectorResponseHandler(res: iResultHttp) {
    this.suppliers = [...res.data];
  }
  /**
   * CALLS TO API
   */

  getAllProjects() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.getAllSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllProjectsResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  getAllSupliers() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.suplierSE.getSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetSelectorResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  getAllFamilies() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.familySE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successGetAllFamoliesResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  getAllTaxations() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.taxationSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllTaxationResponseHandler(res),
          error: false,
        });
      });
  }
}
