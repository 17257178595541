import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecoveryService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  sendEmailRecovery(
    behaviorSubject: BehaviorSubject<any>,
    recoveryData: { email: string }
  ) {
    this.http.post(this.urlApi + '/recover/account', recoveryData).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: data.message,
          data: null,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.sendEmailRecovery,
          args: [behaviorSubject],
        });
      },
    });
  }
  recoveryPassword(
    behaviorSubject: BehaviorSubject<any>,
    recoveryPassword: { new_password: string; recover_token: string }
  ) {
    this.http
      .post(this.urlApi + '/change/password', recoveryPassword)
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.recoveryPassword,
            args: [behaviorSubject],
          });
        },
      });
  }
}
