import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import {
  iEmployeeList,
  iEmployeeListFilter,
} from 'src/app/Shared/Interfaces/Api/Employee/iEmployeeList';
import { INewEmployee } from 'src/app/Shared/Interfaces/Api/Employee/iNewEmployee';
import { iPermissionEmployeeList } from 'src/app/Shared/Interfaces/Api/Employee/iPermissionEmployeeList';
import { iPermissionEmployeeListFilter } from 'src/app/Shared/Interfaces/Api/Employee/iPermissionEmployeeListFilter';
import { iSingleEmployee } from 'src/app/Shared/Interfaces/Api/Employee/iSingleEmployee';
import { iUserSelector } from 'src/app/Shared/Interfaces/Api/User/iUserSelector';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getManagerSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/empresa/responsables', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iUserSelector.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message
              ? error.error.message
              : ''
              ? error.error.message
              : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getManagerSelector,
            args: [behaviorSubject],
          });
        },
      });
  }
  getEmployeeRolesStatistics(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/empleados/rango/estadisticas', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message
              ? error.error.message
              : ''
              ? error.error.message
              : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getManagerSelector,
            args: [behaviorSubject],
          });
        },
      });
  }
  getAllForPermissions(
    behaviorSubject: BehaviorSubject<any>,
    filter: iPermissionEmployeeListFilter
  ) {
    this.http
      .get(
        this.urlApi + '/api/lista/rango/empleados' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iPermissionEmployeeList.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message
              ? error.error.message
              : ''
              ? error.error.message
              : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllForPermissions,
            args: [behaviorSubject],
          });
        },
      });
  }
  getAllByCompany(
    behaviorSubject: BehaviorSubject<any>,
    all: boolean = false,
    filter?: any
  ) {
    const url: string = all
      ? '/api/user' +
        (filter ? this.optionsGet(filter) : this.optionsGet({ all }))
      : '/api/user';
    this.http.get(this.urlApi + url, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: {
            total: data.total,
            data: iEmployeeList.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data.data)
            ),
          },
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message
            ? error.error.message
            : ''
            ? error.error.message
            : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.getAllByCompany,
          args: [behaviorSubject],
        });
      },
    });
  }
  getSingle(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http.get(this.urlApi + '/api/user/' + id, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: iSingleEmployee.convertFromObject(
            iStaticUtilities.normalizeNames(data)
          ),
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.getSingle,
          args: [behaviorSubject],
        });
      },
    });
  }
  getStatistics(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/user/estadisticas', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStatistics,
            args: [behaviorSubject],
          });
        },
      });
  }
  remove(behaviorSubject: BehaviorSubject<any>, ids: number | string) {
    let idsObjs = { users_ids: [ids] };
    this.http
      .delete(this.urlApi + '/api/user', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.remove,
            args: [behaviorSubject],
          });
        },
      });
  }
  restorePassword(behaviorSubject: BehaviorSubject<any>, email: string) {
    this.http
      .post(
        this.urlApi + '/api/user/restauracion',
        { email: email },
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.restorePassword,
            args: [behaviorSubject, email],
          });
        },
      });
  }
  edit(behaviorSubject: BehaviorSubject<any>, data) {
    this.http
      .put(this.urlApi + '/api/user/' + data.id, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Empledo editado correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.edit,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/api/user', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Empledo creado correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
}
