<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Crear una nueva asignación de producto</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="customFormContainer">
        <label for="">Productos<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="projectDetailsClass.productOptions" [(ngModel)]="projectDetailsClass.newAssignation.id"
          (change)="projectDetailsClass.newAssignation.cantidad = null;checkIfDataIsCompleted();">
        </selector>
        <customInput [options]="projectDetailsClass.quantityOption"
          [(ngModel)]="projectDetailsClass.newAssignation.cantidad" (change)="checkIfDataIsCompleted()"></customInput>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.openConfirmDisacarActionPopup()">
      <ng-container>
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !projectDetailsClass.isCompleted}" (click)="actionHandler()">
      <ng-container>
        <mat-spinner *ngIf="projectDetailsClass.isLoading"></mat-spinner>
        <mat-icon *ngIf="!projectDetailsClass.isLoading">check</mat-icon>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>