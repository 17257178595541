import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { CreateEditClient } from 'src/app/Shared/Class/Client/create-edit-client';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { ClientService } from 'src/app/Services/Api/client.service';

@Component({
  selector: 'app-create-edit-client-popup',
  templateUrl: './create-edit-client-popup.component.html',
  styleUrls: ['./create-edit-client-popup.component.scss'],
})
export class CreateEditClientPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  createEditClientClass: CreateEditClient = new CreateEditClient();
  constructor(
    public dialogRef: MatDialogRef<CreateEditClientPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private clientSE: ClientService
  ) {}

  ngOnInit(): void {
    this.modalData ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * GETTERS & SETTERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  setData() {
    this.createEditClientClass.createEditClientObj.clientes[0].id =
      this.modalData.id;
    this.createEditClientClass.createEditClientObj.clientes[0].nombre =
      this.modalData.nombre;
    this.createEditClientClass.createEditClientObj.clientes[0].cif =
      this.modalData.cif;
    this.createEditClientClass.createEditClientObj.clientes[0].mail =
      this.modalData.email ?? this.modalData.mail;
    this.createEditClientClass.createEditClientObj.clientes[0].telefono =
      this.modalData.telefono;
    this.createEditClientClass.createEditClientObj.clientes[0].direccion_fiscal =
      this.modalData.direccionFiscal;
  }

  /**
   * HANDLERS
   */

  discardActionHandler() {
    this.modalData ? this.closePopup() : this.forceClosePopup();
  }
  actionHandler() {
    this.modalData ? this.closePopup() : this.validateIfDataIsCorrectHandler();
  }
  validateIfDataIsCorrectHandler() {
    if (this.createEditClientClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  checkIfDataIsCompletedHandler() {
    this.createEditClientClass.isCompleted =
      this.createEditClientClass.createEditClientObj.clientes[0].nombre != '';
  }
  checkIfEmailHasValidFormat() {
    if (
      !StaticUtilitiesService.isValidFormat(
        this.createEditClientClass.createEditClientObj.clientes[0].mail
      )
    ) {
      this.createEditClientClass.isCompleted = false;
      StaticUtilitiesService.showError(
        'Error al validar el campo',
        'El email no tiene un formato válido.'
      );
    }
  }
  successCreateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.createEditClientClass.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorCreateResponseHandler() {
    this.createEditClientClass.isLoading = false;
  }
  successEditResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.createEditClientClass.isLoading = false;
  }
  errorEditResponseHandler() {
    this.createEditClientClass.isLoading = false;
  }
  checkIfAllDataIsCompleted(element: string, value: any) {
    switch (element) {
      case 'nombre':
        if (value) {
          this.updateClientHandler(element, value);
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'El nombre es un campo obligatorio y no puede estar vacío.'
          );
          this.createEditClientClass.createEditClientObj.clientes[0].nombre =
            this.createEditClientClass.backupCreateEditClientObj.clientes[0].nombre;
        }
        break;
    }
  }
  updateClientHandler(element: string, value) {
    if (this.modalData) {
      const obj: any = { id: this.modalData.id };
      obj[element] = value;
      this.edit(obj);
    }
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.clientSE.create(
      behaviorSubject,
      this.createEditClientClass.createEditClientObj
    );
    this.createEditClientClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  edit(obj) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.clientSE.edit(behaviorSubject, obj);
    this.createEditClientClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successEditResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorEditResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
