import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
  sha256,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { DepartmentService } from 'src/app/Services/Api/department.service';
import { EmployeeService } from 'src/app/Services/Api/employee.service';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/overrideStaticUtilities.service';
import { UpdateBridgeHandlerService } from 'src/app/Services/Utils/updateBridgeHandler.service';
import { Employees } from 'src/app/Shared/Class/Employees/employees';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { environment } from 'src/environments/environment';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-quick-edit-employee',
  templateUrl: './quick-edit-employee.component.html',
  styleUrls: ['./quick-edit-employee.component.scss'],
})
export class QuickEditEmployeeComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }
  employeesClass: Employees = new Employees();
  backupData: any = null;
  isLoading: boolean = false;
  formatos: Array<string> = ['image/jpg', 'image/jpeg', 'image/png'];
  file: any = null;
  isYourUser: boolean = false;
  isAdmin: boolean = false;
  update: boolean = true;
  isIntermedite: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<QuickEditEmployeeComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private departmentSE: DepartmentService,
    private employeeSE: EmployeeService,
    private updateDataBridgeSE: UpdateBridgeHandlerService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private popupSE: PopupService
  ) {}

  ngOnInit(): void {
    this.costTypeHandler();
    this.setSelectedDepartments();
    this.getAllDepartmentSelector();
    this.backupData = { ...this.modalData };
    this.checkUserRole();
    this.clickOutClosePopupHandler();
  }
  ngOnDestroy() {
    sessionStorage.clear();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  generateRandonImage(img) {
    img.src = OverrideStaticUtilitiesService.returnRandomPlaceholderImage();
  }
  goToEditEmployee() {
    this.staticUtilitiesSE.goTo(
      '/recursos/empleados/editar-empleado',
      slideNavigationTypes.slideToTop,
      { id: this.modalData.id }
    );
    this.forceClosePopup();
  }
  openDisclaimerActivatePopup(obj) {
    const confirmCloseResponseMessage: iConfirmPopup = {
      buttonConfirmText: 'Sí, confirmar',
      buttonCancelText: 'No, cancelar',
      title: '¿Estas seguro de que quieres activar la licencia?',
      description:
        'Al volver a activar a un empleado, se activará automáticamente una licencia asociada a su cuenta.',
      extraDescription:
        `Si activa la licencia a partir de hoy, en el periodo de facturación actual, abonará ` +
        OverrideStaticUtilitiesService.calculateProportionalPart().toFixed(2) +
        ` €. A partir del siguiente periodo se le cobrará la tarifa regular 3,50 € por licencia.`,
      icon: '/assets/Icons/warning.svg',
    };
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmPopupComponent, confirmCloseResponseMessage);
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) return;
        if (res.activeAction) {
          this.modalData.canAccess = obj.can_access;
          this.editEmployee(obj);
        }
        subject.next('');
      });
  }
  openDisclaimerDesactivatePopup(obj) {
    const confirmCloseResponseMessage: iConfirmPopup = {
      buttonConfirmText: 'Sí, confirmar',
      buttonCancelText: 'No, cancelar',
      title: '¿Estas seguro de que quieres desactivar la licencia?',
      description:
        'Al desactivar la licencia, se impedirá que el empleado acceda a la plataforma, y además, no se generarán cargos correspondientes a dicha licencia.',
      icon: '/assets/Icons/warning.svg',
    };
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmPopupComponent, confirmCloseResponseMessage);
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) return;
        if (res.activeAction) {
          this.modalData.canAccess = obj.can_access;
          this.editEmployee(obj);
        }
        subject.next('');
      });
  }
  /**
   * VALIDATIONS
   */

  checkIfQuickEditDataIsComplete(type: string, data: any) {
    switch (type) {
      case 'horas':
        if (data) {
          this.updateEmployeeHandler('horas', data);
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'Las horas semanales es un campo obligatorio y no puede estar vacío.'
          );
          this.modalData.horas = this.backupData.horas;
        }
        break;
      case 'coste':
        if (data) {
          this.updateEmployeeHandler('coste', data);
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'El coste de €/hora es un campo obligatorio y no puede estar vacío.'
          );
          this.modalData.coste = this.backupData.coste;
        }
        break;
      case 'departamentos':
        if (data.length > 0) {
          this.updateEmployeeHandler('departamentos', data);
        } else {
          StaticUtilitiesService.showError(
            'Error al validar el campo',
            'Los departamentos es un campo obligatorio y no puede estar vacío.'
          );
          this.modalData.coste = this.backupData.coste;
        }
        break;
      case 'roles':
        this.updateEmployeeHandler('roles', [data]);
        setTimeout(() => {
          localStorage.setItem('role', sha256(data)!);
        }, 500);
        break;
    }
  }
  /**
   * GETTERS & SETTERS
   */
  setSelectedDepartments() {
    this.employeesClass.selectedDepartments = this.modalData.departamentos.map(
      (element) => {
        return element.id;
      }
    );
  }
  getFile(event: any) {
    let BreakException = {};
    try {
      event.forEach((element: any) => {
        if (!this.formatos.includes(element.file.type)) {
          StaticUtilitiesService.showError('El formato no es válido');
          this.file = null;
          throw BreakException;
        }
        if (element.file.size > 10485760) {
          StaticUtilitiesService.showError('El archivo es demasiado pesado');
          this.file = null;
          throw BreakException;
        }
        this.setProfileImage(element);
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }
  setProfileImage(element: any) {
    this.modalData.profile = element.base64;
    this.updateEmployeeHandler('profile', element.base64);
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  costTypeHandler() {
    this.modalData.tipoCoste === 'Directo'
      ? (this.employeesClass.costTypeSelected = 0)
      : (this.employeesClass.costTypeSelected = 1);
  }
  successGetAllDepartmentSelectorResponseHandler(res: iResultHttp) {
    this.employeesClass.departmentOptions.items = [...res.data];
  }
  successEditEmployeeResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.updateDataBridgeSE.sendUpdateData();
    this.isLoading = false;
  }
  errorEditEmployeeResponseHandler(res: iResultHttp) {
    this.isLoading = false;
  }
  updateEmployeeHandler(element: string, value: any) {
    const obj: any = { id: this.modalData.id };

    if (element == 'tipo_coste') {
      this.employeesClass.costTypeSelected == 0
        ? (obj[element] = 'Directo')
        : (obj[element] = 'Indirecto');
    } else if (element == 'can_access') {
      this.modalData.canAccess = !value;
      this.update = false;
      setTimeout(() => {
        this.update = true;
      }, 1);
      if (value) {
        obj[element] = value;
        this.openDisclaimerActivatePopup(obj);
      } else {
        obj[element] = value;
        this.openDisclaimerDesactivatePopup(obj);
      }
    } else {
      obj[element] = value;
      this.editEmployee(obj);
    }
  }
  checkUserRole() {
    const userId = Number(localStorage.getItem('idUser'));
    const userRole = String(localStorage.getItem('role'))!;
    this.isYourUser = this.modalData.id === userId;
    this.isAdmin = userRole === sha256('ROLE_ADMIN');
    this.isIntermedite = userRole === sha256('ROLE_INTERMEDIO');
    if (this.isAdmin) {
      this.setRoleOptions([
        { value: 'ROLE_USER', label: 'Básico' },
        { value: 'ROLE_INTERMEDIO', label: 'Gestor' },
        { value: 'ROLE_ADMIN', label: 'Administrador' },
      ]);
    } else if (this.isIntermedite) {
      this.setRoleOptions([
        { value: 'ROLE_USER', label: 'Básico' },
        { value: 'ROLE_INTERMEDIO', label: 'Gestor' },
      ]);
    }
  }

  setRoleOptions(options) {
    this.employeesClass.rolOptions.items = [...options];
  }
  /**
   * CALLS TO API
   */
  getAllDepartmentSelector() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.departmentSE.getAllSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () =>
            this.successGetAllDepartmentSelectorResponseHandler(res),
          error: false,
        });
      });
  }
  editEmployee(data: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.employeeSE.edit(behaviorSubject, data);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successEditEmployeeResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorEditEmployeeResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
}
