<div class="registerReminderGeneralContainer">
  <div class="left">
    <h1>
      Estás <b>a un paso </b>de <br> llevar tu productividad <br><b>más allá.</b>
    </h1>
    <h2>Da de alta tu empresa en fennech</h2>
  </div>
  <div class="right">
    <div class="formContainer">
      <img src="/assets/Images/Logos/logoFennechAnimado.gif" alt="">
      <p class="title">¡Bienvenido a fennech!</p>
      <p>La validación de tu correo electrónico es un paso crucial para garantizar la seguridad de tu cuenta y proteger
        tu información personal. Este proceso nos ayuda a confirmar que la dirección de correo electrónico proporcionada
        es válida y que realmente eres tú quien está intentando acceder a la cuenta.</p>
      <p>Por favor, revisa tu bandeja de entrada y busca un correo electrónico de nuestra parte con el asunto
        "Validación de correo fennech". En el correo encontrarás un enlace que te llevará a completar el proceso de
        validación.
        Una vez hecho esto, podrás acceder a tu cuenta y comenzar a utilizar nuestro servicio de manera completa.
      </p>
      <p>Recuerda que si no encuentras el correo de validación en tu bandeja de entrada, te recomendamos revisar también
        la carpeta de correo no deseado o spam, ya que a veces nuestros mensajes pueden filtrarse allí por error.</p>
      <btn class="action" [ngClass]="{'disabled': !hasEmail}" (click)="resendEmail()">
        <ng-container>
          <span>Reenviar correo electrónico</span>
        </ng-container>
      </btn>
    </div>
  </div>
</div>