import { environment } from 'src/environments/environment';
import { iCompany } from '../Company/iCompany';
import { iDepartmentSelector } from '../Departments/iDepartmentSelector';

export interface iSelfData {
  id: number | null;
  username: string;
  roles: Array<string>;
  nombre: string;
  apellidos: string;
  horas: number | null;
  empresa: iCompany;
  alta: string;
  horasCorto: number | null;
  horasMedio: number | null;
  horasLargo: number | null;
  profile: string | null;
  idClockify: string;
  userClockify: string | null;
  departamentos: Array<iDepartmentSelector>;
  permisos: Array<{
    id: number;
    permiso: { id: number; nombre: string };
    editar: boolean;
  }>;
}

export class iSelfData {
  static convertFromCollection(ob: any): Array<iSelfData> {
    let iSelfDataCollection: Array<iSelfData> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iSelfData {
    let iSelfDataObj = new iSelfData();

    iSelfDataObj.id = ob.id;
    iSelfDataObj.username = ob.username;
    iSelfDataObj.roles = ob.roles;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.apellidos = ob.apellidos;
    iSelfDataObj.horas = ob.horas;
    iSelfDataObj.empresa =
      ob.empresas.length > 0 ? ob.empresas[0] : iCompany.createEmptyObject();
    iSelfDataObj.alta = ob.alta;
    iSelfDataObj.horasCorto = ob.horasCorto;
    iSelfDataObj.horasMedio = ob.horasMedio;
    iSelfDataObj.horasLargo = ob.horasLargo;
    iSelfDataObj.profile = ob.profile;
    iSelfDataObj.idClockify = ob.idClockify;
    iSelfDataObj.userClockify = ob.userClockify;
    iSelfDataObj.permisos = ob.userPermisos;
    iSelfDataObj.departamentos = iDepartmentSelector.convertFromCollection(
      ob.departamentos
    );

    return iSelfDataObj;
  }

  static createEmptyObject(): iSelfData {
    let iSelfDataObj = new iSelfData();
    iSelfDataObj.id = null;
    iSelfDataObj.username = '';
    iSelfDataObj.roles = [];
    iSelfDataObj.nombre = '';
    iSelfDataObj.apellidos = '';
    iSelfDataObj.horas = null;
    iSelfDataObj.empresa = iCompany.createEmptyObject();
    iSelfDataObj.alta = '';
    iSelfDataObj.horasCorto = null;
    iSelfDataObj.horasMedio = null;
    iSelfDataObj.horasLargo = null;
    iSelfDataObj.profile = '';
    iSelfDataObj.idClockify = '';
    iSelfDataObj.userClockify = '';
    iSelfDataObj.departamentos = [];
    iSelfDataObj.permisos = [];
    return iSelfDataObj;
  }
}
