<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Crear nueva columna</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="funnelClass.nameOptions" [(ngModel)]="funnelClass.newColumnObj.nombre"
        (change)="checkIfDataIsCompletedHandler()"></customInput>
      <div class="customToggleContainer">
        <toggle [(ngModel)]="funnelClass.newColumnObj.cierre" (change)="getCierre()"></toggle>
        <span>Marcar columna como <b>'Cierre'</b></span>
      </div>
      <div class="customToggleContainer">
        <toggle [(ngModel)]="funnelClass.newColumnObj.noCierre" (change)="getPerdido()"></toggle>
        <span>Marcar columna como <b>'Perdido'</b></span>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !funnelClass.isCompleted}" (click)="validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!funnelClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="funnelClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>