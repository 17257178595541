<div class="popupGeneralContainer">
  <div class="header">
    <p>Estimación de tiempos: Método PERT - #{{modalData.numero}}</p>
  </div>
  <div class="body">
    <div class="procesoContainer">
      <span>
        <img src="/assets/Icons/QuotationEngine/cantidadProcesosIcon.svg" alt="">
        <ng-container *ngIf="modalData.nombre == null">
          Proceso sin nombre
        </ng-container>
        <ng-container *ngIf="modalData.nombre != null">
          {{modalData.nombre}}
        </ng-container>
      </span>
      <div class="fechas">
        <div class="fecha">
          <label for="">Inicio proceso</label>
          <span>
            <ng-container *ngIf="modalData.inicio == null">
              -
            </ng-container>
            <ng-container *ngIf="modalData.inicio != null">
              {{modalData.inicio | date:'dd/MM/yyyy'}}
            </ng-container>
          </span>
        </div>
        <div class="fecha">
          <label for="">Fin proceso</label>
          <span>
            <ng-container *ngIf="modalData.fin == null">
              -
            </ng-container>
            <ng-container *ngIf="modalData.fin != null">
              {{modalData.fin | date:'dd/MM/yyyy'}}
            </ng-container>
          </span>
        </div>
      </div>
    </div>
    <div class="tiemposContainer">
      <div class="inputContainer">
        <customInput [options]="calculateStimatedTimeClass.optimistTimeOptions"
          [(ngModel)]="calculateStimatedTimeClass.optimist"></customInput>
        <customInput [options]="calculateStimatedTimeClass.realistTimeOptions"
          [(ngModel)]="calculateStimatedTimeClass.realist"></customInput>
        <customInput [options]="calculateStimatedTimeClass.pessimistTimeOptions"
          [(ngModel)]="calculateStimatedTimeClass.pessimist"></customInput>
      </div>
      <btn class="action"
        [class.disabled]="!calculateStimatedTimeClass.optimist || !calculateStimatedTimeClass.realist || !calculateStimatedTimeClass.pessimist"
        (click)="stimateTime()">
        <ng-container>
          <mat-icon class="material-icons-outlined">
            calculate
          </mat-icon>
          <span>Estimar tiempos</span>
        </ng-container>
      </btn>
    </div>
    <div class="tiemposFinales" *ngIf="calculateStimatedTimeClass.moreDataOpened">
      <div class="tiempoEstimado">
        <label for="">
          Tiempo estimado <br>
          (Te)
        </label>
        <span>{{calculateStimatedTimeClass.timeEstimated|number:'0.0-2'}} horas</span>
      </div>
      <div class="desviacion">
        <div class="tipica">
          <label for="">
            Desviación típica
            <br>
            <small>Escoge la desviación mas adecuada para tu proyecto</small>
          </label>
          <div class="slider">
            <label for="">{{calculateStimatedTimeClass.step}}</label>
            <span>{{calculateStimatedTimeClass.deviationAmount|number:'0.0-2'}} horas</span>
            <mat-slider min="-3" max="3" step="0.5" value="0" (valueChange)="getDeviation($event)">
              <input matSliderThumb>
            </mat-slider>
          </div>
        </div>
        <div class="probabilidad">
          <label for="">Probabilidad de exito</label>
          <span>{{calculateStimatedTimeClass.probability}}%</span>
        </div>
      </div>
      <div class="tiempoFinal">
        <label for="">
          Tiempo final <br>
          (Tf)
        </label>
        <span>{{calculateStimatedTimeClass.timeFinal|number:'0.0-2'}} horas</span>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" (click)="send();" [ngClass]="{'disabled': !calculateStimatedTimeClass.moreDataOpened}">
      <ng-container>
        <mat-icon>check</mat-icon>
        <span>Guardar</span>
      </ng-container>

    </btn>
  </div>
</div>