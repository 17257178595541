<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar un departamento</p>
    <p *ngIf="!modalData.edit">Crear un nuevo departamento</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="deparmentClass.nombreOptions" [(ngModel)]="deparmentClass.createEditObj.nombre"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('nombre', deparmentClass.createEditObj.nombre)">
      </customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !deparmentClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!deparmentClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="deparmentClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>