import { iDepartmentSelector } from '../Departments/iDepartmentSelector';

export interface iPermissionEmployeeList {
  id: number | null;
  username: string;
  roles: Array<string>;
  nombre: string;
  apellidos: string;
  departamentos: Array<iDepartmentSelector>;
  alta: string;
  profile: string | null;
  baja: boolean;
  canAccess: boolean;
  permisos: Array<{
    id: number;
    permiso: { id: number; nombre: string };
    editar: boolean;
  }>;
}

export class iPermissionEmployeeList {
  static convertFromCollection(ob: any): Array<iPermissionEmployeeList> {
    let iPermissionEmployeeListCollection: Array<iPermissionEmployeeList> = [];
    ob.forEach((element: any) => {
      iPermissionEmployeeListCollection.push(this.convertFromObject(element));
    });
    return iPermissionEmployeeListCollection;
  }

  static convertFromObject(ob: any): iPermissionEmployeeList {
    let iPermissionEmployeeListObj = new iPermissionEmployeeList();
    iPermissionEmployeeListObj.id = ob.id;
    iPermissionEmployeeListObj.username = ob.username;
    iPermissionEmployeeListObj.nombre = ob.nombre;
    iPermissionEmployeeListObj.apellidos = ob.apellidos;
    iPermissionEmployeeListObj.roles = ob.roles;
    iPermissionEmployeeListObj.departamentos = ob.departamentos ?? [];
    iPermissionEmployeeListObj.alta = ob.alta;
    iPermissionEmployeeListObj.profile = ob.profile;
    iPermissionEmployeeListObj.baja = ob.baja;
    iPermissionEmployeeListObj.canAccess = ob.canAccess;
    iPermissionEmployeeListObj.permisos = ob.userPermisos;
    return iPermissionEmployeeListObj;
  }

  static createEmptyObject(): iPermissionEmployeeList {
    let iPermissionEmployeeListObj = new iPermissionEmployeeList();
    iPermissionEmployeeListObj.id = null;
    iPermissionEmployeeListObj.username = '';
    iPermissionEmployeeListObj.nombre = '';
    iPermissionEmployeeListObj.apellidos = '';
    iPermissionEmployeeListObj.roles = [];
    iPermissionEmployeeListObj.departamentos = [];
    iPermissionEmployeeListObj.profile = '';
    iPermissionEmployeeListObj.alta = '';
    iPermissionEmployeeListObj.baja = false;
    iPermissionEmployeeListObj.canAccess = false;
    iPermissionEmployeeListObj.permisos = [];
    return iPermissionEmployeeListObj;
  }
}
