import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iKnownIp } from '../../Interfaces/Api/IP/iKnownIp';

export class Ip {
  genericFilter: string = '';
  isLoading: boolean = false;
  isCompleted: boolean = false;
  knownIP: Array<iKnownIp> = [];
  backupKnownIP: Array<iKnownIp> = [];
  createEditKnownIP: iKnownIp = {
    nombre: '',
    ip: '',
  };
  backUpCreateEditKnownIP: iKnownIp = {
    nombre: '',
    ip: '',
  };

  /**
   * OPTIONS
   */
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  ipOptions: iOptionsCustomInput = {
    label: 'IP',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
}
