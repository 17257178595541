export interface iHistoricalAdvances {
  fecha: string;
  avance: number | null;
}

export class iHistoricalAdvances {
  static convertFromCollection(ob: any): Array<iHistoricalAdvances> {
    let iSelfDataCollection: Array<iHistoricalAdvances> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iHistoricalAdvances {
    let iSelfDataObj = new iHistoricalAdvances();
    iSelfDataObj.fecha = ob.fecha;
    iSelfDataObj.avance = ob.avance;
    return iSelfDataObj;
  }

  static createEmptyObject(): iHistoricalAdvances {
    let iSelfDataObj = new iHistoricalAdvances();
    iSelfDataObj.fecha = '';
    iSelfDataObj.avance = null;
    return iSelfDataObj;
  }
}
