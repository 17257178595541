<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar un proveedor</p>
    <p *ngIf="!modalData.edit">Crear un proveedor</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="supplierClass.nameOptions" [(ngModel)]="supplierClass.createEditObj.nombre"
          (change)="checkIfDataIsCompletedHandler();updateDataHandler('nombre',supplierClass.createEditObj.nombre )">
        </customInput>
        <customInput [options]="supplierClass.bussinesNameOptions" [(ngModel)]="supplierClass.createEditObj.razonSocial"
          (change)="checkIfDataIsCompletedHandler();updateDataHandler('razonSocial',supplierClass.createEditObj.razonSocial )">
        </customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="supplierClass.cifOptions" [(ngModel)]="supplierClass.createEditObj.cif"
          (change)="checkIfDataIsCompletedHandler();updateDataHandler('cif',supplierClass.createEditObj.cif )">
        </customInput>
        <customInput [options]="supplierClass.serviceOptions" [(ngModel)]="supplierClass.createEditObj.servicio"
          (change)="checkIfDataIsCompletedHandler();updateDataHandler('servicio',supplierClass.createEditObj.servicio )">
        </customInput>
      </div>
      <customInput [options]="supplierClass.contactOptions" [(ngModel)]="supplierClass.createEditObj.contacto"
        (change)="checkIfDataIsCompletedHandler();updateDataHandler('contacto',supplierClass.createEditObj.contacto )">
      </customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !supplierClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!supplierClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="supplierClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>