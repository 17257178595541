import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iGeneralProjectDetail } from '../../Interfaces/Api/Project/iGeneralProjectDetail';
import { iSingleProjectInfo } from '../../Interfaces/Api/Project/iSingleProjectInfo';
import { iProjectStatistic } from '../../Interfaces/Api/Project/iProjectStatistic';
import { iSingleProjectHistoric } from '../../Interfaces/Api/Project/iSingleProjectHistoric';
import { iProjectIncidences } from '../../Interfaces/Api/Incidences/iProjectIncidences';
import { iProjectPhases } from '../../Interfaces/Api/Phases/iProjectPhases';
import { iInvoiceTable } from '../../Interfaces/Api/Bill/iInvoiceTable';
import { iSimpleEmployee } from '../../Interfaces/Api/Employee/iSimpleEmployee';
import { iBonusHours } from '../../Interfaces/Api/BonusHours/iBonusHours';

export class ProjectDetails {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  projectId: number | null = null;
  viewActive: number = 0;

  projectInfo: iSingleProjectInfo = iSingleProjectInfo.createEmptyObject();
  projectInfoBackup: iSingleProjectInfo =
    iSingleProjectInfo.createEmptyObject();
  projectStatistics: iProjectStatistic = iProjectStatistic.createEmptyObject();
  projectHistorics: iSingleProjectHistoric =
    iSingleProjectHistoric.createEmptyObject();
  projectIncidences: Array<iProjectIncidences> = [];
  projectPhases: Array<iProjectPhases> = [];
  projectProducts: Array<any> = [];
  projectBonusHours: Array<iBonusHours> = [];
  projectPaymentMilestones: Array<iInvoiceTable> = [];
  projectEmployees: Array<iSimpleEmployee> = [];
  stateOptions: iOptionsSelector = {
    items: [
      {
        value: 1,
        label: 'Activo',
      },
      {
        value: 0,
        label: 'Pausado',
      },
      {
        value: 2,
        label: 'Finalizado',
      },
      {
        value: -1,
        label: 'Archivado',
      },
      {
        value: 3,
        label: 'Hibernado',
      },
    ],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'label',
    bindValue: 'value',
    classList: 'specialFormSelector',
    search: true,
  };
  costs: any = {
    fijo: [],
    variable: [],
    periodo: [],
  };
  newAssignation: any = {
    id: null,
    cantidad: null,
  };
  avanceGrafica: any = {
    forecastDataPoints: {
      count: 8,
      dashArray: 4,
    },
    annotations: {
      xaxis: [
        {
          x: new Date().getTime(),
          borderColor: '#ccc',
          width: 5,
          strokeDashArray: 0,
        },
      ],
    },
    series: [
      {
        name: 'Objetivo',
        data: [],
      },
      {
        name: 'Real',
        data: [],
      },
    ],
    chart: {
      height: 240,
      toolbar: {
        show: false,
      },
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: ['straight', 'straight'],
    },
    grid: {},
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#192b45',
          fontFamily: 'Satoshi-regular',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 1,
      tickAmount: 5,
      labels: {
        formatter: (value: any) => {
          return Math.round(value * 100) + '%';
        },
        style: {
          colors: '#1f586b',
          fontFamily: 'Satoshi-regular',
        },
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let dia = w.config.series[seriesIndex].data[dataPointIndex][0];
        let arrayBusqeuda = 0;
        if (seriesIndex == 0) {
          arrayBusqeuda = 1;
        }
        let valor = -1;
        w.config.series[arrayBusqeuda].data.forEach((element) => {
          if (element[0] == dia) {
            valor = element[1];
          }
        });
        if (valor == -1) {
          return '';
        }
        let objetivo;
        let actual;
        if (seriesIndex == 0) {
          objetivo = series[0][dataPointIndex];
          actual = valor;
        } else {
          objetivo = valor;
          actual = series[1][dataPointIndex];
        }
        let round = Math.round((actual - objetivo) * 100) / 100;
        return (
          '<div class="arrow_box_margen">' +
          '<span> Diferencia: ' +
          round +
          ' %</span>' +
          '</div>'
        );
      },
    },
  };
  presupuestoGrafica: any = {
    annotations: {
      yaxis: [],
    },
    series: [
      {
        name: 'Real',
        data: [],
      },
      {
        name: 'Presupuestado',
        data: [],
      },
    ],
    chart: {
      height: 240,
      toolbar: {
        show: false,
      },
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: 'straight',
    },
    grid: {},
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#192b45',
          fontFamily: 'Satoshi-regular',
        },
      },
    },
    yaxis: {
      max: 0,
      labels: {
        formatter: function (val: any) {
          let formatter = new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
          });
          return formatter.format(val);
        },
        style: {
          colors: '#1f586b',
          fontFamily: 'Satoshi-regular',
        },
      },
      tickAmount: 5,
    },
    tooltip: {
      style: {
        colors: '#1f586b',
        fontFamily: 'Satoshi-regular',
      },
    },
  };
  margenGrafica: any = {
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: '#00E396',
          strokeDashArray: 0,
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              fontFamily: 'Satoshi-regular',
              background: '#00E396',
            },
            text: 'Objetivo',
          },
        },

        {
          y: 0.3,
          borderColor: '#fd454d',
          strokeDashArray: 0,
          label: {
            borderColor: '#fd454d',
            style: {
              color: '#fff',
              fontFamily: 'Satoshi-regular',
              background: '#fd454d',
            },
            text: 'Suelo',
          },
        },
      ],
    },
    series: [
      {
        name: 'Real',
        data: [],
      },
    ],
    chart: {
      height: 240,
      toolbar: {
        show: false,
      },
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    grid: {},
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: '#192b45',
          fontFamily: 'Satoshi-regular',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return Math.round(value * 100) + '%';
        },
        style: {
          colors: '#1f586b',
          fontFamily: 'Satoshi-regular',
        },
      },
      forceNiceScale: true,
    },
    tooltip: {
      style: {
        colors: '#1f586b',
        fontFamily: 'Satoshi-regular',
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let val = series[seriesIndex][dataPointIndex] * 100;
        let round = Math.round(val * 100) / 100;
        return (
          '<div class="arrow_box_margen">' +
          '<span> Margen - ' +
          round +
          ' %</span>' +
          '</div>'
        );
      },
    },
  };
  fasesGantt: Array<any> = [];
  fases: Array<any> = [];
  filtroFases: Array<any> = [];
  procesos: Array<any> = [];
  anchoDia: number = 20;
  tamanyosMeses: Array<string> = ['3M', '6M', '12M'];
  ancho3Meses: number = 0;
  ancho6Meses: number = 0;
  ancho12Meses: number = 0;

  /**
   * OPTIONS
   */
  quantityOption: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Cantidad (Uds)',
    required: true,
    classList: 'specialFormInput',
  };
  productOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción....',
    clearable: true,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
}
