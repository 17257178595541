<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="!modalData.edit">Crear nuevo producto</p>
    <p *ngIf="modalData.edit">Editar produto</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="inventoryClass.nombreOptions"
          [(ngModel)]="inventoryClass.createEditObj.productos[0].nombre"
          (change)="checkIfDataIsCompleted();updateDataHandler('nombre', inventoryClass.createEditObj.productos[0].nombre)">
        </customInput>
        <customInput [options]="inventoryClass.codigoOptions"
          [(ngModel)]="inventoryClass.createEditObj.productos[0].codigo"
          (change)="checkIfDataIsCompleted();updateDataHandler('codigo', inventoryClass.createEditObj.productos[0].codigo)">
        </customInput>
      </div>
      <div class="columnDisplay">

        <div class="customFormContainer">
          <label for="">Categorías<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [options]="inventoryClass.categoryProductOptions"
            (change)="this.checkIfDataIsCompleted(); updateDataHandler('categorias', inventoryClass.createEditObj.productos[0].categorias)"
            [(ngModel)]="inventoryClass.createEditObj.productos[0].categorias"></selector>
        </div>
        <customInput [options]="inventoryClass.cantidadOptions"
          [(ngModel)]="inventoryClass.createEditObj.productos[0].cantidad"
          (change)="checkIfDataIsCompleted();updateDataHandler('cantidad', inventoryClass.createEditObj.productos[0].cantidad)">
        </customInput>

      </div>
      <div class="columnDisplay">
        <customInput [options]="inventoryClass.precioCompraOptions"
          [(ngModel)]="inventoryClass.createEditObj.productos[0].precioCompra"
          (change)="checkIfDataIsCompleted();updateDataHandler('precioCompra', inventoryClass.createEditObj.productos[0].precioCompra)">
        </customInput>
        <customInput [options]="inventoryClass.precioOptions"
          [(ngModel)]="inventoryClass.createEditObj.productos[0].precio"
          (change)="checkIfDataIsCompleted();updateDataHandler('precio', inventoryClass.createEditObj.productos[0].precio)">
        </customInput>
      </div>
      <selector [options]="inventoryClass.taxationOptions"
        (change)="this.checkIfDataIsCompleted(); updateDataHandler('fiscalidad', inventoryClass.createEditObj.productos[0].fiscalidad)"
        [(ngModel)]="inventoryClass.createEditObj.productos[0].fiscalidad"></selector>

      <customTextArea [options]="inventoryClass.descriptionOptions"
        [(ngModel)]="inventoryClass.createEditObj.productos[0].descripcion"
        (change)="checkIfDataIsCompleted();updateDataHandler('descripcion', inventoryClass.createEditObj.productos[0].descripcion)">
      </customTextArea>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup();">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !inventoryClass.isCompleted }" (click)="actionHubHandler()">
      <ng-container>
        <mat-icon *ngIf="!inventoryClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="inventoryClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>