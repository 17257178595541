import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { BussinessLineService } from 'src/app/Services/Api/bussiness-line.service';
import { DepartmentService } from 'src/app/Services/Api/department.service';
import { ProjectService } from 'src/app/Services/Api/project.service';
import { SprintPeriodService } from 'src/app/Services/Api/sprint-period.service';
import { SprintService } from 'src/app/Services/Api/sprint.service';
import { CreateEditSprint } from 'src/app/Shared/Class/Sprint/create-edit-sprint';

@Component({
  selector: 'app-create-edit-sprint-popup',
  templateUrl: './create-edit-sprint-popup.component.html',
  styleUrls: ['./create-edit-sprint-popup.component.scss'],
})
export class CreateEditSprintPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  createEditSprintClass: CreateEditSprint = new CreateEditSprint();

  constructor(
    public dialogRef: MatDialogRef<CreateEditSprintPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private sprintSE: SprintService,
    private sprintPeriodSE: SprintPeriodService,
    private projectSE: ProjectService,
    private departmentSE: DepartmentService,
    private bussinessLineSE: BussinessLineService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
    this.getAllRanges();
    this.getAllProjects();
    this.getAllDepartments();
    this.getAllBussinesLine();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  calculateDates() {
    const endDate = new Date();
    const range = this.createEditSprintClass.sprintPeriodOptions.items.filter(
      (sprintPeriod) =>
        sprintPeriod.id == this.createEditSprintClass.createEditObj.rango
    )[0].rango;
    endDate.setDate(endDate.getDate() + range * 7);
    const daysInterval: Array<Date> = iDate.getDaysFromInterval(
      new Date(),
      endDate
    );
    this.createEditSprintClass.createEditObj.fechaInicio = daysInterval[0];
    this.createEditSprintClass.createEditObj.fechaFin =
      daysInterval[daysInterval.length - 1];
    if (this.createEditSprintClass.createEditObj.nombre == '') {
      this.createEditSprintClass.createEditObj.nombre = `Sprint semana ${iDate.getWeek(
        this.createEditSprintClass.createEditObj.fechaInicio
      )} - ${iDate.getWeek(this.createEditSprintClass.createEditObj.fechaFin)}`;
    }
  }
  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.createEditSprintClass.createEditObj = this.modalData.sprint;
    this.createEditSprintClass.backupCreateEditObj = JSON.parse(
      JSON.stringify(this.modalData.sprint)
    );
    if (
      (this.createEditSprintClass.createEditObj.activo &&
        this.createEditSprintClass.createEditObj.validado &&
        !this.createEditSprintClass.createEditObj.finalizado) ||
      (!this.createEditSprintClass.createEditObj.activo &&
        this.createEditSprintClass.createEditObj.validado &&
        this.createEditSprintClass.createEditObj.finalizado)
    ) {
      this.createEditSprintClass.isEditingEnabled = false;
    }
    this.createEditSprintClass.createEditObj.proyectos =
      this.createEditSprintClass.createEditObj.proyectos?.map((proyecto) => {
        return proyecto.id;
      });
    this.createEditSprintClass.createEditObj.departamentos =
      this.createEditSprintClass.createEditObj.departamentos?.map(
        (department) => {
          return department.id;
        }
      );
    this.createEditSprintClass.createEditObj.lineasNegocio =
      this.createEditSprintClass.createEditObj.lineasNegocio?.map(
        (bussinesLine) => {
          return bussinesLine.id;
        }
      );
    if (this.createEditSprintClass.createEditObj.rango !== null) {
      this.createEditSprintClass.isCustomRange = false;
      this.createEditSprintClass.createEditObj.rango;
    } else {
      this.createEditSprintClass.isCustomRange = true;
    }
    if (this.createEditSprintClass.createEditObj.departamentos?.length! > 0) {
      this.createEditSprintClass.typeSelected = 'departamentos';
    }
    if (this.createEditSprintClass.createEditObj.proyectos?.length! > 0) {
      this.createEditSprintClass.typeSelected = 'proyectos';
    }
    if (this.createEditSprintClass.createEditObj.lineasNegocio?.length! > 0) {
      this.createEditSprintClass.typeSelected = 'lineasNegocio';
    }
  }
  getSprintDate(fechaInicio: any, fechaFin: any) {
    if (fechaInicio) {
      this.createEditSprintClass.createEditObj.fechaInicio = fechaInicio.value;
    }
    if (fechaFin) {
      this.createEditSprintClass.createEditObj.fechaFin = fechaFin.value;
    }
    if (
      this.createEditSprintClass.createEditObj.fechaFin &&
      this.createEditSprintClass.createEditObj.fechaInicio
    ) {
      if (this.createEditSprintClass.createEditObj.nombre == '') {
        this.createEditSprintClass.createEditObj.nombre = `Sprint semana ${iDate.getWeek(
          this.createEditSprintClass.createEditObj.fechaInicio
        )} - ${iDate.getWeek(
          this.createEditSprintClass.createEditObj.fechaFin
        )}`;
      }
    }
    this.checkIfDataIsCompleted();
    if (
      this.createEditSprintClass.createEditObj.fechaInicio &&
      this.createEditSprintClass.createEditObj.fechaFin
    ) {
      this.updateDataHandler('fechas', true);
    }
  }
  getRangeTypeSelectedValue(event) {
    const normalizedValue = iStaticUtilities.normalizeBoolean(event.value);
    this.createEditSprintClass.isCustomRange = normalizedValue;

    this.createEditSprintClass.createEditObj.rango = null;
    this.createEditSprintClass.createEditObj.fechaInicio = null;
    this.createEditSprintClass.createEditObj.fechaFin = null;
    if (this.createEditSprintClass.createEditObj.nombre == '') {
      this.createEditSprintClass.createEditObj.nombre = '';
    }

    this.checkIfDataIsCompleted();
  }

  getTypeSelectedValue(event) {
    this.createEditSprintClass.typeSelected = event.value;
    delete this.createEditSprintClass.createEditObj.departamentos;
    delete this.createEditSprintClass.createEditObj.proyectos;
    delete this.createEditSprintClass.createEditObj.lineasNegocio;
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    !this.modalData.edit
      ? this.validateIfDataIsCorrectHandler()
      : this.modalData.edit
      ? this.closePopup()
      : this.forceClosePopup();
  }
  validateIfDataIsCorrectHandler() {
    if (this.createEditSprintClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'Los campos son obligatorios y no pueden estar vacíos.'
      );
    }
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = { id: this.createEditSprintClass.createEditObj.id };
      switch (element) {
        case 'nombre':
          if (value) {
            obj[element] = value;
            this.update(obj);
          } else {
            StaticUtilitiesService.showError(
              'Error al validar el campo',
              'El campo nombre es obligatorio y no puede estar vacío.'
            );
            this.createEditSprintClass.createEditObj.nombre =
              this.createEditSprintClass.backupCreateEditObj.nombre;
          }
          break;
        case 'fechas':
          obj['fechaInicio'] = new iDate(
            iDate.toPhp(
              new Date(this.createEditSprintClass.createEditObj.fechaInicio)
            )
          ).toStringDate('JAP');
          obj['fechaFin'] = new iDate(
            iDate.toPhp(
              new Date(this.createEditSprintClass.createEditObj.fechaFin)
            )
          ).toStringDate('JAP');
          obj['nombre'] = this.createEditSprintClass.createEditObj.nombre;
          this.update(obj);
          break;
        default:
          obj[element] = value;
          this.update(obj);
          break;
      }
    }
  }
  checkIfDataIsCompleted() {
    const { fechaInicio, fechaFin, rango } =
      this.createEditSprintClass.createEditObj;
    if (this.createEditSprintClass.isCustomRange) {
      this.createEditSprintClass.isCompleted = !!fechaInicio && !!fechaFin;
    } else {
      this.createEditSprintClass.isCompleted =
        !!fechaInicio && !!fechaFin && !!rango;
    }
  }
  parseDatesHandler() {
    this.createEditSprintClass.createEditObj.fechaInicio = new iDate(
      iDate.toPhp(
        new Date(this.createEditSprintClass.createEditObj.fechaInicio)
      )
    ).toStringDate('JAP');

    this.createEditSprintClass.createEditObj.fechaFin = new iDate(
      iDate.toPhp(new Date(this.createEditSprintClass.createEditObj.fechaFin))
    ).toStringDate('JAP');
  }
  /**
   * API RESPONSES
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.createEditSprintClass.isLoading = false;
    !this.modalData.edit ? this.closePopup() : null;
  }
  errorResponseHandler() {
    this.createEditSprintClass.isLoading = false;
  }
  successGetAllResponseHandler(res: iResultHttp) {
    this.createEditSprintClass.sprintPeriodOptions.items = [...res.data];
  }
  successGetAllProjectResponseHandler(res: iResultHttp) {
    this.createEditSprintClass.projectPeriodOptions.items = [...res.data];
  }
  successGetAllDepartmentsResponseHandler(res: iResultHttp) {
    this.createEditSprintClass.departmentPeriodOptions.items = [...res.data];
  }
  successGetAllBussinesLineResponseHandler(res: iResultHttp) {
    this.createEditSprintClass.bussinesLineOptions.items = [...res.data];
  }
  /**
   * CALLS TO API
   */
  getAllRanges() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sprintPeriodSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllResponseHandler(res),
          error: false,
        });
      });
  }
  getAllProjects() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.getAllSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllProjectResponseHandler(res),
          error: false,
        });
      });
  }
  getAllDepartments() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.departmentSE.getAllSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllDepartmentsResponseHandler(res),
          error: false,
        });
      });
  }
  getAllBussinesLine() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.bussinessLineSE.getAllSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllBussinesLineResponseHandler(res),
          error: false,
        });
      });
  }
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.parseDatesHandler();
    this.sprintSE.create(
      behaviorSubject,
      this.createEditSprintClass.createEditObj
    );
    this.createEditSprintClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.sprintSE.update(behaviorSubject, obj);
    this.createEditSprintClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
