<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>👋🏼&nbsp;&nbsp;¡{{notValidatedDailiesClass.dayTime}}, {{notValidatedDailiesClass.name}}!</p>
    <span>¿Pudiste terminar las tareas de ayer?</span>
  </div>
  <div class="body">
    <ng-container *ngFor="let daily of modalData|slice:notValidatedDailiesClass.start:notValidatedDailiesClass.end">
      <notValidatedDailyCard [notValidatedDailiesClass]="notValidatedDailiesClass" [data]="$any(daily)"
        (update)="removeDailyFromArray($event)"
        (showReasonsEmitter)="notValidatedDailiesClass.showReasons = $event;notValidatedDailiesClass.failedDailyObj.id = $any(daily).id; notValidatedDailiesClass.selectedDaily = $any(daily);">
      </notValidatedDailyCard>
    </ng-container>

    <div class="nextPreviousContainer" [class.disabled]="notValidatedDailiesClass.showReasons">
      <div class="previous" (click)="previousValue()" [class.disabled]="notValidatedDailiesClass.start==0">
        <mat-icon>arrow_back</mat-icon>
        <span>Anterior</span>
      </div>
      <span class="total">{{notValidatedDailiesClass.start + 1}} de {{modalData.length}}</span>
      <div class="next">
        <div class="previous" (click)="nextValue()"
          [class.disabled]="notValidatedDailiesClass.end==this.modalData.length">
          <span>Siguiente</span>
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </div>
    </div>
    <div class="reasons" *ngIf="notValidatedDailiesClass.showReasons">
      <div class="percentaje" [ngClass]="{'noClick': notValidatedDailiesClass.failedDailyObj.completada}">
        <div class="fakeInput">
          <span>{{(notValidatedDailiesClass.failedDailyObj.porcentaje! / 100) | percent}}</span>
          <input *ngIf="!notValidatedDailiesClass.failedDailyObj.completada" required
            [(ngModel)]="notValidatedDailiesClass.failedDailyObj.porcentaje" (input)="checkIfIsValidPercentaje(); "
            type="number" min="0" max="100">
        </div>
        <div class="progress-container" [style]="'--width: '+notValidatedDailiesClass.failedDailyObj.porcentaje+'%'">
          <progress max="100" [value]="notValidatedDailiesClass.failedDailyObj.porcentaje" name="progress"
            ngDefaultControl></progress>
          <mat-slider [value]="notValidatedDailiesClass.failedDailyObj.porcentaje" step="1" [max]="100"
            (input)="notValidatedDailiesClass.failedDailyObj.porcentaje = $event.value; ">
            <input matSliderThumb>
          </mat-slider>
        </div>
      </div>
      <div class="customFormContainer">
        <label for="">Categoría<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="notValidatedDailiesClass.categoryOptions"
          [(ngModel)]="notValidatedDailiesClass.failedDailyObj.categoria">
        </selector>
      </div>
      <customTextArea [options]="notValidatedDailiesClass.reasonOption"
        [(ngModel)]="notValidatedDailiesClass.failedDailyObj.motivo"></customTextArea>

      <div class="postponeContainer">
        <mat-checkbox [(ngModel)]="duplicateAnotherDay">Trabajar en esta tarea otro día</mat-checkbox>
        <ng-container *ngIf="duplicateAnotherDay">
          <customInput [options]="notValidatedDailiesClass.dateOption" (change)="getDate($event)"></customInput>
        </ng-container>
      </div>
      <btn class="action" (click)="updateDaily()"
        [ngClass]="{'disabled': notValidatedDailiesClass.showReasons && !notValidatedDailiesClass.failedDailyObj.categoria}">
        <ng-container>
          <mat-icon *ngIf="!notValidatedDailiesClass.isLoading">check</mat-icon>
          <mat-spinner *ngIf="notValidatedDailiesClass.isLoading"></mat-spinner>
          <span>Envíar</span>
        </ng-container>
      </btn>
    </div>
  </div>

</div>