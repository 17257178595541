<div class="popupGeneralContainer">
  <div class="header">
    <p>Gestión de la tarea</p>
  </div>
  <div class="body">
    <div class="optionContainer">
      <div class="option" (click)="this.closePopup(1)">
        <mat-icon class="material-icons-outlined">
          check
        </mat-icon>
        <p>Validar tarea</p>
      </div>
      <div class="option" (click)="this.closePopup(2)">
        <mat-icon class="material-icons-outlined">
          manage_history
        </mat-icon>
        <p>Gestionar progreso</p>
      </div>
    </div>

    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
  </div>

</div>