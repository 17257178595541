export interface iSimpleProject {
  id: number | null;
  nombre: string;
  abreviatura: string;
  potencial: boolean;
  etiqueta: string;
}

export class iSimpleProject {
  static convertFromCollection(ob: any): Array<iSimpleProject> {
    let iSelfDataCollection: Array<iSimpleProject> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iSimpleProject {
    let iSelfDataObj = new iSimpleProject();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.abreviatura = ob.abreviatura;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.potencial = ob.potencial;
    iSelfDataObj.etiqueta = ob.etiqueta;
    return iSelfDataObj;
  }

  static createEmptyObject(): iSimpleProject {
    let iSelfDataObj = new iSimpleProject();
    iSelfDataObj.id = null;
    iSelfDataObj.abreviatura = '';
    iSelfDataObj.nombre = '';
    iSelfDataObj.potencial = false;
    iSelfDataObj.etiqueta = '';
    return iSelfDataObj;
  }
}
