export interface iProjectCountStatistics {
  proyectosActivos: number;
  proyectosArchivados: number;
  proyectosFinalizado: number;
  proyectosHibernados: number;
  proyectosPausados: number;
  totalProyectos: number;
}

export class iProjectCountStatistics {
  static convertFromCollection(ob: any): Array<iProjectCountStatistics> {
    let iSelfDataCollection: Array<iProjectCountStatistics> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iProjectCountStatistics {
    let iSelfDataObj = new iProjectCountStatistics();
    iSelfDataObj.proyectosActivos = ob.proyectosActivos;
    iSelfDataObj.proyectosArchivados = ob.proyectosArchivados;
    iSelfDataObj.proyectosFinalizado = ob.proyectosFinalizado;
    iSelfDataObj.proyectosHibernados = ob.proyectosHibernados;
    iSelfDataObj.proyectosPausados = ob.proyectosPausados;
    iSelfDataObj.totalProyectos = ob.totalProyectos;
    return iSelfDataObj;
  }

  static createEmptyObject(): iProjectCountStatistics {
    let iSelfDataObj = new iProjectCountStatistics();
    iSelfDataObj.proyectosActivos = 0;
    iSelfDataObj.proyectosArchivados = 0;
    iSelfDataObj.proyectosFinalizado = 0;
    iSelfDataObj.proyectosHibernados = 0;
    iSelfDataObj.proyectosPausados = 0;
    iSelfDataObj.totalProyectos = 0;
    return iSelfDataObj;
  }
}
