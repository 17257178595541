import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { log } from 'console';
import { filter, takeUntil } from 'rxjs';
import { CheckInService } from 'src/app/Services/Api/checkIn.service';
import { UpdateBridgeHandlerService } from 'src/app/Services/Utils/updateBridgeHandler.service';
import { iCheckin } from 'src/app/Shared/Interfaces/Api/Checkin/iCheckin';

@Component({
  selector: 'app-checkin-popup',
  templateUrl: './checkin-popup.component.html',
  styleUrls: ['./checkin-popup.component.scss'],
})
export class CheckinPopupComponent implements OnInit, OnDestroy {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }
  today: Date = new Date();
  interval: any = null;
  seconds: number = 0;
  lastCheckin: iCheckin | null = null;
  isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<CheckinPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private checkInSE: CheckInService,
    private updateDataBridgeSE: UpdateBridgeHandlerService
  ) {}

  ngOnDestroy(): void {
    clearInterval(this.interval);

    if (!this.lastCheckin?.pausado && !this.lastCheckin?.finalizado) {
      localStorage.setItem(
        'seconds',
        String(this.timeToSeconds(this.timeCounter()))
      );
      localStorage.setItem('fechaCierre', iDate.toPhp(new Date()));
    } else {
      localStorage.removeItem('seconds');
      localStorage.removeItem('fechaCierra');
    }
  }

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.counterHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  timeCounter(): string {
    const hours = Math.floor(this.seconds / 3600);
    const minutes = Math.floor((this.seconds % 3600) / 60);
    const remainingSeconds = this.seconds % 60;

    const hoursStr = hours < 10 ? '0' + hours : String(hours);
    const minutesStr = minutes < 10 ? '0' + minutes : String(minutes);
    const secondsStr =
      remainingSeconds < 10 ? '0' + remainingSeconds : String(remainingSeconds);

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }
  private timeToSeconds(timeString: string): number {
    const [hoursStr, minutesStr, secondsStr] = timeString.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const seconds = parseInt(secondsStr, 10);

    return hours * 3600 + minutes * 60 + seconds;
  }
  startCounter() {
    this.interval = setInterval(() => {
      this.seconds++;
      const formattedTime = this.timeCounter();
    }, 1000);
  }
  generateNewCheckinObj(behaviorSubject) {
    let location: { latitud: string; longitud: string } = {
      latitud: '',
      longitud: '',
    };
    navigator.geolocation.getCurrentPosition((position) => {
      location.latitud = String(position.coords.latitude);
      location.longitud = String(position.coords.longitude);
    });
    this.isLoading = true;
    setTimeout(() => {
      const newObj = {
        finalizado: false,
        pausado: false,
        usuario: localStorage.getItem('idUser'),
        ip: '127.0.0.1',
        latitud: location.latitud,
        longitud: location.longitud,
      };
      this.checkInSE.create(behaviorSubject, newObj);
    }, 1000);
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.closePopup();
      }
    });
  }
  counterHandler() {
    if (!this.modalData.newCheckin) {
      this.lastCheckin = this.modalData.lastCheckin;
      if (!this.lastCheckin?.pausado && !this.lastCheckin?.finalizado) {
        if (localStorage.getItem('seconds')) {
          this.seconds = Number(localStorage.getItem('seconds'));
          if (localStorage.getItem('fechaCierre')) {
            const lastDate = new iDate(
              localStorage.getItem('fechaCierre')!
            ).toJavascript();

            const nowDate = new Date();
            const newSeconds =
              (Number(nowDate.getTime()) - Number(lastDate.getTime())) / 1000;
            this.seconds += Number(newSeconds.toFixed(0));
          }
        } else {
          this.seconds = this.lastCheckin?.segundos!;
        }
        this.startCounter();
        localStorage.removeItem('fechaCierre');
      } else {
        this.seconds = this.lastCheckin?.segundos!;
        clearInterval(this.interval);
      }
    }
  }

  /**
   * API RESPONSE
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.getLastCheckin();
    this.isLoading = false;
    setTimeout(() => {
      this.updateDataBridgeSE.sendUpdateData();
    }, 500);
  }
  successGetLastCheckinResponseHandler(res: iResultHttp) {
    if (res.data.length > 0) {
      this.lastCheckin = res.data[0];
      this.seconds = this.lastCheckin?.segundos!;

      if (!this.lastCheckin?.pausado && !this.lastCheckin?.finalizado) {
        this.startCounter();
      }
      if (
        (this.lastCheckin?.pausado && !this.lastCheckin?.finalizado) ||
        (!this.lastCheckin?.pausado && this.lastCheckin?.finalizado)
      ) {
        clearInterval(this.interval);
      }
      this.timeCounter();
    }
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();

    this.generateNewCheckinObj(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  pause() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.checkInSE.pause(behaviorSubject, this.lastCheckin?.id!);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  reanudate() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.checkInSE.reanudate(behaviorSubject, this.lastCheckin?.id!);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  finalice() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.checkInSE.finalice(behaviorSubject, this.lastCheckin?.id!);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
        ]);
      });
  }
  getLastCheckin() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.checkInSE.getLastCheckIn(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetLastCheckinResponseHandler(res),
          error: false,
        });
      });
  }
}
