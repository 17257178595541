import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';
import { iSimpleClient } from '../Client/iSimpleClient';
import { iSimpleProject } from '../Project/iSimpleProject';
import { iInvoiceStatistic } from './iInvoiceStatistic';

export interface iInvoiceTable {
  id: number | null;
  fecha: string;
  fechaEmision: string;
  pagada: boolean;
  url: string | null;
  fechaPago: string;
  cliente: iSimpleClient;
  cantidad: number | null;
  concepto: string;
  potencial: boolean;
  numero: string;
  iva: number | null;
  irpf: number | null;
  proyecto: iSimpleProject;
}

export class iInvoiceTable {
  static convertFromCollection(ob: any): Array<iInvoiceTable> {
    let iSelfDataCollection: Array<iInvoiceTable> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iInvoiceTable {
    let iSelfDataObj = new iInvoiceTable();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.fecha = ob.fecha;
    iSelfDataObj.fechaEmision = ob.fechaEmision;
    iSelfDataObj.fechaPago = ob.fechaPago;
    iSelfDataObj.pagada = ob.pagada;
    iSelfDataObj.url = ob.url;
    iSelfDataObj.cliente = ob.cliente
      ? iSimpleClient.convertFromObject(ob.cliente)
      : iSimpleClient.createEmptyObject();
    iSelfDataObj.cantidad = ob.cantidad;
    iSelfDataObj.concepto = ob.concepto;
    iSelfDataObj.potencial = ob.potencial;
    iSelfDataObj.proyecto = ob.proyecto
      ? iSimpleProject.convertFromObject(ob.proyecto)
      : iSimpleProject.createEmptyObject();
    iSelfDataObj.numero = ob.numero;
    iSelfDataObj.iva = ob.iva;
    iSelfDataObj.irpf = ob.irpf;

    return iSelfDataObj;
  }

  static createEmptyObject(): iInvoiceTable {
    let iSelfDataObj = new iInvoiceTable();
    iSelfDataObj.id = null;
    iSelfDataObj.fecha = '';
    iSelfDataObj.fechaEmision = '';
    iSelfDataObj.fechaPago = '';
    iSelfDataObj.pagada = false;
    iSelfDataObj.url = null;
    iSelfDataObj.cliente = iSimpleClient.createEmptyObject();
    iSelfDataObj.cantidad = null;
    iSelfDataObj.concepto = '';
    iSelfDataObj.potencial = false;
    iSelfDataObj.proyecto = iSimpleProject.createEmptyObject();
    iSelfDataObj.numero = '';
    iSelfDataObj.iva = null;
    iSelfDataObj.irpf = null;
    iSelfDataObj.fechaPago = '';
    return iSelfDataObj;
  }
}
