<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Detalles de la tarea</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <div class="data">
          <label for="">Nombre</label>
          <span>{{modalData.nombre ?? modalData.nombreTarea}}</span>
        </div>
        <div class="data">
          <label for="">Proyecto</label>
          <span>{{modalData.proyecto? modalData.proyecto.nombre : '-'}}</span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Fase</label>
          <span>{{modalData.proceso ? modalData.proceso.fase?.nombre :
            modalData.subProceso ?
            modalData.subProceso.fase.nombre:'-'}}</span>
        </div>
        <div class="data">
          <label for="">Proceso</label>

          <span>{{modalData.proceso ? modalData.proceso?.nombre :
            modalData.subProceso ?
            modalData.subProceso.proceso.nombre:'-'}}</span>
        </div>
      </div>
      <div class="columnDisplay">

        <div class="data">
          <label for="">Tarea</label>
          <span>{{modalData.subProceso && modalData.subProceso?.nombre != '' ?
            modalData.subProceso.nombre : '-'}} </span>
        </div>
        <div class="data">
          <label for="">Empleado</label>
          <span>{{modalData.usuario.name ?? modalData.usuario.nombre}} {{modalData.usuario.surname ??
            modalData.usuario.apellidos}}</span>
        </div>

      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Fecha</label>
          <span>{{modalData.fecha | date:'dd MMMM YYYY'}}</span>
        </div>
        <div class="data">
          <label for="">Horas estimadas</label>
          <span>
            {{modalData.horasEstimadas ? modalData.horasEstimadas.toFixed(2) + ' horas' : '-'}}
          </span>
        </div>

      </div>

      <div class="columnDisplay">
        <div class="data">
          <label for="">Horas reportadas</label>
          <span>
            {{modalData.horas ? modalData.horas.toFixed(2) + ' horas' : '-'}}
          </span>


        </div>
        <div class="data">
          <label for="">Avance estimado</label>
          <span>
            <ng-container *ngIf="modalData.avanceTeorico">
              {{modalData.avanceTeorico | percent: "1.0-2"}}
            </ng-container>
            <ng-container *ngIf="!modalData.avanceTeorico">
              -
            </ng-container>
          </span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Avance real</label>
          <span>
            <ng-container *ngIf="modalData.avanceReal">
              {{modalData.avanceReal | percent: "1.0-2"}}
            </ng-container>
            <ng-container *ngIf="!modalData.avanceReal">
              -
            </ng-container>
          </span>
        </div>
        <div class="data">
          <label for="">Estado</label>
          <ng-container *ngIf="!modalData.enviado && !modalData.categoria">
            <span title="No enviado">
              No enviado
            </span>
          </ng-container>
          <ng-container *ngIf="modalData.enviado && !modalData.validado && modalData.completada">
            <span title="Pendiente revisión">
              Pendiente revisión
            </span>
          </ng-container>
          <ng-container *ngIf="modalData.enviado && modalData.validado && modalData.completada">
            <span title="Validado">
              Validado
            </span>
          </ng-container>
          <ng-container *ngIf="modalData.enviado && modalData.categoria">
            <span title="Fallido">
              Fallido
            </span>
          </ng-container>
        </div>

      </div>

      <div class="columnDisplay">
        <div class="data">
          <label for="">Categoría de fallo</label>
          <span>{{modalData.categoria ? modalData.categoria.nombre : '-'}}</span>
        </div>
        <div class="data">
          <label for="">Porcentaje de progreso</label>
          <span>{{modalData.porcentaje.toFixed(0)}} %</span>
        </div>
      </div>
      <div class="data">
        <label for="">Motivo</label>
        <span>
          <ng-container *ngIf="modalData.motivo != ''">
            {{modalData.motivo}}
          </ng-container>
          <ng-container *ngIf="modalData.motivo == ''">
            -
          </ng-container>
        </span>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup();">
      <ng-container>
        <mat-icon class="material-icons-outlined">
          arrow_back
        </mat-icon>
        <span>Volver</span>
      </ng-container>
    </btn>
  </div>
</div>