export interface iHistoricalBudget {
  fecha: string;
  presupuesto: number | null;
}

export class iHistoricalBudget {
  static convertFromCollection(ob: any): Array<iHistoricalBudget> {
    let iSelfDataCollection: Array<iHistoricalBudget> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iHistoricalBudget {
    let iSelfDataObj = new iHistoricalBudget();
    iSelfDataObj.fecha = ob.fecha;
    iSelfDataObj.presupuesto = ob.presupuesto;
    return iSelfDataObj;
  }

  static createEmptyObject(): iHistoricalBudget {
    let iSelfDataObj = new iHistoricalBudget();
    iSelfDataObj.fecha = '';
    iSelfDataObj.presupuesto = null;
    return iSelfDataObj;
  }
}
