<p class="noData" *ngIf="data.length == 0">No hay costes disponibles</p>
<ng-container *ngIf="data.length > 0">
  <table mat-table [dataSource]="data">
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data">
          <mat-checkbox (change)="addToSelected(element)"></mat-checkbox>
        </div>

      </td>
    </ng-container>
    <ng-container matColumnDef="numero">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer">
          <label>Nº Factura</label>

        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data">
          <span>{{element.numeroFactura ?? '-'}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="concepto">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer">
          <label>Concepto</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data truncate">
          <span>{{element.concepto ?? "-"}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="familia">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer">
          <label>Familia</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data">
          <span>{{element.familia.nombre}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="cantidad">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer center">
          <label>Cantidad</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data center">
          <span>{{element.cantidad | currency:"EUR"}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="iva">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer center">
          <label>IVA</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data center">
          <span *ngIf="element.familia.nombre != 'Nóminas'">{{element.iva}}%</span>
          <span *ngIf="element.familia.nombre == 'Nóminas'">-</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="fecha">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer center">
          <label>Fecha</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data center">
          <span *ngIf="element.fecha">{{element.fecha | date:"dd MMM YYYY"}}</span>
          <span *ngIf="!element.fecha">-</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer center">
          <label>Estado</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data ">
          <span class="special"
            [ngClass]="{'notPaid ':element.estado === 0, 'paid': element.estado === 1  }">{{element.estado === 0 ?
            "Pendiente" : "Pagada"}}</span>
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="proyecto">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer center">
          <label>Proyecto</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data center">

          <span *ngIf="element.proyecto.nombre !== ''">{{element.proyecto.nombre}}</span>
          <span *ngIf="element.proyecto.nombre === ''">-</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="proveedor">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer center">
          <label>Proveedor</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data center">

          <span *ngIf="element.proveedor">{{element.proveedor.nombre}}</span>
          <span *ngIf="!element.proveedor">-</span>
        </div>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns">
    </tr>
  </table>
</ng-container>