import {
  StaticUtilitiesService,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iDaily } from '../../Interfaces/Api/Daily/iDaily';
import { iDailyCategory } from '../../Interfaces/Api/Category/iDailyCategory';

export class ValidateDaily {
  selectedDaily: iDaily = iDaily.createEmptyObject();
  isDataCompleted: boolean = false;
  validateDaily: {
    id: number;
    validacion: boolean;
    horas: number | null;
    avanceReal: number | null;
  } = {
    id: 0,
    validacion: false,
    horas: 0,
    avanceReal: null,
  };
  isDataComplete: boolean = false;

  estimatedHoursOption: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Horas estimadas',
    required: true,
    classList: 'specialFormInput',
    readOnly: true,
  };
  imputeHoursOption: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Horas imputadas',
    required: true,
    classList: 'specialFormInput',
  };
  estimatedProgress: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Avance estimado (%)',
    required: true,
    classList: 'specialFormInput',
    readOnly: true,
  };
  realProgress: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Avance real (%)',
    required: true,
    classList: 'specialFormInput',
  };
  constructor() {}

  /**
   * VALIDATION
   */
  checkIfDataIsCompleted() {
    this.isDataCompleted =
      this.validateDaily.horas != null && this.validateDaily.avanceReal != null;
  }

  /**
   * GETTERS & SETTERS
   */

  generateValidateDailyObject() {
    return {
      id: this.selectedDaily.id,
      validacion: true,
      horas: this.validateDaily.horas,
      avanceReal: this.validateDaily.avanceReal,
    };
  }
}
