import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { SupplierService } from 'src/app/Services/Api/supplier.service';

@Component({
  selector: 'app-add-multiple-suppliers-revision-popup',
  templateUrl: './add-multiple-suppliers-revision-popup.component.html',
  styleUrls: ['./add-multiple-suppliers-revision-popup.component.scss'],
})
export class AddMultipleSuppliersRevisionPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  selectedSuppliers: Array<any> = [];
  isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddMultipleSuppliersRevisionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private supplierSE: SupplierService
  ) {}

  ngOnInit(): void {}
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  removeSelectedSupplier() {
    this.selectedSuppliers.forEach((element) => {
      this.modalData.suppliers.proveedores.splice(
        this.modalData.suppliers.proveedores.indexOf(element),
        1
      );
    });
    StaticUtilitiesService.showFeedback(
      'Se han eliminado los proveedores seleccionados correctamente'
    );
    this.modalData.suppliers.proveedores = [
      ...this.modalData.suppliers.proveedores,
    ];
    if (this.modalData.suppliers.proveedores.length === 0) {
      this.forceClosePopup();
    }
  }

  /**
   * HANDLERS
   */
  getProductsTreatedHandler() {
    return this.modalData.suppliers.proveedores.map((supplier) => {
      return {
        nombre: supplier.nombre,
        razonSocial: supplier['razon social'],
        cif: supplier.cif ?? '',
        contacto: supplier.contacto ?? '',
        servicio: supplier.servicio ?? '',
        empresa: Number(localStorage.getItem('numEmpresa')),
      };
    });
  }
  /**
   * GETTERS && SETTERS
   */
  getSelectedSuppliers(selectedSuppliers: any) {
    this.selectedSuppliers = [...selectedSuppliers];
  }

  /**
   * API RESPONSES
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }

  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.supplierSE.bulkCreate(
      behaviorSubject,
      this.getProductsTreatedHandler()
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => (this.isLoading = false),
            error: true,
          },
        ]);
      });
  }
}
