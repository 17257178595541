import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iNotValidatedDaily } from '../../Interfaces/Api/Daily/iNotValidatedDaily';

export class NotValidatedDailies {
  notValidatedDailies: Array<iNotValidatedDaily> = [];
  name: string = localStorage.getItem('nombre')!;
  dayTime: string = '';
  start: number = 0;
  end: number = 1;
  showReasons: boolean = false;
  isLoading: boolean = false;
  selectedDaily: iNotValidatedDaily = new iNotValidatedDaily();
  failedDailyObj: any = {
    id: null,
    completada: false,
    motivo: '',
    porcentaje: 0,
    categoria: null,
    enviado: true,
  };

  reasonOption: IOptionCustomTextArea = {
    label: 'Comentario',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
    rows: 6,
    cols: 0,
  };
  categoryOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
  dateOption: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    label: 'Fecha',
    classList: 'specialFormInput',
  };
}
