import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';
import { iInvoiceTable } from '../Bill/iInvoiceTable';
import { iSimpleClient } from '../Client/iSimpleClient';
import { iSimpleEmployee } from '../Employee/iSimpleEmployee';
import { iProjectIncidences } from '../Incidences/iProjectIncidences';
import { iProjectPhases } from '../Phases/iProjectPhases';

export interface iSingleProjectDetail {
  id: number | null;
  abreviatura: string;
  cliente: iSimpleClient | null;
  completado: number | null;
  contacto: string;
  costeDia: number | null;
  empleados: Array<iSimpleEmployee>;
  estado: number | null;
  etiqueta: string;
  fases: Array<iProjectPhases>;
  fechaFin: string;
  fechaInicio: string;
  historicoAvances: Array<{ fecha: string; avance: number }> | null;
  historicoMargenes: Array<{ fecha: string; margen: number }> | null;
  horas: number | null;
  incidencias: Array<iProjectIncidences>;
  multiplicador: number | null;
  nombre: string;
  potencial: boolean;
  presupuesto: number | null;
  responsable: iSimpleEmployee | null;
  sector: string;
  facturas: Array<iInvoiceTable>;
}

export class iSingleProjectDetail {
  static convertFromCollection(ob: any): Array<iSingleProjectDetail> {
    let iSelfDataCollection: Array<iSingleProjectDetail> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iSingleProjectDetail {
    let iSelfDataObj = new iSingleProjectDetail();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.abreviatura = ob.abreviatura;
    iSelfDataObj.cliente = ob.cliente;
    iSelfDataObj.completado = ob.completado;
    iSelfDataObj.contacto = ob.contacto;
    iSelfDataObj.costeDia = ob.coste_dia;
    iSelfDataObj.empleados = iSimpleEmployee.convertFromCollection(
      ob.empleados
    );
    iSelfDataObj.estado = ob.estado;
    iSelfDataObj.etiqueta = ob.etiqueta;
    iSelfDataObj.fases = iProjectPhases.convertFromCollection(ob.fases);
    iSelfDataObj.fechaFin = ob.fecha_fin;
    iSelfDataObj.fechaInicio = ob.fecha_ini;
    iSelfDataObj.historicoAvances = ob.historico_avances;
    iSelfDataObj.historicoMargenes = ob.historico_margenes;
    iSelfDataObj.horas = ob.horas;
    iSelfDataObj.incidencias = ob.incidencias;
    iSelfDataObj.multiplicador = ob.multiplicador;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.potencial = ob.potencial;
    iSelfDataObj.presupuesto = ob.presupuesto;
    iSelfDataObj.responsable = ob.responsable;
    iSelfDataObj.sector = ob.sector;
    iSelfDataObj.facturas = iInvoiceTable.convertFromCollection(
      iStaticUtilities.normalizeNamesCollection(ob.facturas) ?? []
    );
    return iSelfDataObj;
  }

  static createEmptyObject(): iSingleProjectDetail {
    let iSelfDataObj = new iSingleProjectDetail();
    iSelfDataObj.id = null;
    iSelfDataObj.abreviatura = '';
    iSelfDataObj.cliente = null;
    iSelfDataObj.completado = null;
    iSelfDataObj.contacto = '';
    iSelfDataObj.costeDia = null;
    iSelfDataObj.empleados = [];
    iSelfDataObj.estado = null;
    iSelfDataObj.etiqueta = '';
    iSelfDataObj.fases = [];
    iSelfDataObj.fechaFin = '';
    iSelfDataObj.fechaInicio = '';
    iSelfDataObj.historicoAvances = [];
    iSelfDataObj.historicoMargenes = [];
    iSelfDataObj.horas = null;
    iSelfDataObj.incidencias = [];
    iSelfDataObj.multiplicador = null;
    iSelfDataObj.nombre = '';
    iSelfDataObj.potencial = false;
    iSelfDataObj.presupuesto = null;
    iSelfDataObj.responsable = null;
    iSelfDataObj.sector = '';
    iSelfDataObj.facturas = [];
    return iSelfDataObj;
  }
}
