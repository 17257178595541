import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { RecoveryService } from 'src/app/Services/Api/recovery.service';
import { Recovery } from 'src/app/Shared/Class/recovery';
import { FennechLoaderComponent } from 'src/app/Shared/Components/fennech-loader/fennech-loader.component';

@Component({
  selector: 'app-send-recovery-password-email',
  templateUrl: './send-recovery-password-email.component.html',
  styleUrls: ['./send-recovery-password-email.component.scss'],
})
export class SendRecoveryPasswordEmailComponent implements OnInit {
  recoveryClass: Recovery = new Recovery();
  constructor(
    private recoverySE: RecoveryService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  ngOnInit(): void {}
  /**
   * VALIDATE DATA
   */

  checkIfCompletedAndValidFormat() {
    this.recoveryClass.isDataCompleted =
      StaticUtilitiesService.isCompleteObject(this.recoveryClass.recoveryData);
  }

  checkIfIsValidEmailFormat() {
    this.recoveryClass.isValidFormat = StaticUtilitiesService.isValidFormat(
      this.recoveryClass.recoveryData.email
    );
    !StaticUtilitiesService.isValidFormat(this.recoveryClass.recoveryData.email)
      ? StaticUtilitiesService.showError(
          'Hay errores en alguno de los campos',
          'El formato del correo electrónico no es válido'
        )
      : null;
  }

  /**
   * API RESPONSES
   */
  successSendMailRecoveryResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.staticUtilitiesSE.closeLoader();
  }
  errorSendMailRecoveryResponseHandler() {
    this.staticUtilitiesSE.closeLoader();
  }
  /**
   * CALLS TO API
   */
  sendMailRecovery() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.staticUtilitiesSE.openLoader(FennechLoaderComponent);
    this.recoverySE.sendEmailRecovery(
      behaviorSubject,
      this.recoveryClass.recoveryData
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.successSendMailRecoveryResponseHandler(res),
          error: false,
        },
        {
          method: () => this.errorSendMailRecoveryResponseHandler(),
          error: true,
        },
      ]);
    });
  }
}
