import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iFunnelBudget } from '../../Interfaces/Api/Funnel/iFunnelBudget';

export class CreateFunnelBudget {
  isLoading: boolean = false;
  isCompleted: boolean = false;
  days: number | null = null;
  price: number | null = null;
  fixedDays: boolean = true;
  newFunnelBudgetOBJ: iFunnelBudget = {
    potencial: true,
    abreviatura: '',
    nombre: '',
    cliente: null,
    etiqueta: '',
    contacto: '',
    sector: '',
    fechaInicio: '',
    fechaFin: '',
    coste_dia: null,
    multiplicador: null,
    fases: [],
    horas: 0,
  };
  /**
   * OPTIONS
   */
  nameOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Nombre',
    required: true,
    classList: 'specialFormInput',
  };
  abreviatureOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Abreviatura',
    required: true,
    classList: 'specialFormInput',
  };
  dayCostOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Coste día',
    required: true,
    classList: 'specialFormInput',
  };
  multiplierOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Multiplicador',
    required: true,
    classList: 'specialFormInput',
  };
  startDateOptions: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Escribe aquí...',
    label: 'Fecha inicio',
    required: true,
    classList: 'specialFormInput',
  };
  endDateOptions: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Escribe aquí...',
    label: 'Fecha fin',
    required: true,
    classList: 'specialFormInput',
  };
  daysOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Días',
    required: true,
    classList: 'specialFormInput',
  };
  priceOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Precio',
    required: true,
    readOnly: true,
    classList: 'specialFormInput',
  };
}
