<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Progreso de la tarea </p>
  </div>
  <div class="body">
    <toggleSubmenu [elements]='completeDailyClass.elements' [(ngModel)]="completeDailyClass.optionSubmenuSelected"
      (change)="completeDailyClass.updateOptionSelected(); completeDailyClass.checkIfIsDataCompleted()">
    </toggleSubmenu>

    <div class="percentaje" [ngClass]="{'noClick': completeDailyClass.backupSelectedDaily.completada}">
      <div class="fakeInput">
        <span>{{(completeDailyClass.backupSelectedDaily.porcentaje! / 100) | percent}}</span>
        <input *ngIf="!completeDailyClass.backupSelectedDaily.completada" required
          [(ngModel)]="completeDailyClass.backupSelectedDaily.porcentaje"
          (input)="completeDailyClass.checkIfIsValidPercentaje(); completeDailyClass.checkIfIsDataCompleted()"
          type="number" min="0" max="100">
      </div>
      <div class="progress-container" [style]="'--width: '+completeDailyClass.backupSelectedDaily.porcentaje+'%'">
        <progress max="100" [value]="completeDailyClass.backupSelectedDaily.porcentaje" name="progress"
          ngDefaultControl></progress>
        <mat-slider [value]="completeDailyClass.backupSelectedDaily.porcentaje" step="1" [max]="100"
          (input)="completeDailyClass.backupSelectedDaily.porcentaje = $event.value; completeDailyClass.backupPercentaje = $event.value!; completeDailyClass.checkIfIsDataCompleted()">
          <input matSliderThumb>
        </mat-slider>
      </div>
    </div>
    <ng-container *ngIf="!completeDailyClass.backupSelectedDaily.completada">
      <div class="customFormContainer fullWidth">
        <label for="">Categoría<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="completeDailyClass.categoriesOption"
          [(ngModel)]="completeDailyClass.backupSelectedDaily.categoria"
          (change)="completeDailyClass.checkIfIsDataCompleted()">
        </selector>
      </div>
      <customTextArea [options]="completeDailyClass.reasonOption"
        [(ngModel)]="completeDailyClass.backupSelectedDaily.motivo"
        (change)="completeDailyClass.checkIfIsDataCompleted()"></customTextArea>
      <div class="postponeContainer">
        <mat-checkbox [(ngModel)]="duplicateAnotherDay">Trabajar en esta tarea otro día</mat-checkbox>
        <ng-container *ngIf="duplicateAnotherDay">
          <customInput [options]="completeDailyClass.dateOption" (change)="getDate($event)"></customInput>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !completeDailyClass.isDataCompleted}" (click)="completeDaily()">
      <ng-container>
        <mat-icon>check</mat-icon>
        <span>Guardar</span>
      </ng-container>

    </btn>
  </div>
</div>