import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iCreateEditProcess } from '../../Interfaces/Api/Process/iCreateEditProcess';

export class CreateEditProcess {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  createEditObj: iCreateEditProcess = iCreateEditProcess.createEmptyObject();
  backupData: iCreateEditProcess = iCreateEditProcess.createEmptyObject();
  nameOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Nombre',
    required: true,
    classList: 'specialFormInput',
  };
  employeeOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    multiple: {
      isMultiple: true,
    },
    classList: 'specialFormSelector',
    search: true,
  };
  projectOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Proyecto',
    required: true,
    classList: 'specialFormInput',
    readOnly: true,
  };
  phaseOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Fase',
    required: true,
    classList: 'specialFormInput',
    readOnly: true,
  };
  hoursOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Horas planificadas',
    required: true,
    classList: 'specialFormInput',
  };
  completedOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    label: 'Completado (%)',
    required: true,
    classList: 'specialFormInput',
  };
  startDateOptions: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    label: 'Fecha de inicio',
    required: true,
    classList: 'specialFormInput',
  };
  endDateOptions: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    label: 'Fecha de fin',
    required: true,
    classList: 'specialFormInput',
  };
}
