<ng-container *ngIf="data.length === 0">
  <p class="noData">No hay proveedores disponibles</p>
</ng-container>
<ng-container *ngIf="data.length > 0">
  <table mat-table [dataSource]="data">
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data">
          <mat-checkbox (change)="addToSelected(element)"></mat-checkbox>
        </div>

      </td>
    </ng-container>
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer">
          <label>Nombre</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data truncate">
          <span class="bold" [title]="element.nombre ?? '-'">{{element.nombre ?? '-'}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="razonSocial">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer">
          <label>Razón social</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data">
          <span>{{element["razon social"]}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="cif">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer center">
          <label>CIF</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data center">
          <span>{{element.cif !== '' ? element.cif : '-'}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="contacto">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer">
          <label>Contacto</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data">
          <span *ngIf="!element.contacto">-</span>
          <span *ngIf="element.contacto">{{element.contacto}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="servicio">
      <th mat-header-cell *matHeaderCellDef>
        <div class="headerContainer">
          <label>Servicio</label>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="data">
          <span *ngIf="!element.servicio">-</span>
          <span *ngIf=" element.servicio">{{element.servicio}}</span>

        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns ">
    </tr>
  </table>
</ng-container>