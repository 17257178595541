import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iCreateSickLeave } from 'src/app/Shared/Interfaces/Api/SickLeaves/iCreateSickLeave';
import { iSickLeaveCategory } from 'src/app/Shared/Interfaces/Api/SickLeaves/iSickLeaveCategory';
import { iSickLeaveFilter } from 'src/app/Shared/Interfaces/Api/SickLeaves/iSickLeaveFilter';
import { iUserSickLeaves } from 'src/app/Shared/Interfaces/Api/SickLeaves/iUserSickLeaves';
import { iUserSelector } from 'src/app/Shared/Interfaces/Api/User/iUserSelector';

@Injectable({
  providedIn: 'root',
})
export class SickLeaveService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getAllByUser(
    behaviorSubject: BehaviorSubject<any>,
    id: number,
    sickLeaveFilter: iSickLeaveFilter
  ) {
    this.http
      .get(
        this.urlApi +
          '/api/registro/baja/empleado/' +
          id +
          this.optionsGet(sickLeaveFilter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iUserSickLeaves.convertFromObject(
              iStaticUtilities.normalizeNames(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllByUser,
            args: [behaviorSubject, id, sickLeaveFilter],
          });
        },
      });
  }
  create(behaviorSubject: BehaviorSubject<any>, data: iCreateSickLeave) {
    this.http
      .post(this.urlApi + '/api/registro/baja', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  update(
    behaviorSubject: BehaviorSubject<any>,
    data: { id: number; fechaFin: string }
  ) {
    this.http
      .put(
        this.urlApi + '/api/registro/alta/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }
}
