<div class="popupGeneralContainer fullSize">
  <div class="header">
    <p>Crear múltiples proveedores</p>
  </div>
  <div class="buttonsContainer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <div class="actions">
      <btn class="action" [ngClass]="{'disabled': selectedSuppliers.length === 0}" (click)="removeSelectedSupplier()">
        <ng-container>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
          <span>Eliminar seleccionados</span>
        </ng-container>
      </btn>
      <btn class="action" (click)="create()">
        <ng-container>
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <img src="/assets/Icons/add.svg" alt="" *ngIf="!isLoading">
          <span>Añadir los proveedores</span>
        </ng-container>
      </btn>
    </div>
  </div>
  <suppliersTableListRevision [data]="modalData.suppliers.proveedores" (update)="getSelectedSuppliers($event)">
  </suppliersTableListRevision>
</div>