<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar una línea de negocio</p>
    <p *ngIf="!modalData.edit">Crear una línea de negocio</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="bussinesLineClass.nombreOptions" [(ngModel)]="bussinesLineClass.createEditObj.nombre"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('nombre', bussinesLineClass.createEditObj.nombre)">
      </customInput>
      <customTextArea [options]="bussinesLineClass.descriptionOptions"
        [(ngModel)]="bussinesLineClass.createEditObj.descripcion"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('descripcion', bussinesLineClass.createEditObj.descripcion)">
      </customTextArea>
      <selector [options]="bussinesLineClass.bussinessLineCategoriesOptions"
        [(ngModel)]="bussinesLineClass.createEditObj.categorias"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('categorias', bussinesLineClass.createEditObj.categorias)">
      </selector>
      <selector [options]="bussinesLineClass.proyectsOptions" [(ngModel)]="bussinesLineClass.createEditObj.proyectos"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('proyectos', bussinesLineClass.createEditObj.proyectos)">
      </selector>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !bussinesLineClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!bussinesLineClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="bussinesLineClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>