import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iCreateEditFunnelBudget } from 'src/app/Shared/Interfaces/Api/Funnel/iCreateEditFunnelBudget';
import { iCreateFunnelColumn } from 'src/app/Shared/Interfaces/Api/Funnel/iCreateFunnelColumn';
import { iFunnelList } from 'src/app/Shared/Interfaces/Api/Funnel/iFunnelList';

@Injectable({
  providedIn: 'root',
})
export class CrmService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getAll(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(
        this.urlApi +
          '/api/estadoscrm' +
          this.optionsGet({ empresa: localStorage.getItem('numEmpresa') }),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iFunnelList.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject],
          });
        },
      });
  }
  getSingleClienteEstadoCRM(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/cliente/estado/crm/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iStaticUtilities.normalizeNames(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSingleClienteEstadoCRM,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  createColumn(
    behaviorSubject: BehaviorSubject<any>,
    data: iCreateFunnelColumn
  ) {
    this.http
      .post(this.urlApi + '/api/estadoscrm', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha creado la columna correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createColumn,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  createCRMBudget(
    behaviorSubject: BehaviorSubject<any>,
    data: iCreateEditFunnelBudget
  ) {
    this.http
      .post(this.urlApi + '/api/cliente/estado/crm', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha creado el prostecto correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createCRMBudget,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  updateCRMBudget(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(
        this.urlApi + '/api/cliente/estado/crm/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha editado el prospecto correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updateCRMBudget,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  update(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(
        this.urlApi + '/api/cliente/estado/crm/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  unlinkProject(behaviorSubject: BehaviorSubject<any>, project: number) {
    this.http
      .put(
        this.urlApi + '/api/cliente/estado/crm/proyecto/' + project,
        {},
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha desvinculado el proyecto correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.unlinkProject,
            args: [behaviorSubject, project],
          });
        },
      });
  }
  updateColumn(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(this.urlApi + '/api/estadoscrm/' + data.id, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Columna editada correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updateColumn,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  deleteColumn(behaviorSubject: BehaviorSubject<any>, ids: number | string) {
    let idsObjs = { ids: [ids] };
    this.http
      .delete(this.urlApi + '/api/estadoscrm', this.sendBodyOptions(idsObjs))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha eliminado la columna correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.message,
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.deleteColumn,
            args: [behaviorSubject, ids],
          });
        },
      });
  }
  deleteOffer(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(
        this.urlApi + '/api/cliente/estado/crm',
        this.sendBodyOptions({ id: id })
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.deleteOffer,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
