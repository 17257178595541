import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { OverrideTokenManagmentServiceService } from './OverrideTokenManagmentService.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OverrideDefaultBaseServiceService {
  private _unsub = new Subject<any>();
  private methodsRefreshToken: Array<{ method: Function; args: Array<any> }> =
    [];
  private alreadyListener = false;
  /**
   * Función que se tiene que llamar cada vez que ejecutas una llamada a la API con el token
   * @returns {Object} Contiene HttpHeaders
   */
  protected overrideGetHeader() {
    if (
      localStorage.getItem('timeTrackerToken') != null ||
      localStorage.getItem('timeTrackerToken') != undefined
    ) {
      return {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + localStorage.getItem('timeTrackerToken'),
        }),
      };
    } else {
      return {
        headers: new HttpHeaders({
          Authorization: 'No Auth',
        }),
      };
    }
  }
  /**
   * Método que controla todas las actualizaciones de todos los datos en los servicios con un Subject personalizado (que no sean los estandar) y sigue
   * el estandar de llamadas a la API junto a checkear el error 401 (Token lost)
   * @param {Subject|BehaviorSubject} observable El subject que se quiere envíar
   * @param {Object} data La información que se quiere enviar
   * @param {boolean} isError Si tiene que checkear si el error es 401
   * @param {Object} methodCall Variable que decide cuando es error 401, volver a refrescar la variable
   */
  protected overrideSendNextObservable(
    observable: Subject<any> | BehaviorSubject<any>,
    data: any,
    isError = false,
    methodCall?: { method: Function; args: Array<any> }
  ) {
    observable.next(data);
    if (isError) {
      this.overrideCheckStatusError(data, methodCall);
    }
    setTimeout(() => {
      observable.next(null);
    }, 150);
  }

  /**
   * Método interno que se encarga de hacer que el token se refresque cuando falla un método
   * @param methodCall El método que ha dado error, puede ser nulo para evitar errores (ya que es opcional que se llave una vez que termine de refrescar el token)
   */
  private overridelistenerRefreshToken(methodCall?: {
    method: Function;
    args: Array<any>;
  }) {
    if (methodCall != null) {
      this.methodsRefreshToken.push(methodCall);
    } else {
      console.error('Exist one method without error methodCall');
    }
    if (!this.alreadyListener) {
      this.alreadyListener = true;
      OverrideTokenManagmentServiceService.listenerTimeTrackerRefreshToken()
        .pipe(takeUntil(this._unsub))
        .subscribe((value) => {
          setTimeout(() => {
            this._unsub.next('');
            this.alreadyListener = false;
            this.overrideReloadMethodRefreshToken();
          }, 100);
        });
      OverrideTokenManagmentServiceService.startTimeTrackerTokenRefresh();
    }
  }

  /**
   * Método para llamar a todos los métodos que han dado error 401
   */
  protected overrideReloadMethodRefreshToken() {
    setTimeout(() => {
      this.methodsRefreshToken.forEach((element: any, i) => {
        this[element.method.name](...element.args);
      });
      this.methodsRefreshToken = [];
    }, 1000);
  }

  /**
   * Método para chequear si es necesario refrescar el token. Si da error 401 refresca el token, si da error 403 por seguridad, se desloguea
   * @param {Object} data El objeto que devuelve la petición HTTP
   * @param methodCall El método donde quieres repetir la llamada que ha dado error (Para que el usuario no se de cuenta del error) y refresque el token en medio
   */
  protected overrideCheckStatusError(
    data: any,
    methodCall?: { method: Function; args: Array<any> }
  ) {
    if (data.status == 401) {
      this.overridelistenerRefreshToken(methodCall);
    }
    if (data.status == 403) {
      OverrideTokenManagmentServiceService.timeTrackerTokenLost();
    }
  }

  constructor() {}
}
