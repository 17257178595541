export interface iTaxation {
  id?: number | null;
  nombre: string;
  valor: number | null;
  articulo: string;
}

export class iTaxation {
  static convertFromCollection(ob: any): Array<iTaxation> {
    let iSelfDataCollection: Array<iTaxation> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iTaxation {
    let iSelfDataObj = new iTaxation();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.valor = ob.valor;
    iSelfDataObj.articulo = ob.articulo;
    return iSelfDataObj;
  }

  static createEmptyObject(): iTaxation {
    let iSelfDataObj = new iTaxation();
    iSelfDataObj.id = null;
    iSelfDataObj.nombre = '';
    iSelfDataObj.valor = null;
    iSelfDataObj.articulo = '';
    return iSelfDataObj;
  }
}
