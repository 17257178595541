import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iCheckin } from 'src/app/Shared/Interfaces/Api/Checkin/iCheckin';
import { iCheckinFilter } from 'src/app/Shared/Interfaces/Api/Checkin/iCheckinFilter';

@Injectable({
  providedIn: 'root',
})
export class CheckInService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getSingle(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/fichaje/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iCheckin.convertFromObject(
              iStaticUtilities.normalizeNames(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  getAll(behaviorSubject: BehaviorSubject<any>, filter: iCheckinFilter) {
    this.http
      .get(
        this.urlApi + '/api/fichaje' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iCheckin.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  getLastCheckIn(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/ultimo/fichaje', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iCheckin.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getLastCheckIn,
            args: [behaviorSubject],
          });
        },
      });
  }

  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/api/fichaje', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  pause(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .put(this.urlApi + '/api/fichaje/pausar/' + id, {}, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.pause,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  finalice(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .put(this.urlApi + '/api/fichaje/finalizar/' + id, {}, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.finalice,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  reanudate(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .put(this.urlApi + '/api/fichaje/reanudar/' + id, {}, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.reanudate,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  edit(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(this.urlApi + '/api/fichaje/' + data.id, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.reanudate,
            args: [behaviorSubject, data],
          });
        },
      });
  }
}
