export interface iHistoricalCost {
  fecha: string;
  coste: number | null;
}

export class iHistoricalCost {
  static convertFromCollection(ob: any): Array<iHistoricalCost> {
    let iSelfDataCollection: Array<iHistoricalCost> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iHistoricalCost {
    let iSelfDataObj = new iHistoricalCost();
    iSelfDataObj.fecha = ob.fecha;
    iSelfDataObj.coste = ob.coste;
    return iSelfDataObj;
  }

  static createEmptyObject(): iHistoricalCost {
    let iSelfDataObj = new iHistoricalCost();
    iSelfDataObj.fecha = '';
    iSelfDataObj.coste = null;
    return iSelfDataObj;
  }
}
