<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Crear hito de cobro</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="customFormContainer">
        <label for="">Fase<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="newPaymentMilestonePaymentClass.phaseOptions"
          [(ngModel)]="newPaymentMilestonePaymentClass.newMilestonesPaymentQuotationEngine.fase"
          (change)="validateIfDataIsCompletedHandler(); getProcessOfPhases()"></selector>
      </div>
      <div class="customFormContainer">
        <label for="">Proceso<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [ngClass]="{'readOnly': !newPaymentMilestonePaymentClass.newMilestonesPaymentQuotationEngine.fase}"
          [options]="newPaymentMilestonePaymentClass.processOptions"
          [(ngModel)]="newPaymentMilestonePaymentClass.newMilestonesPaymentQuotationEngine.proceso"
          (change)="validateIfDataIsCompletedHandler()"></selector>
      </div>
      <customInput [options]="newPaymentMilestonePaymentClass.emisionDateOptions"
        [(ngModel)]="newPaymentMilestonePaymentClass.newMilestonesPaymentQuotationEngine.fecha"
        (change)="getDate($event); validateIfDataIsCompletedHandler()"></customInput>
      <customInput [options]="newPaymentMilestonePaymentClass.conceptOptions"
        [(ngModel)]="newPaymentMilestonePaymentClass.newMilestonesPaymentQuotationEngine.concepto"
        (change)="validateIfDataIsCompletedHandler()"></customInput>
      <customInput [options]="newPaymentMilestonePaymentClass.quantityOptions"
        [(ngModel)]="newPaymentMilestonePaymentClass.newMilestonesPaymentQuotationEngine.importe"
        (change)="validateIfDataIsCompletedHandler()"></customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="   this.forceClosePopup();">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !newPaymentMilestonePaymentClass.isCompleted}"
      (click)="validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!newPaymentMilestonePaymentClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="newPaymentMilestonePaymentClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>