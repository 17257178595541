<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar un próximo paso</p>
    <p *ngIf="!modalData.edit">Crear un próximo paso</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="nameOption" [(ngModel)]="name" (change)="checkIfDataIsCompleted();updateDataHandler()">
      </customInput>
      <customInput [options]="dateOption" [selectedDate]="date"
        (change)="getDate($event);checkIfDataIsCompleted();updateDataHandler()"></customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !isCompleted && !modalData.edit}"
      (click)="modalData.edit ? this.closePopup() : create();">
      <ng-container>
        <mat-icon *ngIf="!isLoading">check</mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>