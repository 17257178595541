import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/user.service';
import { FennechLoaderComponent } from 'src/app/Shared/Components/fennech-loader/fennech-loader.component';

@Component({
  selector: 'app-register-reminder-email-validation',
  templateUrl: './register-reminder-email-validation.component.html',
  styleUrls: ['./register-reminder-email-validation.component.scss'],
})
export class RegisterReminderEmailValidationComponent implements OnInit {
  hasEmail: boolean = false;
  username: string = '';
  constructor(
    private userSE: UserService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  ngOnInit(): void {
    this.checkIfHasEmailHandler();
  }

  /**
   * HANDLERS
   */
  checkIfHasEmailHandler() {
    const auxUsername: string = localStorage.getItem(
      iStaticUtilities.simpleCrypt('email')
    )!;
    if (auxUsername) {
      this.hasEmail = true;
      this.username = iStaticUtilities.simpleDecrypt(auxUsername);
    }
  }
  successResendResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.staticUtilitiesSE.closeLoader();
  }
  errorResendResponseHandler() {
    this.staticUtilitiesSE.closeLoader();
  }
  /**
   * CALLS TO API
   */
  resendEmail() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.staticUtilitiesSE.openLoader(FennechLoaderComponent);
    this.userSE.rensendEmail(behaviorSubject, this.username);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResendResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorResendResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
