import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iResultHttp,
  sha256,
} from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { DailyService } from 'src/app/Services/Api/daily.service';
import { EmployeeService } from 'src/app/Services/Api/employee.service';
import { ProcessService } from 'src/app/Services/Api/process.service';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/overrideStaticUtilities.service';
import { CreateDaily } from 'src/app/Shared/Class/Implications/create-daily';

@Component({
  selector: 'app-create-daily-popup',
  templateUrl: './create-daily-popup.component.html',
  styleUrls: ['./create-daily-popup.component.scss'],
})
export class CreateDailyPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.calculateDayRange();
      this.modalData.edit
        ? this.closePopup({
            rango: this.createDailyClass.newDaily.rango,
            nombre: this.createDailyClass.newDaily.nombreTarea,
            fecha: this.createDailyClass.newDaily.fecha,
            horasEstimadas: this.createDailyClass.newDaily.horasEstimadas,
          })
        : this.forceClosePopup();
    }
  }
  createDailyClass = new CreateDaily();
  selectedDate: any = null;

  constructor(
    public dialogRef: MatDialogRef<CreateDailyPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private processSE: ProcessService,
    private employeeSE: EmployeeService,
    private dailySE: DailyService
  ) {}

  ngOnInit(): void {
    this.createDailyClass.newDaily.fecha = iDate.toPhp(new Date());
    this.checkIfUserIsAdminHandler();
    this.getAllProcessByUser();
    this.clickOutClosePopupHandler();
    this.setDataHandler();
    this.modalData.draft
      ? (this.createDailyClass.newDaily.sprint = this.modalData.sprint)
      : null;
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  clearData() {
    this.createDailyClass.newDaily.proceso = null;
    this.createDailyClass.newDaily.subProceso = null;
  }
  updateName() {
    if (this.createDailyClass.newDaily.nombreTarea == '') {
      this.createDailyClass.newDaily.nombreTarea =
        this.createDailyClass.subprocessOption.items.filter(
          (element) => element.id == this.createDailyClass.newDaily.subProceso
        )[0]?.nombre;
    }
  }
  convertMinutes(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours: hours, minutes: minutes };
  }
  calculateDayRange() {
    if (
      this.createDailyClass.recurrencySelected &&
      this.createDailyClass.recurrencyEndDate
    ) {
      switch (this.createDailyClass.recurrencySelected) {
        case 1:
          this.createDailyClass.newDaily.rango = [
            ...OverrideStaticUtilitiesService.getWorkdays(
              new Date(this.createDailyClass.newDaily.fecha),
              this.createDailyClass.recurrencyEndDate
            ),
          ];
          break;
        case 2:
          this.createDailyClass.newDaily.rango = [
            ...OverrideStaticUtilitiesService.getWeeklyDates(
              new Date(this.createDailyClass.newDaily.fecha),
              this.createDailyClass.recurrencyEndDate
            ),
          ];
          break;
        case 3:
          this.createDailyClass.newDaily.rango = [
            ...OverrideStaticUtilitiesService.getFirstWeekdayOfMonth(
              new Date(this.createDailyClass.newDaily.fecha),
              this.createDailyClass.recurrencyEndDate
            ),
          ];
          break;
        case 4:
          this.createDailyClass.newDaily.rango = [
            ...OverrideStaticUtilitiesService.getLastWeekdayOfMonth(
              new Date(this.createDailyClass.newDaily.fecha),
              this.createDailyClass.recurrencyEndDate
            ),
          ];
          break;
        case 5:
          this.createDailyClass.newDaily.rango = [
            ...OverrideStaticUtilitiesService.getAnnualDates(
              new Date(this.createDailyClass.newDaily.fecha),
              this.createDailyClass.recurrencyEndDate
            ),
          ];
          break;
        default:
          this.createDailyClass.newDaily.rango = [];
          this.createDailyClass.newDaily.rango.push(
            this.createDailyClass.newDaily.fecha
          );
          break;
      }
    } else {
      this.createDailyClass.newDaily.rango = [];
      this.createDailyClass.newDaily.rango.push(
        this.createDailyClass.newDaily.fecha
      );
    }
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.calculateDayRange();
        this.modalData.edit
          ? this.closePopup({
              rango: this.createDailyClass.newDaily.rango,
              nombre: this.createDailyClass.newDaily.nombreTarea,
              fecha: this.createDailyClass.newDaily.fecha,
              horasEstimadas: this.createDailyClass.newDaily.horasEstimadas,
            })
          : this.forceClosePopup();
      }
    });
  }
  checkIfUserIsAdminHandler() {
    this.createDailyClass.isAdmin =
      localStorage.getItem('role') === sha256('ROLE_ADMIN') ||
      localStorage.getItem('role') === sha256('ROLE_INTERMEDIO')
        ? true
        : false;
    if (this.createDailyClass.isAdmin) {
      this.getCompanyEmployees();
    } else {
      this.createDailyClass.employeesOption.items = [
        {
          id: Number(localStorage.getItem('idUser')),
          name:
            localStorage.getItem('nombre') +
            ' ' +
            localStorage.getItem('apellidos'),
        },
      ];
      if (!this.modalData.draft) {
        this.createDailyClass.newDaily.usuario = Number(
          localStorage.getItem('idUser')
        );
      }
    }
  }
  setDataHandler() {
    if (this.modalData.edit) {
      this.createDailyClass.newDaily.usuario = this.modalData.daily.usuario.id;
      this.createDailyClass.newDaily.nombreTarea =
        this.modalData.daily.nombreTarea;
      this.createDailyClass.newDaily.fecha = this.modalData.daily.fecha;
      this.selectedDate = new Date(this.modalData.daily.fecha);
      this.createDailyClass.newDaily.horas = this.convertMinutes(
        this.modalData.daily.horasEstimadas * 60
      ).hours;
      this.createDailyClass.newDaily.minutos = this.convertMinutes(
        this.modalData.daily.horasEstimadas * 60
      ).minutes;
      this.createDailyClass.isCompleteData = true;
    }
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = {
        id: this.modalData.daily.id,
      };
      obj[element] = value;

      this.update(obj);
    }
  }

  /**
   * GETTERS & SETTERS
   */
  getDate(date) {
    this.createDailyClass.newDaily.fecha = new iDate(
      iDate.toPhp(date)
    ).toStringDate('JAP');
    this.createDailyClass.newDaily.rango = [
      ...this.createDailyClass.newDaily.fecha,
    ];
  }
  getEndDate(date) {
    this.createDailyClass.recurrencyEndDate = date;
  }
  /**
   * API RESPONSES
   */
  successGetAllProcessByUserResponseHandler(res: iResultHttp) {
    this.createDailyClass.proyectOption.items = [...res.data.projects];
    this.createDailyClass.procesos = [...res.data.processes];
  }
  successGetCompanyEmployeesResponseHandler(res: iResultHttp) {
    this.createDailyClass.employeesOption.items = [
      ...res.data.data.map((element) => {
        return {
          id: element.id,
          name: element.nombre + ' ' + element.apellidos,
        };
      }),
    ];
    this.createDailyClass.newDaily.usuario =
      this.createDailyClass.employeesOption.items.filter((element) => {
        return element.id === Number(localStorage.getItem('idUser'));
      })[0].id;
  }
  successCreateNewDailyResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  successUpdateDailyResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.createDailyClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  getCompanyEmployees() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.employeeSE.getAllByCompany(behaviorSubject);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successGetCompanyEmployeesResponseHandler(res),
        error: false,
      });
    });
  }
  getAllProcessByUser() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.processSE.getAllProcessByUser(
      behaviorSubject,
      this.createDailyClass.userId
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successGetAllProcessByUserResponseHandler(res),
        error: false,
      });
    });
  }
  createDaily() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.createDailyClass.isLoading = true;

    this.dailySE.create(
      behaviorSubject,
      this.createDailyClass.newDaily,
      this.modalData.draft ? true : false
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successCreateNewDailyResponseHandler(res),
        error: false,
      });
      this.createDailyClass.isLoading = false;
    });
  }

  createDailyRange() {
    this.calculateDayRange();
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.createDailyClass.isLoading = true;

    this.dailySE.createRange(behaviorSubject, this.createDailyClass.newDaily);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successCreateNewDailyResponseHandler(res),
        error: false,
      });
      this.createDailyClass.isLoading = false;
    });
  }

  update(editObj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.createDailyClass.isLoading = true;
    this.dailySE.update(behaviorSubject, editObj);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.successUpdateDailyResponseHandler(res),
          error: false,
        },
        {
          method: () => (this.createDailyClass.isLoading = false),
          error: true,
        },
      ]);
    });
  }
}
