import { iProjectPhasesProcess } from '../Process/iProjectPhasesProcess';

export interface iProjectPhases {
  id: number | null;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  horas: number | null;
  procesos: Array<iProjectPhasesProcess>;
}

export class iProjectPhases {
  static convertFromCollection(ob: any): Array<iProjectPhases> {
    let iSelfDataCollection: Array<iProjectPhases> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iProjectPhases {
    let iSelfDataObj = new iProjectPhases();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.horas = ob.horas;
    iSelfDataObj.fechaInicio = ob.fecha_ini;
    iSelfDataObj.fechaFin = ob.fecha_fin;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.procesos = iProjectPhasesProcess.convertFromCollection(
      ob.procesos
    );
    return iSelfDataObj;
  }

  static createEmptyObject(): iProjectPhases {
    let iSelfDataObj = new iProjectPhases();
    iSelfDataObj.id = null;
    iSelfDataObj.horas = null;
    iSelfDataObj.fechaInicio = '';
    iSelfDataObj.fechaFin = '';
    iSelfDataObj.nombre = '';
    iSelfDataObj.procesos = [];

    return iSelfDataObj;
  }
}
