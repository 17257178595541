import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';

export interface iProyectProcessSelector {
  nombre: string;
  id: number | null;
  proyecto: number | null;
  completado: boolean;
  subProceso: boolean;
}
export class iProyectProcessSelector {
  static convertFromCollection(ob: any): Array<iProyectProcessSelector> {
    let iProyectProcessSelectorCollection: Array<iProyectProcessSelector> = [];
    ob.forEach((element: any) => {
      iProyectProcessSelectorCollection.push(this.convertFromObject(element));
    });
    return iProyectProcessSelectorCollection;
  }

  static convertFromObject(ob: any): iProyectProcessSelector {
    let iProyectProcessSelectorObj = new iProyectProcessSelector();
    iProyectProcessSelectorObj.id = ob.id;
    iProyectProcessSelectorObj.nombre = ob.nombre;
    iProyectProcessSelectorObj.proyecto = ob.proyecto;
    iProyectProcessSelectorObj.completado = iStaticUtilities.normalizeBoolean(
      ob.completado
    );
    iProyectProcessSelectorObj.subProceso = ob.subProceso;
    return iProyectProcessSelectorObj;
  }

  static createEmptyObject(): iProyectProcessSelector {
    let iProyectProcessSelectorObj = new iProyectProcessSelector();
    iProyectProcessSelectorObj.id = null;
    iProyectProcessSelectorObj.nombre = '';
    iProyectProcessSelectorObj.proyecto = null;
    iProyectProcessSelectorObj.completado = false;
    iProyectProcessSelectorObj.subProceso = false;
    return iProyectProcessSelectorObj;
  }
}
