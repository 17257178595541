import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { FuturibleCategoryService } from 'src/app/Services/Api/futurible-category.service';
import { FuturibleCategory } from 'src/app/Shared/Class/Futuribles/futurible-category';
import { iFuturibleCategory } from 'src/app/Shared/Interfaces/Api/Futurible/iFuturibleCategory';

@Component({
  selector: 'app-create-edit-futurible-category',
  templateUrl: './create-edit-futurible-category.component.html',
  styleUrls: ['./create-edit-futurible-category.component.scss'],
})
export class CreateEditFuturibleCategoryComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  futuribleCategoryClass: FuturibleCategory = new FuturibleCategory();
  constructor(
    public dialogRef: MatDialogRef<CreateEditFuturibleCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private futuribleCategorySE: FuturibleCategoryService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * GETTERS & SETTERS
   */
  setData() {
    this.futuribleCategoryClass.createEditObj = this.modalData.category;
    this.futuribleCategoryClass.isCompleted = true;
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    !this.modalData.edit
      ? this.validateIfDataIsCorrectHandler()
      : this.forceClosePopup();
  }
  validateIfDataIsCorrectHandler() {
    if (this.futuribleCategoryClass.isCompleted) {
      this.create();
    } else {
      StaticUtilitiesService.showError(
        'Error al validar los campos',
        'El campo nombre es obligatorio y no pueden estar vacíos.'
      );
    }
  }
  checkIfDataIsCompleted() {
    this.futuribleCategoryClass.isCompleted =
      StaticUtilitiesService.isCompleteObject(
        this.futuribleCategoryClass.createEditObj
      );
  }
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.futuribleCategoryClass.isLoading = false;
    if (!this.modalData.edit) {
      setTimeout(() => {
        this.closePopup();
      }, 500);
    }
  }
  errorResponseHandler() {
    this.futuribleCategoryClass.isLoading = false;
  }
  updateDataHandler(element: string, value: any) {
    if (this.modalData.edit) {
      const obj: any = { id: this.futuribleCategoryClass.createEditObj.id };
      obj[element] = value;
      this.update(obj);
    }
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.futuribleCategorySE.create(
      behaviorSubject,
      this.futuribleCategoryClass.createEditObj
    );
    this.futuribleCategoryClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successResponseHandler(res),
            error: false,
          },
          {
            method: this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
  update(obj: iFuturibleCategory) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.futuribleCategorySE.update(behaviorSubject, obj);
    this.futuribleCategoryClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successResponseHandler(res),
            error: false,
          },
          {
            method: this.errorResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
