import { iDate } from '@quasar-dynamics/basic-designsystem';

export interface iCreateDaily {
  nombreTarea: '';
  horasEstimadas: number | null;
  usuario: number | null;
  fecha: string;
  proyecto: number | null;
  proceso: number | null;
  subProceso: number | null;
  horas?: number | null;
  minutos?: number | null;
  sprint?: number | null;
  rango?: Array<string>;
}

export class iCreateDaily {
  static createEmptyObject(): iCreateDaily {
    let iCreateDailyObj = new iCreateDaily();
    iCreateDailyObj.usuario = null;
    iCreateDailyObj.fecha = new iDate(iDate.toPhp(new Date())).toStringDate(
      'JAP'
    );
    iCreateDailyObj.nombreTarea = '';
    iCreateDailyObj.proyecto = null;
    iCreateDailyObj.proceso = null;
    iCreateDailyObj.subProceso = null;
    iCreateDailyObj.horasEstimadas = null;
    iCreateDailyObj.horas = null;
    iCreateDailyObj.rango = [];
    return iCreateDailyObj;
  }
}
