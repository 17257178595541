import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';
import { iSimpleEmployee } from '../Employee/iSimpleEmployee';
import { iHoliday } from './iHoliday';

export interface iHolidayList {
  diasRestantes: number;
  solicitudes: Array<iHoliday>;
}

export class iHolidayList {
  static convertFromCollection(ob: any): Array<iHolidayList> {
    let iSelfDataCollection: Array<iHolidayList> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iHolidayList {
    let iSelfDataObj = new iHolidayList();
    iSelfDataObj.diasRestantes = ob.diasRestantes;
    iSelfDataObj.solicitudes = iHoliday.convertFromCollection(
      iStaticUtilities.normalizeNamesCollection(ob.solicitudes)
    );

    return iSelfDataObj;
  }
}
