import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iOptionsSelector,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { iProjectPhasesProcess } from 'src/app/Shared/Interfaces/Api/Process/iProjectPhasesProcess';
import { OverrideStaticUtilitiesService } from 'src/app/Services/Utils/overrideStaticUtilities.service';
import { ProcessService } from 'src/app/Services/Api/process.service';
import { EmployeeService } from 'src/app/Services/Api/employee.service';
import { iSimpleEmployee } from 'src/app/Shared/Interfaces/Api/Employee/iSimpleEmployee';

@Component({
  selector: 'app-assign-process-employee-popup',
  templateUrl: './assign-process-employee-popup.component.html',
  styleUrls: ['./assign-process-employee-popup.component.scss'],
})
export class AssignProcessEmployeePopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  isCompleted: boolean = false;
  isLoading: boolean = false;
  selectedProcess: Array<iProjectPhasesProcess> = [];
  selectedEmployee: iSimpleEmployee | null = null;
  employeeOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'name',
    classList: 'specialFormSelector',
    search: true,
  };
  processOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    multiple: {
      isMultiple: true,
      multipleLimitReplacer: 2,
    },
    bindLabel: 'nombre',
    classList: 'specialFormSelector',
    search: true,
  };
  constructor(
    public dialogRef: MatDialogRef<AssignProcessEmployeePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private processSE: ProcessService,
    private employeeSE: EmployeeService
  ) {}

  ngOnInit(): void {
    this.getProcess();
    this.getCompanyEmployees();
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  generateRandonImage(img) {
    img.src = OverrideStaticUtilitiesService.returnRandomPlaceholderImage();
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  removeEmployeeHandler(
    employeeId: number,
    processId: number,
    assignationId: number
  ) {
    if (assignationId === null) {
      var auxAsignaciones = this.selectedProcess.filter(
        (process) => process.id == processId
      )[0].asignaciones;
      var auxAsignacion = auxAsignaciones.filter(
        (a) => a.id === null && a.empleado?.id === employeeId
      )[0];
      auxAsignaciones.splice(auxAsignaciones.indexOf(auxAsignacion), 1);
      this.selectedProcess = [...this.selectedProcess];
    } else {
      const auxEmployee: Array<number> = [];

      this.selectedProcess
        .filter((process) =>
          process.asignaciones.some((a) => a.id === assignationId)
        )
        .forEach((process) => {
          process.asignaciones.forEach((assignment) => {
            if (assignment.empleado?.id !== employeeId) {
              auxEmployee.push(assignment.empleado?.id!);
            }
          });
        });

      const obj = {
        empleados: auxEmployee,
        id: processId,
        assignation: assignationId,
      };
      this.removeEmployeeProcess(obj);
    }
  }
  employeeDataTreatmentHandler(data: any) {
    data.forEach((element) => {
      const newEmployeeTreated: iSimpleEmployee = {
        id: element.id,
        name: element.nombre,
        profile: element.profile,
        surname: element.apellidos,
        departamentos: element.departamentos,
      };
      this.employeeOptions.items.push(newEmployeeTreated);
    });
    this.employeeOptions.items = [...this.employeeOptions.items];
  }
  successUpdateProcessHandler(res: iResultHttp, obj: any) {
    this.isLoading = false;
    if (this.isCompleted === false) {
      StaticUtilitiesService.showFeedback(String(res.message));
    }
    this.isCompleted = true;

    this.selectedProcess.forEach((proceso) => {
      proceso.asignaciones = proceso.asignaciones.filter(
        (asignacion) => asignacion.id !== obj.assignation
      );
    });

    this.selectedProcess = [...this.selectedProcess];

    setTimeout(() => {
      this.closePopup(this.selectedProcess);
    }, 500);
  }

  errorUpdateProcessHandler() {
    this.isLoading = false;
  }
  successRemoveProcessHandler(res: iResultHttp, obj: any) {
    this.isLoading = false;
    StaticUtilitiesService.showFeedback(String(res.message));

    this.selectedProcess.forEach((proceso) => {
      proceso.asignaciones = proceso.asignaciones.filter(
        (asignacion) => asignacion.id !== obj.assignation
      );
    });

    this.selectedProcess = [...this.selectedProcess];
  }

  errorRemoveProcessHandler() {
    this.isLoading = false;
  }
  successGetCompanyEmployeesResponseHandler(res: iResultHttp) {
    this.employeeDataTreatmentHandler(res.data.data);
  }
  sendNewEmployeesHandler() {
    const auxUpdates: Array<any> = [];

    this.selectedProcess.forEach((proceso) => {
      proceso.asignaciones.forEach((asignacion) => {
        const empleadoId = asignacion.empleado?.id;
        if (empleadoId !== undefined) {
          const obj = {
            empleados: [empleadoId],
            id: proceso.id,
          };
          auxUpdates.push(obj);
        }
      });
    });

    if (auxUpdates.length === 0) {
      this.forceClosePopup();
    } else {
      auxUpdates.forEach((element) => this.updateProcess(element));
    }
  }
  /**
   * GETTERS & SETTERS
   */
  getProcess() {
    this.modalData.forEach((fases) => {
      fases.procesos.forEach((proceso) => {
        this.processOptions.items.push(proceso);
      });
    });
  }
  setEmployees(employee: iSimpleEmployee) {
    this.selectedEmployee = employee;
    this.selectedProcess.forEach((element) => {
      const newAssignment: any = {
        id: null,
        horas: 0,
        empleado: this.selectedEmployee,
      };
      if (
        element.asignaciones.filter(
          (a) => a.empleado?.id === newAssignment.empleado.id
        ).length === 0
      ) {
        element.asignaciones.push(newAssignment);
      }
    });
    this.selectedProcess = [...this.selectedProcess];
  }
  setEmptyEmployee() {
    if (this.selectedProcess.length === 0) {
      this.selectedEmployee = null;
    }
  }
  removeEmployeeFromProcess(event?) {
    if (event) {
      event.value.asignaciones.forEach((element) => {
        if (element.id === null) {
          event.value.asignaciones.splice(
            event.value.asignaciones.indexOf(element),
            1
          );
        }
      });
    }
  }
  /**
   * CALL TO API
   */
  updateProcess(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.processSE.update(behaviorSubject, obj);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateProcessHandler(res, obj),
            error: false,
          },
          {
            method: () => this.errorUpdateProcessHandler(),
            error: true,
          },
        ]);
      });
  }
  removeEmployeeProcess(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.processSE.update(behaviorSubject, obj);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successRemoveProcessHandler(res, obj),
            error: false,
          },
          {
            method: () => this.errorRemoveProcessHandler(),
            error: true,
          },
        ]);
      });
  }
  getCompanyEmployees() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.employeeSE.getAllByCompany(behaviorSubject);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successGetCompanyEmployeesResponseHandler(res),
        error: false,
      });
    });
  }
}
