export interface iProjectSelector {
  id: number | null;
  nombre: string;
}

export class iProjectSelector {
  static convertFromCollection(ob: any): Array<iProjectSelector> {
    let iSelfDataCollection: Array<iProjectSelector> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iProjectSelector {
    let iSelfDataObj = new iProjectSelector();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.nombre = ob.nombre;
    return iSelfDataObj;
  }

  static createEmptyObject(): iProjectSelector {
    let iSelfDataObj = new iProjectSelector();
    iSelfDataObj.id = null;
    iSelfDataObj.nombre = '';
    return iSelfDataObj;
  }
}
