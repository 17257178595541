export interface iProjectDaily {
  id: number | null;
  categoria: string;
  color: string;
  cant: number | null;
}

export class iProjectDaily {
  static convertFromCollection(ob: any): Array<iProjectDaily> {
    let iSelfDataCollection: Array<iProjectDaily> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iProjectDaily {
    let iSelfDataObj = new iProjectDaily();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.categoria = ob.nombre;
    iSelfDataObj.color = ob.apellidos;
    iSelfDataObj.cant = ob.cant;
    return iSelfDataObj;
  }

  static createEmptyObject(): iProjectDaily {
    let iSelfDataObj = new iProjectDaily();
    iSelfDataObj.id = null;
    iSelfDataObj.categoria = '';
    iSelfDataObj.color = '';
    iSelfDataObj.cant = null;
    return iSelfDataObj;
  }
}
