export interface iNotValidatedDaily {
  id: number | null;
  taskName: string;
  process: string;
  project: { id: number | null; abbreviation: string; name: string };
  date: string;
}

export class iNotValidatedDaily {
  static convertFromCollection(ob: any): Array<iNotValidatedDaily> {
    let iNotValidatedDailyCollection: Array<iNotValidatedDaily> = [];
    ob.forEach((element: any) => {
      iNotValidatedDailyCollection.push(this.convertFromObject(element));
    });
    return iNotValidatedDailyCollection;
  }

  static convertFromObject(ob: any): iNotValidatedDaily {
    let iNotValidatedDailyObj = new iNotValidatedDaily();
    iNotValidatedDailyObj.id = ob.id;
    iNotValidatedDailyObj.taskName = ob.nombre_tarea;
    iNotValidatedDailyObj.process = ob.proceso?.nombre;
    iNotValidatedDailyObj.project = {
      id: ob.proyecto?.id,
      abbreviation: ob.proyecto?.abreviatura,
      name: ob.proyecto?.nombre,
    };
    iNotValidatedDailyObj.date = ob.fecha;
    return iNotValidatedDailyObj;
  }
  static createEmptyObject(): iNotValidatedDaily {
    let iNotValidatedDailyObj = new iNotValidatedDaily();
    iNotValidatedDailyObj.id = null;
    iNotValidatedDailyObj.taskName = '';
    iNotValidatedDailyObj.process = '';
    iNotValidatedDailyObj.project = { id: null, abbreviation: '', name: '' };
    iNotValidatedDailyObj.date = '';
    return iNotValidatedDailyObj;
  }
}
