import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iHolidayFilter } from 'src/app/Shared/Interfaces/Api/Holidays/iHolidayFilter';
import { iHolidayList } from 'src/app/Shared/Interfaces/Api/Holidays/iHolidayList';
import { iRequestedHolidays } from 'src/app/Shared/Interfaces/Api/Holidays/iRequestedHolidays';

@Injectable({
  providedIn: 'root',
})
export class HolidayService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getAllRequest(behaviorSubject: BehaviorSubject<any>) {
    this.http.get(this.urlApi + '/api/vacaciones', this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: iRequestedHolidays.convertFromCollection(data),
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.getAllRequest,
          args: [behaviorSubject],
        });
      },
    });
  }
  getNextHolidays(behaviorSubject: BehaviorSubject<any>) {
    const year: number = new Date().getFullYear();
    const url: string = 'https://date.nager.at/api/v3/NextPublicHolidays/ES';
    this.http.get(url, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: data,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.getNextHolidays,
          args: [behaviorSubject],
        });
      },
    });
  }
  getAllMyHolidays(
    behaviorSubject: BehaviorSubject<any>,
    filter: iHolidayFilter
  ) {
    this.http
      .get(
        this.urlApi + '/api/vacaciones/all/user' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iHolidayList.convertFromObject(
              iStaticUtilities.normalizeNames(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllMyHolidays,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  getAllHolidaysByUser(
    behaviorSubject: BehaviorSubject<any>,
    filter: iHolidayFilter,
    id: number
  ) {
    this.http
      .get(
        this.urlApi +
          '/api/vacaciones/all/user/' +
          id +
          this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iHolidayList.convertFromObject(
              iStaticUtilities.normalizeNames(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllHolidaysByUser,
            args: [behaviorSubject, filter, id],
          });
        },
      });
  }
  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/api/vacaciones', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  acceptRequest(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .put(this.urlApi + '/api/vacaciones/aprobar/' + id, {}, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha aceptado la solicitud correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.acceptRequest,
            args: [behaviorSubject],
          });
        },
      });
  }
  rejectRequest(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .put(this.urlApi + '/api/vacaciones/rechazar/' + id, {}, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha denegado la solicitud correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.rejectRequest,
            args: [behaviorSubject],
          });
        },
      });
  }
}
