<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar un contacto</p>
    <p *ngIf="!modalData.edit">Crear un nuevo contacto</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="contactClass.nameOptions" [(ngModel)]="contactClass.createEditObj.nombre"
          (change)="checkIfDataIsCompleted();updateDataHandler('nombre', contactClass.createEditObj.nombre)">
        </customInput>
        <customInput [options]="contactClass.surnameOptions" [(ngModel)]="contactClass.createEditObj.apellidos"
          (change)="checkIfDataIsCompleted();updateDataHandler('apellidos', contactClass.createEditObj.apellidos)">
        </customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="contactClass.emailOptions" [(ngModel)]="contactClass.createEditObj.email"
          (change)="checkIfDataIsCompleted();updateDataHandler('email', contactClass.createEditObj.email)">
        </customInput>
        <customInput [options]="contactClass.phoneOptions" [(ngModel)]="contactClass.createEditObj.telefono"
          (change)="checkIfDataIsCompleted();updateDataHandler('telefono', contactClass.createEditObj.telefono)">
        </customInput>
      </div>
      <customInput [options]="contactClass.positionOptions" [(ngModel)]="contactClass.createEditObj.puesto"
        (change)="checkIfDataIsCompleted();updateDataHandler('puesto', contactClass.createEditObj.puesto)">
      </customInput>
      <customTextArea [options]="contactClass.observationsOptions"
        [(ngModel)]="contactClass.createEditObj.observaciones"
        (change)="checkIfDataIsCompleted();updateDataHandler('observaciones', contactClass.createEditObj.observaciones)">
      </customTextArea>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !contactClass.isCompleted && !modalData.edit}"
      (click)="modalData.edit ? this.closePopup() : create();">
      <ng-container>
        <mat-icon *ngIf="!contactClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="contactClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>