import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iAccessRegisterFilter } from 'src/app/Shared/Interfaces/Api/IP/iAccessRegisterFilter';
import { iAccessRegisterList } from 'src/app/Shared/Interfaces/Api/IP/iAccessRegisterList';

@Injectable({
  providedIn: 'root',
})
export class AccessRegisterService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }

  getAll(behaviorSubject: BehaviorSubject<any>, filter: iAccessRegisterFilter) {
    this.http
      .get(
        this.urlApi + '/api/registro/acceso' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iAccessRegisterList.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  create(
    behaviorSubject: BehaviorSubject<any>,
    data: { latitud: string; longitud: string }
  ) {
    this.http
      .post(this.urlApi + '/api/registro/acceso', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  update(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .put(this.urlApi + '/api/registro/acceso', {}, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject],
          });
        },
      });
  }
}
