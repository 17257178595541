import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AnimationControllerService,
  DefaultBaseService,
  StaticUtilitiesService,
  TokenManagmentService,
  iResultHttp,
  iStaticUtilities,
  sha256,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iLogin } from 'src/app/Shared/Interfaces/Api/iLogin';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  private roles: Array<any> = [
    sha256('ROLE_USER'),
    sha256('ROLE_GESTOR'),
    sha256('ROLE_INTERMEDIO'),
    sha256('ROLE_ADMIN'),
  ];
  constructor(
    private http: HttpClient,
    private router: Router,
    private animationSE: AnimationControllerService
  ) {
    super();
  }

  /*
   * FUNCTIONALITIES
   */

  logOut() {
    localStorage.clear();
    sessionStorage.clear();
  }
  isLogged() {
    if (this.getToken() && this.getRefreshToken()) {
      return true;
    }
    return false;
  }
  getUserTypeToken() {
    let roles: Array<any> = TokenManagmentService.getParseToken(
      localStorage.getItem('token')
    ).roles;
    roles = roles.map((role: any) => {
      {
        return sha256(role);
      }
    });
    return roles;
  }
  redirectToRol() {
    const userTypeToken = this.getUserTypeToken();
    setTimeout(() => {
      if (userTypeToken.includes(sha256('ROLE_ADMIN'))) {
        localStorage.setItem('role', sha256('ROLE_ADMIN')!);
        StaticUtilitiesService.showFeedback(
          'Se ha iniciado sesión correctamente'
        );
        this.router.navigate(['/home']);
        this.animationSE.setCurrentAnimation(slideNavigationTypes.slideToTop);
        return;
      }
      if (userTypeToken.includes(sha256('ROLE_INTERMEDIO'))) {
        localStorage.setItem('role', sha256('ROLE_INTERMEDIO')!);
        StaticUtilitiesService.showFeedback(
          'Se ha iniciado sesión correctamente'
        );
        this.router.navigate(['/home']);
        this.animationSE.setCurrentAnimation(slideNavigationTypes.slideToTop);
        return;
      }
      if (userTypeToken.includes(sha256('ROLE_GESTOR'))) {
        localStorage.setItem('role', sha256('ROLE_GESTOR')!);
        StaticUtilitiesService.showFeedback(
          'Se ha iniciado sesión correctamente'
        );
        this.router.navigate(['/home']);
        this.animationSE.setCurrentAnimation(slideNavigationTypes.slideToTop);
        return;
      }
      if (userTypeToken.includes(sha256('ROLE_USER'))) {
        localStorage.setItem('role', sha256('ROLE_USER')!);
        StaticUtilitiesService.showFeedback(
          'Se ha iniciado sesión correctamente'
        );
        this.router.navigate(['/home']);
        this.animationSE.setCurrentAnimation(slideNavigationTypes.slideToTop);
        return;
      }
    }, 500);
  }
  /**
   * CALLS TO API
   */

  login(behaviorSubject: BehaviorSubject<any>, loginData: iLogin) {
    this.http.post(this.urlApi + '/api/login_check', loginData).subscribe({
      next: (data: any) => {
        const normalizedTokens = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: normalizedTokens,
        });
        this.setToken(normalizedTokens['token']);
        this.setRefreshToken(normalizedTokens['refreshToken']);
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.login,
          args: [behaviorSubject],
        });
      },
    });
  }

  refreshToken() {
    this.http
      .post(environment.urlApi + '/api/refreshToken', {
        refresh_token: localStorage.getItem('refreshToken'),
      })
      .subscribe({
        next: (data) => {
          let normalizedTokens = iStaticUtilities.normalizeNames(data);
          this.setToken(normalizedTokens['token']);
          this.setRefreshToken(normalizedTokens['refreshToken']);
          TokenManagmentService.completeRefreshToken();
        },
        error: (error) => {
          TokenManagmentService.tokenLost();
        },
      });
  }
}
