<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Asignar empleado a un proceso</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="customFormContainer">
        <label for="">Procesos</label>
        <selector [options]="processOptions" [(ngModel)]="selectedProcess" (change)="setEmptyEmployee()"
          (remove)="removeEmployeeFromProcess($event)"
          (add)="selectedEmployee != null ? setEmployees( selectedEmployee) : null">
        </selector>
      </div>
      <div class="customFormContainer">
        <label for="">Empleados</label>
        <selector [ngClass]="{'readOnly': selectedProcess.length == 0}" [options]="employeeOptions"
          [(ngModel)]="selectedEmployee" (change)="setEmployees($event);">
        </selector>
      </div>
      <div class="processesSelected" *ngIf="selectedProcess">
        <div class="process" *ngFor="let process of selectedProcess">
          <label for="">{{process.nombre}}</label>
          <p *ngIf="process.asignaciones.length == 0">No hay asignaciones disponibles</p>
          <div class="employees" *ngFor="let assignation of process.asignaciones">
            <div class="name">
              <img [src]="assignation.empleado?.profile" alt="" (error)="generateRandonImage($event.target)">
              <span>
                {{assignation.empleado?.name}} {{assignation.empleado?.surname}}
              </span>
            </div>
            <mat-icon
              (click)="removeEmployeeHandler(assignation.empleado?.id!, process?.id!, assignation.id!)">close</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" (click)="sendNewEmployeesHandler()">
      <ng-container>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <mat-icon *ngIf="!isLoading">check</mat-icon>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>