import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iCreateEditDepartment } from '../../Interfaces/Api/Departments/iCreateEditDepartment';
import { iDepartmentSelector } from '../../Interfaces/Api/Departments/iDepartmentSelector';

export class Department {
  isCompleted: boolean = false;

  isLoading: boolean = false;
  departments: Array<iDepartmentSelector> = [];
  backUpDepartments: Array<iDepartmentSelector> = [];
  genericFilter: string = '';
  createEditObj: iCreateEditDepartment = {
    nombre: '',
  };
  nombreOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
}
