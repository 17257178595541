import { iSimpleClient } from '../Client/iSimpleClient';
import { iFunnelProject } from './iFunnelProject';

export interface iFunnelData {
  id: number | null;
  temperatura: number | null;
  fechaCreacion: string;
  cliente: iSimpleClient | null;
  proyecto: iFunnelProject | null;
  presupuesto: number | null;
}

export class iFunnelData {
  static convertFromCollection(ob: any): Array<iFunnelData> {
    let iSelfDataCollection: Array<iFunnelData> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iFunnelData {
    let iSelfDataObj = new iFunnelData();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.temperatura = ob.temperatura;
    iSelfDataObj.fechaCreacion = ob.fechaCreacion;
    iSelfDataObj.cliente = iSimpleClient.convertFromObject(ob.cliente);
    iSelfDataObj.presupuesto = ob.presupuesto;
    iSelfDataObj.proyecto = ob.proyecto
      ? iFunnelProject.convertFromObject(ob.proyecto)
      : null;
    return iSelfDataObj;
  }

  static createEmptyObject(): iFunnelData {
    let iSelfDataObj = new iFunnelData();
    iSelfDataObj.id = null;
    iSelfDataObj.temperatura = null;
    iSelfDataObj.fechaCreacion = '';
    iSelfDataObj.cliente = null;
    iSelfDataObj.proyecto = null;
    iSelfDataObj.presupuesto = null;
    return iSelfDataObj;
  }
}
