import { iSimpleClient } from '../Client/iSimpleClient';

export interface iContact {
  id?: number | null;
  nombre: string;
  apellidos?: string;
  email?: string;
  telefono?: string;
  observaciones?: string;
  puesto: string;
  cliente: iSimpleClient | null;
}

export class iContact {
  static convertFromCollection(ob: any): Array<iContact> {
    let iContactCollection: Array<iContact> = [];
    ob.forEach((element: any) => {
      iContactCollection.push(this.convertFromObject(element));
    });
    return iContactCollection;
  }

  static convertFromObject(ob: any): iContact {
    let iContactObj = new iContact();
    iContactObj.id = ob.id;
    iContactObj.nombre = ob.nombre;
    iContactObj.apellidos = ob.apellidos;
    iContactObj.email = ob.email;
    iContactObj.observaciones = ob.observaciones;
    iContactObj.telefono = ob.telefono;
    iContactObj.puesto = ob.puesto;
    iContactObj.cliente = ob.cliente;
    return iContactObj;
  }

  static createEmptyObject(): iContact {
    let iContactObj = new iContact();
    iContactObj.id = null;
    iContactObj.nombre = '';
    iContactObj.apellidos = '';
    iContactObj.email = '';
    iContactObj.observaciones = '';
    iContactObj.telefono = '';
    iContactObj.puesto = '';
    iContactObj.cliente = null;
    return iContactObj;
  }
}
