import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { UserService } from 'src/app/Services/Api/user.service';
import { FennechLoaderComponent } from 'src/app/Shared/Components/fennech-loader/fennech-loader.component';

@Component({
  selector: 'app-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss'],
})
export class EmailValidationComponent implements OnInit {
  constructor(
    private userSE: UserService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  ngOnInit(): void {
    this.emailValidations();
  }

  /**
   * HANDLERS
   */
  generateObjHandler() {
    const urlParams = new URLSearchParams(window.location.search);
    const otp = urlParams.get('otp')!;
    const type = urlParams.get('type')!;
    return {
      otp: otp,
      type: type,
    };
  }
  successEmailValidationResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.staticUtilitiesSE.closeLoader();
    setTimeout(() => {
      this.staticUtilitiesSE.goTo('/login', slideNavigationTypes.slideToTop);
    }, 500);
  }
  errorEmailValidationResponseHandler() {
    this.staticUtilitiesSE.closeLoader();
    setTimeout(() => {
      this.staticUtilitiesSE.goTo('/login', slideNavigationTypes.slideToTop);
    }, 500);
  }
  /**
   * CALLS TO API
   */
  emailValidations() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.staticUtilitiesSE.openLoader(FennechLoaderComponent);
    this.userSE.emailValidation(behaviorSubject, this.generateObjHandler());
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successEmailValidationResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorEmailValidationResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
