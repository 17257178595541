import { iSimpleClient } from '../Client/iSimpleClient';
import { iSimpleEmployee } from '../Employee/iSimpleEmployee';
import { iProjectStatistic } from './iProjectStatistic';

export interface iProject {
  id: number | null;
  nombre: string;
  abreviatura: string;
  etiqueta: string;
  fechaIni: string;
  fechaFin: string;
  potencial: boolean;
  presupuesto: number | null;
  cliente: iSimpleClient | null;
  horas: number | null;
  estado: number | null;
  completado: number | null;
  empleados: Array<iSimpleEmployee>;
  multiplicador: number | null;
  estadisticas: iProjectStatistic | null;
}

export class iProject {
  static convertFromCollection(ob: any): Array<iProject> {
    let iProjectCollection: Array<iProject> = [];
    ob.forEach((element: any) => {
      iProjectCollection.push(this.convertFromObject(element));
    });
    return iProjectCollection;
  }

  static convertFromObject(ob: any): iProject {
    let projectObj = new iProject();
    projectObj.id = ob.id;
    projectObj.nombre = ob.nombre;
    projectObj.abreviatura = ob.abreviatura;
    projectObj.etiqueta = ob.etiqueta;
    projectObj.fechaIni = ob.fechaIni;
    projectObj.fechaFin = ob.fechaFin;
    projectObj.potencial = ob.potencial;
    projectObj.presupuesto = ob.presupuesto;
    projectObj.cliente = ob.cliente;
    projectObj.horas = ob.horas;
    projectObj.estado = ob.estado;
    projectObj.completado = ob.completado;
    projectObj.empleados = iSimpleEmployee.convertFromCollection(ob.empleados);
    projectObj.multiplicador = ob.multiplicador;
    projectObj.estadisticas = ob.estadisticas;

    return projectObj;
  }

  static createEmptyObject(): iProject {
    let iProjectObj = new iProject();
    iProjectObj.id = null;
    iProjectObj.nombre = '';
    iProjectObj.abreviatura = '';
    iProjectObj.etiqueta = '';
    iProjectObj.fechaIni = '';
    iProjectObj.fechaFin = '';
    iProjectObj.potencial = false;
    iProjectObj.presupuesto = null;
    iProjectObj.cliente = null;
    iProjectObj.horas = null;
    iProjectObj.estado = null;
    iProjectObj.completado = null;
    iProjectObj.empleados = [];
    iProjectObj.multiplicador = null;
    iProjectObj.estadisticas = null;

    return iProjectObj;
  }
}
