import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iResultHttp,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { EmployeeService } from 'src/app/Services/Api/employee.service';
import { ProjectService } from 'src/app/Services/Api/project.service';
import { EditProject } from 'src/app/Shared/Class/Projects/edit-project';

@Component({
  selector: 'app-quick-edit-project-popup',
  templateUrl: './quick-edit-project-popup.component.html',
  styleUrls: ['./quick-edit-project-popup.component.scss'],
})
export class QuickEditProjectPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }
  editProjectClass: EditProject = new EditProject();
  backUpData: any = null;
  constructor(
    public dialogRef: MatDialogRef<QuickEditProjectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private employeeSE: EmployeeService,
    private projectSE: ProjectService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.getAllEmployees();
    this.backUpData = JSON.parse(JSON.stringify(this.modalData));
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  goToEditProject() {
    this.forceClosePopup();
    this.staticUtilitiesSE.goTo(
      '/portfolio/cotizador/editar-proyecto',
      slideNavigationTypes.slideToTop,
      { proyecto: this.modalData.id }
    );
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.closePopup();
      }
    });
  }
  validateIfDataIsCorrectHandler(element, value) {
    const showErrorAndRestore = (errorMessage: string, backupValue: any) => {
      StaticUtilitiesService.showError(
        'Error al validar el campo',
        errorMessage
      );
      this.modalData[element] = backupValue;
    };
    switch (element) {
      case 'nombre':
      case 'abreviatura':
      case 'etiqueta':
      case 'responsable':
        if (value) {
          this.updateProjectHandler(element, value);
        } else {
          showErrorAndRestore(
            `El ${element} es un campo obligatorio y no puede estar vacío.`,
            this.backUpData[element]
          );
        }
        break;
    }
  }
  updateProjectHandler(element, value) {
    const obj = { id: this.modalData.id };
    obj[element] = value;
    this.update(obj);
  }
  successGetAllEmployeesResponseHandler(res: iResultHttp) {
    this.editProjectClass.managerOptions.items = [...res.data.data];
  }
  successUpdateResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha actualizado el projecto correctamente'
    );
    this.editProjectClass.isLoading = false;
  }
  errorUpdateResponseHandler() {
    this.editProjectClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  getAllEmployees() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.employeeSE.getAllByCompany(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllEmployeesResponseHandler(res),
          error: false,
        });
      });
  }
  update(obj: any) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectSE.update(behaviorSubject, obj);
    this.editProjectClass.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorUpdateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
