import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  StaticUtilitiesService,
  iStaticUtilities,
  sha256,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RolePermissionGuard implements CanActivate {
  constructor(private staticUtilitiesSE: StaticUtilitiesService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var hasPermission: boolean = true;
    if (localStorage.getItem('role') === sha256('ROLE_USER')) {
      hasPermission = false;
    }
    if (localStorage.getItem('role') === sha256('ROLE_GESTOR')) {
      const permissions = JSON.parse(
        iStaticUtilities.simpleDecrypt(
          localStorage.getItem(iStaticUtilities.simpleCrypt('permisos'))!
        )
      );
      const userPermission = permissions.map((permission) => {
        return permission.permiso.id;
      });
      switch (route.url[0].path) {
        case 'dashboard':
          hasPermission = userPermission.includes(1);
          break;
        case 'lineas-negocio':
          hasPermission = userPermission.includes(2);
          break;
        case 'portfolio':
          hasPermission = userPermission.includes(3);
          break;
        case 'recursos':
          hasPermission = userPermission.includes(4);
          break;
        case 'sprint':
          hasPermission = userPermission.includes(5);
          break;
        case 'horas-sin-imputar':
          hasPermission = userPermission.includes(5);
          break;
        case 'registro':
          hasPermission = userPermission.includes(5);
          break;
        case 'seguimiento':
          hasPermission = userPermission.includes(5);
          break;
        case 'contabilidad':
          hasPermission = userPermission.includes(6);
          break;
        case 'crm':
          hasPermission = userPermission.includes(7);
          break;
        case 'preferencias':
          hasPermission = userPermission.includes(8);
          break;
        case 'registro-de-accesos':
          hasPermission = userPermission.includes(9);
          break;
        case 'registro-de-fichajes':
          hasPermission = userPermission.includes(9);
          break;
      }
    }

    if (!hasPermission) {
      this.staticUtilitiesSE.goTo(
        '/home/hub',
        slideNavigationTypes.slideToBottom
      );
      StaticUtilitiesService.showError(
        'Se ha producido un error al verificar el usuario',
        'Su usuario no tiene permisos para acceder a esta sección.'
      );
    }
    return hasPermission;
  }
}
