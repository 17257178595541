import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateBridgeHandlerService {
  private _updateData = new BehaviorSubject<any>(null);
  private _updateDataClass = new BehaviorSubject<any>(null);
  constructor() {}

  sendUpdateData() {
    this._updateData.next(true);
    setTimeout(() => {
      this._updateData.next(null);
    }, 150);
  }
  getUpdateData() {
    return this._updateData;
  }
  sendUpdateDataClass(classObj: any) {
    this._updateDataClass.next(classObj);
    setTimeout(() => {
      this._updateDataClass.next(null);
    }, 150);
  }
  getUpdateDataClass() {
    return this._updateDataClass;
  }
}
