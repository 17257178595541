import { iSimpleEmployee } from '../Employee/iSimpleEmployee';
import { iProfile } from '../Profile/iProfile';

export interface iProcessAssignments {
  id: number | null;
  horas: number | null;
  empleado: any;
  futurible: any;
  perfil: iProfile | null;
}

export class iProcessAssignments {
  static convertFromCollection(ob: any): Array<iProcessAssignments> {
    let iSelfDataCollection: Array<iProcessAssignments> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iProcessAssignments {
    let iSelfDataObj = new iProcessAssignments();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.horas = ob.horas;
    iSelfDataObj.empleado = ob.empleado;
    iSelfDataObj.futurible = ob.futurible;
    iSelfDataObj.perfil = ob.perfil;

    return iSelfDataObj;
  }

  static createEmptyObject(): iProcessAssignments {
    let iSelfDataObj = new iProcessAssignments();
    iSelfDataObj.id = null;
    iSelfDataObj.horas = null;
    iSelfDataObj.empleado = null;
    iSelfDataObj.perfil = null;
    return iSelfDataObj;
  }
}
