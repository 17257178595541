<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Solicitar vacaciones</p>
  </div>
  <div class="body">
    <div class="dateContainer">
      <mat-date-range-input [rangePicker]="picker" [min]="minDate">
        <input matStartDate placeholder="dd/mm/yyyy" (dateChange)="getDatePhase($event, null)">
        <input matEndDate placeholder="dd/mm/yyyy" (dateChange)="getDatePhase(null, $event);">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker">
        <img src="/assets/Icons/QuotationEngine/calendarioIcon.svg" alt="" matDatepickerToggleIcon>
      </mat-datepicker-toggle>
      <mat-date-range-picker [ngStyle]="{'display': 'none'}" #picker></mat-date-range-picker>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !isCompleted}" (click)="validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!isLoading">check</mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>