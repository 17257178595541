<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar una categoría</p>
    <p *ngIf="!modalData.edit">Crear una nueva categoría</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <customInput [options]="categoryClass.nombreOptions" [(ngModel)]="categoryClass.createEditObj.nombre"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('nombre', categoryClass.createEditObj.nombre)">
      </customInput>
      <customInput [options]="categoryClass.colorOption" [(ngModel)]="categoryClass.createEditObj.color"
        (change)="this.updateDataHandler('color', categoryClass.createEditObj.color)">
      </customInput>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !categoryClass.isCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!categoryClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="categoryClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>