export interface iClientStatistic {
  coste: number;
  ingresos: number;
  lifeTime: number;
  rentabilidad: number;
  totalHoras: number;
}

export class iClientStatistic {
  static convertFromCollection(ob: any): Array<iClientStatistic> {
    let iClientStatisticCollection: Array<iClientStatistic> = [];
    ob.forEach((element: any) => {
      iClientStatisticCollection.push(this.convertFromObject(element));
    });
    return iClientStatisticCollection;
  }

  static convertFromObject(ob: any): iClientStatistic {
    let iClientStatisticObj = new iClientStatistic();
    iClientStatisticObj.coste = ob.coste ?? 0;
    iClientStatisticObj.ingresos = ob.ingresos ?? 0;
    iClientStatisticObj.lifeTime = ob.lifetime ?? 0;
    iClientStatisticObj.rentabilidad = ob.rentabilidad ?? 0;
    iClientStatisticObj.totalHoras = ob.horas ?? 0;
    return iClientStatisticObj;
  }

  static createEmptyObject(): iClientStatistic {
    let iClientStatisticObj = new iClientStatistic();
    iClientStatisticObj.coste = 0;
    iClientStatisticObj.ingresos = 0;
    iClientStatisticObj.lifeTime = 0;
    iClientStatisticObj.rentabilidad = 0;
    iClientStatisticObj.totalHoras = 0;
    return iClientStatisticObj;
  }
}
