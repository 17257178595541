<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar coste</p>
    <p *ngIf="!modalData.edit">Crear nuevo coste</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <div class="customFormContainer">
          <label for="">Tipo de coste<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [options]="billClass.billTypeOptions" [(ngModel)]="billClass.newBill.tipo"
            (change)="checkIfIsDataCompletedHandler();updateProjectHandler('tipo',billClass.newBill.tipo)"></selector>
        </div>
        <div class="customFormContainer">
          <label for="">Imputación del coste<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [options]="billClass.allocationOfExpenditureOptions" [(ngModel)]="billClass.newBill.directo"
            (change)="checkIfIsDataCompletedHandler(); updateProjectHandler('directo',billClass.newBill.directo)">
          </selector>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="customFormContainer">
          <label for="">Estado<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [options]="billClass.stateOptions" [(ngModel)]="billClass.newBill.estado"
            (change)="checkIfIsDataCompletedHandler(); updateProjectHandler('estado',billClass.newBill.estado)">
          </selector>
        </div>
        <div class="customFormContainer">
          <label for="">Categoría<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector [options]="billClass.familyOptions" [(ngModel)]="billClass.newBill.familia"
            (change)="checkIfIsDataCompletedHandler();updateProjectHandler('familia',billClass.newBill.familia)">
          </selector>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="customFormContainer">
          <label for="">Proyecto <sup [ngStyle]="{'color': 'red'}" *ngIf="billClass.newBill.directo ">*</sup></label>
          <selector [ngClass]="{'readOnly': !billClass.projectActive}" [options]="billClass.projectOptions"
            [(ngModel)]="billClass.newBill.proyecto"
            (change)="checkIfIsDataCompletedHandler();updateProjectHandler('proyecto',billClass.newBill.proyecto)">
          </selector>
        </div>
        <div class="customFormContainer">
          <label for="">Proveedor </label>
          <selector [options]="billClass.suplierOptions" [(ngModel)]="billClass.newBill.proveedor"
            (change)="checkIfIsDataCompletedHandler();updateProjectHandler('proveedor',billClass.newBill.proveedor)">
          </selector>
        </div>

      </div>
      <div class="columnDisplay">
        <customInput [options]="billClass.quantityOptions" [(ngModel)]="billClass.newBill.cantidad"
          (change)="checkIfIsDataCompletedHandler(); updateProjectHandler('cantidad',billClass.newBill.cantidad)">
        </customInput>
        <selector [options]="billClass.taxationsOptions" [(ngModel)]="billClass.newBill.iva"
          (change)="checkIfIsDataCompletedHandler();updateProjectHandler('iva',billClass.newBill.iva)"></selector>
      </div>
      <div class="columnDisplay">
        <customInput [options]="billClass.dateOption" (change)="getDate($event)"></customInput>

        <customInput [options]="billClass.billNumberOptions" [(ngModel)]="billClass.newBill.numeroFactura"
          (change)="checkIfIsDataCompletedHandler(); updateProjectHandler('numeroFactura',billClass.newBill.numeroFactura)">
        </customInput>

      </div>
      <customInput [options]="billClass.conceptOptions" [(ngModel)]="billClass.newBill.concepto"
        (change)="checkIfIsDataCompletedHandler(); updateProjectHandler('concepto',billClass.newBill.concepto)">
      </customInput>
      <btn class="action" fileInput [accept]="billClass.formatos" (change)="getFile(billClass.file)"
        [(ngModel)]="billClass.file">
        <ng-container>
          <mat-icon>attach_file</mat-icon>
          <span>Adjuntar factura</span>
        </ng-container>
      </btn>
      <div class="fileContainer" *ngIf="billClass.file">
        <div class="name" (click)="previewDocument()">
          <mat-icon>attach_file</mat-icon>
          <span>{{billClass.file.file.name}}</span>
        </div>
        <mat-icon (click)="removeFile()">close</mat-icon>
      </div>
    </div>

  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !billClass.isCompleted}" (click)="validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!billClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="billClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>