import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';

export class CreateInvoice {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  emisionDateOptions: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    required: true,
    label: 'Fecha de emisión',
    classList: 'specialFormInput',
  };
  invoiceNumberOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Nº de factura',
    classList: 'specialFormInput',
  };
  taxationsOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: false,
    append: true,
    bindLabel: 'nombre',
    bindValue: 'valor',
    classList: 'specialFormSelector',
    search: true,
    label: 'Tipo de fiscalidad',
    required: true,
  };
  irpfOptions: iOptionsCustomInput = {
    type: 'number',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'IRPF(%)',
    classList: 'specialFormInput',
  };
  newInvoiceObj: any = {
    numero: '',
    iva: null,
    irpf: null,
    fechaEmision: null,
  };
}
