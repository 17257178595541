import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
  sha256,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { PermissionService } from 'src/app/Services/Api/permission.service';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { UserService } from 'src/app/Services/Api/user.service';
import { EmployeeService } from 'src/app/Services/Api/employee.service';

@Component({
  selector: 'app-manage-roles-permissions-popup',
  templateUrl: './manage-roles-permissions-popup.component.html',
  styleUrls: ['./manage-roles-permissions-popup.component.scss'],
})
export class ManageRolesPermissionsPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePopup();
    }
  }
  isIntermedite: boolean = false;
  isDataLoaded: boolean = false;
  isLoading: boolean = false;
  permissions: Array<{ id: number; nombre: string }> = [];
  userPermission: {
    user: number;
    permisos: Array<{ user: number; permiso: number; editar: boolean }>;
  } = {
    user: 0,
    permisos: [],
  };
  constructor(
    public dialogRef: MatDialogRef<ManageRolesPermissionsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private permissionSE: PermissionService,
    private employeeSE: EmployeeService,
    private popupSE: PopupService
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.setDataOfUserPermissions();
    this.checkUserRoleHandler();
    this.getAllPermissions();
  }

  /**
   * POPUPS
   */
  openConfirmChangeRolePopup(role: string) {
    const confirmCloseResponseMessage: iConfirmPopup = {
      buttonConfirmText: 'Sí, confirmar',
      buttonCancelText: 'No, cancelar',
      title: `¿Estas seguro de que quieres cambiar el rol de ${this.modalData.nombre} ${this.modalData.apellidos} a ${role}?`,
      icon: '/assets/Icons/warning.svg',
    };
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmPopupComponent, confirmCloseResponseMessage);
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) return;
        if (res.activeAction) {
          this.roleDataTreatmentBeforeSendHandler(role);
        }
        subject.next('');
      });
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.closePopup();
      }
    });
  }
  checkUserRoleHandler() {
    this.isIntermedite =
      localStorage.getItem('role') === sha256('ROLE_INTERMEDIO');
  }

  checkIfHasThisPermissionHandler(id: number) {
    return (
      this.userPermission.permisos.filter((permiso) => permiso.permiso === id)
        .length > 0
    );
  }
  checkIfHasThisPermissionAndEditHandler(id: number) {
    const permissionSelected = this.userPermission.permisos.filter(
      (permiso) => permiso.permiso === id
    );
    return permissionSelected.length > 0 && permissionSelected[0].editar;
  }
  roleDataTreatmentBeforeSendHandler(role: string) {
    var newEditObj: { id: number; roles: Array<string> } = {
      id: this.modalData.id,
      roles: [],
    };

    switch (role) {
      case 'empleado':
        newEditObj.roles = ['ROLE_USER'];
        break;
      case 'gestor':
        newEditObj.roles = ['ROLE_GESTOR'];
        break;
      case 'intermedio':
        newEditObj.roles = ['ROLE_INTERMEDIO'];
        break;
      case 'administrador':
        newEditObj.roles = ['ROLE_ADMIN'];
        break;
    }

    this.updateUser(newEditObj);
  }
  /**
   * GETTERS & SETTERS
   */
  setDataOfUserPermissions() {
    this.userPermission.user = this.modalData.id;
    this.userPermission.permisos = this.modalData.permisos.map((permission) => {
      return {
        permiso: permission.permiso.id,
        user: this.modalData.id,
        editar: permission.editar,
      };
    });
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  changeEmployeeRole(role: string) {
    this.openConfirmChangeRolePopup(role);
  }
  updatePermission(event, id) {
    const filteredPermission = this.userPermission.permisos.filter(
      (permiso) => permiso.permiso === id
    );
    if (filteredPermission.length > 0) {
      this.userPermission.permisos.splice(
        this.userPermission.permisos.indexOf(filteredPermission[0]),
        1
      );
    } else {
      const newPermission: { permiso: number; editar: boolean; user: number } =
        {
          permiso: id,
          editar: false,
          user: this.modalData.id,
        };
      this.userPermission.permisos.push(newPermission);
    }
    this.updateUserPermission();
  }
  updateEditPermission(event, id) {
    const filteredPermission = this.userPermission.permisos.filter(
      (permiso) => permiso.permiso === id
    );
    if (filteredPermission.length > 0) {
      filteredPermission[0].editar = event.checked;
    } else {
      const newPermission: { permiso: number; editar: boolean; user: number } =
        {
          permiso: id,
          editar: true,
          user: this.modalData.id,
        };
      this.userPermission.permisos.push(newPermission);
    }
    this.updateUserPermission();
  }
  updateViewPermission(event, id) {
    const filteredPermission = this.userPermission.permisos.filter(
      (permiso) => permiso.permiso === id
    );
    if (filteredPermission.length > 0) {
      if (!event.checked) {
        this.userPermission.permisos.splice(
          this.userPermission.permisos.indexOf(filteredPermission[0]),
          1
        );
      }
    } else {
      const newPermission: { permiso: number; editar: boolean; user: number } =
        {
          permiso: id,
          editar: false,
          user: this.modalData.id,
        };
      this.userPermission.permisos.push(newPermission);
    }
    this.updateUserPermission();
  }
  /**
   * API RESPONSES
   */
  successGetAllPermissionsResponseHandler(res: iResultHttp) {
    this.permissions = [...res.data];
    this.isDataLoaded = true;
  }
  successUpdateUserPermissionResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
  }
  successUpdateUserRoleResponseHandler(res: iResultHttp, obj) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.modalData.roles = obj.roles;

    this.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  getAllPermissions() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.permissionSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllPermissionsResponseHandler(res),
          error: false,
        });
      });
  }
  updateUserPermission() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.permissionSE.updateUserPermission(
      behaviorSubject,
      this.userPermission
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateUserPermissionResponseHandler(res),
            error: false,
          },
          {
            method: () => (this.isLoading = false),
            error: true,
          },
        ]);
      });
  }
  updateUser(newEditObj: { id: number; roles: Array<string> }) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.isLoading = true;
    this.employeeSE.edit(behaviorSubject, newEditObj);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () =>
              this.successUpdateUserRoleResponseHandler(res, newEditObj),
            error: false,
          },
          {
            method: () => (this.isLoading = false),
            error: true,
          },
        ]);
      });
  }
}
