export interface iDepartmentSelector {
  id: number | null;
  nombre: string;
}

export class iDepartmentSelector {
  static convertFromCollection(ob: any): Array<iDepartmentSelector> {
    let iDepartmentSelectorCollection: Array<iDepartmentSelector> = [];
    ob.forEach((element: any) => {
      iDepartmentSelectorCollection.push(this.convertFromObject(element));
    });
    return iDepartmentSelectorCollection;
  }

  static convertFromObject(ob: any): iDepartmentSelector {
    let iDepartmentSelectorObj = new iDepartmentSelector();
    iDepartmentSelectorObj.id = ob.id;
    iDepartmentSelectorObj.nombre = ob.nombre;
    return iDepartmentSelectorObj;
  }

  static createEmptyObject(): iDepartmentSelector {
    let iDepartmentSelectorObj = new iDepartmentSelector();
    iDepartmentSelectorObj.id = null;
    iDepartmentSelectorObj.nombre = '';
    return iDepartmentSelectorObj;
  }
}
