export interface iProjectStatistic {
  horasPlanificadas: number | null;
  avancePlanificado: number | null;
  costePlanificado: number | null;
  avanceReal: number | null;
  horasClockify: number | null;
  costeReal: number | null;
}

export class iProjectStatistic {
  static convertFromObject(ob: any): iProjectStatistic {
    let iSelfDataObj = new iProjectStatistic();
    iSelfDataObj.horasPlanificadas = ob.horasPlanificadas;
    iSelfDataObj.avancePlanificado = ob.avancePlanificado;
    iSelfDataObj.costePlanificado = ob.costePlanificado;
    iSelfDataObj.avanceReal = ob.avanceReal;
    iSelfDataObj.horasClockify = ob.horasClockify;
    iSelfDataObj.costeReal = ob.costeReal;

    return iSelfDataObj;
  }

  static createEmptyObject(): iProjectStatistic {
    let iSelfDataObj = new iProjectStatistic();
    iSelfDataObj.horasPlanificadas = null;
    iSelfDataObj.avancePlanificado = null;
    iSelfDataObj.costePlanificado = null;
    iSelfDataObj.avanceReal = null;
    iSelfDataObj.horasClockify = null;
    iSelfDataObj.costeReal = null;

    return iSelfDataObj;
  }
}
