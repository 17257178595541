export interface iCompany {
  id: number | null;
  nombre: string;
  color: string | null;
  plan: number | null;
  alta: string;
  idClockify: string;
}

export class iCompany {
  static convertFromCollection(ob: any): Array<iCompany> {
    let iCompanyCollection: Array<iCompany> = [];
    ob.forEach((element: any) => {
      iCompanyCollection.push(this.convertFromObject(element));
    });
    return iCompanyCollection;
  }

  static convertFromObject(ob: any): iCompany {
    let iCompanyObj = new iCompany();
    iCompanyObj.id = ob.id;
    iCompanyObj.nombre = ob.nombre;
    iCompanyObj.alta = ob.alta;
    iCompanyObj.plan = ob.plan;
    iCompanyObj.color = ob.color;
    iCompanyObj.idClockify = ob.idClockify;
    return iCompanyObj;
  }

  static createEmptyObject(): iCompany {
    let iCompanyObj = new iCompany();
    iCompanyObj.id = null;
    iCompanyObj.nombre = '';
    iCompanyObj.alta = '';
    iCompanyObj.plan = null;
    iCompanyObj.color = '';
    iCompanyObj.idClockify = '';

    return iCompanyObj;
  }
}
