import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iDate,
} from '@quasar-dynamics/basic-designsystem';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { takeUntil } from 'rxjs';
import { PaymentMilestonesCreate } from 'src/app/Shared/Class/PaymentMilestones/payment-milestones-create';

@Component({
  selector: 'app-new-milestone-payment',
  templateUrl: './new-milestone-payment-popup.component.html',
  styleUrls: ['./new-milestone-payment-popup.component.scss'],
})
export class NewMilestonePaymentComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  newPaymentMilestonePaymentClass: PaymentMilestonesCreate =
    new PaymentMilestonesCreate();
  constructor(
    public dialogRef: MatDialogRef<NewMilestonePaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService
  ) {}

  ngOnInit(): void {
    this.newPaymentMilestonePaymentClass.quantityOptions.label =
      'Cantidad máx. ( ' +
      this.modalData.presupuestoRestante.toFixed(2) +
      ' € )';

    this.newPaymentMilestonePaymentClass.phaseOptions.items = [
      ...this.modalData.fases,
    ];
    this.clickOutClosePopupHandler();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  validateIfDataIsCorrectHandler() {
    this.newPaymentMilestonePaymentClass.isCompleted
      ? this.closePopup(
          this.newPaymentMilestonePaymentClass
            .newMilestonesPaymentQuotationEngine
        )
      : StaticUtilitiesService.showError(
          'Hay errores en alguno de los campos',
          'Los campos son obligatorios y no pueden estar vacío'
        );
  }
  validateIfDataIsCompletedHandler() {
    const { fase, fecha, concepto, importe, proceso } =
      this.newPaymentMilestonePaymentClass.newMilestonesPaymentQuotationEngine;
    if (fase) {
      if (fecha && concepto && importe && fase && proceso) {
        if (Number(importe) > Number(this.modalData.presupuestoRestante)) {
          StaticUtilitiesService.showError(
            'Hay errores en alguno de los campos',
            'La cantidad no puede ser mayor al presupuesto restante'
          );
        } else {
          this.newPaymentMilestonePaymentClass.isCompleted = true;
        }
      }
    } else {
      if (fecha && concepto && importe && fase) {
        if (Number(importe) > Number(this.modalData.presupuestoRestante)) {
          StaticUtilitiesService.showError(
            'Hay errores en alguno de los campos',
            'La cantidad no puede ser mayor al presupuesto restante'
          );
        } else {
          this.newPaymentMilestonePaymentClass.isCompleted = true;
        }
      }
    }
  }

  /**
   * GETTERS & SETTERS
   */
  getDate(event: Date) {
    this.newPaymentMilestonePaymentClass.newMilestonesPaymentQuotationEngine.fecha =
      new iDate(iDate.toPhp(event)).toStringDate('JAP');
  }
  getProcessOfPhases() {
    this.newPaymentMilestonePaymentClass.processOptions.items = [
      ...this.newPaymentMilestonePaymentClass
        .newMilestonesPaymentQuotationEngine.fase.procesos,
    ];
  }
}
