import { iDailyCategory } from '../Category/iDailyCategory';
import { iSimpleEmployee } from '../Employee/iSimpleEmployee';
import { iSimpleProject } from '../Project/iSimpleProject';

export interface iDailyRegister {
  id: number | null;
  nombre: string;
  categoria: iDailyCategory | null;
  fecha: string;
  completada: boolean;
  enviado: boolean;
  porcentaje: number | null;
  validado: boolean;
  motivo: string;
  proyecto: iSimpleProject | null;
  usuario: iSimpleEmployee;
  proceso: {
    id: number | null;
    nombre: string;
    fase: { id: number | null; nombre: string };
  } | null;
  subProceso: {
    id: number | null;
    nombre: string;
    fase: { id: number | null; nombre: string };
    proceso: { id: number | null; nombre: string };
  } | null;
  avanceReal: number | null;
  avanceTeorico: number | null;
  horas: number | null;
  horasEstimadas: number | null;
}

export class iDailyRegister {
  static convertFromCollection(ob: any): Array<iDailyRegister> {
    let iSelfDataCollection: Array<iDailyRegister> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iDailyRegister {
    let iSelfDataObj = new iDailyRegister();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.nombre = ob.nombreTarea;
    iSelfDataObj.fecha = ob.fecha;
    iSelfDataObj.completada = ob.completada;
    iSelfDataObj.enviado = ob.enviado;
    iSelfDataObj.porcentaje = ob.porcentaje;
    iSelfDataObj.validado = ob.validado;
    iSelfDataObj.motivo = ob.motivo;
    iSelfDataObj.proyecto = ob.proyecto
      ? iSimpleProject.convertFromObject(ob.proyecto)
      : null;
    iSelfDataObj.usuario = iSimpleEmployee.convertFromObject(ob.usuario);
    iSelfDataObj.proceso = ob.proceso ?? null;
    iSelfDataObj.subProceso = ob.subProceso ?? null;
    iSelfDataObj.categoria = ob.categoria
      ? iDailyCategory.convertFromObject(ob.categoria)
      : null;
    iSelfDataObj.avanceReal = ob.avanceReal;
    iSelfDataObj.avanceTeorico = ob.avanceTeorico;
    iSelfDataObj.horas = ob.horas;
    iSelfDataObj.horasEstimadas = ob.horasEstimadas;
    return iSelfDataObj;
  }

  static createEmptyObject(): iDailyRegister {
    let iSelfDataObj = new iDailyRegister();
    iSelfDataObj.id = null;
    iSelfDataObj.nombre = '';
    iSelfDataObj.fecha = '';
    iSelfDataObj.completada = false;
    iSelfDataObj.enviado = false;
    iSelfDataObj.porcentaje = null;
    iSelfDataObj.validado = false;
    iSelfDataObj.motivo = '';
    iSelfDataObj.proyecto = iSimpleProject.createEmptyObject();
    iSelfDataObj.usuario = iSimpleEmployee.createEmptyObject();
    iSelfDataObj.proceso = {
      id: null,
      nombre: '',
      fase: { id: null, nombre: '' },
    };
    iSelfDataObj.subProceso = {
      id: null,
      nombre: '',
      fase: { id: null, nombre: '' },
      proceso: { id: null, nombre: '' },
    };
    iSelfDataObj.avanceReal = null;
    iSelfDataObj.avanceTeorico = null;
    iSelfDataObj.horas = null;
    iSelfDataObj.horasEstimadas = null;
    iSelfDataObj.categoria = null;
    return iSelfDataObj;
  }
}
