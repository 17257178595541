import { iSimpleEmployee } from '../Employee/iSimpleEmployee';
import { iCheckinHistoric } from './iCheckinHistoric';

export interface iCheckin {
  id?: number | null;
  fechaInicio?: string;
  fechaPausa?: string;
  fechaFin?: string;
  finalizado: boolean;
  ip: string;
  latitud: string;
  longitud: string;
  pausado: boolean;
  segundos?: number | null;
  alerta?: boolean;
  usuario: iSimpleEmployee | null;
  historico: Array<iCheckinHistoric>;
}

export class iCheckin {
  static convertFromCollection(ob: any): Array<iCheckin> {
    let iCheckinCollection: Array<iCheckin> = [];
    ob.forEach((element: any) => {
      iCheckinCollection.push(this.convertFromObject(element));
    });
    return iCheckinCollection;
  }

  static convertFromObject(ob: any): iCheckin {
    let iCheckinObj = new iCheckin();

    iCheckinObj.id = ob.id;
    iCheckinObj.fechaInicio = ob.fechaInicio;
    iCheckinObj.fechaPausa = ob.fechaPausa;
    iCheckinObj.fechaFin = ob.fechaFin;
    iCheckinObj.finalizado = ob.finalizado;
    iCheckinObj.ip = ob.ip;
    iCheckinObj.latitud = ob.latitud;
    iCheckinObj.longitud = ob.longitud;
    iCheckinObj.pausado = ob.pausado;
    iCheckinObj.segundos = ob.segundos;
    iCheckinObj.alerta = ob.alerta;
    iCheckinObj.usuario = iSimpleEmployee.convertFromObject(ob.usuario);
    iCheckinObj.historico = ob.historicoCambioFichajes
      ? iCheckinHistoric.convertFromCollection(ob.historicoCambioFichajes)
      : [];
    return iCheckinObj;
  }
}
