import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iSimpleEmployee } from 'src/app/Shared/Interfaces/Api/Employee/iSimpleEmployee';
import { iRegister } from 'src/app/Shared/Interfaces/Api/Register/iRegister';
import { iResourceSelector } from 'src/app/Shared/Interfaces/Api/User/iResourceSelector';
import { iSelfData } from 'src/app/Shared/Interfaces/Api/User/iSelfData';
import { iUserSelector } from 'src/app/Shared/Interfaces/Api/User/iUserSelector';

@Injectable({
  providedIn: 'root',
})
export class UserService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/user/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iUserSelector.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSelector,
            args: [behaviorSubject],
          });
        },
      });
  }
  getResourceSelector(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/recursos/selector', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iResourceSelector.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getResourceSelector,
            args: [behaviorSubject],
          });
        },
      });
  }
  getMyData(behaviorSubject: BehaviorSubject<any>) {
    this.http.get(this.urlApi + '/api/user/me', this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: iSelfData.convertFromObject(
            iStaticUtilities.normalizeNames(data)
          ),
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.getMyData,
          args: [behaviorSubject],
        });
      },
    });
  }
  create(behaviorSubject: BehaviorSubject<any>, data: iRegister) {
    this.http.post(this.urlApi + '/register', data).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: data.message,
          data: null,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.create,
          args: [behaviorSubject, data],
        });
      },
    });
  }
  emailValidation(
    behaviorSubject: BehaviorSubject<any>,
    data: { otp: string; type: string }
  ) {
    this.http.post(this.urlApi + '/validate', data).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: data.message,
          data: null,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.emailValidation,
          args: [behaviorSubject, data],
        });
      },
    });
  }

  rensendEmail(behaviorSubject: BehaviorSubject<any>, email: string) {
    this.http.post(this.urlApi + '/reenviar', { username: email }).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: data.message,
          data: null,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.rensendEmail,
          args: [behaviorSubject, email],
        });
      },
    });
  }
  updateSelfData(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(this.urlApi + '/api/userMe', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updateSelfData,
            args: [behaviorSubject, data],
          });
        },
      });
  }
}
