import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { ProductService } from 'src/app/Services/Api/product.service';
import { ProjectDetails } from 'src/app/Shared/Class/Projects/project-details';
import { iConfirmPopup } from 'src/app/Shared/Interfaces/Utils/iConfirmPopup';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { filter, takeUntil } from 'rxjs';
import { ProjectService } from 'src/app/Services/Api/project.service';

@Component({
  selector: 'app-assign-product-proyect-popup',
  templateUrl: './assign-product-proyect-popup.component.html',
  styleUrls: ['./assign-product-proyect-popup.component.scss'],
})
export class AssignProductProyectPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.openConfirmDisacarActionPopup();
    }
  }
  projectDetailsClass: ProjectDetails = new ProjectDetails();
  constructor(
    public dialogRef: MatDialogRef<AssignProductProyectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private staticUtilitiesSE: StaticUtilitiesService,
    private productSE: ProductService,
    private projectSE: ProjectService
  ) {}

  ngOnInit(): void {
    this.projectDetailsClass = JSON.parse(JSON.stringify(this.modalData.class));
    this.getAllProducts();
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  openConfirmDisacarActionPopup() {
    const confirmCloseResponseMessage: iConfirmPopup = {
      buttonConfirmText: 'Sí, confirmar',
      buttonCancelText: 'No, cancelar',
      title:
        '¿Estas seguro de que quieres descartar la asignación del producto?',
      icon: '/assets/Icons/warning.svg',
    };
    const subject = StaticUtilitiesService.createSubject();
    this.popupSE.openPopup(ConfirmPopupComponent, confirmCloseResponseMessage);
    this.popupSE
      .returnData()
      .pipe(takeUntil(subject))
      .subscribe((res: any) => {
        if (!res) return;
        if (res.activeAction) {
          this.forceClosePopup();
        }
        subject.next('');
      });
  }
  checkIfDataIsCompleted() {
    this.projectDetailsClass.isCompleted =
      StaticUtilitiesService.isCompleteObject(
        this.projectDetailsClass.newAssignation
      );
  }
  generateUpdateObject() {
    const updateObj: any = {
      id: this.projectDetailsClass.projectId,
      productos: this.projectDetailsClass.projectProducts.map((product) => {
        return { id: product.id, cantidad: product.cantidad };
      }),
    };
    updateObj.productos.push(this.projectDetailsClass.newAssignation);
    return updateObj;
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  actionHandler() {
    if (this.projectDetailsClass.isCompleted) {
      this.updateProject();
    } else {
      StaticUtilitiesService.showError(
        'Hay errores en los campos',
        'Los campos son obligatorios y no pueden estar vacíos'
      );
    }
  }
  successGetAllProductsSelectorResponseHandler(res: iResultHttp) {
    this.projectDetailsClass.productOptions.items = [...res.data];
  }
  successUpdateProjectResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(
      'Se ha asignado el producto correctamente'
    );
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorUpdateProjectResponseHandler() {
    this.projectDetailsClass.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  updateProject() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.projectDetailsClass.isLoading = true;
    this.projectSE.update(behaviorSubject, this.generateUpdateObject());
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateProjectResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorUpdateProjectResponseHandler(),
            error: true,
          },
        ]);
      });
  }

  getAllProducts() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.productSE.getAllSelector(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllProductsSelectorResponseHandler(res),
          error: false,
        });
      });
  }
}
