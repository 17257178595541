import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupService } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'costTableRevision',
  templateUrl: './cost-table-revision.component.html',
  styleUrls: ['./cost-table-revision.component.scss'],
})
export class CostTableRevisionComponent implements OnInit {
  @Input() data: Array<any> = [];
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  costsSelected: Array<any> = [];
  displayedColumns: string[] = [
    'acciones',
    'numero',
    'concepto',
    'familia',
    'cantidad',
    'iva',
    'fecha',
    'estado',
    'proyecto',
    'proveedor',
  ];

  constructor(private popupSE: PopupService) {}

  ngOnInit(): void {}

  addToSelected(cost: any) {
    if (this.costsSelected.includes(cost)) {
      this.costsSelected.splice(this.costsSelected.indexOf(cost), 1);
    } else {
      this.costsSelected.push(cost);
    }
    this.update.emit(this.costsSelected);
  }
}
