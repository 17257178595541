<div class="popupGeneralContainer quickEdit">
  <div class=" header">
    <p>Detalles del producto</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <div class="data">
          <label for="">Código</label>
          <span *ngIf="modalData.product.codigo">{{modalData.product.codigo}}</span>
          <span *ngIf="!modalData.product.codigo">-</span>
        </div>
        <div class="data">
          <label for="">Nombre</label>
          <span *ngIf="modalData.product.nombre !== ''">{{modalData.product.nombre}}</span>
          <span *ngIf="modalData.product.nombre === ''">-</span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Categorías</label>
          <ng-container *ngIf="modalData.product.categoria.length > 0">
            <span>
              <ng-container *ngFor="let categoria of modalData.product.categoria; let i = index;">
                {{categoria.nombre}}
                <ng-container *ngIf=" modalData.product.categoria.length - 1 > i">,</ng-container>
              </ng-container>
            </span>
          </ng-container>
        </div>
        <div class="data">
          <label for="">Cantidad</label>
          <span>{{modalData.product.cantidad}} unidades</span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Precio compra</label>
          <span>{{modalData.product.precioCompra | currency:'EUR'}}</span>
        </div>
        <div class="data">
          <label for="">Precio venta bruto</label>
          <span>{{modalData.product.precio | currency:'EUR'}}</span>
        </div>
      </div>
      <div class="columnDisplay">
        <div class="data">
          <label for="">Fiscalidad</label>
          <span>{{modalData.product.fiscalidad.nombre}} ({{modalData.product.fiscalidad.valor}}%)</span>
        </div>
        <div class="data">
          <label for="">Precio venta neto</label>
          <span>{{modalData.product.precio + (modalData.product.precio * (modalData.product.fiscalidad.valor / 100))
            | currency:'EUR'}}</span>
        </div>
      </div>
      <div class="columnDisplay fullWidth">
        <div class="data">
          <label for="">Descripción</label>
          <span>{{modalData.product.descripcion}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup();">
      <ng-container>
        <mat-icon class="material-icons-outlined">
          arrow_back
        </mat-icon>
        <span>Volver</span>
      </ng-container>
    </btn>
  </div>
</div>