import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupService } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'clientsTableRevision',
  templateUrl: './clients-table-revision.component.html',
  styleUrls: ['./clients-table-revision.component.scss'],
})
export class ClientsTableRevisionComponent implements OnInit {
  @Input() data: Array<any> = [];
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  clientsSelected: Array<any> = [];
  displayedColumns: string[] = [
    'actions',
    'nombre',
    'cif',
    'direccion',
    'telefono',
    'email',
  ];
  constructor(private popupSE: PopupService) {}

  ngOnInit(): void {}

  addToSelected(client: any) {
    if (this.clientsSelected.includes(client)) {
      this.clientsSelected.splice(this.clientsSelected.indexOf(client), 1);
    } else {
      this.clientsSelected.push(client);
    }
    this.update.emit(this.clientsSelected);
  }
}
