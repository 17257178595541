import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iSupplier } from '../../Interfaces/Api/Suppliers/iSupplier';
import { iSupplierFilter } from '../../Interfaces/Api/Suppliers/iSupplierFilter';

export class Supplier {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  supplierFilter: iSupplierFilter = {
    num_pagina: 1,
    num_devoluciones: 10,
  };
  supplierList: Array<iSupplier> = [];
  newSuppliers: Array<any> = [];
  createEditObj: iSupplier = {
    nombre: '',
    razonSocial: '',
    cif: '',
    contacto: '',
    servicio: '',
    empresa: Number(localStorage.getItem('numEmpresa'))!,
  };
  backUpCreateEditObj: iSupplier = {
    nombre: '',
    razonSocial: '',
    cif: '',
    contacto: '',
    servicio: '',
    empresa: Number(localStorage.getItem('numEmpresa'))!,
  };

  /**
   * OPTIONS
   */
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    required: true,
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  bussinesNameOptions: iOptionsCustomInput = {
    label: 'Razón social',
    type: 'text',
    required: true,
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  cifOptions: iOptionsCustomInput = {
    label: 'CIF/NIF',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  serviceOptions: iOptionsCustomInput = {
    label: 'Servicio',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
  contactOptions: iOptionsCustomInput = {
    label: 'Contacto',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
  };
}
