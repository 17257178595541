import { iStaticUtilities } from '@quasar-dynamics/basic-designsystem';
import { iFunnelData } from './iFunnelData';

export interface iFunnelList {
  id: number | null;
  cierre: boolean;
  noCierre: boolean;
  nombre: string;
  orden: number | null;
  clienteEstadoCRM: Array<iFunnelData>;
}

export class iFunnelList {
  static convertFromCollection(ob: any): Array<iFunnelList> {
    let iSelfDataCollection: Array<iFunnelList> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iFunnelList {
    let iSelfDataObj = new iFunnelList();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.cierre = ob.cierre;
    iSelfDataObj.noCierre = ob.noCierre;
    iSelfDataObj.nombre = ob.nombre;
    iSelfDataObj.orden = ob.orden;
    iSelfDataObj.clienteEstadoCRM = iFunnelData.convertFromCollection(
      iStaticUtilities.normalizeNamesCollection(ob.clienteEstadoCrms)
    );
    return iSelfDataObj;
  }

  static createEmptyObject(): iFunnelList {
    let iSelfDataObj = new iFunnelList();
    iSelfDataObj.id = null;
    iSelfDataObj.cierre = false;
    iSelfDataObj.noCierre = false;
    iSelfDataObj.nombre = '';
    iSelfDataObj.orden = null;
    iSelfDataObj.clienteEstadoCRM = [];
    return iSelfDataObj;
  }
}
