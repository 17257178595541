import { iSprintPeriod } from './iSprintPeriod';

export interface iSprint {
  id?: number | null;
  nombre: string;
  fechaInicio: any;
  fechaFin: any;
  finalizado: boolean;
  validado: boolean;
  activo: boolean;
  rango: iSprintPeriod | null;
  proyectos?: Array<any>;
  departamentos?: Array<any>;
  lineasNegocio?: Array<any>;
}

export class iSprint {
  static convertFromCollection(ob: any): Array<iSprint> {
    let iSprintCollection: Array<iSprint> = [];
    ob.forEach((element: any) => {
      iSprintCollection.push(this.convertFromObject(element));
    });
    return iSprintCollection;
  }

  static convertFromObject(ob: any): iSprint {
    let iSprintObj = new iSprint();

    iSprintObj.id = ob.id;
    iSprintObj.nombre = ob.nombre;
    iSprintObj.fechaInicio = ob.fechaInicio;
    iSprintObj.fechaFin = ob.fechaFin;
    iSprintObj.finalizado = ob.finalizado;
    iSprintObj.validado = ob.validado;
    iSprintObj.activo = ob.activo;
    iSprintObj.proyectos = ob.proyectos;
    iSprintObj.departamentos = ob.departamentos;
    iSprintObj.lineasNegocio = ob.lineasNegocio;
    iSprintObj.rango = ob.rango
      ? iSprintPeriod.convertFromObject(ob.rango)
      : null;
    return iSprintObj;
  }

  static createEmptyObject(): iSprint {
    let iSprintObj = new iSprint();
    iSprintObj.id = null;
    iSprintObj.nombre = '';
    iSprintObj.fechaInicio = '';
    iSprintObj.fechaFin = '';
    iSprintObj.finalizado = false;
    iSprintObj.activo = false;
    iSprintObj.validado = false;
    iSprintObj.rango = null;
    iSprintObj.proyectos = [];
    iSprintObj.departamentos = [];
    iSprintObj.lineasNegocio = [];
    iSprintObj.rango = null;
    return iSprintObj;
  }
}
