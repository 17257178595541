<table mat-table [dataSource]="data">
  <ng-container matColumnDef="acciones">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <mat-checkbox (change)="addToSelected(element)"></mat-checkbox>
      </div>

    </td>
  </ng-container>
  <ng-container matColumnDef="codigo">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Código</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span class="bold">{{element.codigo ?? '-'}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Nombre</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span class="bold">{{element.nombre ?? '-'}}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="precioCompra">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Precio compra</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span>{{element.precioCompra | currency:"EUR"}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="precio">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Precio venta</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span>{{element.precio | currency:"EUR"}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="fiscalidad">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Fiscaldiad</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span *ngIf="!element.auxFiscalidad">-</span>
        <span *ngIf="element.auxFiscalidad">{{element.auxFiscalidad.nombre}} ({{element.auxFiscalidad.valor
          | number}}%)</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="cantidad">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer center">
        <label>Cantidad</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data center">
        <span>{{element.cantidad}}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="categorias">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Categorías</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data categories">
        <span class="category" *ngFor="let category of element.categorias | slice:0:1">
          {{$any(category).nombre}}
        </span>
        <span class="category" *ngIf="element.categorias.length > 1">
          +{{element.categorias.length - 1}}
        </span>
        <div class="categoryFolder" *ngIf=" element.categorias.length > 1">
          <div class="arrow"></div>
          <span *ngFor="let auxCategory of  element.categorias">{{auxCategory.nombre}}</span>
        </div>

      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns ">
  </tr>
</table>