import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CheckInService } from 'src/app/Services/Api/checkIn.service';
import { CheckinRegister } from 'src/app/Shared/Class/AccessRegister/checkin-register';

@Component({
  selector: 'app-edit-checkin-hours-popup',
  templateUrl: './edit-checkin-hours-popup.component.html',
  styleUrls: ['./edit-checkin-hours-popup.component.scss'],
})
export class EditCheckinHoursPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.modalData.edit ? this.closePopup() : this.forceClosePopup();
    }
  }
  checkinRegisterClass: CheckinRegister = new CheckinRegister();
  constructor(
    public dialogRef: MatDialogRef<EditCheckinHoursPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private staticUtilitiesSE: StaticUtilitiesService,
    private chekinSE: CheckInService
  ) {}

  ngOnInit(): void {
    this.modalData.edit ? this.setData() : null;
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * GETTERS & SETTERS
   */
  setData() {
    const { fechaInicio, fechaFin, id } = this.modalData.checkin;
    const startDateParsed: iDate = new iDate(fechaInicio);
    const endDateParsed: iDate = new iDate(fechaFin);

    this.checkinRegisterClass.editObj.id = id;
    this.checkinRegisterClass.editObj.startDate =
      startDateParsed.toStringDate('JAP');
    this.checkinRegisterClass.editObj.endDate =
      endDateParsed.toStringDate('JAP');
    this.checkinRegisterClass.editObj.startHour =
      startDateParsed.toStringHours();
    this.checkinRegisterClass.editObj.endHour = endDateParsed.toStringHours();
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.modalData.edit ? this.closePopup() : this.forceClosePopup();
      }
    });
  }
  updateDataHandler(element: string, value: any) {
    const obj: any = { id: this.checkinRegisterClass.editObj.id };
    obj[element] = value;
    this.update(obj);
  }

  /**
   * API RESPONSES
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.checkinRegisterClass.isLoading = false;
  }

  /**
   * CALLS TO API
   */
  update(obj) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    (this.checkinRegisterClass.isLoading = true),
      this.chekinSE.edit(behaviorSubject, obj);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successResponseHandler(res),
            error: false,
          },
          {
            method: () => (this.checkinRegisterClass.isLoading = false),
            error: true,
          },
        ]);
      });
  }
}
