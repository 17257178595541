export interface iFuturibleCategory {
  id?: number | null;
  nombre: string;
}

export class iFuturibleCategory {
  static convertFromCollection(ob: any): Array<iFuturibleCategory> {
    let iSelfDataCollection: Array<iFuturibleCategory> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iFuturibleCategory {
    let iSelfDataObj = new iFuturibleCategory();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.nombre = ob.nombre;

    return iSelfDataObj;
  }

  static createEmptyObject(): iFuturibleCategory {
    let iSelfDataObj = new iFuturibleCategory();
    iSelfDataObj.id = null;
    iSelfDataObj.nombre = '';
    return iSelfDataObj;
  }
}
