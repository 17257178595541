<div class="loginGeneralContainer">
  <div class="left">
    <form (submit)="recoveryClass.isDataCompleted ? recoveryPassword(): null">
      <div class="logo">
        <img src="/assets/Images/Logos/Fenech_V2-20.png" />
      </div>
      <div class="welcomeMessageContainer">
        <p class="title">Recuperación de contraseña</p>
        <p class="subtitle">Introduce la nueva contraseña</p>
      </div>
      <div class="inputs">
        <customInput [options]="recoveryClass.passwordOptions" [(ngModel)]="recoveryClass.newPassData.new_password"
          name="password" (input)="checkIfCompletedAndValidFormat()">
        </customInput>
      </div>
      <btn class="loginButton" [ngClass]="{'disabled': !recoveryClass.isDataCompleted}">
        <ng-container>
          <span>Cambiar contraseña</span>
        </ng-container>
      </btn>

    </form>
    <div class="footer">
      <img src="/assets/Images/Logos/logoQuasarDashboard.png" />
      <p>2023 Copyright © Atlas Venture Builder S.L. Valencia, España</p>
    </div>
  </div>
  <div class="right"></div>
</div>