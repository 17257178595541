import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PopupService,
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { ExpenseService } from 'src/app/Services/Api/expense.service';

@Component({
  selector: 'app-add-multiple-costs-revision-popup',
  templateUrl: './add-multiple-costs-revision-popup.component.html',
  styleUrls: ['./add-multiple-costs-revision-popup.component.scss'],
})
export class AddMultipleCostsRevisionPopupComponent implements OnInit {
  selectedCosts: Array<any> = [];
  isLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddMultipleCostsRevisionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private popupSE: PopupService,
    private expenseSE: ExpenseService
  ) {}

  ngOnInit(): void {}
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  removeSelectedCosts() {
    this.selectedCosts.forEach((element) => {
      this.modalData.costs.splice(this.modalData.costs.indexOf(element), 1);
    });
    StaticUtilitiesService.showFeedback(
      'Se han eliminado los costes seleccionados correctamente'
    );
    this.modalData.costs = [...this.modalData.costs];
    if (this.modalData.costs.length === 0) {
      this.forceClosePopup();
    }
  }
  generateCreateBulkData() {
    var auxBulkData: Array<any> = [];
    this.modalData.costs.forEach((cost) => {
      const newObj: any = JSON.parse(JSON.stringify(cost));
      newObj.proyecto = newObj.proyecto.id;
      newObj.familia = newObj.familia.id;
      newObj.proveedor = newObj.proveedor.id;
      auxBulkData.push(newObj);
    });
    return auxBulkData;
  }
  /**
   * GETTERS && SETTERS
   */
  getSelectedCosts(selectedCost: any) {
    this.selectedCosts = [...selectedCost];
  }

  /**
   * HANDLERS
   */
  successResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorResponseHandler() {
    this.isLoading = false;
  }
  successCreateResponseHandler(res) {
    StaticUtilitiesService.showFeedback(
      `Se han creado los ${this.modalData.costs.length} costes correctamente`
    );
    this.isLoading = false;
    setTimeout(() => {
      this.closePopup();
    }, 500);
  }
  errorCreateResponseHandler() {
    this.isLoading = false;
  }
  /**
   * CALLS TO API
   */
  create() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.expenseSE.createBulk(behaviorSubject, this.generateCreateBulkData());
    this.isLoading = true;
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: this.successCreateResponseHandler(res),
            error: false,
          },
          {
            method: this.errorCreateResponseHandler(),
            error: true,
          },
        ]);
      });
  }
}
