<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Edición rapida empleado</p>
  </div>
  <div class="body">
    <div class="imageContainer" fileInput [accept]="formatos" [(ngModel)]="file" (change)="getFile(file)"
      [ngClass]="{'placeholder': !modalData.profile}">
      <img [src]="modalData.profile" (error)="generateRandonImage($event.target)" alt="">
    </div>
    <p class="title">{{modalData.nombre}} {{modalData.apellidos}}</p>
    <div class="formComponents">
      <div class="columnDisplay">
        <div class="customFormContainer fullWidth">
          <label for="">Estado<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <selector *ngIf="update"
            [ngClass]="{'readOnly': isYourUser || isIntermedite && modalData.roles[0] === 'ROLE_ADMIN'}"
            [options]="employeesClass.stateOptions" [(ngModel)]="modalData.canAccess"
            (change)="updateEmployeeHandler('can_access',modalData.canAccess )"></selector>
        </div>

        <div class="customFormContainer">
          <label for="" title="Horas efectivamente trabajadas. Reducir en caso de jornada reducida">Horas semanales<sup
              [ngStyle]="{'color': 'red'}">*</sup></label>
          <customInput [options]="employeesClass.hoursOptions" [(ngModel)]="modalData.horas"
            (change)="checkIfQuickEditDataIsComplete('horas',modalData.horas)"></customInput>
        </div>

      </div>
      <div class="columnDisplay">

        <div class="customFormContainer">
          <label for="" title="Coste real. Reducir en caso de jornada reducida">Coste (€/hora)<sup
              [ngStyle]="{'color': 'red'}">*</sup></label>
          <customInput [options]="employeesClass.costOptions" [(ngModel)]="modalData.coste"
            (change)="checkIfQuickEditDataIsComplete('coste',modalData.coste)"></customInput>
        </div>
        <div class="toggle">
          <label for="">Tipo del coste<sup [ngStyle]="{'color': 'red'}">*</sup></label>
          <toggleSubmenu [classList]="'black'" [elements]="employeesClass.costType"
            [(ngModel)]="employeesClass.costTypeSelected"
            (change)="updateEmployeeHandler('tipo_coste',modalData.tipoCoste )">
          </toggleSubmenu>
        </div>
      </div>
      <div class="customFormContainer">
        <label for="">Departamento<sup [ngStyle]="{'color': 'red'}">*</sup></label>
        <selector [options]="employeesClass.departmentOptions" [(ngModel)]="employeesClass.selectedDepartments"
          (change)="checkIfQuickEditDataIsComplete('departamentos',employeesClass.selectedDepartments)">
        </selector>
      </div>

    </div>
  </div>
  <div class="footer">
    <btn (click)="goToEditEmployee()" class="action">
      <ng-container>
        <img *ngIf="!isLoading" [src]="'/assets/Icons/editEmployee.svg'" alt="">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Editar empleado</span>
      </ng-container>
    </btn>
  </div>
</div>