import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iVacationPeriod } from '../../Interfaces/Api/Vacations/iVacationPeriod';

export class VacationsPeriod {
  isCompleted: boolean = false;
  vacationPeriod: Array<iVacationPeriod> = [];
  vacationPeriodBackup: Array<iVacationPeriod> = [];
  isLoading: boolean = false;
  genericFilter: string = '';
  createEditObj: iVacationPeriod = {
    nombre: '',
    dias: null,
    tipo: null,
  };
  backUpCreateEditObj: iVacationPeriod = {
    nombre: '',
    dias: null,
    tipo: null,
  };
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  daysOptions: iOptionsCustomInput = {
    label: 'Días',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  typeOptions: iOptionsSelector = {
    items: ['Natural', 'Hábil'],
    placeholder: 'Selecciona una opción...',
    append: true,
    clearable: false,
    classList: 'specialFormSelector',
    search: true,
  };
}
