import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';

export class Recovery {
  /**
   * FORM VARIABLES
   */
  recoveryData: { email: string } = {
    email: '',
  };
  newPassData: { new_password: string; recover_token } = {
    new_password: '',
    recover_token: '',
  };
  passwordOptions: iOptionsCustomInput = {
    placeholder: 'Nueva contraseña',
    type: 'password',
  };
  /**
   * OTHER VARIABLES
   */
  isDataCompleted: boolean = false;
  isValidFormat: boolean = true;
  /**
   * INPUT OPTIONS
   */
  emailOptions: iOptionsCustomInput = {
    placeholder: 'Correo electronico',
    type: 'text',
  };
}
