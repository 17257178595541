import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  sha256,
} from '@quasar-dynamics/basic-designsystem';
import * as XLSX from 'xlsx';
import * as Diacritics from 'diacritics';
@Component({
  selector: 'app-manage-employee-excel',
  templateUrl: './manage-employee-excel.component.html',
  styleUrls: ['./manage-employee-excel.component.scss'],
})
export class ManageEmployeeExcelComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  formatos: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  file: any = null;
  users: Array<any> = [];
  isAdmin: boolean = false;
  isIntermedite: boolean = false;
  validKeys: Array<string> = [
    'nombre',
    'apellidos',
    'email',
    'horassemanales',
    'costeporhora',
    'costeanual',
    'tipodecoste',
    'tipodeusuario',
    'diasdevacaciones',
  ];
  constructor(
    public dialogRef: MatDialogRef<ManageEmployeeExcelComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
    this.checkUserRoleHandler();
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  checkUserRoleHandler() {
    this.isAdmin = localStorage.getItem('role') === sha256('ROLE_ADMIN');
    this.isIntermedite =
      localStorage.getItem('role') === sha256('ROLE_INTERMEDIO');
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  downloadTemplate() {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'href',
      '/assets/Documents/plantilla-empleados.xlsx'
    );
    downloadLink.setAttribute('download', 'plantilla-empleados');
    downloadLink.click();
  }
  /**
   * GETTERS & SETTERS
   */
  getFile(event: any) {
    let BreakException = {};
    try {
      event.forEach((element: any) => {
        if (!this.formatos.includes(element.file.type)) {
          StaticUtilitiesService.showError('El formato no es válido');
          this.file = null;
          throw BreakException;
        }
        if (element.file.size > 10485760) {
          StaticUtilitiesService.showError('El archivo es demasiado pesado');
          this.file = null;
          throw BreakException;
        }

        this.excelDataTreatmentHandler(element);
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }
  getDataExcel(data: any) {
    this.users = [];
    var hasError: boolean = false;
    data.forEach((element) => {
      const employee: any = {};
      Object.keys(element).forEach((key) => {
        const normalizedKey = Diacritics.remove(
          key.toLocaleLowerCase()
        ).replace(/\s/g, '');

        if (!this.checkIfKeysAreValid(normalizedKey)) {
          hasError = true;
          return;
        }

        employee[normalizedKey] = element[key];
      });
      if (!hasError) {
        const newEmployee: any = {
          nombre: employee.nombre,
          apellidos: employee.apellidos,
          horas: employee.horassemanales,
          username: employee.email,
          coste: employee.costeporhora,
          costeAnual: employee.costeanual,
          tipoCoste: employee.tipodecoste,
          roles: [],
          diasVacaciones: employee.diasdevacaciones,
        };
        switch (employee.tipodeusuario.toLocaleLowerCase()) {
          case 'administrador':
            newEmployee.roles = ['ROLE_ADMIN'];
            break;
          case 'intermedio':
            newEmployee.roles = ['ROLE_INTERMEDIO'];
            break;
          case 'gestor':
            newEmployee.roles = ['ROLE_GESTOR'];
            break;
          case 'empleado':
            newEmployee.roles = ['ROLE_USER'];
            break;
          default:
            newEmployee.roles = ['ROLE_USER'];
            break;
        }
        if (
          newEmployee.roles.includes('ROLE_INTERMEDIO') ||
          newEmployee.roles.includes('ROLE_ADMIN')
        ) {
          if (!this.isAdmin && !this.isIntermedite) {
            StaticUtilitiesService.showError(
              'El empleado introducido tiene un rol que el usuario actual no puede gestionar.'
            );
          } else {
            this.users.push(newEmployee);
          }
        } else {
          this.users.push(newEmployee);
        }
      }
    });
    if (hasError) {
      StaticUtilitiesService.showError(
        'El formato del fichero no es válido.',
        'Algunas de las columnas en el archivo Excel cargado no son válidas.'
      );
      return;
    }
    this.closePopup(this.users);
  }
  /**
   * HANDLERS
   */
  checkIfKeysAreValid(normalizedKey: string) {
    return this.validKeys.includes(normalizedKey);
  }
  excelDataTreatmentHandler(element) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(element.file);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      this.getDataExcel(data);
    };
  }
}
