import { iSimpleEmployee } from '../Employee/iSimpleEmployee';

export interface iHoliday {
  id: number | null;
  solicitud: boolean;
  aprobada: boolean;
  diasSolicitados: number;
  diasRestantes: number;
  empleado: iSimpleEmployee;
  fechaFin: string;
  fechaIni: string;
}

export class iHoliday {
  static convertFromCollection(ob: any): Array<iHoliday> {
    let iSelfDataCollection: Array<iHoliday> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iHoliday {
    let iSelfDataObj = new iHoliday();
    iSelfDataObj.id = ob.id;
    iSelfDataObj.solicitud = ob.solicitud;
    iSelfDataObj.aprobada = ob.aprobada;
    iSelfDataObj.diasSolicitados = ob.diasSolicitados;
    iSelfDataObj.diasRestantes = ob.diasRestantes;
    iSelfDataObj.fechaFin = ob.fechaFin;
    iSelfDataObj.fechaIni = ob.fechaIni;
    iSelfDataObj.empleado = iSimpleEmployee.convertFromObject(ob.empleado);

    return iSelfDataObj;
  }
}
