import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iCreateDaily } from 'src/app/Shared/Interfaces/Api/Daily/iCreateDaily';
import {
  iDaily,
  iDailyFilter,
} from 'src/app/Shared/Interfaces/Api/Daily/iDaily';
import { iDailyRegister } from 'src/app/Shared/Interfaces/Api/Daily/iDailyRegister';
import { iDailyRegistryFilter } from 'src/app/Shared/Interfaces/Api/Daily/iDailyRegistryFilter';
import { iDailyStatistics } from 'src/app/Shared/Interfaces/Api/Daily/iDailyStatistics';
import { iNotValidatedDaily } from 'src/app/Shared/Interfaces/Api/Daily/iNotValidatedDaily';
import { iUnassignedDaily } from 'src/app/Shared/Interfaces/Api/Daily/iUnassignedDaily';
import { iUnassignedDailyFilter } from 'src/app/Shared/Interfaces/Api/Daily/iUnassignedDailyFilter';
import { iUserThatNotHasSendDaily } from 'src/app/Shared/Interfaces/Api/User/iUserThatNotHasSendDaily';

@Injectable({
  providedIn: 'root',
})
export class DailyService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getDailyRegister(
    behaviorSubject: BehaviorSubject<any>,
    filter: iDailyRegistryFilter
  ) {
    this.http
      .get(
        this.urlApi + '/api/daily/register/list' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iDailyRegister.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getDailyRegister,
            args: [behaviorSubject, filter],
          });
        },
      });
  }

  getDailyRegisterExcel(
    behaviorSubject: BehaviorSubject<any>,
    filter: iDailyRegistryFilter
  ) {
    this.http
      .get(
        this.urlApi + '/api/daily/register/list' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iDailyRegister.convertFromCollection(
              iStaticUtilities.normalizeNamesCollection(data)
            ),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getDailyRegisterExcel,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  getSelectedEmployeeDaily(
    behaviorSubject: BehaviorSubject<any>,
    filter: iDailyFilter
  ) {
    this.http
      .get(
        this.urlApi + '/api/daily' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              data: iDaily.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
              total: data.total,
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getSelectedEmployeeDaily,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  getNotValidateDailyUsers(
    behaviorSubject: BehaviorSubject<any>,
    filter: iDailyStatistics
  ) {
    this.http
      .get(
        this.urlApi + '/api/daily/notValidated/all' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iUserThatNotHasSendDaily.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getNotValidateDaily,
            args: [behaviorSubject],
          });
        },
      });
  }
  getNotValidateDaily(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/daily/notValidated', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iNotValidatedDaily.convertFromCollection(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getNotValidateDaily,
            args: [behaviorSubject],
          });
        },
      });
  }
  getDailyiesByCategories(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .get(this.urlApi + '/api/daily/categoria/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getDailyiesByCategories,
            args: [behaviorSubject],
          });
        },
      });
  }
  getDailyiesStadistics(
    behaviorSubject: BehaviorSubject<any>,
    filter: iDailyStatistics
  ) {
    this.http
      .get(
        this.urlApi + '/api/daily/estadisticas' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getDailyiesStadistics,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  getAbsoluteDailyiesStadistics(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(this.urlApi + '/api/daily/statistic/absolute', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAbsoluteDailyiesStadistics,
            args: [behaviorSubject],
          });
        },
      });
  }
  getDailyiesStadisticsByUser(
    behaviorSubject: BehaviorSubject<any>,
    filter: iDailyStatistics,
    id: number
  ) {
    this.http
      .get(
        this.urlApi + '/api/daily/estadisticas/' + id + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: data,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getDailyiesStadistics,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  validateDaily(behaviorSubject: BehaviorSubject<any>, daily: any) {
    this.http
      .post(
        this.urlApi + '/api/daily/' + daily.id + '/validation',
        daily,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.validateDaily,
            args: [behaviorSubject, daily],
          });
        },
      });
  }
  duplicate(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(
        this.urlApi + '/api/duplicate/daily',
        { id: data.id, fecha: data.fecha },
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.validateDaily,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  create(
    behaviorSubject: BehaviorSubject<any>,
    newDaily: iCreateDaily,
    preview: boolean = false
  ) {
    var url = '';
    preview
      ? (url =
          this.urlApi + '/api/daily' + this.optionsGet({ preview: preview }))
      : (url = this.urlApi + '/api/daily');
    this.http.post(url, newDaily, this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: data.message,
          data: null,
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.create,
          args: [behaviorSubject, newDaily],
        });
      },
    });
  }
  createRange(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(this.urlApi + '/api/daily/range', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.createRange,
            args: [behaviorSubject, data],
          });
        },
      });
  }

  update(behaviorSubject: BehaviorSubject<any>, daily: any) {
    this.http
      .put(this.urlApi + '/api/daily/' + daily.id, daily, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, daily],
          });
        },
      });
  }
  updateRange(behaviorSubject: BehaviorSubject<any>, daily: any) {
    this.http
      .put(
        this.urlApi + '/api/daily/range/' + daily.id,
        daily,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updateRange,
            args: [behaviorSubject, daily],
          });
        },
      });
  }

  updateHours(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .put(this.urlApi + '/api/daily/horas/' + id, {}, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.updateHours,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(this.urlApi + '/api/daily', this.sendBodyOptions({ id: id }))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id],
          });
        },
      });
  }
  deleteRange(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .delete(
        this.urlApi + '/api/daily/range',
        this.sendBodyOptions({ codigo: data.codigo })
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.deleteRange,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  deleteDraft(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(this.urlApi + '/api/sprint/tarea/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.deleteDraft,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
