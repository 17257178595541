import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iCreateEditCategory } from 'src/app/Shared/Interfaces/Api/Category/iCreateEditCategory';
import { iCategoryProduct } from 'src/app/Shared/Interfaces/Api/Products/iCategoryProduct';
import { iCreateEditCategoryProduct } from 'src/app/Shared/Interfaces/Api/Products/iCreateEditCategoryProduct';

@Injectable({
  providedIn: 'root',
})
export class CategoryProductService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }

  getAll(behaviorSubject: BehaviorSubject<any>) {
    this.http.get(this.urlApi + '/api/categoria', this.getHeader()).subscribe({
      next: (data: any) => {
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: iCategoryProduct.convertFromCollection(
            iStaticUtilities.normalizeNamesCollection(data)
          ),
        });
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.getAll,
          args: [behaviorSubject],
        });
      },
    });
  }
  create(
    behaviorSubject: BehaviorSubject<any>,
    data: iCreateEditCategoryProduct
  ) {
    this.http
      .post(this.urlApi + '/api/categoria', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha creado la categoría correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  update(
    behaviorSubject: BehaviorSubject<any>,
    data: iCreateEditCategoryProduct
  ) {
    this.http
      .put(this.urlApi + '/api/categoria/' + data.id, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha editado la categoría correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }
}
