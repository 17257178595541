<div class="checkinGeneralContainer">
  <div class="header">
    <div class="icon">
      <mat-icon class="material-icons-outlined">
        schedule
      </mat-icon>
    </div>
  </div>
  <p class="title">Registra tu tiempo de trabajo</p>
  <p class="subtitle"> {{ today| date: "EEEE" | titlecase }}, {{ today| date:
    "d \'d\'e MMMM \'d\'e y" }}</p>
  <ng-container *ngIf="lastCheckin">
    <p class="counter">{{timeCounter()}}</p>
    <div class="auxCounter">
      <p>H</p>
      <p>M</p>
      <p>S</p>
    </div>
  </ng-container>
  <div class="buttonsContainer">
    <btn class="secondary" *ngIf="lastCheckin && !lastCheckin?.finalizado" (click)="finalice()">
      <ng-container>
        <mat-icon class="material-icons-outlined">
          stop_circle
        </mat-icon>
        <span>Finalizar jornada laboral</span>
      </ng-container>
    </btn>
    <btn class="action" *ngIf="lastCheckin  && lastCheckin?.finalizado" (click)="reanudate()">
      <ng-container>
        <mat-icon class="material-icons-outlined" *ngIf="!isLoading">
          play_circle
        </mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Reanudar jornada laboral</span>
      </ng-container>
    </btn>

    <btn class="action" *ngIf="lastCheckin  && !lastCheckin?.finalizado && lastCheckin.pausado" (click)="reanudate()">
      <ng-container>
        <mat-icon class="material-icons-outlined" *ngIf="!isLoading">
          play_circle
        </mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Volver al trabajo</span>
      </ng-container>
    </btn>
    <btn class="action" *ngIf="lastCheckin  && !lastCheckin?.finalizado && !lastCheckin.pausado" (click)="pause()">
      <ng-container>
        <mat-icon class="material-icons-outlined" *ngIf="!isLoading">
          pause_circle
        </mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Tomar un descanso</span>
      </ng-container>
    </btn>
    <btn class="action" *ngIf="!lastCheckin " (click)="create()">
      <ng-container>
        <mat-icon class="material-icons-outlined" *ngIf="!isLoading">
          play_circle
        </mat-icon>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span>Iniciar jornada laboral</span>
      </ng-container>
    </btn>

  </div>
</div>