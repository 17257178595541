export interface iPeriod {
  cortoPlazo: number | null;
  medioPlazo: number | null;
  largoPlazo: number | null;
}

export class iPeriod {
  static convertFromCollection(ob: any): Array<iPeriod> {
    let iPeriodCollection: Array<iPeriod> = [];
    ob.forEach((element: any) => {
      iPeriodCollection.push(this.convertFromObject(element));
    });
    return iPeriodCollection;
  }

  static convertFromObject(ob: any): iPeriod {
    let iPeriodObj = new iPeriod();
    iPeriodObj.cortoPlazo = ob.cortoPlazo;
    iPeriodObj.medioPlazo = ob.medioPlazo;
    iPeriodObj.largoPlazo = ob.largoPlazo;
    return iPeriodObj;
  }

  static createEmptyObject(): iPeriod {
    let iPeriodObj = new iPeriod();
    iPeriodObj.cortoPlazo = null;
    iPeriodObj.medioPlazo = null;
    iPeriodObj.largoPlazo = null;
    return iPeriodObj;
  }
}
