import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iNewContract } from 'src/app/Shared/Interfaces/Api/Contract/iNewContract';

@Injectable({
  providedIn: 'root',
})
export class ContractService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  create(behaviorSubject: BehaviorSubject<any>, data: iNewContract) {
    this.http
      .post(this.urlApi + '/api/contrato', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Contrato asignado al empleado correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(this.urlApi + '/api/contrato/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Contrato eliminado correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
