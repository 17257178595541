import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import * as Diacritics from 'diacritics';
import { StaticUtilitiesService } from '@quasar-dynamics/basic-designsystem';
@Component({
  selector: 'app-manage-suppliers-excel-popup',
  templateUrl: './manage-suppliers-excel-popup.component.html',
  styleUrls: ['./manage-suppliers-excel-popup.component.scss'],
})
export class ManageSuppliersExcelPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  formatos: Array<string> = [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  file: any = null;
  newSuppliers: any = {
    proveedores: [],
  };

  validKeys: Array<string> = [
    'nombre',
    'razon social',
    'cif',
    'contacto',
    'servicio',
  ];
  constructor(
    public dialogRef: MatDialogRef<ManageSuppliersExcelPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  excelDataTreatmentHandler(element) {
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(element.file);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      this.getDataExcel(data);
    };
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  downloadTemplate() {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'href',
      '/assets/Documents/plantilla-proveedores.xlsx'
    );
    downloadLink.setAttribute('download', 'plantilla-proveedores');
    downloadLink.click();
  }
  /**
   * GETTERS & SETTERS
   */
  getFile(event: any) {
    let BreakException = {};
    try {
      event.forEach((element: any) => {
        if (!this.formatos.includes(element.file.type)) {
          StaticUtilitiesService.showError('El formato no es válido');
          this.file = null;
          throw BreakException;
        }
        if (element.file.size > 10485760) {
          StaticUtilitiesService.showError('El archivo es demasiado pesado');
          this.file = null;
          throw BreakException;
        }

        this.excelDataTreatmentHandler(element);
      });
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  }
  getDataExcel(data: any) {
    this.newSuppliers.proveedores = [];
    var hasError: boolean = false;
    data.forEach((element) => {
      const proveedor: any = {};
      Object.keys(element).forEach((key) => {
        const normalizedKey = Diacritics.remove(key.toLocaleLowerCase());
        if (!this.checkIfKeysAreValid(normalizedKey)) {
          hasError = true;
          return;
        }

        proveedor[normalizedKey] = element[key];
      });
      if (!hasError) {
        this.newSuppliers.proveedores.push(proveedor);
      }
    });
    if (hasError) {
      StaticUtilitiesService.showError(
        'El formato del fichero no es válido.',
        'Algunas de las columnas en el archivo Excel cargado no son válidas.'
      );
      return;
    }
    this.closePopup(this.newSuppliers);
  }

  /**
   * HANDLERS
   */
  checkIfKeysAreValid(normalizedKey: string) {
    return this.validKeys.includes(normalizedKey);
  }
}
