import { iSimpleProject } from '../Project/iSimpleProject';
import { iSupplier } from '../Suppliers/iSupplier';

export interface iExpenseDetail {
  id: number | null;
  concepto: string;
  cantidad: number | null;
  familia: { nombre: string };
  proyecto: iSimpleProject;
  iva: number | null;
  tipo: number | null;
  directo: boolean;
  numeroFactura: string;
  fecha: string;
  estado: number | null;
  proveedor: iSupplier | null;
}

export class iExpenseDetail {
  static convertFromCollection(ob: any): Array<iExpenseDetail> {
    let iExpenseDetailCollection: Array<iExpenseDetail> = [];
    ob.forEach((element: any) => {
      iExpenseDetailCollection.push(this.convertFromObject(element));
    });
    return iExpenseDetailCollection;
  }

  static convertFromObject(ob: any): iExpenseDetail {
    let iExpenseDetailObj = new iExpenseDetail();
    iExpenseDetailObj.id = ob.id;
    iExpenseDetailObj.concepto = ob.concepto;
    iExpenseDetailObj.cantidad = ob.cantidad;
    iExpenseDetailObj.familia = ob.familia;
    iExpenseDetailObj.proyecto = ob.proyecto
      ? iSimpleProject.convertFromObject(ob.proyecto)
      : iSimpleProject.createEmptyObject();
    iExpenseDetailObj.proveedor = ob.proveedor
      ? iSupplier.convertFromObject(ob.proveedor)
      : null;
    iExpenseDetailObj.iva = ob.iva;
    iExpenseDetailObj.tipo = ob.tipo;
    iExpenseDetailObj.directo = ob.directo;
    iExpenseDetailObj.numeroFactura = ob.numeroFactura;
    iExpenseDetailObj.fecha = ob.fecha;
    iExpenseDetailObj.estado = ob.estado;
    return iExpenseDetailObj;
  }

  static createEmptyObject(): iExpenseDetail {
    let iExpenseDetailObj = new iExpenseDetail();
    iExpenseDetailObj.id = null;
    iExpenseDetailObj.concepto = '';
    iExpenseDetailObj.cantidad = null;
    iExpenseDetailObj.familia = { nombre: '' };
    iExpenseDetailObj.proyecto = iSimpleProject.createEmptyObject();
    iExpenseDetailObj.iva = null;
    iExpenseDetailObj.tipo = null;
    iExpenseDetailObj.directo = false;
    iExpenseDetailObj.numeroFactura = '';
    iExpenseDetailObj.fecha = '';
    iExpenseDetailObj.estado = null;
    return iExpenseDetailObj;
  }
}
