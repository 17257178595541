import {
  IOptionCustomTextArea,
  StaticUtilitiesService,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iDaily } from '../../Interfaces/Api/Daily/iDaily';
import { iDailyCategory } from '../../Interfaces/Api/Category/iDailyCategory';

export class CompleteDaily {
  elements: Array<string> = ['No completado', 'Completado'];
  optionSubmenuSelected: number = 0;
  backupPercentaje: number = 0;
  selectedDaily: iDaily = iDaily.createEmptyObject();
  backupSelectedDaily: iDaily = iDaily.createEmptyObject();
  isDataCompleted: boolean = false;
  dateOption: iOptionsCustomInput = {
    type: 'date',
    placeholder: 'Selecciona una fecha...',
    label: 'Fecha',
    classList: 'specialFormInput',
  };
  categoriesOption: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    append: true,
    bindLabel: 'nombre',
    classList: 'specialFormSelector',
    clearable: false,
    search: true,
  };
  reasonOption: IOptionCustomTextArea = {
    label: 'Comentario',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
    rows: 6,
    cols: 0,
  };
  constructor() {}
  /**
   * FUNCTIONALITIES
   */
  updateOptionSelected() {
    switch (this.optionSubmenuSelected) {
      case 0:
        this.backupSelectedDaily.porcentaje = this.backupPercentaje;
        this.backupSelectedDaily.completada = false;
        break;
      case 1:
        this.backupSelectedDaily.porcentaje = 100;
        this.backupSelectedDaily.completada = true;
        break;
    }
  }
  /**
   * VALIDATIONS
   */
  checkIfIsValidPercentaje() {
    if (this.backupSelectedDaily.porcentaje! > 100) {
      this.backupSelectedDaily.porcentaje = 100;
      this.backupPercentaje = this.backupSelectedDaily.porcentaje;

      StaticUtilitiesService.showError(
        'El porcentaje de progreso no puede superar el 100%'
      );
      return;
    }
  }
  checkIfIsDataCompleted() {
    this.isDataCompleted =
      this.backupSelectedDaily.completada ||
      (this.backupSelectedDaily.porcentaje != 100 &&
        this.backupSelectedDaily.categoria.id != null);
  }

  /**
   * GETTERS & SETTERS
   */

  generateUpdateDailyObject() {
    var newUpdateDailyObj: any = null;
    if (this.backupSelectedDaily.completada) {
      newUpdateDailyObj = {
        id: this.backupSelectedDaily.id,
        porcentaje: this.backupSelectedDaily.porcentaje,
        completada: this.backupSelectedDaily.completada,
        enviado: true,
      };
    } else {
      newUpdateDailyObj = {
        id: this.backupSelectedDaily.id,
        porcentaje: this.backupSelectedDaily.porcentaje,
        completada: this.backupSelectedDaily.completada,
        motivo: this.backupSelectedDaily.motivo,
        categoria: this.backupSelectedDaily.categoria.id,
        enviado: true,
      };
    }
    return newUpdateDailyObj;
  }
}
