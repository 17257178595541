export interface iSprintPeriod {
  id?: number | null;
  nombre: string;
  rango: number | null;
}

export class iSprintPeriod {
  static convertFromCollection(ob: any): Array<iSprintPeriod> {
    let iSprintPeriodCollection: Array<iSprintPeriod> = [];
    ob.forEach((element: any) => {
      iSprintPeriodCollection.push(this.convertFromObject(element));
    });
    return iSprintPeriodCollection;
  }

  static convertFromObject(ob: any): iSprintPeriod {
    let iSprintPeriodObj = new iSprintPeriod();
    iSprintPeriodObj.id = ob.id;
    iSprintPeriodObj.nombre = ob.nombre;
    iSprintPeriodObj.rango = ob.rango;
    return iSprintPeriodObj;
  }

  static createEmptyObject(): iSprintPeriod {
    let iSprintPeriodObj = new iSprintPeriod();
    iSprintPeriodObj.id = null;
    iSprintPeriodObj.nombre = '';
    iSprintPeriodObj.rango = null;
    return iSprintPeriodObj;
  }
}
