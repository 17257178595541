import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iOptionsSelector,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { TemplateService } from 'src/app/Services/Api/template.service';

@Component({
  selector: 'app-select-template-option-popup',
  templateUrl: './select-template-option-popup.component.html',
  styleUrls: ['./select-template-option-popup.component.scss'],
})
export class SelectTemplateOptionPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  isTemplate: boolean = false;
  templateOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción',
    append: true,
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
  templateSelected: number | null = null;
  constructor(
    public dialogRef: MatDialogRef<SelectTemplateOptionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private templateSE: TemplateService
  ) {}

  ngOnInit(): void {
    this.getAll();
    this.clickOutClosePopupHandler();
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  successGetAllResponseHandler(res: iResultHttp) {
    this.templateOptions.items = [...res.data];
  }

  /**
   * CALLS TO API
   */
  getAll() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.templateSE.getAll(behaviorSubject);
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, {
          method: () => this.successGetAllResponseHandler(res),
          error: false,
        });
      });
  }
}
