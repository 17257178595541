import { iDailyCategory } from '../Category/iDailyCategory';
import { iDailyProcess } from '../Process/iDailyProcess';
import { iDailyProject } from '../Project/iDailyproject';
import { iDailyUser } from '../User/iDailyUser';

export interface iDaily {
  id: number | null;
  usuario: iDailyUser;
  fecha: string;
  completada: boolean;
  porcentaje: number | null;
  motivo: string;
  nombreTarea: string;
  validado: boolean;
  categoria: iDailyCategory;
  proyecto: iDailyProject;
  idClockify: Array<string>;
  proceso: iDailyProcess | null;
  subProceso: { nombre: string; proceso: iDailyProcess } | null;
  horas: number | null;
  avanceTeorico: number | null;
  horasEstimadas: number | null;
  enviado: boolean;
  codigo?: string;
}

export interface iDailyFilter {
  num_devoluciones: number;
  num_pagina: number;
  fecha?: string;
  usuario?: number;
  total?: number;
  estado?: number | null;
  categoria?: number | null;
  proyecto?: number | null;
  fechaIni?: string;
  fechaFin?: string;
}

export class iDaily {
  static convertFromCollection(ob: any): Array<iDaily> {
    let iDailyCollection: Array<iDaily> = [];
    ob.forEach((element: any) => {
      iDailyCollection.push(this.convertFromObject(element));
    });
    return iDailyCollection;
  }

  static convertFromObject(ob: any): iDaily {
    let iDailyObj = new iDaily();

    iDailyObj.id = ob.id;
    iDailyObj.usuario = ob.usuario ?? iDailyUser.createEmptyObject();
    iDailyObj.fecha = ob.fecha;
    iDailyObj.completada = ob.completada;
    iDailyObj.porcentaje = ob.porcentaje;
    iDailyObj.motivo = ob.motivo;
    iDailyObj.nombreTarea = ob.nombreTarea;
    iDailyObj.validado = ob.validado;
    iDailyObj.categoria = ob.categoria;
    iDailyObj.proyecto = ob.proyecto ?? iDailyProject.createEmptyObject();
    iDailyObj.idClockify = ob.idClockify;
    iDailyObj.proceso = ob.proceso ?? null;
    iDailyObj.subProceso = ob.subProceso ?? null;
    iDailyObj.horas = ob.horas;
    iDailyObj.avanceTeorico = ob.avanceTeorico;
    iDailyObj.horasEstimadas = ob.horasEstimadas;
    iDailyObj.enviado = ob.enviado;
    iDailyObj.codigo = ob.codigo;
    return iDailyObj;
  }

  static createEmptyObject(): iDaily {
    let iDailyObj = new iDaily();
    iDailyObj.id = null;
    iDailyObj.usuario = iDailyUser.createEmptyObject();
    iDailyObj.fecha = '';
    iDailyObj.completada = false;
    iDailyObj.porcentaje = 0;
    iDailyObj.motivo = '';
    iDailyObj.nombreTarea = '';
    iDailyObj.validado = false;
    iDailyObj.categoria = iDailyCategory.createEmptyObject();
    iDailyObj.proyecto = iDailyProject.createEmptyObject();
    iDailyObj.idClockify = [];
    iDailyObj.proceso = null;
    iDailyObj.subProceso = null;
    iDailyObj.horas = null;
    iDailyObj.avanceTeorico = 0;
    iDailyObj.enviado = false;
    iDailyObj.horasEstimadas = null;
    return iDailyObj;
  }
}
