import { iOptionsCustomInput } from '@quasar-dynamics/basic-designsystem';
import { iCreateEditClient } from '../../Interfaces/Api/Client/iCreateEditClient';

export class CreateEditClient {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  nameOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    required: true,
    label: 'Nombre',
    classList: 'specialFormInput',
  };
  cifOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'CIF',
    classList: 'specialFormInput',
  };
  emailOptions: iOptionsCustomInput = {
    type: 'email',
    placeholder: 'Escribe aquí...',
    label: 'Email',
    classList: 'specialFormInput',
  };
  phoneOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Teléfono',
    classList: 'specialFormInput',
  };
  fiscalAddressOptions: iOptionsCustomInput = {
    type: 'text',
    placeholder: 'Escribe aquí...',
    label: 'Dirección',
    classList: 'specialFormInput',
  };
  createEditClientObj: any = {
    clientes: [
      {
        nombre: '',
        cif: '',
        mail: '',
        telefono: '',
        direccion_fiscal: '',
      },
    ],
  };
  backupCreateEditClientObj: any = {
    clientes: [
      {
        nombre: '',
        cif: '',
        mail: '',
        telefono: '',
        direccion_fiscal: '',
      },
    ],
  };
}
