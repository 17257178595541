import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-ubication-popup',
  templateUrl: './view-ubication-popup.component.html',
  styleUrls: ['./view-ubication-popup.component.scss'],
})
export class ViewUbicationPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }

  zoom = 25;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    maxZoom: 25,
    minZoom: 5,
  };
  marker: any = {
    position: {
      lat: Number(this.modalData.latitud),
      lng: Number(this.modalData.longitud),
    },
  };
  constructor(
    public dialogRef: MatDialogRef<ViewUbicationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    this.center = {
      lat: Number(this.modalData.latitud),
      lng: Number(this.modalData.longitud),
    };
  }

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
}
