<div class="popupGeneralContainer fullSize">
  <div class="header">
    <p>Crear múltiples clientes</p>
  </div>
  <div class="buttonsContainer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon class="material-icons-outlined">close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <div class="actions">
      <btn class="action" [ngClass]="{'disabled': selectedClients.length === 0}" (click)="removeSelectedClients()">
        <ng-container>
          <mat-icon class="material-icons-outlined">delete</mat-icon>
          <span>Eliminar seleccionados</span>
        </ng-container>
      </btn>
      <btn class="action" (click)="create()">
        <ng-container>
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <mat-icon class="material-icons-outlined" *ngIf="!isLoading">add_circle_outline</mat-icon>
          <span>Añadir los clientes</span>
        </ng-container>
      </btn>
    </div>
  </div>
  <clientsTableRevision [data]="modalData.clients" (update)="getSelectedClients($event)">
  </clientsTableRevision>
</div>