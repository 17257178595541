import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopupService } from '@quasar-dynamics/basic-designsystem';

@Component({
  selector: 'productListTableRevision',
  templateUrl: './product-list-table-revision.component.html',
  styleUrls: ['./product-list-table-revision.component.scss'],
})
export class ProductListTableRevisionComponent implements OnInit {
  @Input() data: Array<any> = [];
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  productSelected: Array<any> = [];
  displayedColumns: string[] = [
    'acciones',
    'codigo',
    'nombre',
    'precioCompra',
    'precio',
    'fiscalidad',
    'cantidad',
    'categorias',
  ];
  constructor(private popupSE: PopupService) {}

  ngOnInit(): void {}

  addToSelected(product: any) {
    if (this.productSelected.includes(product)) {
      this.productSelected.splice(this.productSelected.indexOf(product), 1);
    } else {
      this.productSelected.push(product);
    }
    this.update.emit(this.productSelected);
  }
}
