<div class="popupGeneralContainer">
  <div class="header">
    <p>Añadir clientes desde excel</p>
  </div>
  <div class="body">
    <div class="optionContainer">
      <div class="option" fileInput [accept]="formatos" [(ngModel)]="file" (change)="getFile(file)">
        <mat-icon class="material-icons-outlined">
          add_circle_outline
        </mat-icon>
        <p>Seleccionar fichero</p>
      </div>
      <div class="option" (click)="downloadTemplate()">
        <mat-icon class="material-icons-outlined">
          file_download
        </mat-icon>
        <p>Descargar plantilla</p>
      </div>
    </div>

    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
  </div>

</div>