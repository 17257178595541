import {
  IOptionCustomTextArea,
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iBussinessLine } from '../../Interfaces/Api/BussinesLine/iBussinessLine';
import { iBussinessLineFilter } from '../../Interfaces/Api/BussinesLine/iBussinessLineFilter';

export class BussinessLine {
  bussinessLineFilter: iBussinessLineFilter = {
    num_pagina: 1,
    num_devoluciones: 10,
  };
  bussinessLine: Array<iBussinessLine> = [];
  filterOpened: boolean = false;
  isCompleted: boolean = false;
  isLoading: boolean = false;
  bussinessLineCategoryOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opcion...',
    clearable: true,
    append: true,
    label: 'Categoría',
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };

  /**
   * CREATE EDIT BUSSINESS LINE
   */
  createEditObj: iBussinessLine = {
    nombre: '',
    descripcion: '',
    categorias: [],
    proyectos: [],
  };
  backupCreateEditObj: iBussinessLine = {
    nombre: '',
    descripcion: '',
    categorias: [],
    proyectos: [],
  };
  nombreOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  descriptionOptions: IOptionCustomTextArea = {
    label: 'Descripción',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: false,
    rows: 6,
    cols: 0,
  };
  proyectsOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opcion...',
    clearable: true,
    append: true,
    label: 'Proyectos',
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
    multiple: {
      isMultiple: true,
    },
  };
  bussinessLineCategoriesOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opcion...',
    clearable: true,
    append: true,
    label: 'Categorías',
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
    multiple: {
      isMultiple: true,
    },
  };
}
