import { iHistoricalAdvances } from '../Historic/iHistoricalAdvances';
import { iHistoricalBudget } from '../Historic/iHistoricalBudget';
import { iHistoricalCost } from '../Historic/iHistoricalCost';
import { iHistoricalMargins } from '../Historic/iHistoricalMargins';

export interface iSingleProjectHistoric {
  historicoAvances: Array<iHistoricalAdvances>;
  historicoMargenes: Array<iHistoricalMargins>;
  historicoPresupuesto: Array<iHistoricalBudget>;
  historicoCostes: Array<iHistoricalCost>;
}

export class iSingleProjectHistoric {
  static convertFromCollection(ob: any): Array<iSingleProjectHistoric> {
    let iSelfDataCollection: Array<iSingleProjectHistoric> = [];
    ob.forEach((element: any) => {
      iSelfDataCollection.push(this.convertFromObject(element));
    });
    return iSelfDataCollection;
  }

  static convertFromObject(ob: any): iSingleProjectHistoric {
    let iSelfDataObj = new iSingleProjectHistoric();
    iSelfDataObj.historicoAvances = iHistoricalAdvances.convertFromCollection(
      ob.historicoAvances
    );
    iSelfDataObj.historicoMargenes = iHistoricalMargins.convertFromCollection(
      ob.historicoMargenes
    );
    iSelfDataObj.historicoPresupuesto = iHistoricalBudget.convertFromCollection(
      ob.historicoPresupuestos
    );
    iSelfDataObj.historicoCostes = iHistoricalCost.convertFromCollection(
      ob.historicoCostes
    );

    return iSelfDataObj;
  }

  static createEmptyObject(): iSingleProjectHistoric {
    let iSelfDataObj = new iSingleProjectHistoric();
    iSelfDataObj.historicoAvances = [];
    iSelfDataObj.historicoMargenes = [];
    iSelfDataObj.historicoPresupuesto = [];
    iSelfDataObj.historicoCostes = [];
    return iSelfDataObj;
  }
}
