<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p>Generar factura</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="createInvoiceClass.emisionDateOptions"
          (change)="getDate($event); checkIfDataIsCompleteHandler()"></customInput>
        <customInput [options]="createInvoiceClass.invoiceNumberOptions"
          [(ngModel)]="createInvoiceClass.newInvoiceObj.numero" (change)="checkIfDataIsCompleteHandler()"></customInput>
      </div>
      <div class="columnDisplay">
        <selector [options]="createInvoiceClass.taxationsOptions" [(ngModel)]="createInvoiceClass.newInvoiceObj.iva"
          (change)="checkIfDataIsCompleteHandler()"></selector>
        <customInput [options]="createInvoiceClass.irpfOptions" [(ngModel)]="createInvoiceClass.newInvoiceObj.irpf"
          (change)="checkIfDataIsCompleteHandler()">
        </customInput>
      </div>

    </div>

  </div>
  <div class="footer">
    <btn class="secondary" (click)="forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !createInvoiceClass.isCompleted}"
      (click)="validateIfDataIsCorrectHandler()">
      <ng-container>
        <mat-icon *ngIf="!createInvoiceClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="createInvoiceClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>