<div class="loginGeneralContainer">
  <div class="left">
    <form (submit)="recoveryClass.isDataCompleted && recoveryClass.isValidFormat ? sendMailRecovery(): null">
      <div class="logo">
        <img src="/assets/Images/Logos/Fenech_V2-20.png" />
      </div>
      <div class="welcomeMessageContainer">
        <p class="title">Recuperación de contraseña</p>
        <p class="subtitle">Introduce tus datos para recuperar tu cuenta</p>
      </div>
      <div class="inputs">
        <customInput [options]="recoveryClass.emailOptions" [(ngModel)]="recoveryClass.recoveryData.email"
          name="username" (input)="checkIfCompletedAndValidFormat()" (change)="checkIfIsValidEmailFormat()">
        </customInput>
      </div>
      <btn class="loginButton" [ngClass]="{'disabled': !recoveryClass.isDataCompleted || !recoveryClass.isValidFormat}">
        <ng-container>
          <span>Enviar email</span>
        </ng-container>
      </btn>
      <a class="recoveryPassword" routerLink="/login" routerAnimationTop>Ya he recordado la contraseña</a>
    </form>
    <div class="footer">
      <img src="/assets/Images/Logos/logoQuasarDashboard.png" />
      <p>2023 Copyright © Atlas Venture Builder S.L. Valencia, España</p>
    </div>
  </div>
  <div class="right"></div>
</div>