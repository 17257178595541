import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss'],
})
export class PreviewDocumentComponent implements OnInit, OnDestroy {
  url: string = sessionStorage.getItem('embeddedUrl')!;
  constructor() {}

  ngOnDestroy(): void {
    sessionStorage.removeItem('embeddedUrl');
  }

  ngOnInit(): void {}
}
