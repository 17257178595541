import { iDate } from '@quasar-dynamics/basic-designsystem';
import { iSprint } from '../../Interfaces/Api/Sprint/iSprint';

export class Weekly {
  weekNumber: number = 0;
  actualNumberWeek: number = 0;
  companyHours: number = 0;
  year: number = new Date().getFullYear();
  actualYear: number = new Date().getFullYear();
  mondayWeek: string = '';
  sprintId: number = 0;
  sprint: iSprint = iSprint.createEmptyObject();
  weekly: any = null;
  process: Array<any> = [];
  projectsWeekly: Array<any> = [];
  selectedProjects: Array<any> = [];
  notSelectedProjects: Array<any> = [];
  hours: Array<any> = [];
  colors: Array<any> = [];
  totalHours: number = 0;
  selectedProject: any = null;
  employees: Array<any> = [];
  updateData: boolean = false;
  isValidated: boolean = false;
  tasksList: Array<any> = [];
  chartOptions: any = {
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    series: [],
    colors: [],
    chart: {
      type: 'pie',
      width: 380,
    },
    dataLabels: {
      style: {
        colors: ['#000000'],
      },
      dropShadow: {
        enabled: false,
      },
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    legend: {
      show: false,
    },
  };
  /**
   * FUNCTIONALITIES
   */
  getNumberOfWeek(date) {
    let startDate = new Date(date.getFullYear(), 0, 1);
    var days = Math.floor(
      (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    var weekNumber = Math.ceil((days + startDate.getDay() + 1) / 7);
    return weekNumber;
  }
  getDateOfWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return new iDate(iDate.toPhp(ISOweekStart)).toStringDate('JAP');
  }
}
