import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iInvoiceStatistic } from 'src/app/Shared/Interfaces/Api/Bill/iInvoiceStatistic';
import { iInvoiceTable } from 'src/app/Shared/Interfaces/Api/Bill/iInvoiceTable';
import { iInvoicesFilter } from 'src/app/Shared/Interfaces/Api/Bill/iInvoicesFilter';
import { iPaymentMilestonesFilter } from 'src/app/Shared/Interfaces/Api/Bill/iPaymentMilestonesFilter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }

  getAll(
    behaviorSubject: BehaviorSubject<any>,
    invoicesFilter: iInvoicesFilter
  ) {
    this.http
      .get(
        environment.urlApi +
          '/api/facturas/tabla' +
          this.optionsGet(invoicesFilter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              data: iInvoiceTable.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
              total: data.total,
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, invoicesFilter],
          });
        },
      });
  }
  getAllByCompany(
    behaviorSubject: BehaviorSubject<any>,
    filter: iPaymentMilestonesFilter
  ) {
    this.http
      .get(
        environment.urlApi + '/api/facturas' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              total: data.total,
              data: iStaticUtilities.normalizeNamesCollection(data.data),
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAllByCompany,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  getStatistics(behaviorSubject: BehaviorSubject<any>) {
    this.http
      .get(environment.urlApi + '/api/finanzas', this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: iInvoiceStatistic.convertFromObject(data),
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getStatistics,
            args: [behaviorSubject],
          });
        },
      });
  }
  update(
    behaviorSubject: BehaviorSubject<any>,
    data: any,
    customMessage?: string
  ) {
    this.http
      .put(
        environment.urlApi + '/api/facturas/' + data.id,
        data,
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: customMessage ?? 'Se ha pagado la factura correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  create(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .post(environment.urlApi + '/api/facturas', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: 'Se ha creado el hito de cobro correctamente',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(environment.urlApi + '/api/facturas/' + id, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject, id],
          });
        },
      });
  }
}
