import { iSimpleEmployee } from '../Employee/iSimpleEmployee';
import { iUserSelector } from './iUserSelector';

export interface iUserThatNotHasSendDaily {
  id: number;
  nombre: string;
}

export class iUserThatNotHasSendDaily {
  static convertFromCollection(ob: any): Array<iUserThatNotHasSendDaily> {
    let iUserThatNotHasSendDailyCollection: Array<iUserThatNotHasSendDaily> =
      [];
    ob.forEach((element: any) => {
      iUserThatNotHasSendDailyCollection.push(this.convertFromObject(element));
    });
    return iUserThatNotHasSendDailyCollection;
  }

  static convertFromObject(ob: any): iUserThatNotHasSendDaily {
    let iUserThatNotHasSendDailyObj = new iUserThatNotHasSendDaily();

    iUserThatNotHasSendDailyObj.id = ob.id;
    iUserThatNotHasSendDailyObj.nombre =
      ob.usuario.nombre + ' ' + ob.usuario.apellidos;
    return iUserThatNotHasSendDailyObj;
  }
}
