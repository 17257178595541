<table mat-table [dataSource]="data">
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <mat-checkbox (change)="addToSelected(element)"></mat-checkbox>
      </div>

    </td>
  </ng-container>
  <ng-container matColumnDef="nombre">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Nombre</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span class="bold">
          {{element.nombre ?? "-"}}
        </span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="cif">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>CIF</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span *ngIf="element.cif != ''">
          {{element.cif ?? "-"}}
        </span>
        <span *ngIf="element.cif == ''">
          -
        </span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="direccion">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Dirección</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data direccion">
        <span [title]="element.direccionFiscal"
          *ngIf="element.direccionFiscal != null && element.direccionFiscal != ''">
          {{element.direccionFiscal}}
        </span>
        <span [title]="'-'" *ngIf="element.direccionFiscal == null || element.direccionFiscal == ''">
          -
        </span>

      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="telefono">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Teléfono</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span *ngIf="element.telefono != '' && element.telefono != null">
          {{element.telefono}}
        </span>
        <span *ngIf="element.telefono == '' || element.telefono == null">
          -
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Email</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span>{{element.email ?? "-"}}</span>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="proyectos">
    <th mat-header-cell *matHeaderCellDef>
      <div class="headerContainer">
        <label>Proyectos</label>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="data">
        <span>{{element.proyectos ?? "-"}}</span>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns ">
  </tr>
</table>