import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iOptionsCustomInput,
  iOptionsSelector,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { filter, takeUntil } from 'rxjs';
import { CategoryService } from 'src/app/Services/Api/category.service';
import { DailyService } from 'src/app/Services/Api/daily.service';
import { NotValidatedDailies } from 'src/app/Shared/Class/Daily/not-validated-dailies';
import { iNotValidatedDaily } from 'src/app/Shared/Interfaces/Api/Daily/iNotValidatedDaily';

@Component({
  selector: 'app-daily-reminder-popup',
  templateUrl: './daily-reminder-popup.component.html',
  styleUrls: ['./daily-reminder-popup.component.scss'],
})
export class DailyReminderPopupComponent implements OnInit {
  notValidatedDailiesClass: NotValidatedDailies = new NotValidatedDailies();
  duplicateAnotherDay: boolean = false;
  date: string = '';
  constructor(
    public dialogRef: MatDialogRef<DailyReminderPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private categorySE: CategoryService,
    private dailySE: DailyService
  ) {}

  ngOnInit(): void {
    this.getTimeOfDay();
    this.getAllDailyCategories();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  getTimeOfDay() {
    const currentHour = new Date().getHours();

    if (currentHour >= 6 && currentHour < 12) {
      this.notValidatedDailiesClass.dayTime = 'Buenos días';
    } else if (currentHour >= 12 && currentHour < 18) {
      this.notValidatedDailiesClass.dayTime = 'Buenas tardes';
    } else {
      this.notValidatedDailiesClass.dayTime = 'Buenas noches';
    }
  }
  nextValue() {
    if (this.notValidatedDailiesClass.end < this.modalData.length) {
      this.notValidatedDailiesClass.end++;
      this.notValidatedDailiesClass.start++;
    }
  }
  previousValue() {
    if (this.notValidatedDailiesClass.start > 0) {
      this.notValidatedDailiesClass.end--;
      this.notValidatedDailiesClass.start--;
    }
  }
  removeDailyFromArray(event: iNotValidatedDaily) {
    this.modalData.splice(this.modalData.indexOf(event), 1);
    if (this.modalData.length === 0) {
      this.forceClosePopup();
    }
  }
  checkIfIsValidPercentaje() {
    if (this.notValidatedDailiesClass.failedDailyObj.porcentaje! > 100) {
      this.notValidatedDailiesClass.failedDailyObj.porcentaje = 100;

      StaticUtilitiesService.showError(
        'El porcentaje de progreso no puede superar el 100%'
      );
      return;
    }
  }
  /**
   * HANDLERS
   */
  successGetAllDailyCategories(res) {
    this.notValidatedDailiesClass.categoryOptions.items = [...res.data];
  }
  successUpdateDailyResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback('Daily enviado correctamente');
    this.notValidatedDailiesClass.isLoading = false;
    this.notValidatedDailiesClass.showReasons = false;
    if (this.duplicateAnotherDay) {
      this.duplicate(this.notValidatedDailiesClass.selectedDaily.id!);
    }
    this.removeDailyFromArray(this.notValidatedDailiesClass.selectedDaily);
  }
  errorUpdateDailyResponseHandler(res: iResultHttp) {
    this.notValidatedDailiesClass.isLoading = false;
  }

  /**
   * GETTERS & SETTERS
   */
  getDate(event: Date) {
    this.date = new iDate(iDate.toPhp(event)).toStringDate('JAP');
  }
  /**
   * CALLS TO API
   */
  getAllDailyCategories() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.categorySE.getAllDailyCategories(behaviorSubject);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successGetAllDailyCategories(res),
        error: false,
      });
    });
  }
  updateDaily() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.notValidatedDailiesClass.isLoading = true;
    this.dailySE.update(
      behaviorSubject,
      this.notValidatedDailiesClass.failedDailyObj
    );
    behaviorSubject
      .pipe(
        takeUntil(subject),
        filter((res) => res)
      )
      .subscribe((res: iResultHttp) => {
        StaticUtilitiesService.apiResponseHandler(res, subject, [
          {
            method: () => this.successUpdateDailyResponseHandler(res),
            error: false,
          },
          {
            method: () => this.errorUpdateDailyResponseHandler(res),
            error: true,
          },
        ]);
      });
  }
  duplicate(id: number) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dailySE.duplicate(behaviorSubject, { id: id, fecha: this.date });
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject);
    });
  }
}
