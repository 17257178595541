export interface iVacationPeriod {
  id?: number | null;
  dias: number | null;
  nombre: string;
  tipo: 'Natural' | 'Hábil' | null;
}

export class iVacationPeriod {
  static convertFromCollection(ob: any): Array<iVacationPeriod> {
    let iVacationPeriodCollection: Array<iVacationPeriod> = [];
    ob.forEach((element: any) => {
      iVacationPeriodCollection.push(this.convertFromObject(element));
    });
    return iVacationPeriodCollection;
  }

  static convertFromObject(ob: any): iVacationPeriod {
    let iVacationPeriodObj = new iVacationPeriod();
    iVacationPeriodObj.id = ob.id;
    iVacationPeriodObj.nombre = ob.nombre;
    iVacationPeriodObj.dias = ob.dias;
    iVacationPeriodObj.tipo = ob.tipo;
    return iVacationPeriodObj;
  }

  static createEmptyObject(): iVacationPeriod {
    let iVacationPeriodObj = new iVacationPeriod();
    iVacationPeriodObj.id = null;
    iVacationPeriodObj.nombre = '';
    iVacationPeriodObj.dias = null;
    iVacationPeriodObj.tipo = null;
    return iVacationPeriodObj;
  }
}
