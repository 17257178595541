import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  StaticUtilitiesService,
  iDate,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { CategoryService } from 'src/app/Services/Api/category.service';
import { DailyService } from 'src/app/Services/Api/daily.service';
import { CompleteDaily } from 'src/app/Shared/Class/Implications/complete-daily';

@Component({
  selector: 'app-complete-daily-popup',
  templateUrl: './complete-daily-popup.component.html',
  styleUrls: ['./complete-daily-popup.component.scss'],
})
export class CompleteDailyPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  completeDailyClass = new CompleteDaily();
  duplicateAnotherDay: boolean = false;
  date: string = '';
  constructor(
    public dialogRef: MatDialogRef<CompleteDailyPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private categorySE: CategoryService,
    private dailySE: DailyService
  ) {}

  ngOnInit(): void {
    this.getAllDailyCategories();
    this.modalData.porcentaje === 100
      ? (this.completeDailyClass.optionSubmenuSelected = 1)
      : (this.completeDailyClass.optionSubmenuSelected = 0);
    this.completeDailyClass.backupSelectedDaily = this.modalData;
    this.completeDailyClass.backupSelectedDaily = { ...this.modalData };
    this.completeDailyClass.backupPercentaje =
      this.completeDailyClass.backupSelectedDaily.porcentaje!;
    this.clickOutClosePopupHandler();
  }
  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }

  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }

  /**
   * GETTERS & SETTERS
   */
  getDate(event: Date) {
    this.date = new iDate(iDate.toPhp(event)).toStringDate('JAP');
  }

  /**
   * CALLS TO API
   */

  successGetAllDailyCategories(res) {
    this.completeDailyClass.categoriesOption.items = [...res.data];
  }
  successCompleteDailyResponseHandler(res) {
    StaticUtilitiesService.showFeedback(res.message);
    this.closePopup();
    if (this.duplicateAnotherDay) {
      this.duplicate(this.modalData.id);
    }
  }
  /**
   * CALLS TO API
   */
  getAllDailyCategories() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.categorySE.getAllDailyCategories(behaviorSubject);
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: () => this.successGetAllDailyCategories(res),
        error: false,
      });
    });
  }
  completeDaily() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dailySE.update(
      behaviorSubject,
      this.completeDailyClass.generateUpdateDailyObject()
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, {
        method: this.successCompleteDailyResponseHandler(res),
        error: false,
      });
    });
  }
  duplicate(id: number) {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.dailySE.duplicate(behaviorSubject, { id: id, fecha: this.date });
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject);
    });
  }
}
