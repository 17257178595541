import {
  iOptionsCustomInput,
  iOptionsSelector,
} from '@quasar-dynamics/basic-designsystem';
import { iCreateEditFuturible } from '../../Interfaces/Api/Futurible/iCreateEditFuturible';

export class CreateEditFuturible {
  isCompleted: boolean = false;
  isLoading: boolean = false;
  optionSelected: number = 0;
  costType: Array<string> = ['Directo', 'Indirecto'];
  createEditObj: iCreateEditFuturible = {
    nombre: '',
    costeHora: null,
    costeAnual: null,
    horasSemanales: null,
    tipoCoste: 'Directo',
    categoria: [],
  };
  backUpCreateEditObj: iCreateEditFuturible = {
    nombre: '',
    costeHora: null,
    costeAnual: null,
    horasSemanales: null,
    tipoCoste: 'Directo',
    categoria: [],
  };

  /**
   * OPTIONS
   */
  nameOptions: iOptionsCustomInput = {
    label: 'Nombre',
    type: 'text',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  hourCostOptions: iOptionsCustomInput = {
    label: 'Coste hora (€)',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  anualCostOptions: iOptionsCustomInput = {
    label: 'Coste anual (€)',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  weekHoursOptions: iOptionsCustomInput = {
    label: 'Horas semanales',
    type: 'number',
    placeholder: 'Escribe aquí...',
    classList: 'specialFormInput',
    required: true,
  };
  categoryOptions: iOptionsSelector = {
    items: [],
    placeholder: 'Selecciona una opción...',
    clearable: true,
    append: true,
    multiple: {
      isMultiple: true,
    },
    bindLabel: 'nombre',
    bindValue: 'id',
    classList: 'specialFormSelector',
    search: true,
  };
}
