import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupService } from '@quasar-dynamics/basic-designsystem';
import { iInvoiceTable } from 'src/app/Shared/Interfaces/Api/Bill/iInvoiceTable';

@Component({
  selector: 'app-invoice-details-popup',
  templateUrl: './invoice-details-popup.component.html',
  styleUrls: ['./invoice-details-popup.component.scss'],
})
export class InvoiceDetailsPopupComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.forceClosePopup();
    }
  }
  constructor(
    public dialogRef: MatDialogRef<InvoiceDetailsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: iInvoiceTable
  ) {}

  ngOnInit(): void {
    this.clickOutClosePopupHandler();
  }

  /**
   * FUNCTIONALITY
   */
  closePopup(returnValue?: any) {
    let p = { returnValue: { returnValue }, activeAction: true };
    this.dialogRef.close(p);
  }
  forceClosePopup() {
    let p = { returnValue: null, activeAction: false };
    this.dialogRef.close(p);
  }
  /**
   * HANDLERS
   */
  clickOutClosePopupHandler() {
    document.addEventListener('click', (e: Event) => {
      const targetElement = e.target as HTMLElement;
      if (
        targetElement.classList &&
        targetElement.classList.contains('cdk-overlay-backdrop')
      ) {
        const elementClasses = Array.from(targetElement.classList);
        for (const className of elementClasses) {
          if (className.includes('datepicker')) {
            return;
          }
        }
        this.forceClosePopup();
      }
    });
  }
}
