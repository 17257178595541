<div class="popupGeneralContainer quickEdit">
  <div class="header">
    <p *ngIf="modalData.edit">Editar un bono de horas</p>
    <p *ngIf="!modalData.edit">Crear un bono de horas</p>
  </div>
  <div class="body">
    <div class="formComponents">
      <div class="columnDisplay">
        <customInput [options]="bonusHoursClass.codeOptions" [(ngModel)]="bonusHoursClass.createEditObj.codigo"
          (change)="checkIfDataIsCompleted(); this.updateDataHandler('codigo', bonusHoursClass.createEditObj.codigo)">
        </customInput>
        <customInput [options]="bonusHoursClass.nameOptions" [(ngModel)]="bonusHoursClass.createEditObj.nombre"
          (change)="checkIfDataIsCompleted(); this.updateDataHandler('nombre', bonusHoursClass.createEditObj.nombre)">
        </customInput>
      </div>
      <div class="columnDisplay">
        <customInput [options]="bonusHoursClass.hoursOptions" [(ngModel)]="bonusHoursClass.createEditObj.horas"
          (change)="checkIfDataIsCompleted(); this.updateDataHandler('horas', bonusHoursClass.createEditObj.horas)">
        </customInput>
        <customInput [options]="bonusHoursClass.priceOptions" [(ngModel)]="bonusHoursClass.createEditObj.precio"
          (change)="checkIfDataIsCompleted(); this.updateDataHandler('precio', bonusHoursClass.createEditObj.precio)">
        </customInput>
      </div>
      <customTextArea [options]="bonusHoursClass.descriptionOptions"
        [(ngModel)]="bonusHoursClass.createEditObj.descripcion"
        (change)="checkIfDataIsCompleted(); this.updateDataHandler('descripcion', bonusHoursClass.createEditObj.descripcion)">
      </customTextArea>
    </div>
  </div>
  <div class="footer">
    <btn class="secondary" (click)="this.forceClosePopup()">
      <ng-container>
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </ng-container>
    </btn>
    <btn class="action" [ngClass]="{'disabled': !bonusHoursClass.isDataCompleted && !modalData.edit}"
      (click)="actionHandler()">
      <ng-container>
        <mat-icon *ngIf="!bonusHoursClass.isLoading">check</mat-icon>
        <mat-spinner *ngIf="bonusHoursClass.isLoading"></mat-spinner>
        <span>Finalizar</span>
      </ng-container>
    </btn>
  </div>
</div>