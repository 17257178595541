export interface iClientSelector {
  id: number;
  nombre: string;
  direccionFiscal: string;
  telefono: string;
  mail: string;
}

export class iClientSelector {
  static convertFromCollection(ob: any): Array<iClientSelector> {
    let iClientSelectorCollection: Array<iClientSelector> = [];
    ob.forEach((element: any) => {
      iClientSelectorCollection.push(this.convertFromObject(element));
    });
    return iClientSelectorCollection;
  }

  static convertFromObject(ob: any): iClientSelector {
    let iClientSelectorObj = new iClientSelector();

    iClientSelectorObj.id = ob.id;
    iClientSelectorObj.nombre = ob.nombre;
    iClientSelectorObj.direccionFiscal = ob.direccionFiscal;
    iClientSelectorObj.telefono = ob.telefono;
    iClientSelectorObj.mail = ob.mail;
    return iClientSelectorObj;
  }
}
