import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DefaultBaseService,
  iResultHttp,
  iStaticUtilities,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';
import { iCreateEditFuturible } from 'src/app/Shared/Interfaces/Api/Futurible/iCreateEditFuturible';
import { iFuturibleFilter } from 'src/app/Shared/Interfaces/Api/Futurible/iFuturibleFilter';
import { iFuturibleList } from 'src/app/Shared/Interfaces/Api/Futurible/iFuturibleList';

@Injectable({
  providedIn: 'root',
})
export class FuturibleService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }
  getAll(behaviorSubject: BehaviorSubject<any>, filter: iFuturibleFilter) {
    this.http
      .get(
        this.urlApi + '/api/futurible' + this.optionsGet(filter),
        this.getHeader()
      )
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: '',
            data: {
              data: iFuturibleList.convertFromCollection(
                iStaticUtilities.normalizeNamesCollection(data.data)
              ),
              total: data.total,
            },
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.getAll,
            args: [behaviorSubject, filter],
          });
        },
      });
  }
  create(behaviorSubject: BehaviorSubject<any>, data: iCreateEditFuturible) {
    this.http
      .post(this.urlApi + '/api/futurible', data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.create,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  update(behaviorSubject: BehaviorSubject<any>, data: any) {
    this.http
      .put(this.urlApi + '/api/futurible/' + data.id, data, this.getHeader())
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.update,
            args: [behaviorSubject, data],
          });
        },
      });
  }
  delete(behaviorSubject: BehaviorSubject<any>, id: number) {
    this.http
      .delete(this.urlApi + '/api/futurible', this.sendBodyOptions({ id: id }))
      .subscribe({
        next: (data: any) => {
          this.res = new iResultHttp({
            status: 200,
            ok: true,
            message: data.message,
            data: null,
          });
          this.sendNextObservable(behaviorSubject, this.res);
        },
        error: (error: any) => {
          this.res = new iResultHttp({
            status: error.status,
            ok: false,
            message: error.error.message ? error.error.message : '',
          });
          this.sendNextObservable(behaviorSubject, this.res, true, {
            method: this.delete,
            args: [behaviorSubject],
          });
        },
      });
  }
}
