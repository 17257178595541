import { Component, OnInit } from '@angular/core';
import {
  StaticUtilitiesService,
  iResultHttp,
  sha256,
  slideNavigationTypes,
} from '@quasar-dynamics/basic-designsystem';
import { takeUntil } from 'rxjs';
import { RecoveryService } from 'src/app/Services/Api/recovery.service';
import { Recovery } from 'src/app/Shared/Class/recovery';
import { FennechLoaderComponent } from 'src/app/Shared/Components/fennech-loader/fennech-loader.component';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
})
export class RecoveryPasswordComponent implements OnInit {
  recoveryClass: Recovery = new Recovery();
  constructor(
    private recoverySE: RecoveryService,
    private staticUtilitiesSE: StaticUtilitiesService
  ) {}

  ngOnInit(): void {
    this.recoveryClass.newPassData.recover_token = this.staticUtilitiesSE
      .getParams()
      .token.replaceAll(/ /g, '+');
  }
  /**
   * VALIDATE DATA
   */

  checkIfCompletedAndValidFormat() {
    this.recoveryClass.isDataCompleted =
      StaticUtilitiesService.isCompleteObject(this.recoveryClass.newPassData);
  }

  /**
   * FUNCTIONALITIES
   */
  generateNewPassObj() {
    return {
      new_password: sha256(this.recoveryClass.newPassData.new_password)!,
      recover_token: this.recoveryClass.newPassData.recover_token,
    };
  }

  /**
   * API RESPONSES
   */
  successRecoveryResponseHandler(res: iResultHttp) {
    StaticUtilitiesService.showFeedback(String(res.message));
    this.staticUtilitiesSE.closeLoader();
    this.staticUtilitiesSE.goTo('login', slideNavigationTypes.slideToBottom);
  }
  errorRecoveryResponseHandler() {
    this.staticUtilitiesSE.closeLoader();
  }
  /**
   * CALLS TO API
   */
  recoveryPassword() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.staticUtilitiesSE.openLoader(FennechLoaderComponent);
    this.recoverySE.recoveryPassword(
      behaviorSubject,
      this.generateNewPassObj()
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.successRecoveryResponseHandler(res),
          error: false,
        },
        {
          method: () => this.errorRecoveryResponseHandler(),
          error: true,
        },
      ]);
    });
  }
}
