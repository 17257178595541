export interface iInvoiceStatistic {
  anterior: number;
  facturado: number;
  negocioGenerado: number;
  meses: Array<{
    pagado: number;
    pendiente: number;
    estimado: number;
    potencial: number;
  }>;
}

export class iInvoiceStatistic {
  static convertFromCollection(ob: any): Array<iInvoiceStatistic> {
    let iInvoiceStatisticCollection: Array<iInvoiceStatistic> = [];
    ob.forEach((element: any) => {
      iInvoiceStatisticCollection.push(this.convertFromObject(element));
    });
    return iInvoiceStatisticCollection;
  }

  static convertFromObject(ob: any): iInvoiceStatistic {
    let iInvoiceStatisticObj = new iInvoiceStatistic();
    iInvoiceStatisticObj.anterior = ob.anterior;
    iInvoiceStatisticObj.facturado = ob.facturado;
    iInvoiceStatisticObj.negocioGenerado = ob.negocioGenerado;
    iInvoiceStatisticObj.meses = ob.meses;
    return iInvoiceStatisticObj;
  }

  static createEmptyObject(): iInvoiceStatistic {
    let iInvoiceStatisticObj = new iInvoiceStatistic();
    iInvoiceStatisticObj.anterior = 0;
    iInvoiceStatisticObj.facturado = 0;
    iInvoiceStatisticObj.negocioGenerado = 0;
    iInvoiceStatisticObj.meses = [];
    return iInvoiceStatisticObj;
  }
}
